import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  contactus: { list: [], totalRecords: { count: 0 } },
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_CONTACTUS_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_CONTACTUS_SUCCESS: {
      const { contactus } = payload;
      return { ...state, contactus, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_CONTACTUS_FAIL:
      return {
        ...state,
        contactus: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.EDIT_MARK_AS_RESOLVED_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_MARK_AS_RESOLVED_SUCCESS: {
      const { contactus } = state;
      const index = contactus.list.findIndex(item => item._id === payload.id);
      if (index !== -1 && contactus.list.length > index) {
        contactus.list[index].isResolved = !contactus.list[index].isResolved;
      }
      return { ...state, contactus, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_MARK_AS_RESOLVED_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_CONTACTUS_LIST: {
      return {
        ...state,
        contactus: { list: [], totalRecords: { count: 0 } },
      };
    }
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }
    default:
      return state;
  }
};
