import { put } from 'redux-saga/effects';
import Axios from 'axios';
import {
  getAllChefSaga as getAllChefSagaAction,
  getAllChefStart,
  getAllChefSuccess,
  getAllChefFail,
  getChefByIdStart,
  getChefByIdSuccess,
  getChefByIdFail,
  editBlockChefStatusStart,
  editBlockChefStatusSuccess,
  editBlockChefStatusFail,
  addBankDetailStart,
  addBankDetailSuccess,
  addBankDetailFail,
  addAddressStart,
  addAddressSuccess,
  addAddressFail,
  getAcceptingOrderSaga as getAcceptingOrderSagaAction,
  getAcceptingOrderStart,
  getAcceptingOrderSuccess,
  getAcceptingOrderFail,
  editAcceptingOrderChefStart,
  editAcceptingOrderChefSuccess,
  editAcceptingOrderChefFail,
  editLocationStart,
  editLocationSuccess,
  editLocationFail,
  logout,
  chefApproveRejectSaga as chefApproveRejectSagaAction,
  chefApproveRejectStart,
  chefApproveRejectSuccess,
  chefApproveRejectFail,
  editChefCommissionStart,
  editChefCommissionSuccess,
  editChefCommissionFail,
  getChefProfileStart,
  getChefProfileSuccess,
  getLatLongFromAddressStart,
  getLatLongFromAddressSuccess,
  getChefProfileFail,
  editPersonalDetailStart,
  editPersonalDetailSuccess,
  editPersonalDetailFail,
  editOrderScheduleStart,
  editOrderScheduleSuccess,
  editOrderScheduleFail,
  editRestaurantDetailStart,
  editRestaurantDetailSuccess,
  editRestaurantDetailFail,
  editMediaStart,
  editMediaSuccess,
  editMediaFail,
  deleteMediaStart,
  deleteMediaSuccess,
  deleteMediaFail,
  editIdproofStart,
  editIdproofSuccess,
  editIdproofFail,
  deleteIdproofStart,
  deleteIdproofSuccess,
  deleteIdproofFail,
  showModal,
  editChefFeatureStart,
  editChefFeatureSuccess,
  editChefFeatureFail,
  hideModal,
  editProfileSubmitStart,
  editProfileSubmitSuccess,
  editProfileSubmitFail,
  getAllBrandTypesStart,
  getAllBrandTypesSuccess,
  getAllBrandTypesFail,
  getChefBrandTypesStart,
  getChefBrandTypesSuccess,
  getChefBrandTypesFail,
  enableDisableOtherVendorStart,
  enableDisableOtherVendorSuccess,
  enableDisableOtherVendorFail,
  editBankAccountStart,
  editBankAccountSuccess,
  editBankAccountFail,
  editBankAccountStatusStart,
  editBankAccountStatusSuccess,
  editBankAccountStatusFail,
  deleteBankAccountStart,
  deleteBankAccountSuccess,
  deleteBankAccountFail,
  assignToLogisticStart,
  assignToLogisticSuccess,
  assignToLogisticFail,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';


export function* getAllChefSaga(action) {
  yield put(getAllChefStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllChefList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllChefSuccess({ chefs: response.data.data }));
    } else {
      yield put(getAllChefFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllChefFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllChefFail(error.response.data.message));
      } else {
        yield put(getAllChefFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllChefFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getAllBrandTypesSaga(action) {
  yield put(getAllBrandTypesStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/chef/getAllBrandTypes`, action.payload),
    );
    // const response = yield axios.get(`/chef/getAllBrandTypes`);
    if (response.status === 200) {
      yield put(
        getAllBrandTypesSuccess({ brandTypes: response.data.data.list }),
      );
    } else {
      yield put(
        getAllBrandTypesFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllBrandTypesFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllBrandTypesFail(error.response.data.message));
      } else {
        yield put(getAllBrandTypesFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllBrandTypesFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getChefByIdSaga(action) {
  yield put(getChefByIdStart());
  try {
    const response = yield axios.get(
      `/admin/getChefDetailById?id=${action.payload.id}`,
    );
    if (response.status === 200) {
      yield put(getChefByIdSuccess({ chef: response.data.data }));
    } else {
      yield put(getChefByIdFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getChefByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getChefByIdFail(error.response.data.message));
      } else {
        yield put(getChefByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(getChefByIdFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editBlockChefStatusSaga(action) {
  yield put(editBlockChefStatusStart());
  try {
    const response = yield axios.post(
      `/admin/blockUnBlockChef`,
      // `/admin/updateVehicleTypeStatus`,
      action.payload,
    );
    if (response.status === 200) {
      yield put(editBlockChefStatusSuccess(action.payload));
    } else {
      yield put(
        editBlockChefStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBlockChefStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBlockChefStatusFail(error.response.data.message));
      } else {
        yield put(editBlockChefStatusFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editBlockChefStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* editChefFeatureSaga(action) {
  yield put(editChefFeatureStart());
  try {
    const response = yield axios.post(
      `/admin/featureUnfeatureChef`,
      action.payload,
    );
    if (response.status === 200) {
      yield put(editChefFeatureSuccess(action.payload));
    } else {
      yield put(editChefFeatureFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editChefFeatureFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editChefFeatureFail(error.response.data.message));
      } else {
        yield put(editChefFeatureFail('Server error! Please try again.'));
      }
    } else {
      yield put(editChefFeatureFail('Something went wrong! Please try again.'));
    }
  }
}

export function* addBankDetailSaga(action) {
  yield put(addBankDetailStart());
  const { setIsDisable, isApproved, setPageName, closeModel } = action.payload;
  const url = isApproved
    ? '/chef/AddMoreBankDetails'
    : '/chef/updateBankDetails';
  try {
    const response = yield axios.post(url, action.payload.values);
    if (response.status === 200) {
      if (isApproved) {
        yield put(addBankDetailSuccess(response.data.data));
      } else {
        yield put(addBankDetailSuccess(action.payload));
      }
      if (isApproved) {
        yield put(
          showModal({
            open: true,
            notifyType: 2,
            message: response.data.message || 'Success',
          }),
        );
      }
      setIsDisable(false);
      if (closeModel) {
        closeModel();
      }
      if (!isApproved) {
        setPageName('Order');
      }
    } else {
      yield put(addBankDetailFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addBankDetailFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addBankDetailFail(error.response.data.message));
      } else {
        yield put(addBankDetailFail('Server error! Please try again.'));
      }
    } else {
      yield put(addBankDetailFail('Something went wrong! Please try again.'));
    }
  }
}
export function* editBankAccountSaga(action) {
  yield put(editBankAccountStart());
  const { setIsDisable, values, closeModel } = action.payload;
  const url = '/chef/editBankAccountById';
  try {
    const response = yield axios.post(url, values);
    if (response.status === 200) {
      yield put(editBankAccountSuccess(values));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );

      setIsDisable(false);

      if (closeModel) {
        closeModel();
      }
    } else {
      yield put(editBankAccountFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBankAccountFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBankAccountFail(error.response.data.message));
      } else {
        yield put(editBankAccountFail('Server error! Please try again.'));
      }
    } else {
      yield put(editBankAccountFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editBankAccountStatusSaga(action) {
  yield put(editBankAccountStatusStart());
  const url = 'chef/setAccountActive';
  try {
    const response = yield axios.post(url, action.payload);
    if (response.status === 200) {
      yield put(editBankAccountStatusSuccess(action.payload));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: `Default Account Changed Successfully` || 'Success',
        }),
      );
    } else {
      yield put(
        editBankAccountStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBankAccountStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBankAccountStatusFail(error.response.data.message));
      } else {
        yield put(editBankAccountStatusFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editBankAccountStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* deleteBankAccountSaga(action) {
  yield put(deleteBankAccountStart());
  const { id, setModelDeleteOpen } = action.payload;
  const url = '/chef/deteteBankAccountDetailById';
  try {
    const response = yield axios.post(url, { id });
    if (response.status === 200) {
      yield put(deleteBankAccountSuccess(id));

      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );

      setModelDeleteOpen(false);
    } else {
      yield put(
        deleteBankAccountFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(deleteBankAccountFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteBankAccountFail(error.response.data.message));
      } else {
        yield put(deleteBankAccountFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        deleteBankAccountFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* addAddressSaga(action) {
  yield put(addAddressStart());
  try {
    const response = yield axios.post(`/chef/updateAddress`, action.payload);
    if (response.status === 200) {
      yield put(addAddressSuccess(action.payload));
    } else {
      yield put(addAddressFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addAddressFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addAddressFail(error.response.data.message));
      } else {
        yield put(addAddressFail('Server error! Please try again.'));
      }
    } else {
      yield put(addAddressFail('Something went wrong! Please try again.'));
    }
  }
}

// eslint-disable-next-line no-unused-vars
export function* getAcceptingOrderSaga(action) {
  yield put(getAcceptingOrderStart());
  try {
    const response = yield axios.get(`/chef/getAllStatus`);
    if (response.status === 200) {
      yield put(getAcceptingOrderSuccess(response.data.data));
    } else {
      yield put(
        getAcceptingOrderFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 401) {
        yield put(logout());
      }
    }
  }
}

export function* editAcceptingOrderChefSaga(action) {
  yield put(editAcceptingOrderChefStart());
  try {
    const response = yield axios.post(
      `/chef/changeAcceptOrders`,
      action.payload,
    );
    if (!action.payload.isLogout) {
      if (response.status === 200) {
        yield put(editAcceptingOrderChefSuccess());
      } else {
        yield put(
          editAcceptingOrderChefFail('Something went wrong! Please try again.'),
        );
      }
    }
  } catch (error) {
    if (
      !action.payload.isLogout &&
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        // yield put(editAcceptingOrderFail(error.response.data.message));
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: error.response.data.message,
          }),
        );
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        // yield put(editAcceptingOrderFail(error.response.data.message));
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: error.response.data.message,
          }),
        );
      } else {
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: 'Server error! Please try again.',
          }),
        );
        // yield put(editAcceptingOrderFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        showModal({
          open: true,
          notifyType: 1,
          message: 'Something went wrong! Please try again.',
        }),
      );
      // yield put(
      //   editAcceptingOrderFail('Something went wrong! Please try again.'),
      // );
    }
  }
  if (action.payload.isLogout) {
    yield put(logout());
  }
}

export function* editLocationSaga(action) {
  yield put(editLocationStart());
  try {
    const response = yield axios.post(`/chef/updateLocation`, action.payload);
    if (response.status === 200) {
      yield put(editLocationSuccess());
    } else {
      yield put(editLocationFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editLocationFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editLocationFail(error.response.data.message));
      } else {
        yield put(editLocationFail('Server error! Please try again.'));
      }
    } else {
      yield put(editLocationFail('Something went wrong! Please try again.'));
    }
  }
}

// added promoterSubmitted because this saga runs from admin and promoter side
export function* chefApproveRejectSaga(action) {
  yield put(chefApproveRejectStart());
  try {
    const response = yield axios.post(
      // `/admin/approveOrRejectChef`,
       `promoter/approveRejectVehicle`,
      action.payload,
    );
    if (response.status === 200) {
      const { closeModel, promoterSubmitted } = action.payload;
      yield put(chefApproveRejectSuccess());

      const redirectURL = promoterSubmitted
        ? '/promoters/vendors'
        : '/vendors/chefs';
      const modalMessage = promoterSubmitted
        ? 'Vendor Profile is Verified & Sent To Administrator'
        : response.data.message;

      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL,
          message: modalMessage || 'Success',
        }),
      );
      if (closeModel) {
        closeModel();
      }
    } else {
      yield put(
        chefApproveRejectFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(chefApproveRejectFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(chefApproveRejectFail(error.response.data.message));
      } else {
        yield put(chefApproveRejectFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        chefApproveRejectFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* editChefCommissionSaga(action) {
  yield put(editChefCommissionStart());
  const { setIsSubmitted } = action.payload;
  try {
    const response = yield axios.post(
      `/admin/updateChefCommission`,
      action.payload,
    );
    if (response.status === 200) {
      const { closeModel, tableFilter } = action.payload;
      yield put(editChefCommissionSuccess(action.payload));
      if (action.payload.isUpdated === true) {
        if (closeModel) {
          closeModel();
        }
        yield put(
          showModal({
            open: true,
            notifyType: 2,
            message: 'Commission updated successfully',
            // message: response.data.message || 'Success',
          }),
        );
        yield put(getAllChefSagaAction(tableFilter));
      } else {
        yield put(
          chefApproveRejectSagaAction({
            id: action.payload.id,
            isApproved: true,
            isRejected: false,
            closeModel,
          }),
        );
      }
    } else {
      yield put(
        editChefCommissionFail('Something went wrong! Please try again.'),
      );
      setIsSubmitted(false);
    }
  } catch (error) {
    setIsSubmitted(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editChefCommissionFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editChefCommissionFail(error.response.data.message));
      } else {
        yield put(editChefCommissionFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editChefCommissionFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// eslint-disable-next-line no-unused-vars
export function* getChefProfileSaga(action) {
  yield put(getChefProfileStart());
  try {
    const response = yield axios.get(`/chef/getChefProfile`);
    if (response.status === 200) {
      yield put(getChefProfileSuccess({ chef: response.data.data }));
    }
    else {
      yield put(getChefProfileFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getChefProfileFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getChefProfileFail(error.response.data.message));
      } else {
        yield put(getChefProfileFail('Server error! Please try again.'));
      }
    } else {
      yield put(getChefProfileFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getLatLongFromAddressSaga(action) {
  const WebURL = 'https://maps.googleapis.com/maps/api/geocode/json?';
  const APIKey = 'AIzaSyD6M4Qgp4QzRf1PMYCsOTpDuVYSm0GAFbU';
  yield put(getLatLongFromAddressStart());
  try {
    const response = yield Axios.get(
      `${WebURL}address=${encodeURIComponent(action.payload)}&key=${APIKey}`,
    );
    if (response.data.results.length !== 0) {
      const coordinate = {
        lat: response.data.results[0].geometry.location.lat,
        long: response.data.results[0].geometry.location.lng,
      };
      yield put(getLatLongFromAddressSuccess(coordinate));
    } else {
      const coordinate = {
        lat: '',
        long: '',
      };
      yield put(getLatLongFromAddressSuccess(coordinate));
    }
  } catch (e) {
    const coordinate = {
      lat: '',
      long: '',
    };
    yield put(getLatLongFromAddressSuccess(coordinate));
  }
}

export function* editPersonalDetailSaga(action) {
  yield put(editPersonalDetailStart());
  try {
    const { setIsDisable, setPageName, isApproved } = action.payload;
    const response = yield axios.post(
      `/chef/updatePersonalDetails`,
      action.payload.values,
    );
    if (response.status === 200) {
      yield put(editPersonalDetailSuccess(action.payload));
      if (isApproved) {
        yield put(
          showModal({
            open: true,
            notifyType: 2,
            message: response.data.message || 'Success',
          }),
        );
      }
      setIsDisable(false);
      if (!isApproved) {
        setPageName('Banking');
      }
    } else {
      yield put(
        editPersonalDetailFail('Something went wrong! Please try again.'),
      );
      setIsDisable(false);
    }
  } catch (error) {
    const { setIsDisable } = action.payload;
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editPersonalDetailFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editPersonalDetailFail(error.response.data.message));
      } else {
        yield put(editPersonalDetailFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editPersonalDetailFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* editOrderScheduleSaga(action) {
  const { setIsDisable, isApproved, setPageName } = action.payload;
  yield put(editOrderScheduleStart());
  try {
    const response = yield axios.post(
      `/chef/updateSchedule`,
      action.payload.data,
    );
    if (response.status === 200) {
      yield put(editOrderScheduleSuccess(action.payload.data));
      if (isApproved) {
        yield put(
          showModal({
            open: true,
            notifyType: 2,
            message: response.data.message || 'Success',
          }),
        );
      }
      setIsDisable(false);
      if (!isApproved) {
        // yield put(getAcceptingOrderSagaAction());
        setPageName('MenuItems');
      }
    } else {
      yield put(
        editOrderScheduleFail('Something went wrong! Please try again.'),
      );
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editOrderScheduleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editOrderScheduleFail(error.response.data.message));
      } else {
        yield put(editOrderScheduleFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editOrderScheduleFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* editRestaurantDetailSaga(action) {
  const { setIsDisable, setPageName, isApproved } = action.payload;
  yield put(editRestaurantDetailStart());
  try {
    const response = yield axios.post(
      `/chef/updateRestaurantDetails`,
      action.payload.data,
    );
    if (response.status === 200) {
      yield put(editRestaurantDetailSuccess(action.payload.data));
      if (isApproved) {
        yield put(
          showModal({
            open: true,
            notifyType: 2,
            message: response.data.message || 'Success',
          }),
        );
      }
      setIsDisable(false);
      if (!isApproved) {
        setPageName('Personal');
      }
    } else {
      yield put(
        editRestaurantDetailFail('Something went wrong! Please try again.'),
      );
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editRestaurantDetailFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editRestaurantDetailFail(error.response.data.message));
      } else {
        yield put(editRestaurantDetailFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editRestaurantDetailFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* editMediaSaga(action) {
  yield put(editMediaStart());
  try {
    const formdata = new FormData();
    formdata.append('isDefaultImage', action.payload.isDefaultImage);
    action.payload.media.forEach(item => formdata.append(`media`, item));

    const response = yield axios.post(`/chef/mediaUpload`, formdata);
    if (response.status === 200) {
      yield put(editMediaSuccess({ media: response.data.data }));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          // message: response.data.message || 'Success',
          message: "Vendor Media file uploaded successfully"
        }),
      );
    } else {
      yield put(editMediaFail('Something went wrong! Please try again.'));
      yield put(hideModal());
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield put(hideModal());
      if (error.response.status === 400) {
        yield put(editMediaFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editMediaFail(error.response.data.message));
      } else {
        yield put(editMediaFail('Server error! Please try again.'));
      }
    } else {
      yield put(editMediaFail('Something went wrong! Please try again.'));
    }
  }
}

export function* deleteMediaSaga(action) {
  yield put(deleteMediaStart());
  try {
    const response = yield axios.post(`/chef/deleteMedia`, action.payload);
    if (response.status === 200) {
      yield put(deleteMediaSuccess(action.payload));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(deleteMediaFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(deleteMediaFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteMediaFail(error.response.data.message));
      } else {
        yield put(deleteMediaFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteMediaFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editIdproofSaga(action) {
  yield put(editIdproofStart());
  try {
    const formdata = new FormData();
    action.payload.idProof.forEach(item => formdata.append(`idProof`, item));

    const response = yield axios.post(`/chef/documentUpload`, formdata);
    if (response.status === 200) {
      yield put(editIdproofSuccess({ idProof: response.data.data }));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          // message: response.data.message || 'Success',
          message: 'Legal Documents file uploaded successfully',
        }),
      );
    } else {
      yield put(editIdproofFail('Something went wrong! Please try again.'));
      yield put(hideModal());
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield put(hideModal());
      if (error.response.status === 400) {
        yield put(editIdproofFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editIdproofFail(error.response.data.message));
      } else {
        yield put(editIdproofFail('Server error! Please try again.'));
      }
    } else {
      yield put(editIdproofFail('Something went wrong! Please try again.'));
    }
  }
}

export function* deleteIdproofSaga(action) {
  yield put(deleteIdproofStart());
  try {
    const response = yield axios.post(`/chef/deleteDocument`, action.payload);
    if (response.status === 200) {
      yield put(deleteIdproofSuccess(action.payload));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(deleteIdproofFail('Something went wrong! Please try again.'));
      yield put(hideModal());
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield put(hideModal());
      if (error.response.status === 400) {
        yield put(deleteIdproofFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteIdproofFail(error.response.data.message));
      } else {
        yield put(deleteIdproofFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteIdproofFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editProfileSubmitSaga(action) {
  const { setIsDisable } = action.payload;
  yield put(editProfileSubmitStart());
  try {
    const response = yield axios.post(`/chef/submitProfile`);
    if (response.status === 200) {
      yield put(editProfileSubmitSuccess(action.payload));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      setIsDisable(false);
      yield put(getAcceptingOrderSagaAction());
    } else {
      yield put(
        editProfileSubmitFail('Something went wrong! Please try again.'),
      );
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editProfileSubmitFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editProfileSubmitFail(error.response.data.message));
      } else {
        yield put(editProfileSubmitFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editProfileSubmitFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Get Chef Brand Types
// eslint-disable-next-line no-unused-vars
export function* getChefBrandTypesSaga(action) {
  yield put(getChefBrandTypesStart());
  try {
    const response = yield axios.get(`chef/getAllBrandTypesByChefId`);
    // const response = yield axios.get(`/chef/getAllBrandTypes`);
    if (response.status === 200) {
      yield put(
        getChefBrandTypesSuccess({ chefBrandTypes: response.data.data }),
      );
    } else {
      yield put(
        getChefBrandTypesFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getChefBrandTypesFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getChefBrandTypesFail(error.response.data.message));
      } else {
        yield put(getChefBrandTypesFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getChefBrandTypesFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Get Other Vendors Saga Start
export function* getOtherVendorsSaga(action) {
  yield put(getAllChefStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/chef/getOtherChefList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllChefSuccess({ chefs: response.data.data }));
    } else {
      yield put(getAllChefFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllChefFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllChefFail(error.response.data.message));
      } else {
        yield put(getAllChefFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllChefFail('Something went wrong! Please try again.'));
    }
  }
}
// Get Other Vendors Saga End

// Enable Disable Vendors for advertisment start
export function* enableDisableOtherVendorSaga(action) {
  yield put(enableDisableOtherVendorStart());
  try {
    const response = yield axios.post(
      `/chef/enableDisableChefByChef`,
      action.payload,
    );
    if (response.status === 200) {
      yield put(enableDisableOtherVendorSuccess(action.payload));
    } else {
      yield put(
        enableDisableOtherVendorFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(enableDisableOtherVendorFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(enableDisableOtherVendorFail(error.response.data.message));
      } else {
        yield put(
          enableDisableOtherVendorFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        enableDisableOtherVendorFail('Something went wrong! Please try again.'),
      );
    }
  }
}
// Enable Disable Vendors for advertisment End

// get all produer saga
export function* getAllProducerSaga(action) {
  yield put(getAllChefStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/chef/getAllProducerStores`, action.payload),
    );
    console.log(response.data);
    if (response.status === 200) {
      yield put(getAllChefSuccess({ chefs: response.data.data }));
    } else {
      yield put(getAllChefFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllChefFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllChefFail(error.response.data.message));
      } else {
        yield put(getAllChefFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllChefFail('Something went wrong! Please try again.'));
    }
  }
}

// Assign to logistic
export function* assignToLogisticSaga(action) {
  yield put(assignToLogisticStart());
  try {
    console.log("assignToLogistic Called")
    const { orderIds, logisticsId, route, setModelOpen } = action.payload;
    const response = yield axios.post(`/${route}/assignToLogistic`, { orderIds, logisticsId });
    console.log(response);
    if (response.status === 200) {
      yield put(assignToLogisticSuccess({ orderIds, logisticsId }));
      setModelOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(assignToLogisticFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 1,
          message: error.response.data.message || 'Error',
        }),
      );
      console.log({ error })
      if (error.response.statusCode === 400) {
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: error.response.data.message || 'Error',
          }),
        );
        yield put(assignToLogisticFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(assignToLogisticFail(error.response.data.message));
      } else {
        yield put(assignToLogisticFail('Server error! Please try again.'));
      }
    } else {
      yield put(assignToLogisticFail('Something went wrong! Please try again.'));
    }
  }
}