import {
  GET_ALL_SUBADMIN_START,
  GET_ALL_SUBADMIN_SAGA,
  GET_ALL_SUBADMIN_SUCCESS,
  GET_ALL_SUBADMIN_FAIL,
  GET_SUBADMIN_BY_ID_START,
  GET_SUBADMIN_BY_ID_SAGA,
  GET_SUBADMIN_BY_ID_SUCCESS,
  GET_SUBADMIN_BY_ID_FAIL,
  ADD_SUBADMIN_START,
  ADD_SUBADMIN_SAGA,
  ADD_SUBADMIN_SUCCESS,
  ADD_SUBADMIN_FAIL,
  EDIT_SUBADMIN_START,
  EDIT_SUBADMIN_SAGA,
  EDIT_SUBADMIN_SUCCESS,
  EDIT_SUBADMIN_FAIL,
  EDIT_SUBADMIN_STATUS_START,
  EDIT_SUBADMIN_STATUS_SAGA,
  EDIT_SUBADMIN_STATUS_SUCCESS,
  EDIT_SUBADMIN_STATUS_FAIL,
  GENERATE_PDF_START,
  GENERATE_PDF_SAGA,
  GENERATE_PDF_SUCCESS,
  GENERATE_PDF_FAIL,
} from '../actionLabels';

export const getAllSubadminStart = () => ({
  type: GET_ALL_SUBADMIN_START,
});

export const getAllSubadminSaga = payload => ({
  type: GET_ALL_SUBADMIN_SAGA,
  payload,
});

export const getAllSubadminSuccess = payload => ({
  type: GET_ALL_SUBADMIN_SUCCESS,
  payload,
});

export const getAllSubadminFail = payload => ({
  type: GET_ALL_SUBADMIN_FAIL,
  payload,
});

export const getSubadminByIdStart = () => ({
  type: GET_SUBADMIN_BY_ID_START,
});

export const getSubadminByIdSaga = payload => ({
  type: GET_SUBADMIN_BY_ID_SAGA,
  payload,
});

export const getSubadminByIdSuccess = payload => ({
  type: GET_SUBADMIN_BY_ID_SUCCESS,
  payload,
});

export const getSubadminByIdFail = payload => ({
  type: GET_SUBADMIN_BY_ID_FAIL,
  payload,
});

export const addSubadminStart = () => ({
  type: ADD_SUBADMIN_START,
});

export const addSubadminSaga = payload => ({
  type: ADD_SUBADMIN_SAGA,
  payload,
});

export const addSubadminSuccess = payload => ({
  type: ADD_SUBADMIN_SUCCESS,
  payload,
});

export const addSubadminFail = payload => ({
  type: ADD_SUBADMIN_FAIL,
  payload,
});

export const editSubadminStart = () => ({
  type: EDIT_SUBADMIN_START,
});

export const editSubadminSaga = payload => ({
  type: EDIT_SUBADMIN_SAGA,
  payload,
});

export const editSubadminSuccess = payload => ({
  type: EDIT_SUBADMIN_SUCCESS,
  payload,
});

export const editSubadminFail = payload => ({
  type: EDIT_SUBADMIN_FAIL,
  payload,
});

export const editSubadminStatusStart = () => ({
  type: EDIT_SUBADMIN_STATUS_START,
});

export const editSubadminStatusSaga = payload => ({
  type: EDIT_SUBADMIN_STATUS_SAGA,
  payload,
});

export const editSubadminStatusSuccess = payload => ({
  type: EDIT_SUBADMIN_STATUS_SUCCESS,
  payload,
});

export const editSubadminStatusFail = payload => ({
  type: EDIT_SUBADMIN_STATUS_FAIL,
  payload,
});

export const generatePDFStart = () => ({
  type: GENERATE_PDF_START,
});

export const generatePDFSaga = payload => ({
  type: GENERATE_PDF_SAGA,
  payload,
});

export const generatePDFSuccess = payload => ({
  type: GENERATE_PDF_SUCCESS,
  payload,
});

export const generatePDFFail = payload => ({
  type: GENERATE_PDF_FAIL,
  payload,
});
