import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  CMSs: { list: [], totalRecords: { count: 0 } },
  CMS: {},
  isLoading: false,
  errorMsg: '',
  CMSContent: '',
  isCMSLoaded: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_CMS_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_CMS_SUCCESS: {
      const { CMSs } = payload;
      return { ...state, CMSs, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_CMS_FAIL:
      return {
        ...state,
        CMSs: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_CMS_PAGE_START:
      return { ...state, CMSContent: '', isCMSLoaded: false, isLoading: true };
    case actionLabels.GET_CMS_PAGE_SUCCESS: {
      const { CMSContent } = payload;
      return {
        ...state,
        CMSContent,
        isCMSLoaded: true,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.GET_CMS_PAGE_FAIL:
      return {
        ...state,
        CMSContent: '',
        isCMSLoaded: true,
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_CMS_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_CMS_BY_ID_SUCCESS: {
      const { CMS } = payload;
      return { ...state, CMS, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_CMS_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.UPDATE_CMS_START:
      return { ...state, isLoading: true };
    case actionLabels.UPDATE_CMS_SUCCESS:
      return { ...state, isLoading: false, errorMsg: '' };
    case actionLabels.UPDATE_CMS_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }
    case actionLabels.RESET_CMS: {
      return {
        ...state,
        isLoading: false,
        CMS: {},
        CMSContent: '',
        isCMSLoaded: false,
      };
    }
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }
    default:
      return state;
  }
};
