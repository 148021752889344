import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  vehicleOwners: { list: [], totalRecords: { count: 0 } },
  vehicleOwner: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_VEHICLE_OWNERS_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_VEHICLE_OWNERS_SUCCESS: {
      const { vehicleOwners } = payload;
      console.log('vehicleOwners reducer data', vehicleOwners);
      return { ...state, vehicleOwners, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_VEHICLE_OWNERS_FAIL:
      return {
        ...state,
        vehicleOwners: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    // Get Vehicle Owner By Id
    case actionLabels.GET_VEHICLE_OWNER_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_VEHICLE_OWNER_BY_ID_SUCCESS: {
      const { vehicleOwner } = payload;
      return { ...state, vehicleOwner, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_VEHICLE_OWNER_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Add Vehicle Owner
    case actionLabels.ADD_VEHICLE_OWNER_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_VEHICLE_OWNER_SUCCESS: {
      const { vehicleOwners } = state;
      vehicleOwners.list.unshift(payload);
      vehicleOwners.totalRecords.count += 1;
      return { ...state, vehicleOwners, isLoading: false, errorMsg: '' };
    }
    case actionLabels.ADD_VEHICLE_OWNER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Edit Vehicle Owner
    case actionLabels.EDIT_VEHICLE_OWNER_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_VEHICLE_OWNER_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.EDIT_VEHICLE_OWNER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Delete
    case actionLabels.DELETE_VEHICLE_OWNER_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_VEHICLE_OWNER_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.DELETE_VEHICLE_OWNER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Block Unblock Status
    case actionLabels.EDIT_BLOCK_VEHICLE_OWNER_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BLOCK_VEHICLE_OWNER_STATUS_SUCCESS: {
      if (payload.isDetail !== true) {
        const { vehicleOwners } = state;
        const index = vehicleOwners.list.findIndex(
          item => item._id === payload.id,
        );
        if (index !== -1 && vehicleOwners.list.length > index) {
          vehicleOwners.list[index].isBlock = !vehicleOwners.list[index]
            .isBlock;
        }
        return { ...state, vehicleOwners, isLoading: false, errorMsg: '' };
      }
      const { vehicleOwner } = state;
      vehicleOwner.isBlock = !vehicleOwner.isBlock;
      return { ...state, vehicleOwner, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_BLOCK_VEHICLE_OWNER_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.RESET_VEHICLE_OWNER_DETAIL:
      return {
        ...state,
        isLoading: false,
        vehicleOwner: {},
        errorMsg: '',
      };
    default:
      return state;
  }
};
