import { put } from 'redux-saga/effects';
import {
  getAllChefReviewStart,
  getAllChefReviewSuccess,
  getAllChefReviewFail,
  getReviewByIdStart,
  getReviewByIdSuccess,
  getReviewByIdFail,
  getAllLogisticsRatingReviewStart,
  getAllLogisticsRatingReviewSuccess,
  getAllLogisticsRatingReviewFail,
  logout,
  getAllRatingReviewByChefIdStart,
  getAllRatingReviewByChefIdSuccess,
  getAllRatingReviewByChefIdFail,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllChefReviewSaga(action) {
  yield put(getAllChefReviewStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/chef/getAllReviews`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllChefReviewSuccess({ reviews: response.data.data }));
    } else {
      yield put(
        getAllChefReviewFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllChefReviewFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllChefReviewFail(error.response.data.message));
      } else {
        yield put(getAllChefReviewFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllChefReviewFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getReviewByIdSaga(action) {
  yield put(getReviewByIdStart());
  try {
    const response = yield axios.get(
      `/admin/getReviewDetailByID?reviewId=${action.payload.id}`,
    );
    if (response.status === 200) {
      yield put(getReviewByIdSuccess({ review: response.data.data }));
    } else {
      yield put(getReviewByIdFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getReviewByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getReviewByIdFail(error.response.data.message));
      } else {
        yield put(getReviewByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(getReviewByIdFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getAllLogisticsRatingReviewSaga(action) {
  yield put(getAllLogisticsRatingReviewStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllLogisticReviewList`, action.payload),
    );
    if (response.status === 200) {
      yield put(
        getAllLogisticsRatingReviewSuccess({
          logisticsReviews: response.data.data,
        }),
      );
    } else {
      yield put(
        getAllLogisticsRatingReviewFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllLogisticsRatingReviewFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllLogisticsRatingReviewFail(error.response.data.message));
      } else {
        yield put(
          getAllLogisticsRatingReviewFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getAllLogisticsRatingReviewFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}

export function* getAllRatingReviewByChefIdSaga(action) {
  yield put(getAllRatingReviewByChefIdStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getReviewListByChefId`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllRatingReviewByChefIdSuccess(response.data.data));
    } else {
      yield put(
        getAllRatingReviewByChefIdFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllRatingReviewByChefIdFail(error.response.data.message));
      } else if (error.response.status === 404) {
        yield put(
          getAllRatingReviewByChefIdFail({
            error: error.response.data.message,
            data: error.response.data.data,
            status: error.response.status,
          }),
        );
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllRatingReviewByChefIdFail(error.response.data.message));
      } else {
        yield put(
          getAllRatingReviewByChefIdFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getAllRatingReviewByChefIdFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}
