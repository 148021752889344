import { put } from 'redux-saga/effects';
import {
    getAllStoreTypesSaga as getAllStoreTypesSagaAction,
    deleteVendorFail,
    deleteVendorStart,
    deleteVendorSuccess,
    logout,
    showModal,
    getAllChefPayoutListSaga,
} from '../../actions';
import axios from '../../../http/axios/axios_main';

// Delete StoreType Start
export function* deleteVendorSaga(action) {
    yield put(deleteVendorStart());
    try {
        const { id, tableFilter, setModelDeleteOpen } = action.payload;
        const response = yield axios.post(`/admin/deleteVendor`, { id });
        console.log(response);
        if (response.status === 200) {
            yield put(deleteVendorSuccess(action.payload));
            yield setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    // redirectURL:window.location.reload(),
                    message: 'Deleted Successsfully'|| 'Success',
                }),
            );
            yield put(window.location.reload('/vendors/chefs'));
            yield put(getAllChefPayoutListSaga(tableFilter));
        } else {
            yield put(deleteVendorFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(deleteVendorFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(deleteVendorFail(error.response.data.message));
            } else {
                yield put(deleteVendorFail('Server error! Please try again.'));
            }
        } else {
            yield put(deleteVendorFail('Something went wrong! Please try again.'));
        }
    }
}