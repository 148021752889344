import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  dishes: { list: [], totalRecords: { count: 0 } },
  categories: { list: [], totalRecords: { count: 0 } },
  brandTypes: { list: [], totalRecords: { count: 0 } },
  subCategories: { list: [], totalRecords: 0 },
  category: {},
  isLoading: false,
  errorMsg: '',
  errorMsgAddDish: '',
  errorMsgAddBrandType: '',
  errorMsgCategory: '',
  errorMsgSubCategory: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_CATEGORY_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_CATEGORY_SUCCESS: {
      const { categories } = payload;
      return {
        ...state,
        categories,
        isLoading: false,
        errorMsg: payload.forDD ? state.errorMsg : '',
      };
    }
    case actionLabels.GET_ALL_CATEGORY_FAIL:
      return {
        ...state,
        categories: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_ALL_CATEGORY_CHEF_FAIL:
      return {
        ...state,
        categories: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_CATEGORY_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_CATEGORY_BY_ID_SUCCESS: {
      const { category } = payload;
      return { ...state, category, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_CATEGORY_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.ADD_CATEGORY_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_CATEGORY_SUCCESS: {
      const { categories } = state;
      categories.list.unshift(payload);
      categories.totalRecords.count += 1;
      return { ...state, categories, isLoading: false, errorMsgCategory: '' };
    }
    case actionLabels.ADD_CATEGORY_FAIL:
      return { ...state, isLoading: false, errorMsgCategory: payload };
    case actionLabels.ADD_SUB_CATEGORY_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_SUB_CATEGORY_SUCCESS: {
      const { subCategories } = state;
      subCategories.list.unshift(payload);
      subCategories.totalRecords.count += 1;
      return {
        ...state,
        subCategories,
        isLoading: false,
        errorMsgSubCategory: '',
      };
    }
    case actionLabels.ADD_SUB_CATEGORY_FAIL:
      return { ...state, isLoading: false, errorMsgSubCategory: payload };

    case 'CLEAR_SUB_CATEGORY_ERROR_MSG':
      return { ...state, isLoading: false, errorMsgSubCategory: '' };
    // Edit Brand Type / Store Status

    case actionLabels.EDIT_BRAND_TYPE_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BRAND_TYPE_STATUS_SUCCESS: {
      const { brandTypes } = state;
      const index = brandTypes.list.findIndex(item => item._id === payload.id);
      if (index !== -1 && brandTypes.list.length > index) {
        brandTypes.list[index].status = !brandTypes.list[index].status;
      }
      return { ...state, brandTypes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_BRAND_TYPE_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Edit Category Status
    case actionLabels.EDIT_CATEGORY_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_CATEGORY_STATUS_SUCCESS: {
      const { categories } = state;
      const index = categories.list.findIndex(item => item._id === payload.id);
      if (index !== -1 && categories.list.length > index) {
        categories.list[index].status = !categories.list[index].status;
      }
      return { ...state, categories, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_CATEGORY_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Edit subCategoryStatus
    case actionLabels.EDIT_SUB_CATEGORY_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_SUB_CATEGORY_STATUS_SUCCESS: {
      const { subCategories } = state;
      const index = subCategories.list.findIndex(
        item => item._id === payload.id,
      );
      if (index !== -1 && subCategories.list.length > index) {
        subCategories.list[index].status = !subCategories.list[index].status;
      }
      return { ...state, subCategories, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_SUB_CATEGORY_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Edit Category
    case actionLabels.EDIT_CATEGORY_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, errorMsg: '' };
    case actionLabels.EDIT_CATEGORY_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_SUB_CATEGORY_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_SUB_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, errorMsg: '' };
    case actionLabels.EDIT_SUB_CATEGORY_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GET_ALL_SUB_CATEGORY_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_SUB_CATEGORY_SUCCESS: {
      const { subCategories } = payload;
      return {
        ...state,
        subCategories,
        isLoading: false,
        errorMsg: payload.forDD ? state.errorMsg : '',
      };
    }
    case actionLabels.GET_ALL_SUB_CATEGORY_FAIL:
      return {
        ...state,
        subCategories: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_ALL_SUB_CATEGORY_CHEF_FAIL:
      return {
        ...state,
        subCategories: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_ALL_DISH_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_DISH_SUCCESS: {
      const { dishes } = payload;
      return { ...state, dishes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_DISH_FAIL:
      return {
        ...state,
        dishes: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.ADD_DISH_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_DISH_SUCCESS: {
      const { dishes } = state;
      dishes.list.unshift(payload);
      dishes.totalRecords.count += 1;
      return { ...state, dishes, isLoading: false, errorMsgAddDish: '' };
    }
    case actionLabels.ADD_DISH_FAIL:
      return { ...state, isLoading: false, errorMsgAddDish: payload };
    case actionLabels.EDIT_DISH_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_DISH_SUCCESS: {
      return { ...state, isLoading: false, errorMsgAddDish: '' };
    }
    case actionLabels.EDIT_DISH_FAIL:
      return { ...state, isLoading: false, errorMsgAddDish: payload };
    case actionLabels.EDIT_DISH_PHOTO_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_DISH_PHOTO_SUCCESS: {
      return { ...state, isLoading: false, errorMsgAddDish: '' };
    }
    case actionLabels.EDIT_DISH_PHOTO_FAIL:
      return { ...state, isLoading: false, errorMsgAddDish: payload };
    case actionLabels.EDIT_DISH_PUBLISH_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_DISH_PUBLISH_STATUS_SUCCESS: {
      const { dishes } = state;
      const index = dishes.list.findIndex(item => item._id === payload.id);
      if (index !== -1 && dishes.list.length > index) {
        dishes.list[index].isPublished = !dishes.list[index].isPublished;
      }
      return { ...state, dishes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_DISH_PUBLISH_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.DELETE_DISH_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_DISH_SUCCESS: {
      const dishes = {
        ...state.dishes,
      };
      dishes.list = dishes.list.filter(item => item._id !== payload.id);
      dishes.totalRecords.count -= 1;
      return { ...state, dishes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.DELETE_DISH_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsgAddDish: '' };
    }
    case actionLabels.GET_ALL_ADMIN_BRAND_TYPES_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_ADMIN_BRAND_TYPES_SUCCESS: {
      const { brandTypes } = payload;
      return { ...state, brandTypes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_ADMIN_BRAND_TYPES_FAIL:
      return {
        ...state,
        brandTypes: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.ADD_BRAND_TYPE_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_BRAND_TYPE_SUCCESS: {
      const { brandTypes } = state;
      brandTypes.list.unshift(payload);
      brandTypes.totalRecords += 1;
      return {
        ...state,
        brandTypes,
        isLoading: false,
        errorMsgAddBrandType: '',
      };
    }
    case actionLabels.ADD_BRAND_TYPE_FAIL:
      return { ...state, isLoading: false, errorMsgAddBrandType: payload };
    case actionLabels.EDIT_BRAND_TYPE_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BRAND_TYPE_SUCCESS: {
      return { ...state, isLoading: false, errorMsgAddBrandType: '' };
    }
    case actionLabels.EDIT_BRAND_TYPE_FAIL:
      return { ...state, isLoading: false, errorMsgAddBrandType: payload };

    case 'CLEAR_BRAND_TYPE_ERROR_MSG':
      return { ...state, isLoading: false, errorMsgAddBrandType: '' };
    case 'CLEAR_CATEGORY_ERROR_MSG':
      return { ...state, isLoading: false, errorMsgCategory: '' };

    case actionLabels.DELETE_PRODUCT_BY_PROMOTER_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_PRODUCT_BY_PROMOTER_SUCCESS: {
      const dishes = {
        ...state.dishes,
      };
      dishes.list = dishes.list.filter(item => item._id !== payload._id);
      dishes.totalRecords.count -= 1;
      return { ...state, dishes, isLoading: false, errorMsg: '' };
    }
    default:
      return state;
  }
};
