import {
    ASSIGN_TO_LOGISTIC_FAIL,
    ASSIGN_TO_LOGISTIC_SAGA,
    ASSIGN_TO_LOGISTIC_START,
    ASSIGN_TO_LOGISTIC_SUCCESS,
    DELETE_VENDOR_FAIL,
    DELETE_VENDOR_SAGA,
    DELETE_VENDOR_START,
    DELETE_VENDOR_SUCCESS
} from "../actionLabels";

export const deleteVendor = payload => ({
    type: DELETE_VENDOR_SAGA,
    payload,
});
export const deleteVendorStart = payload => ({
    type: DELETE_VENDOR_START,
    payload,
});
export const deleteVendorSuccess = payload => ({
    type: DELETE_VENDOR_SUCCESS,
    payload,
});
export const deleteVendorFail = payload => ({
    type: DELETE_VENDOR_FAIL,
    payload,
});
