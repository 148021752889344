import {
  GET_ALL_SUPPORT_REQUEST_START,
  GET_ALL_SUPPORT_REQUEST_SAGA,
  GET_ALL_SUPPORT_REQUEST_SUCCESS,
  GET_ALL_SUPPORT_REQUEST_FAIL,
  ADD_SUPPORT_REQUEST_START,
  ADD_SUPPORT_REQUEST_SAGA,
  ADD_SUPPORT_REQUEST_SUCCESS,
  ADD_SUPPORT_REQUEST_FAIL,
} from '../actionLabels';

export const getAllSupportRequestStart = () => ({
  type: GET_ALL_SUPPORT_REQUEST_START,
});

export const getAllSupportRequestSaga = payload => ({
  type: GET_ALL_SUPPORT_REQUEST_SAGA,
  payload,
});

export const getAllSupportRequestSuccess = payload => ({
  type: GET_ALL_SUPPORT_REQUEST_SUCCESS,
  payload,
});

export const getAllSupportRequestFail = payload => ({
  type: GET_ALL_SUPPORT_REQUEST_FAIL,
  payload,
});

export const addSupportRequestStart = () => ({
  type: ADD_SUPPORT_REQUEST_START,
});

export const addSupportRequestSaga = payload => ({
  type: ADD_SUPPORT_REQUEST_SAGA,
  payload,
});

export const addSupportRequestSuccess = payload => ({
  type: ADD_SUPPORT_REQUEST_SUCCESS,
  payload,
});

export const addSupportRequestFail = payload => ({
  type: ADD_SUPPORT_REQUEST_FAIL,
  payload,
});
