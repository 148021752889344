import { put } from 'redux-saga/effects';
import {
  getAllSettingStart,
  getAllSettingSuccess,
  getAllSettingFail,
  logout,
  editAllSettingsStart,
  editAllSettingsSuccess,
  editAllSettingsFail,
  editDefaultFoodDishImageStart,
  editDefaultFoodDishImageSuccess,
  editDefaultFoodDishImageFail,
  sendNotificationStart,
  sendNotificationSuccess,
  sendNotificationFail,
  showModal,
} from '../../actions';
import axios from '../../../http/axios/axios_main';

// eslint-disable-next-line no-unused-vars
export function* getAllSettingSaga(action) {
  yield put(getAllSettingStart());
  try {
    const response = yield axios.get(`/admin/getAllSettings`);
    if (response.status === 200) {
      yield put(getAllSettingSuccess({ setting: response.data.data }));
    } else {
      yield put(getAllSettingFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllSettingFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllSettingFail(error.response.data.message));
      } else {
        yield put(getAllSettingFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllSettingFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editAllSettingsSaga(action) {
  yield put(editAllSettingsStart());
  const { setIsDisable, values } = action.payload;
  try {
    const response = yield axios.post(`/admin/updateAllSettings`, values);
    if (response.status === 200) {
      yield put(editAllSettingsSuccess(values));
      setIsDisable(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(editAllSettingsFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editAllSettingsFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editAllSettingsFail(error.response.data.message));
      } else {
        yield put(editAllSettingsFail('Server error! Please try again.'));
      }
    } else {
      yield put(editAllSettingsFail('Something went wrong! Please try again.'));
    }
  }
}
export function* editAllSettingsCommissionSaga(action) {
  yield put(editAllSettingsStart());
  const { setIsDisable, payload } = action.payload;
  try {
    const response = yield axios.post(
      `/admin/updateCommissionSettings`,
      payload,
    );
    if (response.status === 200) {
      yield put(editAllSettingsSuccess(payload));
      setIsDisable(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: 'All Commissions Updated Successfully.' || 'Success',
        }),
      );
    } else {
      yield put(editAllSettingsFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editAllSettingsFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editAllSettingsFail(error.response.data.message));
      } else {
        yield put(editAllSettingsFail('Server error! Please try again.'));
      }
    } else {
      yield put(editAllSettingsFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editDefaultFoodDishImageSaga(action) {
  yield put(editDefaultFoodDishImageStart());

  try {
    const formdata = new FormData();
    formdata.append('photo', action.payload.photo);
    const response = yield axios.post(
      `/admin/uploadDefaultImageChef`,
      formdata,
    );
    if (response.status === 200) {
      yield put(editDefaultFoodDishImageSuccess(action.payload));
    } else {
      yield put(
        editDefaultFoodDishImageFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editDefaultFoodDishImageFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editDefaultFoodDishImageFail(error.response.data.message));
      } else {
        yield put(
          editDefaultFoodDishImageFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        editDefaultFoodDishImageFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* sendNotificationSaga(action) {
  yield put(sendNotificationStart());
  const { values, setIsDisable } = action.payload;
  try {
    const response = yield axios.post(
      `/admin/sendPromotionalNotifications`,
      values,
    );
    if (response.status === 200) {
      yield put(sendNotificationSuccess(values));
      setIsDisable(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(
        sendNotificationFail('Something went wrong! Please try again.'),
      );
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(sendNotificationFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(sendNotificationFail(error.response.data.message));
      } else {
        yield put(sendNotificationFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        sendNotificationFail('Something went wrong! Please try again.'),
      );
    }
  }
}
