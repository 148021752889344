import { put } from 'redux-saga/effects';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';
import { capitalize } from '../../../utils/capitalize';

import {
  getAllVehiclesSaga as getAllVehiclesSagaAction,
  getAllVehiclesStart,
  getAllVehiclesSuccess,
  getAllVehiclesFail,
  addVehicleStart,
  addVehicleSuccess,
  addVehicleFail,
  showModal,
  logout,
  editBlockVehicleStatusStart,
  editBlockVehicleStatusSuccess,
  editBlockVehicleStatusFail,
  getVehicleDetailByIdStart,
  getVehicleDetailByIdSuccess,
  getVehicleDetailByIdFail,
  editVehicleStart,
  editVehicleSuccess,
  editVehicleFail,
  deleteVehicleStart,
  deleteVehicleSuccess,
  deleteVehicleFail,
  deleteVehicleByVhicleOwnerSuccess,
  deleteVehicleByVhicleOwnerStart,
  deleteVehicleByVhicleOwnerFail,
  getAllOwnerVehiclesSaga,
  // getVehicleDetailByIdStart,
  // getVehicleDetailByIdSuccess,
  // getVehicleDetailByIdFail,
} from '../../actions';

export function* getAllVehiclesSaga(action) {
  console.log("getAllVehicle",action)
  yield put(getAllVehiclesStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/driver/vehicleList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllVehiclesSuccess({ vehicles: response.data.data }));
    } else {
      yield put(getAllVehiclesFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllVehiclesFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllVehiclesFail(error.response.data.message));
      } else {
        yield put(getAllVehiclesFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllVehiclesFail('Something went wrong! Please try again. 1'),
      );
    }
  }
}

// Get Vehicle Details By Id
export function* getVehicleDetailByIdSaga(action) {
  yield put(getVehicleDetailByIdStart());
  console.log(action.payload);
  const payload = {
    id: action.payload,
  };
  try {
    const response = yield axios.post(`/admin/findVehicleTypeByID`, payload);
    console.log('vehicle details \n', response);
    if (response.status === 200) {
      yield put(getVehicleDetailByIdSuccess({ vehicle: response.data.data }));
    } else {
      yield put(
        getVehicleDetailByIdFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getVehicleDetailByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getVehicleDetailByIdFail(error.response.data.message));
      } else {
        yield put(getVehicleDetailByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getVehicleDetailByIdFail('Something went wrong! Please try again. 1'),
      );
    }
  }
}

export function* addVehicleSaga(action) {
  yield put(addVehicleStart());
  try {
    const { tableFilter } = action.payload;
    console.log(action.payload);
    const formdata = new FormData();
    formdata.append('type', action.payload.formValues.name);
    formdata.append('minFare', action.payload.formValues.minimumFare);
    formdata.append('feePerKM', action.payload.formValues.perKmFare);
    formdata.append(
      'transportModeId',
      action.payload.formValues.transportModeId,
    );
    formdata.append('photo', action.payload.formValues.photo);
    formdata.append('numberOfWheel', action.payload.formValues.numberOfWheel);
    formdata.append('productPrice', action.payload.formValues.productPrice);

    formdata.append('city', action.payload.formValues.city);
    formdata.append('packageFee', action.payload.formValues.feePackage);
    formdata.append('freeKms', action.payload.formValues.freeKilometer);


    const response = yield axios.post('/admin/addVehicleType', formdata);
    
    if (response.status === 200 || response.statusCode === 200) {
      yield put(addVehicleSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vendors/vehicle-types',
          message: 'Vehicle Types Added Successfully',
          // message: capitalize(response.data.message) || 'Success',
        }),
      );
      yield put(getAllVehiclesSagaAction(tableFilter));
    } else {
      yield put(addVehicleFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addVehicleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addVehicleFail(error.response.data.message));
      } else {
        yield put(addVehicleFail('Server error! Please try again.'));
      }
    } else {
      yield put(addVehicleFail('Something went wrong! Please try again. 1'));
    }
  }
}

// Block unblock vehicle status
export function* editBlockVehicleStatusSaga(action) {
  yield put(editBlockVehicleStatusStart());
  try {
    const response = yield axios.post(
      `/admin/updateVehicleTypeStatus`,
      action.payload,
    );
    console.log(response);
    if (response.status === 200) {
      console.log(action.payload);
      yield put(editBlockVehicleStatusSuccess(action.payload));
    } else {
      yield put(
        editBlockVehicleStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBlockVehicleStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBlockVehicleStatusFail(error.response.data.message));
      } else {
        yield put(
          editBlockVehicleStatusFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        editBlockVehicleStatusFail('Something went wrong! Please try again. 1'),
      );
    }
  }
}

// Edit Vehicle
export function* editVehicleSaga(action) {
  yield put(editVehicleStart());
  try {
    const { formValues, tableFilter } = action.payload;
    console.log(action.payload);
    console.log(formValues);

    const formdata = new FormData();
    formdata.append('id', formValues.id);
    formdata.append('type', formValues.name);
    formdata.append('minFare', formValues.minimumFare);
    formdata.append('feePerKM', formValues.perKmFare);
    formdata.append('transportModeId', formValues.transportModeId);


    formdata.append('city', formValues.city);
    formdata.append('freeKms', formValues.freeKms);
    formdata.append('packageFee', formValues.packageFee);

    console.log(formdata);
    if (formValues.photo) {
      formdata.append('photo', formValues.photo);
    }
    formdata.append('numberOfWheel', action.payload.formValues.numberOfWheel);
    const response = yield axios.post('/admin/updayeVehicleType', formdata);
    console.log('edit vehicle saga response \n', response);
    if (response.status === 200 || response.statusCode === 200) {
      yield put(editVehicleSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vendors/vehicle-types',
          message: 'vehicle Type Updated successfully ',
          // message: capitalize(response.data.message) || 'Updated Successfully',
        }),
      );
      yield put(getAllVehiclesSagaAction(tableFilter));
    } else {
      yield put(editVehicleFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editVehicleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editVehicleFail(error.response.data.message));
      } else {
        yield put(editVehicleFail('Server error! Please try again.'));
      }
    } else {
      yield put(editVehicleFail('Something went wrong! Please try again. 1'));
    }
  }
}

// Delete Vehicle
export function* deleteVehicleSaga(action) {
  yield put(deleteVehicleStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    console.log(action.payload);
    // To-DO change API address
    const response = yield axios.post(`/admin/deleteVehicleType`, {
      vehicleTypeId: id,
    });
    if (response.status === 200) {
      yield put(deleteVehicleSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: 'Vehicle Type Deleted Successfully',
          // message: response.data.message || 'Success',
        }),
      );
      yield put(getAllVehiclesSagaAction(tableFilter));
    } else {
      yield put(deleteVehicleFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      if (error.response.status === 400) {
        yield put(deleteVehicleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteVehicleFail(error.response.data.message));
      } else {
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: 'Server error! Please try again.',
          }),
        );
        yield put(deleteVehicleFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteVehicleFail('Something went wrong! Please try again.'));
    }
  }
}
export function* deleteVehicleByVhicleOwnerSaga(action) {
  yield put(deleteVehicleByVhicleOwnerStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    console.log(action.payload);
    // To-DO change API address
    const response = yield axios.get(`admin/deleteVehicleByVhicleOwner?vehicleId=${id}`);
    if (response.status === 200) {
      yield put(deleteVehicleByVhicleOwnerSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: 'Vehicle Deleted Successfully',
          // message: response.data.message || 'Success',
        }),
      );
      yield put(getAllOwnerVehiclesSaga(tableFilter));
    } else {
      yield put(deleteVehicleByVhicleOwnerFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      if (error.response.status === 400) {
        yield put(deleteVehicleByVhicleOwnerFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteVehicleByVhicleOwnerFail(error.response.data.message));
      } else {
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: 'Server error! Please try again.',
          }),
        );
        yield put(deleteVehicleByVhicleOwnerFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteVehicleByVhicleOwnerFail('Something went wrong! Please try again.'));
    }
  }
}
