export const RESET_DRIVER_LIST = 'RESET_DRIVER_LIST';
export const RESET_DRIVER_DETAILS = 'RESET_DRIVER_DETAILS';

export const GET_ALL_DRIVER_START = 'GET_ALL_DRIVER_START';
export const GET_ALL_DRIVER_SAGA = 'GET_ALL_DRIVER_SAGA';
export const GET_ALL_DRIVER_SUCCESS = 'GET_ALL_DRIVER_SUCCESS';
export const GET_ALL_DRIVER_FAIL = 'GET_ALL_DRIVER_FAIL';

export const GET_DRIVER_BY_ID_START = 'GET_DRIVER_BY_ID_START';
export const GET_DRIVER_BY_ID_SAGA = 'GET_DRIVER_BY_ID_SAGA';
export const GET_DRIVER_BY_ID_SUCCESS = 'GET_DRIVER_BY_ID_SUCCESS';
export const GET_DRIVER_BY_ID_FAIL = 'GET_DRIVER_BY_ID_FAIL';

export const ADD_DRIVER_START = 'ADD_DRIVER_START';
export const ADD_DRIVER_SAGA = 'ADD_DRIVER_SAGA';
export const ADD_DRIVER_SUCCESS = 'ADD_DRIVER_SUCCESS';
export const ADD_DRIVER_FAIL = 'ADD_DRIVER_FAIL';

export const EDIT_DRIVER_START = 'EDIT_DRIVER_START';
export const EDIT_DRIVER_SAGA = 'EDIT_DRIVER_SAGA';
export const EDIT_DRIVER_SUCCESS = 'EDIT_DRIVER_SUCCESS';
export const EDIT_DRIVER_FAIL = 'EDIT_DRIVER_FAIL';

export const DRIVER_APPROVE_REJECT_START = 'DRIVER_APPROVE_REJECT_START';
export const DRIVER_APPROVE_REJECT_SAGA = 'DRIVER_APPROVE_REJECT_SAGA';
export const DRIVER_APPROVE_REJECT_SUCCESS = 'DRIVER_APPROVE_REJECT_SUCCESS';
export const DRIVER_APPROVE_REJECT_FAIL = 'DRIVER_APPROVE_REJECT_FAIL';

// Edit DRiver Commission from admin side
export const EDIT_DRIVER_COMMISSION_START = 'EDIT_DRIVER_COMMISSION_START';
export const EDIT_DRIVER_COMMISSION_SAGA = 'EDIT_DRIVER_COMMISSION_SAGA';
export const EDIT_DRIVER_COMMISSION_SUCCESS = 'EDIT_DRIVER_COMMISSION_SUCCESS';
export const EDIT_DRIVER_COMMISSION_FAIL = 'EDIT_DRIVER_COMMISSION_FAIL';

export const PROMOTER_ADD_DRIVER_SAGA = 'PROMOTER_ADD_DRIVER_SAGA';
export const PROMOTER_DRIVER_DETAILS_SAGA = 'PROMOTER_DRIVER_DETAILS_SAGA';
export const PROMOTER_EDIT_DRIVER_SAGA = 'PROMOTER_EDIT_DRIVER_SAGA';

// Driver Block Unblock
export const EDIT_BLOCK_DRIVER_STATUS_START = 'EDIT_BLOCK_DRIVER_STATUS_START';
export const EDIT_BLOCK_DRIVER_STATUS_SAGA = 'EDIT_BLOCK_DRIVER_STATUS_SAGA';
export const EDIT_BLOCK_DRIVER_STATUS_SUCCESS =
  'EDIT_BLOCK_DRIVER_STATUS_SUCCESS';
export const EDIT_BLOCK_DRIVER_STATUS_FAIL = 'EDIT_BLOCK_DRIVER_STATUS_FAIL';

// Driver Service Assign
export const EDIT_DRIVER_SERVICE_ASSIGNED_START =
  'EDIT_DRIVER_SERVICE_ASSIGNED_START';
export const EDIT_DRIVER_SERVICE_ASSIGNED_SAGA =
  'EDIT_DRIVER_SERVICE_ASSIGNED_SAGA';
export const EDIT_DRIVER_SERVICE_ASSIGNED_SUCCESS =
  'EDIT_DRIVER_SERVICE_ASSIGNED_SUCCESS';
export const EDIT_DRIVER_SERVICE_ASSIGNED_FAIL =
  'EDIT_DRIVER_SERVICE_ASSIGNED_FAIL';

// Add Vehicle Owner From Admin in Driver List Screen start
export const ADD_OWNER_IN_DRIVER_START = 'ADD_OWNER_IN_DRIVER_START';
export const ADD_OWNER_IN_DRIVER_SAGA = 'ADD_OWNER_IN_DRIVER_SAGA';
export const ADD_OWNER_IN_DRIVER_SUCCESS = 'ADD_OWNER_IN_DRIVER_SUCCESS';
export const ADD_OWNER_IN_DRIVER_FAIL = 'ADD_OWNER_IN_DRIVER_FAIL';

export const GET_AVAILABLE_DRIVERS_TO_ASSIGN_VEHICLE_SAGA =
  'GET_AVAILABLE_DRIVERS_TO_ASSIGN_VEHICLE_SAGA';

export const ASSIGN_VEHICLE_TO_A_DRIVER_SAGA =
  'ASSIGN_VEHICLE_TO_A_DRIVER_SAGA';

export const CLEAR_AVAILABLE_DRIVER_LIST = 'CLEAR_AVAILABLE_DRIVER_LIST';

// Get Owner's Driver Details
export const DETAILS_OWNER_DRIVER_START = 'DETAILS_OWNER_DRIVER_START';
export const DETAILS_OWNER_DRIVER_SAGA = 'DETAILS_OWNER_DRIVER_SAGA';
export const DETAILS_OWNER_DRIVER_SUCCESS =
  'DETAILS_OWNER_DRIVER_SUCCESS';
export const DETAILS_OWNER_DRIVER_FAIL = 'DETAILS_OWNER_DRIVER_FAIL';


// Get all Vehicle Added By Promoter by
export const PROMOTER_OWNERS_VEHICLE_START = 'PROMOTER_OWNERS_VEHICLE_START';
export const PROMOTER_OWNERS_VEHICLE_SAGA = 'PROMOTER_OWNERS_VEHICLE_SAGA';
export const PROMOTER_OWNERS_VEHICLE_SUCCESS = 'PROMOTER_OWNERS_VEHICLE_SUCCESS';
export const PROMOTER_OWNERS_VEHICLE_FAIL = 'PROMOTER_OWNERS_VEHICLE_FAIL';