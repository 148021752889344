import {
  GET_ALL_CHEF_START,
  GET_ALL_CHEF_SAGA,
  GET_ALL_CHEF_SUCCESS,
  GET_ALL_CHEF_FAIL,
  GET_CHEF_BY_ID_START,
  GET_CHEF_BY_ID_SAGA,
  GET_CHEF_BY_ID_SUCCESS,
  GET_CHEF_BY_ID_FAIL,
  EDIT_BLOCK_CHEF_STATUS_START,
  EDIT_BLOCK_CHEF_STATUS_SAGA,
  EDIT_BLOCK_CHEF_STATUS_SUCCESS,
  EDIT_BLOCK_CHEF_STATUS_FAIL,
  ADD_BANK_DETAIL_START,
  ADD_BANK_DETAIL_SAGA,
  ADD_BANK_DETAIL_SUCCESS,
  ADD_BANK_DETAIL_FAIL,
  ADD_ADDRESS_START,
  ADD_ADDRESS_SAGA,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAIL,
  GET_ACCEPTING_ORDER_START,
  GET_ACCEPTING_ORDER_SAGA,
  GET_ACCEPTING_ORDER_SUCCESS,
  GET_ACCEPTING_ORDER_FAIL,
  EDIT_ACCEPTING_ORDER_CHEF_START,
  EDIT_ACCEPTING_ORDER_CHEF_SAGA,
  EDIT_ACCEPTING_ORDER_CHEF_SUCCESS,
  EDIT_ACCEPTING_ORDER_CHEF_FAIL,
  EDIT_LOCATION_START,
  EDIT_LOCATION_SAGA,
  EDIT_LOCATION_SUCCESS,
  EDIT_LOCATION_FAIL,
  CHEF_APPROVE_REJECT_START,
  CHEF_APPROVE_REJECT_SAGA,
  CHEF_APPROVE_REJECT_SUCCESS,
  CHEF_APPROVE_REJECT_FAIL,
  EDIT_CHEF_COMMISSION_START,
  EDIT_CHEF_COMMISSION_SAGA,
  EDIT_CHEF_COMMISSION_SUCCESS,
  EDIT_CHEF_COMMISSION_FAIL,
  RESET_CHEF_LIST,
  GET_CHEF_PROFILE_START,
  GET_CHEF_PROFILE_SAGA,
  GET_CHEF_PROFILE_SUCCESS,
  GET_CHEF_PROFILE_FAIL,
  GET_LAT_LONG_FROM_ADDRESS_START,
  GET_LAT_LONG_FROM_ADDRESS_SUCCESS,
  RESET_LAT_LONG_FROM_ADDRESS,
  GET_LAT_LONG_FROM_ADDRESS_SAGA,
  EDIT_PERSONAL_DETAIL_START,
  EDIT_PERSONAL_DETAIL_SAGA,
  EDIT_PERSONAL_DETAIL_SUCCESS,
  EDIT_PERSONAL_DETAIL_FAIL,
  EDIT_ORDER_SCHEDULE_START,
  EDIT_ORDER_SCHEDULE_SAGA,
  EDIT_ORDER_SCHEDULE_SUCCESS,
  EDIT_ORDER_SCHEDULE_FAIL,
  EDIT_RESTAURANT_DETAIL_START,
  EDIT_RESTAURANT_DETAIL_SAGA,
  EDIT_RESTAURANT_DETAIL_SUCCESS,
  EDIT_RESTAURANT_DETAIL_FAIL,
  EDIT_MEDIA_START,
  EDIT_MEDIA_SAGA,
  EDIT_MEDIA_SUCCESS,
  EDIT_MEDIA_FAIL,
  DELETE_MEDIA_START,
  DELETE_MEDIA_SAGA,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAIL,
  EDIT_ID_PROOF_START,
  EDIT_ID_PROOF_SAGA,
  EDIT_ID_PROOF_SUCCESS,
  EDIT_ID_PROOF_FAIL,
  DELETE_ID_PROOF_START,
  DELETE_ID_PROOF_SAGA,
  DELETE_ID_PROOF_SUCCESS,
  DELETE_ID_PROOF_FAIL,
  EDIT_CHEF_FEATURE_START,
  EDIT_CHEF_FEATURE_FAIL,
  EDIT_CHEF_FEATURE_SUCCESS,
  EDIT_CHEF_FEATURE_SAGA,
  EDIT_PROFILE_SUBMIT_START,
  EDIT_PROFILE_SUBMIT_SAGA,
  EDIT_PROFILE_SUBMIT_SUCCESS,
  EDIT_PROFILE_SUBMIT_FAIL,
  GET_ALL_BRAND_TYPES_START,
  GET_ALL_BRAND_TYPES_SAGA,
  GET_ALL_BRAND_TYPES_SUCCESS,
  GET_ALL_BRAND_TYPES_FAIL,
  RESET_CHEF_DETAIL,
  GET_CHEF_BRAND_TYPES_SAGA,
  RESET_CHEF_BRAND_TYPES,
  GET_CHEF_BRAND_TYPES_START,
  GET_CHEF_BRAND_TYPES_SUCCESS,
  GET_CHEF_BRAND_TYPES_FAIL,
  GET_OTHER_VENDORS_SAGA,
  ENABLE_DISABLE_OTHER_VENDOR_SAGA,
  ENABLE_DISABLE_OTHER_VENDOR_START,
  ENABLE_DISABLE_OTHER_VENDOR_SUCCESS,
  ENABLE_DISABLE_OTHER_VENDOR_FAIL,
  GET_ALL_PRODUCER_SAGA,
  EDIT_BANK_ACCOUNT_START,
  EDIT_BANK_ACCOUNT_SAGA,
  EDIT_BANK_ACCOUNT_SUCCESS,
  EDIT_BANK_ACCOUNT_FAIL,
  EDIT_BANK_ACCOUNT_STATUS_START,
  EDIT_BANK_ACCOUNT_STATUS_SAGA,
  EDIT_BANK_ACCOUNT_STATUS_SUCCESS,
  EDIT_BANK_ACCOUNT_STATUS_FAIL,
  DELETE_BANK_ACCOUNT_START,
  DELETE_BANK_ACCOUNT_SAGA,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAIL,
  ASSIGN_TO_LOGISTIC_SAGA,
  ASSIGN_TO_LOGISTIC_START,
  ASSIGN_TO_LOGISTIC_SUCCESS,
  ASSIGN_TO_LOGISTIC_FAIL,
} from '../actionLabels';

export const getAllChefStart = payload => ({
  type: GET_ALL_CHEF_START,
  payload,
});

export const getAllChefSaga = payload => ({
  type: GET_ALL_CHEF_SAGA,
  payload,
});

export const getAllChefSuccess = payload => ({
  type: GET_ALL_CHEF_SUCCESS,
  payload,
});

export const getAllChefFail = payload => ({
  type: GET_ALL_CHEF_FAIL,
  payload,
});

export const getChefByIdStart = () => ({
  type: GET_CHEF_BY_ID_START,
});

export const getChefByIdSaga = payload => ({
  type: GET_CHEF_BY_ID_SAGA,
  payload,
});

export const getChefByIdSuccess = payload => ({
  type: GET_CHEF_BY_ID_SUCCESS,
  payload,
});

export const getChefByIdFail = payload => ({
  type: GET_CHEF_BY_ID_FAIL,
  payload,
});

export const editBlockChefStatusStart = () => ({
  type: EDIT_BLOCK_CHEF_STATUS_START,
});

export const editBlockChefStatusSaga = payload => ({
  type: EDIT_BLOCK_CHEF_STATUS_SAGA,
  payload,
});

export const editBlockChefStatusSuccess = payload => ({
  type: EDIT_BLOCK_CHEF_STATUS_SUCCESS,
  payload,
});

export const editBlockChefStatusFail = payload => ({
  type: EDIT_BLOCK_CHEF_STATUS_FAIL,
  payload,
});

export const addBankDetailStart = payload => ({
  type: ADD_BANK_DETAIL_START,
  payload,
});

export const addBankDetailSaga = payload => ({
  type: ADD_BANK_DETAIL_SAGA,
  payload,
});

export const addBankDetailSuccess = payload => ({
  type: ADD_BANK_DETAIL_SUCCESS,
  payload,
});

export const addBankDetailFail = payload => ({
  type: ADD_BANK_DETAIL_FAIL,
  payload,
});

// EDIT ACCOUNT
export const editBankAccountStart = payload => ({
  type: EDIT_BANK_ACCOUNT_START,
  payload,
});

export const editBankAccountSaga = payload => ({
  type: EDIT_BANK_ACCOUNT_SAGA,
  payload,
});

export const editBankAccountSuccess = payload => ({
  type: EDIT_BANK_ACCOUNT_SUCCESS,
  payload,
});

export const editBankAccountFail = payload => ({
  type: EDIT_BANK_ACCOUNT_FAIL,
  payload,
});
// Change ACCOUNT Status
export const editBankAccountStatusStart = payload => ({
  type: EDIT_BANK_ACCOUNT_STATUS_START,
  payload,
});

export const editBankAccountStatusSaga = payload => ({
  type: EDIT_BANK_ACCOUNT_STATUS_SAGA,
  payload,
});

export const editBankAccountStatusSuccess = payload => ({
  type: EDIT_BANK_ACCOUNT_STATUS_SUCCESS,
  payload,
});

export const editBankAccountStatusFail = payload => ({
  type: EDIT_BANK_ACCOUNT_STATUS_FAIL,
  payload,
});

// EDIT ACCOUNT
export const deleteBankAccountStart = payload => ({
  type: DELETE_BANK_ACCOUNT_START,
  payload,
});

export const deleteBankAccountSaga = payload => ({
  type: DELETE_BANK_ACCOUNT_SAGA,
  payload,
});

export const deleteBankAccountSuccess = payload => ({
  type: DELETE_BANK_ACCOUNT_SUCCESS,
  payload,
});

export const deleteBankAccountFail = payload => ({
  type: DELETE_BANK_ACCOUNT_FAIL,
  payload,
});
//

export const addAddressStart = payload => ({
  type: ADD_ADDRESS_START,
  payload,
});

export const addAddressSaga = payload => ({
  type: ADD_ADDRESS_SAGA,
  payload,
});

export const addAddressSuccess = payload => ({
  type: ADD_ADDRESS_SUCCESS,
  payload,
});

export const addAddressFail = payload => ({
  type: ADD_ADDRESS_FAIL,
  payload,
});

export const getAcceptingOrderStart = payload => ({
  type: GET_ACCEPTING_ORDER_START,
  payload,
});

export const getAcceptingOrderSaga = payload => ({
  type: GET_ACCEPTING_ORDER_SAGA,
  payload,
});

export const getAcceptingOrderSuccess = payload => ({
  type: GET_ACCEPTING_ORDER_SUCCESS,
  payload,
});

export const getAcceptingOrderFail = payload => ({
  type: GET_ACCEPTING_ORDER_FAIL,
  payload,
});

export const editAcceptingOrderChefStart = payload => ({
  type: EDIT_ACCEPTING_ORDER_CHEF_START,
  payload,
});

export const editAcceptingOrderChefSaga = payload => ({
  type: EDIT_ACCEPTING_ORDER_CHEF_SAGA,
  payload,
});

export const editAcceptingOrderChefSuccess = payload => ({
  type: EDIT_ACCEPTING_ORDER_CHEF_SUCCESS,
  payload,
});

export const editAcceptingOrderChefFail = payload => ({
  type: EDIT_ACCEPTING_ORDER_CHEF_FAIL,
  payload,
});

export const editLocationStart = payload => ({
  type: EDIT_LOCATION_START,
  payload,
});

export const editLocationSaga = payload => ({
  type: EDIT_LOCATION_SAGA,
  payload,
});

export const editLocationSuccess = payload => ({
  type: EDIT_LOCATION_SUCCESS,
  payload,
});

export const editLocationFail = payload => ({
  type: EDIT_LOCATION_FAIL,
  payload,
});

export const chefApproveRejectStart = payload => ({
  type: CHEF_APPROVE_REJECT_START,
  payload,
});

export const chefApproveRejectSaga = payload => ({
  type: CHEF_APPROVE_REJECT_SAGA,
  payload,
});

export const chefApproveRejectSuccess = payload => ({
  type: CHEF_APPROVE_REJECT_SUCCESS,
  payload,
});

export const chefApproveRejectFail = payload => ({
  type: CHEF_APPROVE_REJECT_FAIL,
  payload,
});

export const editChefCommissionStart = payload => ({
  type: EDIT_CHEF_COMMISSION_START,
  payload,
});

export const editChefCommissionSaga = payload => ({
  type: EDIT_CHEF_COMMISSION_SAGA,
  payload,
});

export const editChefCommissionSuccess = payload => ({
  type: EDIT_CHEF_COMMISSION_SUCCESS,
  payload,
});

export const editChefCommissionFail = payload => ({
  type: EDIT_CHEF_COMMISSION_FAIL,
  payload,
});

export const resetChefList = () => ({
  type: RESET_CHEF_LIST,
});

export const getChefProfileStart = payload => ({
  type: GET_CHEF_PROFILE_START,
  payload,
});

export const getChefProfileSaga = payload => ({
  type: GET_CHEF_PROFILE_SAGA,
  payload,
});

export const getChefProfileSuccess = payload => ({
  type: GET_CHEF_PROFILE_SUCCESS,
  payload,
});

export const getChefProfileFail = payload => ({
  type: GET_CHEF_PROFILE_FAIL,
  payload,
});

export const getLatLongFromAddressStart = payload => ({
  type: GET_LAT_LONG_FROM_ADDRESS_START,
  payload,
});

export const getLatLongFromAddressSaga = payload => ({
  type: GET_LAT_LONG_FROM_ADDRESS_SAGA,
  payload,
});

export const getLatLongFromAddressSuccess = payload => ({
  type: GET_LAT_LONG_FROM_ADDRESS_SUCCESS,
  payload,
});

export const resetLatLongFromAddress = payload => ({
  type: RESET_LAT_LONG_FROM_ADDRESS,
  payload,
});

export const editPersonalDetailStart = payload => ({
  type: EDIT_PERSONAL_DETAIL_START,
  payload,
});

export const editPersonalDetailSaga = payload => ({
  type: EDIT_PERSONAL_DETAIL_SAGA,
  payload,
});

export const editPersonalDetailSuccess = payload => ({
  type: EDIT_PERSONAL_DETAIL_SUCCESS,
  payload,
});

export const editPersonalDetailFail = payload => ({
  type: EDIT_PERSONAL_DETAIL_FAIL,
  payload,
});

export const editOrderScheduleStart = payload => ({
  type: EDIT_ORDER_SCHEDULE_START,
  payload,
});

export const editOrderScheduleSaga = payload => ({
  type: EDIT_ORDER_SCHEDULE_SAGA,
  payload,
});

export const editOrderScheduleSuccess = payload => ({
  type: EDIT_ORDER_SCHEDULE_SUCCESS,
  payload,
});

export const editOrderScheduleFail = payload => ({
  type: EDIT_ORDER_SCHEDULE_FAIL,
  payload,
});

export const editRestaurantDetailStart = payload => ({
  type: EDIT_RESTAURANT_DETAIL_START,
  payload,
});

export const editRestaurantDetailSaga = payload => ({
  type: EDIT_RESTAURANT_DETAIL_SAGA,
  payload,
});

export const editRestaurantDetailSuccess = payload => ({
  type: EDIT_RESTAURANT_DETAIL_SUCCESS,
  payload,
});

export const editRestaurantDetailFail = payload => ({
  type: EDIT_RESTAURANT_DETAIL_FAIL,
  payload,
});

export const editMediaStart = payload => ({
  type: EDIT_MEDIA_START,
  payload,
});

export const editMediaSaga = payload => ({
  type: EDIT_MEDIA_SAGA,
  payload,
});

export const editMediaSuccess = payload => ({
  type: EDIT_MEDIA_SUCCESS,
  payload,
});

export const editMediaFail = payload => ({
  type: EDIT_MEDIA_FAIL,
  payload,
});

export const deleteMediaStart = payload => ({
  type: DELETE_MEDIA_START,
  payload,
});

export const deleteMediaSaga = payload => ({
  type: DELETE_MEDIA_SAGA,
  payload,
});

export const deleteMediaSuccess = payload => ({
  type: DELETE_MEDIA_SUCCESS,
  payload,
});

export const deleteMediaFail = payload => ({
  type: DELETE_MEDIA_FAIL,
  payload,
});

export const editIdproofStart = payload => ({
  type: EDIT_ID_PROOF_START,
  payload,
});

export const editIdproofSaga = payload => ({
  type: EDIT_ID_PROOF_SAGA,
  payload,
});

export const editIdproofSuccess = payload => ({
  type: EDIT_ID_PROOF_SUCCESS,
  payload,
});

export const editIdproofFail = payload => ({
  type: EDIT_ID_PROOF_FAIL,
  payload,
});

export const deleteIdproofStart = payload => ({
  type: DELETE_ID_PROOF_START,
  payload,
});

export const deleteIdproofSaga = payload => ({
  type: DELETE_ID_PROOF_SAGA,
  payload,
});

export const deleteIdproofSuccess = payload => ({
  type: DELETE_ID_PROOF_SUCCESS,
  payload,
});

export const deleteIdproofFail = payload => ({
  type: DELETE_ID_PROOF_FAIL,
  payload,
});

export const editChefFeatureStart = payload => ({
  type: EDIT_CHEF_FEATURE_START,
  payload,
});

export const editChefFeatureSaga = payload => ({
  type: EDIT_CHEF_FEATURE_SAGA,
  payload,
});

export const editChefFeatureSuccess = payload => ({
  type: EDIT_CHEF_FEATURE_SUCCESS,
  payload,
});

export const editChefFeatureFail = payload => ({
  type: EDIT_CHEF_FEATURE_FAIL,
  payload,
});

export const editProfileSubmitStart = payload => ({
  type: EDIT_PROFILE_SUBMIT_START,
  payload,
});

export const editProfileSubmitSaga = payload => ({
  type: EDIT_PROFILE_SUBMIT_SAGA,
  payload,
});

export const editProfileSubmitSuccess = payload => ({
  type: EDIT_PROFILE_SUBMIT_SUCCESS,
  payload,
});

export const editProfileSubmitFail = payload => ({
  type: EDIT_PROFILE_SUBMIT_FAIL,
  payload,
});

export const getAllBrandTypesStart = () => ({
  type: GET_ALL_BRAND_TYPES_START,
});

export const getAllBrandTypesSaga = payload => ({
  type: GET_ALL_BRAND_TYPES_SAGA,
  payload,
});

export const getAllBrandTypesSuccess = payload => ({
  type: GET_ALL_BRAND_TYPES_SUCCESS,
  payload,
});

export const getAllBrandTypesFail = payload => ({
  type: GET_ALL_BRAND_TYPES_FAIL,
  payload,
});

export const resetChefDetail = payload => ({
  type: RESET_CHEF_DETAIL,
  payload,
});

// get chef brand types start

export const getChefBrandTypesSaga = payload => ({
  type: GET_CHEF_BRAND_TYPES_SAGA,
  payload,
});

export const getChefBrandTypesStart = () => ({
  type: GET_CHEF_BRAND_TYPES_START,
});

export const getChefBrandTypesSuccess = payload => ({
  type: GET_CHEF_BRAND_TYPES_SUCCESS,
  payload,
});

export const getChefBrandTypesFail = payload => ({
  type: GET_CHEF_BRAND_TYPES_FAIL,
  payload,
});
// get chef brand types end

export const resetChefBrandTypes = payload => ({
  type: RESET_CHEF_BRAND_TYPES,
  payload,
});

export const getOtherVendorsSaga = payload => ({
  type: GET_OTHER_VENDORS_SAGA,
  payload,
});

// enable disable other vendors
export const enableDisableOtherVendorSaga = payload => ({
  type: ENABLE_DISABLE_OTHER_VENDOR_SAGA,
  payload,
});
export const enableDisableOtherVendorStart = () => ({
  type: ENABLE_DISABLE_OTHER_VENDOR_START,
});
export const enableDisableOtherVendorSuccess = payload => ({
  type: ENABLE_DISABLE_OTHER_VENDOR_SUCCESS,
  payload,
});
export const enableDisableOtherVendorFail = payload => ({
  type: ENABLE_DISABLE_OTHER_VENDOR_FAIL,
  payload,
});

// Ger All producer saga
export const getAllProducerSaga = payload => ({
  type: GET_ALL_PRODUCER_SAGA,
  payload,
});


export const assignToLogisticSaga = payload => ({
  type: ASSIGN_TO_LOGISTIC_SAGA,
  payload,
});
export const assignToLogisticStart = payload => ({
  type: ASSIGN_TO_LOGISTIC_START,
  payload,
});
export const assignToLogisticSuccess = payload => ({
  type: ASSIGN_TO_LOGISTIC_SUCCESS,
  payload,
});
export const assignToLogisticFail = payload => ({
  type: ASSIGN_TO_LOGISTIC_FAIL,
  payload,
});