import React from 'react';

export const promoterRoutes = [
  {
    path: '/promoters/dashboard',
    exact: true,
    name: 'Dashboard',
    component: React.lazy(() =>
      import('../../views/Promoter/Dashboard/Dashboard'),
    ),
  },
  {
    path: '/promoter/profile',
    name: 'Profile',
    component: React.lazy(() => import('../../views/admin/Profile/Profile')),
  },
  // {
  //   path: '/vendors/users',
  //   exact: true,
  //   name: 'Users',
  //   component: React.lazy(() => import('../../views/admin/Users/Users')),
  // },
  // {
  //   path: '/vendors/users/user-detail/:id',
  //   name: 'User Detail',
  //   component: React.lazy(() => import('../../views/admin/Users/UserDetail')),
  // },
  // chef =================================================================
  {
    path: '/promoters/vendors',
    exact: true,
    name: 'Vendors',
    component: React.lazy(() => import('../../views/Promoter/Chefs/Chefs')),
  },
  {
    path: '/promoters/vendors/list',
    exact: true,
    name: 'Vendors',
    component: React.lazy(() => import('../../views/Promoter/Chefs/Chefs')),
  },
  {
    path: '/promoters/add-vendor',
    exact: true,
    name: 'Vendors',
    component: React.lazy(() => import('../../views/Promoter/Chefs/AddChef')),
  },
  // {
  //   path: '/promoter/vendor-detail/:id',
  //   name: 'Vendors Detail',
  //   component: React.lazy(() =>
  //     import('../../views/Promoter/Chefs/ChefDetail'),
  //   ),
  // },
  {
    path: '/promoter/vendor-detail/:id',
    name: 'Vendors Detail',
    component: React.lazy(() =>
      import('../../views/Promoter/Chefs/ChefDetail'),
    ),
  },
  {
    path: '/promoter/chefs',
    exact: true,
    name: 'promoter',
    component: React.lazy(() => import('../../views/admin/Chefs/Chefs')),
  },
  {
    path: '/promoter/drivers',
    exact: true,
    name: 'promoter',
    component: React.lazy(() => import('../../views/Promoter/Drivers/Drivers')),
  },
  {
    path: '/promoters/vehicle-owners',
    exact: true,
    name: 'Vehicle Owners ',
    component: React.lazy(() =>
      import('../../views/admin/VehicleOwners/VehicleOwners'),
    ),
  },
  // {
  //   path: '/vendors/chefs/rating-detail/:id',
  //   name: 'Rating Detail',
  //   component: React.lazy(() => import('../../views/admin/Chefs/RatingDetail')),
  // },
  // Drivers , SS
  {
    path: '/promoters/drivers',
    exact: true,
    name: 'Drivers',
    component: React.lazy(() => import('../../views/Promoter/Drivers/Drivers')),
  },
  {
    path: '/promoters/drivers/add-driver',
    exact: true,
    name: 'Add Driver',
    component: React.lazy(() =>
      import('../../views/Promoter/Drivers/AddDriver'),
    ),
  },
  {
    path: '/promoters/drivers/edit-driver/:id',
    exact: true,
    name: 'Edit Driver',
    component: React.lazy(() =>
      import('../../views/Promoter/Drivers/EditDriver.jsx'),
    ),
  },
  {
    path: '/promoters/drivers/driver-detail/:id',
    name: 'Driver Details',
    component: React.lazy(() =>
      import('../../views/Promoter/Drivers/DriverDetails'),
    ),
  },
  {
    path: '/promoters/drivers/add-Vehicle',
    exact: true,
    name: 'Add Vehicle',
    component: React.lazy(
      () => import('../../views/Promoter/Drivers/AddVehicle'),
      // import('../../views/Promoter/Vehicles/AddVehicle'),
    ),
  },
  {
    path: '/promoters/product',
    exact: true,
    name: 'Product',
    component: React.lazy(
      () => import('../../views/Promoter/Product/Product'),
    ),
  },
  // Product , SS ====================================================
  // {
  //   path: '/vendors/products',
  //   exact: true,
  //   name: 'Products',
  //   component: React.lazy(() => import('../../views/admin/Products/Products')),
  // },
  // {
  //   path: '/vendors/productss/products-detail/:id',
  //   name: 'Product Details',
  //   component: React.lazy(() =>
  //     import('../../views/admin/Drivers/DriverDetails'),
  //   ),
  // },

  // Vehicle Types ==========================
  // {
  //   path: '/vendors/vehicle-types',
  //   exact: true,
  //   name: 'Vehicle Types',
  //   component: React.lazy(() =>
  //     import('../../views/admin/VehiclesTypes/VehiclesTypes'),
  //   ),
  // },
  // {
  //   path: '/vendors/vehicles/add-vehicle',
  //   exact: true,
  //   name: 'Add Vehicle Type',
  //   component: React.lazy(() =>
  //     import('../../views/admin/VehiclesTypes/AddVehicleType'),
  //   ),
  // },

  // Vehicle Owners==========================
  {
    path: '/promoters/vehicle-owner',
    exact: true,
    name: 'Vehicle Owners',
    component: React.lazy(() =>
      import('../../views/Promoter/VehicleOwners/VehicleOwners'),
    ),
  },

  {
    path: '/promoters/vehicle-owners/add-vehicle-owner',
    exact: true,
    name: 'Vehicle Owners',
    component: React.lazy(() =>
      import('../../views/Promoter/VehicleOwners/AddVehicleOwner'),
    ),
  },
  {
    path: '/promoters/vehicle-owners/edit-vehicle-owner/:id',
    exact: true,
    name: 'Vehicle Owners',
    component: React.lazy(() =>
      import('../../views/Promoter/VehicleOwners/EditVehicleOwner'),
    ),
  },

  {
    path: '/promoters/commission',
    name: 'Commission',
    component: React.lazy(() =>
      import('../../views/Promoter/Commission/Commission'),
    ),
  },
  {
    path: '/promoters/vehicles',
    name: 'Commission',
    component: React.lazy(() =>
      import('../../views/Promoter/Vehicles/Vehicles'),
    ),
  },
  {
    path: '/promoters/vehicle/add-vehicle',
    exact: true,
    name: 'Add Vehicle',
    component: React.lazy(() =>
      import('../../views/Promoter/Vehicles/AddVehicle'),
    ),
  },
  {
    path: '/promoters/vehicle/edit-driver/:id',
    exact: true,
    name: 'Edit Driver',
    component: React.lazy(() =>
      import('../../views/Promoter/Vehicles/EditVehicle'),
    ),
  },
  {
    path: '/promoters/vehicle/vehicle-detail/:id',
    name: 'Vehicle Detail',
    component: React.lazy(() =>
      import('../../views/Promoter/Vehicles/VehicleDetail'),
    ),
  },

  // logistic hub

  {
    path: '/promoters/logistic-hub',
    name: 'Logistic Hub',
    component: React.lazy(() =>
      import('../../views/Promoter/Logistic/logistic'),
    ),
  },
  {
    path: '/promoters/add-logistic-hub',
    name: 'Logistic Hub',
    component: React.lazy(() =>
      import('../../views/Promoter/Logistic/AddLogistic'),
    ),
  },
  {
    path: '/promoters/edit-logistic-hub/:id',
    name: 'Logistic Hub',
    component: React.lazy(() =>
      import('../../views/Promoter/Logistic/EditLogistic'),
    ),
  },
  {
    path: '/promoters/logistic-details/:id',
    name: 'Logistic Details',
    component: React.lazy(() =>
      import('../../views/Promoter/Logistic/LogisticDetail'),
    ),
  },

  // {
  //   path: '/vendors/orders',
  //   name: 'Orders',
  //   component: React.lazy(() => import('../../views/admin/Orders/Orders')),
  // },
  // {
  //   path: '/vendors/rating-management',
  //   exact: true,
  //   name: 'Rating Management',
  //   component: React.lazy(() =>
  //     import('../../views/admin/RatingManagement/RatingManagement'),
  //   ),
  // },
  // {
  //   path: '/vendors/inquiries',
  //   exact: true,
  //   name: 'Enquiries',
  //   component: React.lazy(() =>
  //     import('../../views/admin/Inquiries/Inquiries'),
  //   ),
  // },
  // {
  //   path: '/vendors/inquiries/inquiry-detail/:id',
  //   name: 'Enquiry Detail',
  //   component: React.lazy(() =>
  //     import('../../views/admin/Inquiries/InquiryDetail'),
  //   ),
  // },
  // {
  //   path: '/vendors/menu-categories',
  //   name: 'Products',
  //   component: React.lazy(() =>
  //     import('../../views/admin/MenuCategories/MenuCategories'),
  //   ),
  // },
  // {
  //   path: '/vendors/brand-types',
  //   name: 'Vendor Types',
  //   component: React.lazy(() =>
  //     import('../../views/admin/BrandTypes/BrandTypes'),
  //   ),
  // },
  // {
  //   path: '/vendors/menu-sub-categories',
  //   name: 'Sub-Products',
  //   component: React.lazy(() =>
  //     import('../../views/admin/MenuSubCategories/MenuSubCategories'),
  //   ),
  // },
  // {
  //   path: '/vendors/contact-us',
  //   name: 'Contact Us',
  //   component: React.lazy(() =>
  //     import('../../views/admin/ContactUs/ContactUs'),
  //   ),
  // },
  // {
  //   path: '/vendors/settings',
  //   name: 'Settings',
  //   component: React.lazy(() => import('../../views/admin/Settings/Settings')),
  // },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/promoters/dashboard',
    // rootRedirect: true,
  },
];
