import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  supportRequests: { list: [], totalRecords: { count: 0 } },
  isLoading: false,
  errorMsg: '',
  errorMsgAddSupportRequest: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_SUPPORT_REQUEST_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_SUPPORT_REQUEST_SUCCESS: {
      const { supportRequests } = payload;
      return { ...state, supportRequests, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_SUPPORT_REQUEST_FAIL:
      return {
        ...state,
        supportRequests: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.ADD_SUPPORT_REQUEST_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_SUPPORT_REQUEST_SUCCESS: {
      return { ...state, isLoading: false, errorMsgAddSupportRequest: '' };
    }
    case actionLabels.ADD_SUPPORT_REQUEST_FAIL:
      return { ...state, isLoading: false, errorMsgAddSupportRequest: payload };
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsgAddSupportRequest: '' };
    }
    default:
      return state;
  }
};
