import React from 'react';

export * from './promoterRoutes';
export * from './vehicleOwnerRoutes';
export * from './adminRoutes';
export * from './chefRoutes';
export * from './logisticRoutes';

export const guestRoutes = [
  {
    path: '/vendors',
    name: 'Login',
    exact: true,
    component: React.lazy(() => import('../../views/Login/Login')),
  },
  {
    path: '/vendors/inquiry',
    name: 'Inquiry',
    exact: true,
    component: React.lazy(() => import('../../views/Inquiry/Inquiry')),
  },
  {
    path: '/vendors/reset-password/:token',
    name: 'Reset Password',
    exact: true,
    component: React.lazy(() =>
      import('../../views/ResetPassword/ResetPassword'),
    ),
  },
  {
    path: '/vendors/register/:token',
    name: 'Register',
    exact: true,
    component: React.lazy(() => import('../../views/Register/Register')),
  },
  {
    path: '/vendors/email-verification/:token',
    name: 'Email verification',
    exact: true,
    component: React.lazy(() =>
      import('../../views/EmailVerification/EmailVerification'),
    ),
  },
  {
    path: '/vendors/otp-verification',
    name: 'OTP Verification',
    exact: true,
    component: React.lazy(() =>
      import('../../views/OTPVerification/OTPVerification'),
    ),
  },
  {
    path: '/vendors/forgot-password',
    name: 'Forgot Password',
    exact: true,
    component: React.lazy(() =>
      import('../../views/ForgotPassword/ForgotPassword'),
    ),
  },
  {
    path: '/vendors/privacy-policy',
    name: 'Privacy Policy',
    exact: true,
    component: React.lazy(() => import('../../views/CMS/Policy')),
  },
  {
    path: '/vendors/term-condition',
    name: 'Terms & Conditions',
    exact: true,
    component: React.lazy(() => import('../../views/CMS/ChefTC')),
  },
  {
    path: '/vendors/marketing/privacy-policy',
    name: 'Marketing Privacy Policy',
    exact: true,
    component: React.lazy(() =>
      import('../../views/CMS/Marketing/MarketingPolicy'),
    ),
  },
  {
    path: '/vendors/marketing/term-condition',
    name: 'Marketing Terms & Conditions',
    exact: true,
    component: React.lazy(() =>
      import('../../views/CMS/Marketing/MarketingTC'),
    ),
  },
  {
    path: '/vendors/marketing/about-us',
    name: 'Marketing About Us',
    exact: true,
    component: React.lazy(() =>
      import('../../views/CMS/Marketing/MarketingAboutUs'),
    ),
  },
  {
    path: '/vendors/marketing/teams',
    name: 'Marketing Teams',
    exact: true,
    component: React.lazy(() =>
      import('../../views/CMS/Marketing/MarketingTeam'),
    ),
  },
  {
    path: '/vendors/marketing/faqs',
    name: 'Marketing FAQs',
    exact: true,
    component: React.lazy(() =>
      import('../../views/CMS/Marketing/MarketingFAQs'),
    ),
  },
  {
    path: '/vendors/marketing/careers',
    name: 'Marketing Careers',
    exact: true,
    component: React.lazy(() =>
      import('../../views/CMS/Marketing/MarketingCareers'),
    ),
  },
  {
    path: '/vendors/marketing/refund-cancellation',
    name: 'Marketing Refund & Cancellation',
    exact: true,
    component: React.lazy(() =>
      import('../../views/CMS/Marketing/MarketingRC'),
    ),
  },
  {
    path: '/vendors/marketing/cookie-policy',
    name: 'Marketing Cookie Policy',
    exact: true,
    component: React.lazy(() =>
      import('../../views/CMS/Marketing/MarketingCookiePolicy'),
    ),
  },
  {
    path: '/vendors/marketing/offer-terms',
    name: 'Marketing Offer Terms',
    exact: true,
    component: React.lazy(() =>
      import('../../views/CMS/Marketing/MarketingOfferTerms'),
    ),
  },
  {
    path: '/vendors/marketing/site-map',
    name: 'Marketing Site Map',
    exact: true,
    component: React.lazy(() =>
      import('../../views/CMS/Marketing/MarketingSiteMap'),
    ),
  },
];

// {
/*

export const adminRoutes = [
  {
    path: '/vendors/dashboard',
    exact: true,
    name: 'Dashboard',
    component: React.lazy(() =>
      import('../../views/admin/Dashboard/Dashboard'),
    ),
  },
  {
    path: '/vendors/profile',
    name: 'Profile',
    component: React.lazy(() => import('../../views/admin/Profile/Profile')),
  },
  {
    path: '/vendors/users',
    exact: true,
    name: 'Users',
    component: React.lazy(() => import('../../views/admin/Users/Users')),
  },
  {
    path: '/vendors/users/user-detail/:id',
    name: 'User Detail',
    component: React.lazy(() => import('../../views/admin/Users/UserDetail')),
  },
  {
    path: '/vendors/chefs',
    exact: true,
    name: 'Vendors',
    component: React.lazy(() => import('../../views/admin/Chefs/Chefs')),
  },
  {
    path: '/vendors/chefs/chef-detail/:id',
    name: 'Vendors Detail',
    component: React.lazy(() => import('../../views/admin/Chefs/ChefDetail')),
  },
  {
    path: '/vendors/chefs/rating-detail/:id',
    name: 'Rating Detail',
    component: React.lazy(() => import('../../views/admin/Chefs/RatingDetail')),
  },
  // Drivers , SS
  {
    path: '/vendors/drivers',
    exact: true,
    name: 'Drivers',
    component: React.lazy(() => import('../../views/admin/Drivers/Drivers')),
  },
  {
    path: '/vendors/drivers/add-driver',
    exact: true,
    name: 'Add Driver',
    component: React.lazy(() => import('../../views/admin/Drivers/AddDriver')),
  },
  {
    path: '/vendors/drivers/driver-detail/:id',
    name: 'Driver Details',
    component: React.lazy(() =>
      import('../../views/admin/Drivers/DriverDetails'),
    ),
  },
  // Notifications , SS ====================================================
  {
    path: '/vendors/requests',
    exact: true,
    name: 'Enquiries',
    component: React.lazy(() => import('../../views/admin/Requests/Requests')),
  },
  {
    path: '/vendors/requests/request-detail/:id',
    name: 'Enquiry Detail',
    component: React.lazy(() =>
      import('../../views/admin/Requests/RequestDetail'),
    ),
  },
  // Product , SS ====================================================
  {
    path: '/vendors/products',
    exact: true,
    name: 'Products',
    component: React.lazy(() => import('../../views/admin/Products/Products')),
  },
  {
    path: '/vendors/productss/products-detail/:id',
    name: 'Product Details',
    component: React.lazy(() =>
      import('../../views/admin/Drivers/DriverDetails'),
    ),
  },

  // Vehicle Types ==========================
  {
    path: '/vendors/vehicle-types',
    exact: true,
    name: 'Vehicle Types',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/VehiclesTypes'),
    ),
  },
  {
    path: '/vendors/vehicles/add-vehicle',
    exact: true,
    name: 'Add Vehicle Type',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/AddVehicleType'),
    ),
  },
  {
    path: '/vendors/vehicles/edit-vehicle/:id',
    exact: true,
    name: 'Edit Vehicle Type',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/EditVehicleType'),
    ),
  },

  // Vehicle Owners==========================
  {
    path: '/vendors/vehicle-owners',
    exact: true,
    name: 'Vehicle Owners',
    component: React.lazy(() =>
      import('../../views/admin/VehicleOwners/VehicleOwners'),
    ),
  },

  {
    path: '/vendors/vehicle-owners/add-vehicle-owner',
    exact: true,
    name: 'Vehicle Owners',
    component: React.lazy(() =>
      import('../../views/admin/VehicleOwners/AddVehicleOwner'),
    ),
  },
  {
    path: '/vendors/vehicle-owners/edit-vehicle-owner/:id',
    exact: true,
    name: 'Vehicle Owners',
    component: React.lazy(() =>
      import('../../views/admin/VehicleOwners/EditVehicleOwner'),
    ),
  },

  {
    path: '/vendors/subadmins',
    name: 'Subadmins',
    component: React.lazy(() =>
      import('../../views/admin/Subadmins/Subadmins'),
    ),
  },
  {
    path: '/vendors/orders',
    name: 'Orders',
    component: React.lazy(() => import('../../views/admin/Orders/Orders')),
  },
  {
    path: '/vendors/promocodes',
    exact: true,
    name: 'Promocodes',
    component: React.lazy(() =>
      import('../../views/admin/Promocodes/Promocodes'),
    ),
  },
  {
    path: '/vendors/promocodes/add-promocode',
    name: 'Add Promocode',
    component: React.lazy(() =>
      import('../../views/admin/Promocodes/AddPromocode'),
    ),
  },
  {
    path: '/vendors/promocodes/edit-promocode/:id',
    name: 'Edit Promocode',
    component: React.lazy(() =>
      import('../../views/admin/Promocodes/AddPromocode'),
    ),
  },
  {
    path: '/vendors/rating-management',
    exact: true,
    name: 'Rating Management',
    component: React.lazy(() =>
      import('../../views/admin/RatingManagement/RatingManagement'),
    ),
  },
  {
    path: '/vendors/inquiries',
    exact: true,
    name: 'Enquiries',
    component: React.lazy(() =>
      import('../../views/admin/Inquiries/Inquiries'),
    ),
  },
  {
    path: '/vendors/inquiries/inquiry-detail/:id',
    name: 'Enquiry Detail',
    component: React.lazy(() =>
      import('../../views/admin/Inquiries/InquiryDetail'),
    ),
  },
  // Promoters ======================================================
  {
    path: '/vendors/promoters',
    exact: true,
    name: 'Promoters',
    component: React.lazy(() =>
      import('../../views/admin/Promoters/Promoters'),
    ),
  },
  {
    path: '/vendors/promoters/add-promoter',
    exact: true,
    name: 'Promoters',
    component: React.lazy(() =>
      import('../../views/admin/Promoters/AddPromoter'),
    ),
  },
  {
    path: '/vendors/promoters/edit-promoter/:id',
    exact: true,
    name: 'Promoters',
    component: React.lazy(() =>
      import('../../views/admin/Promoters/EditPromoter'),
    ),
  },
  {
    path: '/vendors/services',
    name: 'Services',
    component: React.lazy(() => import('../../views/admin/Services/Services')),
  },
  {
    path: '/vendors/menu-categories',
    name: 'Products',
    component: React.lazy(() =>
      import('../../views/admin/MenuCategories/MenuCategories'),
    ),
  },
  {
    path: '/vendors/brand-types',
    name: 'Vendor Types',
    component: React.lazy(() =>
      import('../../views/admin/BrandTypes/BrandTypes'),
    ),
  },
  {
    path: '/vendors/menu-sub-categories',
    name: 'Sub-Products',
    component: React.lazy(() =>
      import('../../views/admin/MenuSubCategories/MenuSubCategories'),
    ),
  },
  {
    path: '/vendors/contact-us',
    name: 'Contact Us',
    component: React.lazy(() =>
      import('../../views/admin/ContactUs/ContactUs'),
    ),
  },
  {
    path: '/vendors/payout',
    exact: true,
    name: 'Contact Us',
    component: React.lazy(() => import('../../views/admin/Payout/Payout')),
  },
  {
    path: '/vendors/payout/:id',
    name: 'Payout',
    component: React.lazy(() =>
      import('../../views/admin/Payout/PayoutHistory'),
    ),
  },
  {
    path: '/vendors/cms',
    exact: true,
    name: 'CMS',
    component: React.lazy(() => import('../../views/admin/CMS/CMS')),
  },
  {
    path: '/vendors/cms/edit-cms/:key',
    name: 'Edit CMS',
    component: React.lazy(() => import('../../views/admin/CMS/EditCMS')),
  },
  {
    path: '/vendors/cms/view-cms/:key',
    name: 'View CMS',
    component: React.lazy(() => import('../../views/admin/CMS/ViewCMS')),
  },
  {
    path: '/vendors/reports',
    name: 'Reports',
    component: React.lazy(() => import('../../views/admin/Reports/Reports')),
  },
  {
    path: '/vendors/settings',
    name: 'Settings',
    component: React.lazy(() => import('../../views/admin/Settings/Settings')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/vendors/dashboard',
    // rootRedirect: true,
  },
];

 */
// }
export const subAdminRoutes = [
  {
    path: '/vendors/dashboard',
    exact: true,
    name: 'Dashboard',
    component: React.lazy(() =>
      import('../../views/admin/Dashboard/Dashboard'),
    ),
  },
  {
    path: '/vendors/profile',
    name: 'Profile',
    component: React.lazy(() => import('../../views/admin/Profile/Profile')),
  },
  {
    path: '/vendors/users',
    exact: true,
    name: 'Users',
    component: React.lazy(() => import('../../views/admin/Users/Users')),
  },
  {
    path: '/vendors/users/user-detail/:id',
    name: 'User Detail',
    component: React.lazy(() => import('../../views/admin/Users/UserDetail')),
  },
  {
    path: '/vendors/chefs',
    exact: true,
    name: 'Vendors',
    component: React.lazy(() => import('../../views/admin/Chefs/Chefs')),
  },
  {
    path: '/vendors/chefs/chef-detail/:id',
    name: 'Vendors Detail',
    component: React.lazy(() => import('../../views/admin/Chefs/ChefDetail')),
  },
  {
    path: '/vendors/chefs/rating-detail/:id',
    name: 'Rating Detail',
    component: React.lazy(() => import('../../views/admin/Chefs/RatingDetail')),
  },
  {
    path: '/vendors/orders',
    name: 'Orders',
    component: React.lazy(() => import('../../views/admin/Orders/Orders')),
  },
  {
    path: '/vendors/inquiries',
    exact: true,
    name: 'Enquiries',
    component: React.lazy(() =>
      import('../../views/admin/Inquiries/Inquiries'),
    ),
  },
  {
    path: '/vendors/inquiries/inquiry-detail/:id',
    name: 'Enquiry Detail',
    component: React.lazy(() =>
      import('../../views/admin/Inquiries/InquiryDetail'),
    ),
  },
  {
    path: '/vendors/payout',
    exact: true,
    name: 'Contact Us',
    component: React.lazy(() => import('../../views/admin/Payout/Payout')),
  },
  {
    path: '/vendors/payout/:id',
    name: 'Payout',
    component: React.lazy(() =>
      import('../../views/admin/Payout/PayoutHistory'),
    ),
  },
  {
    path: '/vendors/cms',
    exact: true,
    name: 'CMS',
    component: React.lazy(() => import('../../views/admin/CMS/CMS')),
  },
  {
    path: '/vendors/cms/edit-cms/:key',
    name: 'Edit CMS',
    component: React.lazy(() => import('../../views/admin/CMS/EditCMS')),
  },
  {
    path: '/vendors/reports',
    name: 'Reports',
    component: React.lazy(() => import('../../views/admin/Reports/Reports')),
  },
  {
    path: '/vendors/settings',
    name: 'Settings',
    component: React.lazy(() => import('../../views/admin/Settings/Settings')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/vendors/dashboard',
    // rootRedirect: true,
  },
];

// {
/* 
export const chefRoutes = [
  {
    path: '/vendors/dashboard',
    exact: true,
    name: 'Dashboard',
    component: React.lazy(() => import('../../views/chef/Dashboard/Dashboard')),
  },
  {
    path: '/vendors/orders',
    name: 'Orders',
    component: React.lazy(() => import('../../views/chef/Orders/Orders')),
  },
  {
    path: '/vendors/upcoming-orders',
    exact: true,
    name: 'Upcoming Orders',
    component: React.lazy(() =>
      import('../../views/chef/UpcomingOrders/UpcomingOrders'),
    ),
  },
  {
    path: '/vendors/upcoming-orders/:id',
    name: 'Upcoming Orders',
    component: React.lazy(() =>
      import('../../views/chef/UpcomingOrders/UpcomingOrders'),
    ),
  },
  // {
  //   path: '/vendors/menu-categories',
  //   name: 'Menu Categories',
  //   component: React.lazy(() =>
  //     import('../../views/chef/MenuCategories/MenuCategories'),
  //   ),
  // },
  {
    path: '/vendors/menu-items',
    name: 'Menu Items',
    component: React.lazy(() => import('../../views/chef/MenuItems/MenuItems')),
  },
  {
    path: '/vendors/reviews',
    name: 'Reviews',
    component: React.lazy(() => import('../../views/chef/Reviews/Reviews')),
  },
  {
    path: '/vendors/payment-history',
    name: 'Payment History',
    component: React.lazy(() =>
      import('../../views/chef/PaymentHistory/PaymentHistory'),
    ),
  },
  {
    path: '/vendors/payment-history-detail',
    name: 'Customer Componsation',
    component: React.lazy(() =>
      import('../../views/chef/PaymentHistoryDetail/PaymentHistoryDetail'),
    ),
  },
  {
    path: '/vendors/support-request',
    name: 'Support Request',
    component: React.lazy(() =>
      import('../../views/chef/SupportRequest/SupportRequest'),
    ),
  },
  {
    path: '/vendors/profile',
    name: 'Profile',
    component: React.lazy(() => import('../../views/chef/Profile/Profile')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/vendors/dashboard',
    // rootRedirect: true,
  },
];
*/
// }

export const chefRoutesUnverified = [
  {
    path: '/vendors/profile',
    name: 'Profile',
    component: React.lazy(() => import('../../views/chef/Profile/Profile')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/vendors/profile',
    // rootRedirect: true,
  },
];

// {
/*
export const promoterRoutes = [
  {
    path: '/promoters/dashboard',
    exact: true,
    name: 'Dashboard',
    component: React.lazy(() =>
      import('../../views/Promoter/Dashboard/Dashboard'),
    ),
  },
  {
    path: '/vendors/profile',
    name: 'Profile',
    component: React.lazy(() => import('../../views/admin/Profile/Profile')),
  },
  {
    path: '/vendors/users',
    exact: true,
    name: 'Users',
    component: React.lazy(() => import('../../views/admin/Users/Users')),
  },
  {
    path: '/vendors/users/user-detail/:id',
    name: 'User Detail',
    component: React.lazy(() => import('../../views/admin/Users/UserDetail')),
  },
  {
    path: '/promoters/chefs',
    exact: true,
    name: 'Vendors',
    component: React.lazy(() => import('../../views/admin/Chefs/Chefs')),
  },
  {
    path: '/vendors/chefs/chef-detail/:id',
    name: 'Vendors Detail',
    component: React.lazy(() => import('../../views/admin/Chefs/ChefDetail')),
  },
  {
    path: '/vendors/chefs/rating-detail/:id',
    name: 'Rating Detail',
    component: React.lazy(() => import('../../views/admin/Chefs/RatingDetail')),
  },
  // Drivers , SS
  {
    path: '/promoters/drivers',
    exact: true,
    name: 'Drivers',
    component: React.lazy(() => import('../../views/Promoter/Drivers/Drivers')),
  },
  {
    path: '/promoters/drivers/add-driver',
    exact: true,
    name: 'Add Driver',
    component: React.lazy(() =>
      import('../../views/Promoter/Drivers/AddDriver'),
    ),
  },
  {
    path: '/promoters/drivers/driver-detail/:id',
    name: 'Driver Details',
    component: React.lazy(() =>
      import('../../views/Promoter/Drivers/DriverDetails'),
    ),
  },
  // Product , SS ====================================================
  {
    path: '/vendors/products',
    exact: true,
    name: 'Products',
    component: React.lazy(() => import('../../views/admin/Products/Products')),
  },
  {
    path: '/vendors/productss/products-detail/:id',
    name: 'Product Details',
    component: React.lazy(() =>
      import('../../views/admin/Drivers/DriverDetails'),
    ),
  },

  // Vehicle Types ==========================
  {
    path: '/vendors/vehicle-types',
    exact: true,
    name: 'Vehicle Types',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/VehiclesTypes'),
    ),
  },
  {
    path: '/vendors/vehicles/add-vehicle',
    exact: true,
    name: 'Add Vehicle Type',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/AddVehicleType'),
    ),
  },

  // Vehicle Owners==========================
  {
    path: '/promoters/vehicle-owner',
    exact: true,
    name: 'Vehicle Owners',
    component: React.lazy(() =>
      import('../../views/Promoter/VehicleOwners/VehicleOwners'),
    ),
  },

  {
    path: '/promoters/vehicle-owners/add-vehicle-owner',
    exact: true,
    name: 'Vehicle Owners',
    component: React.lazy(() =>
      import('../../views/Promoter/VehicleOwners/AddVehicleOwner'),
    ),
  },
  {
    path: '/promoters/vehicle-owners/edit-vehicle-owner/:id',
    exact: true,
    name: 'Vehicle Owners',
    component: React.lazy(() =>
      import('../../views/Promoter/VehicleOwners/EditVehicleOwner'),
    ),
  },
  {
    path: '/vendors/orders',
    name: 'Orders',
    component: React.lazy(() => import('../../views/admin/Orders/Orders')),
  },
  {
    path: '/vendors/rating-management',
    exact: true,
    name: 'Rating Management',
    component: React.lazy(() =>
      import('../../views/admin/RatingManagement/RatingManagement'),
    ),
  },
  {
    path: '/vendors/inquiries',
    exact: true,
    name: 'Enquiries',
    component: React.lazy(() =>
      import('../../views/admin/Inquiries/Inquiries'),
    ),
  },
  {
    path: '/vendors/inquiries/inquiry-detail/:id',
    name: 'Enquiry Detail',
    component: React.lazy(() =>
      import('../../views/admin/Inquiries/InquiryDetail'),
    ),
  },
  {
    path: '/vendors/menu-categories',
    name: 'Products',
    component: React.lazy(() =>
      import('../../views/admin/MenuCategories/MenuCategories'),
    ),
  },
  {
    path: '/vendors/brand-types',
    name: 'Vendor Types',
    component: React.lazy(() =>
      import('../../views/admin/BrandTypes/BrandTypes'),
    ),
  },
  {
    path: '/vendors/menu-sub-categories',
    name: 'Sub-Products',
    component: React.lazy(() =>
      import('../../views/admin/MenuSubCategories/MenuSubCategories'),
    ),
  },
  {
    path: '/vendors/contact-us',
    name: 'Contact Us',
    component: React.lazy(() =>
      import('../../views/admin/ContactUs/ContactUs'),
    ),
  },
  {
    path: '/vendors/settings',
    name: 'Settings',
    component: React.lazy(() => import('../../views/admin/Settings/Settings')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/promoters/dashboard',
    // rootRedirect: true,
  },
];
 */
// }
// {
/*
export const vehicleOwnerRoutes = [
  {
    path: '/vehicle-owner/dashboard',
    exact: true,
    name: 'Dashboard',
    component: React.lazy(() =>
      import('../../views/VehicleOwner/Dashboard/Dashboard'),
    ),
  },
  // {
  //   path: '/vendors/profile',
  //   name: 'Profile',
  //   component: React.lazy(() => import('../../views/admin/Profile/Profile')),
  // },
  // {
  //   path: '/vendors/users',
  //   exact: true,
  //   name: 'Users',
  //   component: React.lazy(() => import('../../views/admin/Users/Users')),
  // },
  // {
  //   path: '/vendors/users/user-detail/:id',
  //   name: 'User Detail',
  //   component: React.lazy(() => import('../../views/admin/Users/UserDetail')),
  // },
  // {
  //   path: '/vendors/chefs',
  //   exact: true,
  //   name: 'Vendors',
  //   component: React.lazy(() => import('../../views/admin/Chefs/Chefs')),
  // },
  // {
  //   path: '/vendors/chefs/chef-detail/:id',
  //   name: 'Vendors Detail',
  //   component: React.lazy(() => import('../../views/admin/Chefs/ChefDetail')),
  // },
  // {
  //   path: '/vendors/chefs/rating-detail/:id',
  //   name: 'Rating Detail',
  //   component: React.lazy(() => import('../../views/admin/Chefs/RatingDetail')),
  // },
  // Drivers , SS
  {
    path: '/vehicle-owner/drivers',
    exact: true,
    name: 'Drivers',
    component: React.lazy(() =>
      import('../../views/VehicleOwner/Drivers/Drivers'),
    ),
  },
  {
    path: '/vehicle-owner/drivers/add-driver',
    exact: true,
    name: 'Add Driver',
    component: React.lazy(() =>
      import('../../views/VehicleOwner/Drivers/AddDriver'),
    ),
  },
  {
    path: '/vehicle-owner/drivers/driver-detail/:id',
    name: 'Driver Details',
    component: React.lazy(() =>
      import('../../views/VehicleOwner/Drivers/DriverDetails'),
    ),
  },
  // Product , SS ====================================================
  // {
  //   path: '/vendors/products',
  //   exact: true,
  //   name: 'Products',
  //   component: React.lazy(() => import('../../views/admin/Products/Products')),
  // },
  // {
  //   path: '/vendors/productss/products-detail/:id',
  //   name: 'Product Details',
  //   component: React.lazy(() =>
  //     import('../../views/admin/Drivers/DriverDetails'),
  //   ),
  // },

  // All Vehicles ==========================
  {
    path: '/vehicle-owner/vehicles',
    exact: true,
    name: 'Vehicle Types',
    component: React.lazy(() =>
      import('../../views/VehicleOwner/Vehicles/Vehicles'),
    ),
  },
  {
    path: '/vehicle-owner/vehicles/add-owner-vehicle',
    exact: true,
    name: 'Add Vehicle',
    component: React.lazy(() =>
      import('../../views/VehicleOwner/Vehicles/AddVehicle'),
    ),
  },
  {
    path: '/vehicle-owner/vehicles/edit-owner-vehicle/:id',
    exact: true,
    name: 'Edit Vehicle',
    component: React.lazy(() =>
      import('../../views/VehicleOwner/Vehicles/EditVehicle'),
    ),
  },
  // Vehicle Types ==========================
  {
    path: '/vehicle-owner/vehicle-types',
    exact: true,
    name: 'Vehicle Types',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/VehiclesTypes'),
    ),
  },
  {
    path: '/vehicle-owner/vehicles/add-vehicle',
    exact: true,
    name: 'Add Vehicle Type',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/AddVehicleType'),
    ),
  },
  {
    path: '/vehicle-owner/vehicles/edit-vehicle/:id',
    exact: true,
    name: 'Edit Vehicle Type',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/EditVehicleType'),
    ),
  },

  // Vehicle Owners==========================
  // {
  //   path: '/vendors/vehicle-owners',
  //   exact: true,
  //   name: 'Vehicle Owners',
  //   component: React.lazy(() =>
  //     import('../../views/admin/VehicleOwners/VehicleOwners'),
  //   ),
  // },

  // {
  //   path: '/vendors/vehicle-owners/add-vehicle-owner',
  //   exact: true,
  //   name: 'Vehicle Owners',
  //   component: React.lazy(() =>
  //     import('../../views/admin/VehicleOwners/AddVehicleOwner'),
  //   ),
  // },
  // {
  //   path: '/vendors/orders',
  //   name: 'Orders',
  //   component: React.lazy(() => import('../../views/admin/Orders/Orders')),
  // },
  // {
  //   path: '/vendors/rating-management',
  //   exact: true,
  //   name: 'Rating Management',
  //   component: React.lazy(() =>
  //     import('../../views/admin/RatingManagement/RatingManagement'),
  //   ),
  // },
  // {
  //   path: '/vendors/inquiries',
  //   exact: true,
  //   name: 'Enquiries',
  //   component: React.lazy(() =>
  //     import('../../views/admin/Inquiries/Inquiries'),
  //   ),
  // },
  // {
  //   path: '/vendors/inquiries/inquiry-detail/:id',
  //   name: 'Enquiry Detail',
  //   component: React.lazy(() =>
  //     import('../../views/admin/Inquiries/InquiryDetail'),
  //   ),
  // },
  // {
  //   path: '/vendors/menu-categories',
  //   name: 'Products',
  //   component: React.lazy(() =>
  //     import('../../views/admin/MenuCategories/MenuCategories'),
  //   ),
  // },
  // {
  //   path: '/vendors/brand-types',
  //   name: 'Vendor Types',
  //   component: React.lazy(() =>
  //     import('../../views/admin/BrandTypes/BrandTypes'),
  //   ),
  // },
  // {
  //   path: '/vendors/menu-sub-categories',
  //   name: 'Sub-Products',
  //   component: React.lazy(() =>
  //     import('../../views/admin/MenuSubCategories/MenuSubCategories'),
  //   ),
  // },
  {
    path: '/vendors/contact-us',
    name: 'Contact Us',
    component: React.lazy(() =>
      import('../../views/admin/ContactUs/ContactUs'),
    ),
  },
  {
    path: '/vendors/settings',
    name: 'Settings',
    component: React.lazy(() => import('../../views/admin/Settings/Settings')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/vehicle-owner/dashboard',
    // rootRedirect: true,
  },
];
*/
// }
