export * from './dummy/dummy';
export * from './sideBarOpen/sideBarOpen';
export * from './auth/auth';
// eslint-disable-next-line import/export
export * from './dashboard/dashboard';
export * from './user/chef';
export * from './user/user';
export * from './user/driver'; // Added By SS
export * from './vehicle/ownerVehicle'; // Vehicle
export * from './vehicle/vehicle'; // Vehicle
export * from './user/vehicleOwner'; // SS
export * from './services/services';
export * from './requests/requests'; // requests
export * from './commission/commission'; // commission
export * from './user/subadmin';
export * from './promocode/promocode';
export * from './order/order';
export * from './review/review';
export * from './dish/dish';
export * from './driver/driver';
export * from './chef/chef';
export * from './contactus/contactus';
export * from './cms/cms';
export * from './setting/setting';
export * from './inquiry/inquiry';
export * from './supportRequest/supportRequest';
export * from './modal/modal';
export * from './payment/payment';
export * from './notification/notification';
export * from './reports/reports';
export * from './promoters/promoters';
export * from './logistics/logistics';
export * from './referral/referral';
export * from './storeTypes/storeTypes';
export * from './transportationModes/TransportationModes';
export * from './moduleAccess/moduleAccess';
export * from './activeTab/activeTab';
export const resetApp = payload => ({
  type: 'RESET_APP',
  payload,
});
