import {
  GET_ALL_TRANSPORTATION_MODES_START,
  GET_ALL_TRANSPORTATION_MODES_SAGA,
  GET_ALL_TRANSPORTATION_MODES_SUCCESS,
  GET_ALL_TRANSPORTATION_MODES_FAIL,
  ADD_TRANSPORTATION_MODE_START,
  ADD_TRANSPORTATION_MODE_SAGA,
  ADD_TRANSPORTATION_MODE_SUCCESS,
  ADD_TRANSPORTATION_MODE_FAIL,
  EDIT_TRANSPORTATION_MODE_START,
  EDIT_TRANSPORTATION_MODE_SAGA,
  EDIT_TRANSPORTATION_MODE_SUCCESS,
  EDIT_TRANSPORTATION_MODE_FAIL,
  DELETE_TRANSPORTATION_MODE_START,
  DELETE_TRANSPORTATION_MODE_SAGA,
  DELETE_TRANSPORTATION_MODE_SUCCESS,
  DELETE_TRANSPORTATION_MODE_FAIL,
  EDIT_TRANSPORTATION_MODE_STATUS_START,
  EDIT_TRANSPORTATION_MODE_STATUS_SAGA,
  EDIT_TRANSPORTATION_MODE_STATUS_SUCCESS,
  EDIT_TRANSPORTATION_MODE_STATUS_FAIL,
} from '../actionLabels';

// Get All
export const getAllTransportationModesStart = () => ({
  type: GET_ALL_TRANSPORTATION_MODES_START,
});

export const getAllTransportationModesSaga = payload => ({
  type: GET_ALL_TRANSPORTATION_MODES_SAGA,
  payload,
});

export const getAllTransportationModesSuccess = payload => ({
  type: GET_ALL_TRANSPORTATION_MODES_SUCCESS,
  payload,
});

export const getAllTransportationModesFail = payload => ({
  type: GET_ALL_TRANSPORTATION_MODES_FAIL,
  payload,
});

// Add
export const addTransportationModeStart = () => ({
  type: ADD_TRANSPORTATION_MODE_START,
});

export const addTransportationModeSaga = payload => ({
  type: ADD_TRANSPORTATION_MODE_SAGA,
  payload,
});

export const addTransportationModeSuccess = payload => ({
  type: ADD_TRANSPORTATION_MODE_SUCCESS,
  payload,
});

export const addTransportationModeFail = payload => ({
  type: ADD_TRANSPORTATION_MODE_FAIL,
  payload,
});

// Edit
export const editTransportationModeStart = () => ({
  type: EDIT_TRANSPORTATION_MODE_START,
});

export const editTransportationModeSaga = payload => ({
  type: EDIT_TRANSPORTATION_MODE_SAGA,
  payload,
});

export const editTransportationModeSuccess = payload => ({
  type: EDIT_TRANSPORTATION_MODE_SUCCESS,
  payload,
});

export const editTransportationModeFail = payload => ({
  type: EDIT_TRANSPORTATION_MODE_FAIL,
  payload,
});

// change status
export const editTransportationModeStatusStart = () => ({
  type: EDIT_TRANSPORTATION_MODE_STATUS_START,
});

export const editTransportationModeStatusSaga = payload => ({
  type: EDIT_TRANSPORTATION_MODE_STATUS_SAGA,
  payload,
});

export const editTransportationModeStatusSuccess = payload => ({
  type: EDIT_TRANSPORTATION_MODE_STATUS_SUCCESS,
  payload,
});

export const editTransportationModeStatusFail = payload => ({
  type: EDIT_TRANSPORTATION_MODE_STATUS_FAIL,
  payload,
});

// Delete
export const deleteTransportationModeStart = () => ({
  type: DELETE_TRANSPORTATION_MODE_START,
});

export const deleteTransportationModeSaga = payload => ({
  type: DELETE_TRANSPORTATION_MODE_SAGA,
  payload,
});

export const deleteTransportationModeSuccess = payload => ({
  type: DELETE_TRANSPORTATION_MODE_SUCCESS,
  payload,
});

export const deleteTransportationModeFail = payload => ({
  type: DELETE_TRANSPORTATION_MODE_FAIL,
  payload,
});
