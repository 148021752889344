import React from 'react';
import ReactPaginate from 'react-paginate';
// import { Pagination } from 'react-bootstrap';

function TablePagination(props) {
  const { handlePageClick, currentPage, pageCount, sliceStatus } = props;

  return (
    <>
      {sliceStatus && (
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
          onPageChange={pagenum => {
            if (pagenum.selected >= 0 && pagenum.selected < pageCount) {
              handlePageClick(pagenum.selected);
            }
          }}
          forcePage={currentPage}
          containerClassName="pagination mt-3"
          activeClassName="active"
        />
        // <Pagination>
        //   <Pagination.Prev
        //     onClick={() => currentPage > 0 && handlePageClick(currentPage - 1)}
        //     href="#"
        //     disabled={currentPage <= 0}
        //   >
        //     Previous
        //   </Pagination.Prev>
        //   {[...Array(pageCount)].map((_, i) => (
        //     <Pagination.Item
        //       active={i === currentPage}
        //       // eslint-disable-next-line react/no-array-index-key
        //       key={i}
        //       onClick={() => handlePageClick(i)}
        //       href="#"
        //     >
        //       {i + 1}
        //     </Pagination.Item>
        //   ))}
        //   <Pagination.Next
        //     onClick={() =>
        //       currentPage < pageCount - 1 && handlePageClick(currentPage + 1)
        //     }
        //     href="#"
        //     disabled={currentPage >= pageCount - 1}
        //   >
        //     Next
        //   </Pagination.Next>
        // </Pagination>
      )}
    </>
  );
}
export default TablePagination;
