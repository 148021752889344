import { all, takeLatest } from 'redux-saga/effects';
import * as actionLabels from '../actions/actionLabels';
import { dummyActionSaga } from './dummy/dummy';
import {
  loginSaga,
  logoutSaga,
  authenticationValidatorSaga,
  updateProfileSaga,
  changePasswordSaga,
  changePasswordChefSaga,
  registerSaga,
  otpSaga,
  checkResetPasswordToken,
  resetPasswordSaga,
  forgotPasswordSaga,
  checkEmailVerificationToken,
  registerVerifySaga,
} from './auth/auth';
import {
  getAdminDashboardSaga,
  getChefDashboardSaga,
  getOwnerDashboardSaga,
  getPromoterDashboardSaga,
} from './dashboard/dashboard';
import {
  getAllUserSaga,
  getUserByIdSaga,
  getActiveInActiveUserCountSaga,
  deleteUserSaga,
  editBlockUserStatusSaga,
  editAcceptingOrderSaga,
  getAllUserOrderSaga,
  getAllUserOrderDetailSaga,

} from './user/user';
import {
  getAllCategorySaga,
  getAllSubCategorySaga,
  getCategoryByIdSaga,
  getAllSubCategoryChefSaga,
  addCategorySaga,
  addSubCategorySaga,
  editCategorySaga,
  editSubCategorySaga,
  getAllDishSaga,
  getAllDishAdminSaga,
  addDishSaga,
  addAdminDishSaga,
  editDishSaga,
  // editDishPhotoSaga,
  editDishPublishStatusSaga,
  deleteDishSaga,
  getAllCategoryChefSaga,
  editCategoryStatusSaga,
  getAllAdminBrandTypesSaga,
  addBrandTypeSaga,
  editBrandTypeSaga,
  editAdminDishSaga,
  editSubCategoryStatusSaga,
  deleteCategorySaga,
  deleteSubCategorySaga,
  deleteBrandTypeSaga,
  deleteAdminDishSaga,
  getCategoryByVendorTypeSaga,
  getSubCategoryByCategorySaga,
  editBrandTypeStatusSaga,
  addDarkHomeVendorProductSaga,
  getAllDishListForPromoterSaga,
  deleteProductByPromoterSaga,
} from './dish/dish';
import {
  getAllChefSaga,
  getAllBrandTypesSaga,
  getChefByIdSaga,
  editBlockChefStatusSaga,
  addBankDetailSaga,
  addAddressSaga,
  editLocationSaga,
  chefApproveRejectSaga,
  getChefProfileSaga,
  getLatLongFromAddressSaga,
  editPersonalDetailSaga,
  editOrderScheduleSaga,
  editRestaurantDetailSaga,
  editMediaSaga,
  deleteMediaSaga,
  editIdproofSaga,
  deleteIdproofSaga,
  editChefFeatureSaga,
  getAcceptingOrderSaga,
  editAcceptingOrderChefSaga,
  editProfileSubmitSaga,
  editChefCommissionSaga,
  getChefBrandTypesSaga,
  getOtherVendorsSaga,
  enableDisableOtherVendorSaga,
  getAllProducerSaga,
  editBankAccountSaga,
  editBankAccountStatusSaga,
  deleteBankAccountSaga,
  assignToLogisticSaga,
} from './user/chef';
import {
  addDriverSaga,
  getDriverByIdSaga,
  editBlockDriverStatusSaga,
  getAllDriverSaga,
  getDetailsOwnerDriverSaga,
  promoterAddDriverSaga,
  promoterDriverDetailsSaga,
  promoterEditDriverSaga,
  editDriverCommissionSaga,
  driverApproveRejectSaga,
  editDriverServiceAssignedSaga,
  addOwnerInDriverSaga,
  getAvailableDriverToAssignVehicleSaga,
  assignVehicleToADriverSaga,
  promoterOwnernsVehicleSaga,
} from './user/driver'; // SS
import {
  getAllOrderSaga,
  getAllChefOrderSaga,
  getOrderByIdSaga,
  getAllOrderByUserIdSaga,
  getOrderCountSaga,
  getOrderTrackSaga,
  getOrderTrackChefSaga,
  getAllChefUpcomingOrderSaga,
  getChefOrderCountSaga,
  editInstructionSaga,
  editAcceptOrderSaga,
  editRejectOrderSaga,
  editOrderStatusSaga,
  transactionHistorySaga,
} from './order/order';
import {
  getAllSettingSaga,
  editAllSettingsSaga,
  editDefaultFoodDishImageSaga,
  sendNotificationSaga,
  editAllSettingsCommissionSaga,
} from './setting/setting';
import {
  getAllSubadminSaga,
  getSubadminByIdSaga,
  addSubadminSaga,
  editSubadminSaga,
  editSubadminStatusSaga,
  generatePDFSaga,
  
} from './user/subadmin';
import {
  getAllChefReviewSaga,
  getReviewByIdSaga,
  getAllLogisticsRatingReviewSaga,
  getAllRatingReviewByChefIdSaga,
} from './review/review';
import {
  getAllPromocodeSaga,
  addPromocodeSaga,
  editPromocodeStatusSaga,
  getAllRestaurantSaga,
  getPromocodeDetailByIdSaga,
  updatePromocodeSaga,
  deletePromocodeByIdSaga,
} from './promocode/promocode';
import {
  getAllCMSSaga,
  getCMSByIdSaga,
  getCMSPageSaga,
  updateCMSSaga,
} from './cms/cms';
import {
  getAllContactusSaga,
  editMarkAsResolvedSaga,
} from './contactus/contactus';
import {
  getAllInquiriesSaga,
  editMarkAsApprovedSaga,
  editMarkAsRejectedSaga,
} from './inquiry/inquiry';
import {
  getAllSupportRequestSaga,
  addSupportRequestSaga,
} from './supportRequest/supportRequest';
import {
  getPaymentHistorySaga,
  getPaymentHistoryDetailsSaga,
  getPaymentHistoryChefSaga,
  getAllChefPayoutListSaga,
  payPaymentSaga,
  getAllUserPaymentTypeSaga,
  addPackageTypeSaga,
} from './payment/payment';
import {
  editMarkAsReadSaga,
  getAllNotificationSaga,
  sendNotificationToVendorSaga
} from './notification/notification';
import { getReportSaga } from './reports/reports';

import {
  addPromoterSaga,
  getAllPromotersSaga,
  editBlockPromoterStatusSaga,
  getPromoterByIdSaga,
  editPromoterCommissionSaga,
  editPromoterSaga,
  deletePromoterSaga,
  addVendorOfPromoterSaga,
  getAllVendorsOfPromoterSaga,
  editVendorOfPromoterSaga,
  getAllVehicleOfPromoterSaga,
} from './promoters/promoters';
import {
  addVehicleOwnerSaga,
  deleteVehicleOwnerSaga,
  editBlockVehicleOwnerStatusSaga,
  editVehicleOwnerCommissionSaga,
  editVehicleOwnerSaga,
  getAllVehicleOwnersSaga,
  getVehicleOwnerByIdSaga,
} from './user/vehicleOwner';
import {
  addVehicleSaga,
  getAllVehiclesSaga,
  editBlockVehicleStatusSaga,
  getVehicleDetailByIdSaga,
  editVehicleSaga,
  deleteVehicleSaga,
  deleteVehicleByVhicleOwnerSaga,
} from './vehicle/vehicle';
import {
  addServiceSaga,
  deleteServiceSaga,
  editBlockServiceStatusSaga,
  editServiceSaga,
  getAllServiceSaga,
} from './services/services';
import {
  addAccessModuleSaga,
  deleteAccessModuleSaga,
  editBlockAccessModuleStatusSaga,
  editAccessModuleSaga,
  getAllAccessModuleSaga,
} from './moduleAccess/moduleAccess';

import {
  addOwnerVehicleSaga,
  deleteDriverFromVehicleSaga,
  deleteOwnerVehicleSaga,
  editBlockOwnerVehicleStatusSaga,
  editOwnerVehicleSaga,
  getAllOwnerVehiclesSaga,
  getOwnerVehicleDetailByIdSaga,
  getVehicleListByOwnerIdSaga,
  OwnerVehicleApproveRejectSaga,
  unassignDriverFromVehicleSaga,
} from './vehicle/ownerVehicle';
import {
  getAllCommissionListSaga,
  getCommissionDetailSaga,
  getTotalCommissionSaga,
  getVendorTotalCommissionSaga,
} from './commission/commission';
import { getAllReferralSaga } from './referral/referral';
import {
  addStoreTypeSaga,
  editStoreTypeSaga,
  getAllStoreTypesSaga,
  editStoreTypeStatusSaga,
  deleteStoreTypeSaga,
} from './storeTypes/storeTypes';
import {
  addTransportationModeSaga,
  deleteTransportationModeSaga,
  editTransportationModeSaga,
  editTransportationModeStatusSaga,
  getAllTransportationModesSaga,
} from './transportationModes/TransportationModes';
import { deleteVendorSaga } from './chef/chef';
import { deleteDriverByIdSaga } from './driver/driver';
import {
  acceptOrderForDeliverySaga,
  addLogisticOfPromoterSaga,
  addNewEmployeeSaga,
  addSupportRequestLogisticsSaga,
  approveRejectLogisticsSaga,
  arrivedDataToReceptionSaga,
  deleteLogisticEmployeeSaga,
  deleteLogisticOfPromoterSaga,
  editBlockLogisticStatusSaga,
  editEmployeeStatusSaga,
  editLogisticDetailSaga,
  editNewEmployeeSaga,
  editlogisticDetailSaga,
  getAllDestinyDataSaga,
  getAllEmployeeDataSaga,
  getAllLogisticDetailSaga,
  getAllLogisticOfPromoterSaga,
  getAllLogisticOrderSaga,
  getAllLogisticsPayoutHistorySaga,
  getAllOrderListSaga,
  getAllDriverOrderListSaga,
  getAllSupportRequestLogisticsSaga,
  getAllVehicleListSaga,
  getLatLongForLogisticSaga,
  getOrderDetailByIdSaga,
  getReceptionListSaga,
  logisticsDetailsSaga,
  sendProductToLogisticSaga,
  deleteDriverSaga
} from './logistics/logistics';

// import { getAllRequestsSaga } from './requests/requests';







export function* watchDummy() {
  yield all([takeLatest(actionLabels.DUMMY_ACTION_SAGA, dummyActionSaga)]);
}

export function* watchAuthentication() {
  yield all([
    takeLatest(actionLabels.EDIT_USER_PROFILE_SAGA, updateProfileSaga),
    takeLatest(actionLabels.CHANGE_PASSWORD_SAGA, changePasswordSaga),
    takeLatest(actionLabels.CHANGE_PASSWORD_CHEF_SAGA, changePasswordChefSaga),
    takeLatest(actionLabels.LOGIN_SAGA, loginSaga),
    takeLatest(actionLabels.REGISTER_SAGA, registerSaga),
    takeLatest(actionLabels.REGISTER_VERIFY_SAGA, registerVerifySaga),
    takeLatest(actionLabels.OTP_SAGA, otpSaga),
    takeLatest(
      actionLabels.RESET_PASSWORD_TOKEN_VERIFICATION_SAGA,
      checkResetPasswordToken,
    ),
    takeLatest(actionLabels.RESET_PASSWORD_SAGA, resetPasswordSaga),
    takeLatest(actionLabels.FORGOT_PASSWORD_SAGA, forgotPasswordSaga),
    takeLatest(actionLabels.LOGOUT_SAGA, logoutSaga),
    takeLatest(
      actionLabels.AUTHENTICATION_VALIDATOR,
      authenticationValidatorSaga,
    ),
    takeLatest(
      actionLabels.EMAIL_VERIFICATION_TOKEN_VERIFICATION_SAGA,
      checkEmailVerificationToken,
    ),
  ]);
}

export function* watchDashboard() {
  yield all([
    takeLatest(actionLabels.GET_ADMIN_DASHBOARD_SAGA, getAdminDashboardSaga),
    takeLatest(actionLabels.GET_OWNER_DASHBOARD_SAGA, getOwnerDashboardSaga),
    takeLatest(actionLabels.GET_CHEF_DASHBOARD_SAGA, getChefDashboardSaga),
    takeLatest(
      actionLabels.GET_PROMOTER_DASHBOARD_SAGA,
      getPromoterDashboardSaga,
    ),
  ]);
}

export function* watchUser() {
  yield all([
    takeLatest(actionLabels.GET_ALL_USER_SAGA, getAllUserSaga),
    takeLatest(actionLabels.GET_ALL_USER_ORDER_SAGA, getAllUserOrderSaga),
    takeLatest(actionLabels.GET_ALL_USER_ORDER_DETAIL_SAGA, getAllUserOrderDetailSaga),
    takeLatest(actionLabels.GET_USER_BY_ID_SAGA, getUserByIdSaga),
    takeLatest(
      actionLabels.GET_ACTIVE_INACTIVE_USER_COUNT_SAGA,
      getActiveInActiveUserCountSaga,
    ),
    takeLatest(actionLabels.EDIT_ACCEPTING_ORDER_SAGA, editAcceptingOrderSaga),

    takeLatest(actionLabels.DELETE_USER_SAGA, deleteUserSaga),
    takeLatest(
      actionLabels.EDIT_BLOCK_USER_STATUS_SAGA,
      editBlockUserStatusSaga,
    ),
  ]);
}

export function* watchDish() {
  yield all([
    takeLatest(actionLabels.GET_ALL_CATEGORY_SAGA, getAllCategorySaga),
    takeLatest(actionLabels.GET_ALL_SUB_CATEGORY_SAGA, getAllSubCategorySaga),
    takeLatest(actionLabels.GET_ALL_CATEGORY_CHEF_SAGA, getAllCategoryChefSaga),
    takeLatest(
      actionLabels.GET_ALL_SUB_CATEGORY_CHEF_SAGA,
      getAllSubCategoryChefSaga,
    ),
    takeLatest(
      actionLabels.GET_CATEGORY_BY_VENDOR_TYPE_SAGA,
      getCategoryByVendorTypeSaga,
    ),
    takeLatest(
      actionLabels.GET_SUB_CATEGORY_BY_CATEGORY_SAGA,
      getSubCategoryByCategorySaga,
    ),
    takeLatest(actionLabels.GET_CATEGORY_BY_ID_SAGA, getCategoryByIdSaga),
    takeLatest(actionLabels.ADD_CATEGORY_SAGA, addCategorySaga),
    takeLatest(actionLabels.ADD_SUB_CATEGORY_SAGA, addSubCategorySaga),
    takeLatest(actionLabels.ADD_BRAND_TYPE_SAGA, addBrandTypeSaga),
    takeLatest(actionLabels.EDIT_BRAND_TYPE_SAGA, editBrandTypeSaga),
    takeLatest(actionLabels.EDIT_CATEGORY_SAGA, editCategorySaga),
    takeLatest(actionLabels.EDIT_SUB_CATEGORY_SAGA, editSubCategorySaga),
    takeLatest(
      actionLabels.GET_ALL_ADMIN_BRAND_TYPES_SAGA,
      getAllAdminBrandTypesSaga,
    ),
    // Brand Type Means Store
    takeLatest(
      actionLabels.EDIT_BRAND_TYPE_STATUS_SAGA,
      editBrandTypeStatusSaga,
    ),
    takeLatest(actionLabels.GET_ALL_DISH_SAGA, getAllDishSaga),
    takeLatest(actionLabels.GET_ALL_DISH_ADMIN_SAGA, getAllDishAdminSaga),
    takeLatest(actionLabels.ADD_DISH_SAGA, addDishSaga),
    takeLatest(actionLabels.ADD_ADMIN_DISH_SAGA, addAdminDishSaga),
    takeLatest(actionLabels.EDIT_ADMIN_DISH_SAGA, editAdminDishSaga),
    takeLatest(actionLabels.EDIT_DISH_SAGA, editDishSaga),
    takeLatest(actionLabels.EDIT_CATEGORY_STATUS_SAGA, editCategoryStatusSaga),
    takeLatest(
      actionLabels.EDIT_SUB_CATEGORY_STATUS_SAGA,
      editSubCategoryStatusSaga,
    ),
    takeLatest(actionLabels.DELETE_CATEGORY_SAGA, deleteCategorySaga),
    takeLatest(actionLabels.DELETE_SUB_CATEGORY_SAGA, deleteSubCategorySaga),
    takeLatest(actionLabels.DELETE_BRAND_TYPE_SAGA, deleteBrandTypeSaga),
    // takeLatest(actionLabels.EDIT_DISH_PHOTO_SAGA, editDishPhotoSaga),
    takeLatest(
      actionLabels.EDIT_DISH_PUBLISH_STATUS_SAGA,
      editDishPublishStatusSaga,
    ),
    takeLatest(actionLabels.DELETE_DISH_SAGA, deleteDishSaga),
    takeLatest(actionLabels.DELETE_ADMIN_DISH_SAGA, deleteAdminDishSaga),
    takeLatest(actionLabels.GET_ALL_DISH_LIST_PROMOTER_SAGA, getAllDishListForPromoterSaga),
    takeLatest(actionLabels.DELETE_PRODUCT_BY_PROMOTER_SAGA, deleteProductByPromoterSaga),

    // Dark Home Vendor Products Sagas
    takeLatest(
      actionLabels.ADD_DARK_HOME_VENDOR_PRODUCT_SAGA,
      addDarkHomeVendorProductSaga,
    ),
  ]);
}

export function* watchChef() {
  yield all([
    takeLatest(actionLabels.GET_ALL_CHEF_SAGA, getAllChefSaga),
    takeLatest(actionLabels.GET_ALL_BRAND_TYPES_SAGA, getAllBrandTypesSaga),
    takeLatest(actionLabels.GET_CHEF_BY_ID_SAGA, getChefByIdSaga),
    takeLatest(actionLabels.GET_CHEF_BRAND_TYPES_SAGA, getChefBrandTypesSaga),
    takeLatest(
      actionLabels.EDIT_BLOCK_CHEF_STATUS_SAGA,
      editBlockChefStatusSaga,
    ),
    takeLatest(actionLabels.ADD_BANK_DETAIL_SAGA, addBankDetailSaga),
    takeLatest(actionLabels.EDIT_BANK_ACCOUNT_SAGA, editBankAccountSaga),
    takeLatest(
      actionLabels.EDIT_BANK_ACCOUNT_STATUS_SAGA,
      editBankAccountStatusSaga,
    ),
    takeLatest(actionLabels.DELETE_BANK_ACCOUNT_SAGA, deleteBankAccountSaga),

    takeLatest(actionLabels.ADD_ADDRESS_SAGA, addAddressSaga),
    takeLatest(actionLabels.GET_ACCEPTING_ORDER_SAGA, getAcceptingOrderSaga),
    takeLatest(
      actionLabels.EDIT_ACCEPTING_ORDER_CHEF_SAGA,
      editAcceptingOrderChefSaga,
    ),
    takeLatest(actionLabels.EDIT_LOCATION_SAGA, editLocationSaga),
    takeLatest(actionLabels.CHEF_APPROVE_REJECT_SAGA, chefApproveRejectSaga),
    takeLatest(actionLabels.EDIT_CHEF_COMMISSION_SAGA, editChefCommissionSaga),
    takeLatest(actionLabels.GET_CHEF_PROFILE_SAGA, getChefProfileSaga),
    takeLatest(
      actionLabels.GET_LAT_LONG_FROM_ADDRESS_SAGA,
      getLatLongFromAddressSaga,
    ),
    takeLatest(actionLabels.EDIT_PERSONAL_DETAIL_SAGA, editPersonalDetailSaga),
    takeLatest(actionLabels.EDIT_ORDER_SCHEDULE_SAGA, editOrderScheduleSaga),
    takeLatest(
      actionLabels.EDIT_RESTAURANT_DETAIL_SAGA,
      editRestaurantDetailSaga,
    ),
    takeLatest(actionLabels.EDIT_MEDIA_SAGA, editMediaSaga),
    takeLatest(actionLabels.DELETE_MEDIA_SAGA, deleteMediaSaga),
    takeLatest(actionLabels.EDIT_ID_PROOF_SAGA, editIdproofSaga),
    takeLatest(actionLabels.DELETE_ID_PROOF_SAGA, deleteIdproofSaga),
    takeLatest(actionLabels.EDIT_CHEF_FEATURE_SAGA, editChefFeatureSaga),
    takeLatest(actionLabels.EDIT_PROFILE_SUBMIT_SAGA, editProfileSubmitSaga),
    // Other Chef
    takeLatest(actionLabels.GET_OTHER_VENDORS_SAGA, getOtherVendorsSaga),
    takeLatest(
      actionLabels.ENABLE_DISABLE_OTHER_VENDOR_SAGA,
      enableDisableOtherVendorSaga,
    ),
    takeLatest(actionLabels.GET_ALL_PRODUCER_SAGA, getAllProducerSaga),
    takeLatest(actionLabels.DELETE_VENDOR_SAGA, deleteVendorSaga),
  ]);
}
// watch commission
export function* watchCommission() {
  yield all([
    takeLatest(
      actionLabels.GET_ALL_COMMISSION_LIST_SAGA,
      getAllCommissionListSaga,
    ),
    takeLatest(actionLabels.GET_TOTAL_COMMISSION_SAGA, getTotalCommissionSaga),
    takeLatest(
      actionLabels.GET_COMMISSION_DETAIL_SAGA,
      getCommissionDetailSaga,
    ),
    takeLatest(
      actionLabels.GET_VENDOR_TOTAL_COMMISSION_SAGA,
      getVendorTotalCommissionSaga,
    ),
  ]);
}

// Shashank Driver Management Module
export function* watchDriver() {
  yield all([
    takeLatest(actionLabels.GET_ALL_DRIVER_SAGA, getAllDriverSaga),
    takeLatest(actionLabels.DETAILS_OWNER_DRIVER_SAGA, getDetailsOwnerDriverSaga),
    takeLatest(actionLabels.ADD_DRIVER_SAGA, addDriverSaga),
    takeLatest(actionLabels.GET_DRIVER_BY_ID_SAGA, getDriverByIdSaga),
    takeLatest(
      actionLabels.EDIT_BLOCK_DRIVER_STATUS_SAGA,
      editBlockDriverStatusSaga,
    ),
    takeLatest(
      actionLabels.DRIVER_APPROVE_REJECT_SAGA,
      driverApproveRejectSaga,
    ),
    takeLatest(
      actionLabels.EDIT_DRIVER_COMMISSION_SAGA,
      editDriverCommissionSaga,
    ),
    takeLatest(
      actionLabels.EDIT_DRIVER_SERVICE_ASSIGNED_SAGA,
      editDriverServiceAssignedSaga,
    ),
    takeLatest(actionLabels.ADD_OWNER_IN_DRIVER_SAGA, addOwnerInDriverSaga),
    takeLatest(
      actionLabels.GET_AVAILABLE_DRIVERS_TO_ASSIGN_VEHICLE_SAGA,
      getAvailableDriverToAssignVehicleSaga,
    ),
    takeLatest(
      actionLabels.ASSIGN_VEHICLE_TO_A_DRIVER_SAGA,
      assignVehicleToADriverSaga,
    ),
    takeLatest(
      actionLabels.PROMOTER_OWNERS_VEHICLE_SAGA,
      promoterOwnernsVehicleSaga,
    ),
    takeLatest(
      actionLabels.DELETE_DRIVER_BY_ID_SAGA,
      deleteDriverByIdSaga,
    ),
  ]);
}

export function* watchOrder() {
  yield all([
    takeLatest(actionLabels.GET_ALL_ORDER_SAGA, getAllOrderSaga),
    takeLatest(actionLabels.TRANSACTION_HISTORY_SAGA, transactionHistorySaga),
    takeLatest(actionLabels.GET_ORDER_TRACK_SAGA, getOrderTrackSaga),
    takeLatest(actionLabels.GET_ORDER_TRACK_CHEF_SAGA, getOrderTrackChefSaga),
    takeLatest(actionLabels.GET_ALL_CHEF_ORDER_SAGA, getAllChefOrderSaga),
    takeLatest(actionLabels.GET_ORDER_BY_ID_SAGA, getOrderByIdSaga),
    takeLatest(
      actionLabels.GET_ALL_ORDER_BY_USER_ID_SAGA,
      getAllOrderByUserIdSaga,
    ),
    takeLatest(actionLabels.GET_ORDER_COUNT_SAGA, getOrderCountSaga),
    takeLatest(
      actionLabels.GET_ALL_CHEF_UPCOMING_ORDER_SAGA,
      getAllChefUpcomingOrderSaga,
    ),
    takeLatest(actionLabels.GET_CHEF_ORDER_COUNT_SAGA, getChefOrderCountSaga),
    takeLatest(actionLabels.EDIT_INSTRUCTION_SAGA, editInstructionSaga),
    takeLatest(actionLabels.EDIT_ACCEPT_ORDER_SAGA, editAcceptOrderSaga),
    takeLatest(actionLabels.EDIT_REJECT_ORDER_SAGA, editRejectOrderSaga),
    takeLatest(actionLabels.EDIT_ORDER_STATUS_SAGA, editOrderStatusSaga),
  ]);
}

export function* watchSetting() {
  yield all([
    takeLatest(actionLabels.GET_ALL_SETTING_SAGA, getAllSettingSaga),
    takeLatest(actionLabels.EDIT_ALL_SETTINGS_SAGA, editAllSettingsSaga),
    takeLatest(
      actionLabels.EDIT_ALL_SETTINGS_COMMISSION_SAGA,
      editAllSettingsCommissionSaga,
    ),
    takeLatest(
      actionLabels.EDIT_DEFAULT_FOOD_DISH_IMAGE_SAGA,
      editDefaultFoodDishImageSaga,
    ),
    takeLatest(actionLabels.SEND_NOTIFICATION_SAGA, sendNotificationSaga),
  ]);
}

export function* watchSubadmin() {
  yield all([
    takeLatest(actionLabels.GET_ALL_SUBADMIN_SAGA, getAllSubadminSaga),
    takeLatest(actionLabels.GET_SUBADMIN_BY_ID_SAGA, getSubadminByIdSaga),
    takeLatest(actionLabels.ADD_SUBADMIN_SAGA, addSubadminSaga),
    takeLatest(actionLabels.EDIT_SUBADMIN_SAGA, editSubadminSaga),
    takeLatest(actionLabels.EDIT_SUBADMIN_STATUS_SAGA, editSubadminStatusSaga),
    takeLatest(actionLabels.GENERATE_PDF_SAGA, generatePDFSaga),
  ]);
}

export function* watchReview() {
  yield all([
    takeLatest(actionLabels.GET_ALL_CHEF_REVIEW_SAGA, getAllChefReviewSaga),
    takeLatest(actionLabels.GET_REVIEW_BY_ID_SAGA, getReviewByIdSaga),
    takeLatest(
      actionLabels.GET_ALL_LOGISTICS_RATING_REVIEW_SAGA,
      getAllLogisticsRatingReviewSaga,
    ),
    takeLatest(
      actionLabels.GET_ALL_RATING_REVIEW_BY_CHEF_ID_SAGA,
      getAllRatingReviewByChefIdSaga,
    ),
  ]);
}

export function* watchInquiry() {
  yield all([
    takeLatest(actionLabels.GET_ALL_INQUIRIES_SAGA, getAllInquiriesSaga),
    takeLatest(actionLabels.EDIT_MARK_AS_APPROVED_SAGA, editMarkAsApprovedSaga),
    takeLatest(actionLabels.EDIT_MARK_AS_REJECTED_SAGA, editMarkAsRejectedSaga),
  ]);
}

export function* watchPromocode() {
  yield all([
    takeLatest(actionLabels.GET_ALL_PROMOCODE_SAGA, getAllPromocodeSaga),
    takeLatest(actionLabels.GET_ALL_RESTAURANT_SAGA, getAllRestaurantSaga),
    takeLatest(actionLabels.UPDATE_PROMOCODE_SAGA, updatePromocodeSaga),
    takeLatest(
      actionLabels.GET_PROMOCODE_DETAIL_BY_ID_SAGA,
      getPromocodeDetailByIdSaga,
    ),
    takeLatest(actionLabels.ADD_PROMOCODE_SAGA, addPromocodeSaga),
    takeLatest(
      actionLabels.EDIT_PROMOCODE_STATUS_SAGA,
      editPromocodeStatusSaga,
    ),
    takeLatest(
      actionLabels.DELETE_PROMOCODE_BY_ID_SAGA,
      deletePromocodeByIdSaga,
    ),
  ]);
}

export function* watchSupportRequest() {
  yield all([
    takeLatest(
      actionLabels.GET_ALL_SUPPORT_REQUEST_SAGA,
      getAllSupportRequestSaga,
    ),
    takeLatest(actionLabels.ADD_SUPPORT_REQUEST_SAGA, addSupportRequestSaga),
  ]);
}

export function* watchCMS() {
  yield all([takeLatest(actionLabels.GET_ALL_CMS_SAGA, getAllCMSSaga)]);
  yield all([takeLatest(actionLabels.GET_CMS_PAGE_SAGA, getCMSPageSaga)]);
  yield all([takeLatest(actionLabels.GET_CMS_BY_ID_SAGA, getCMSByIdSaga)]);
  yield all([takeLatest(actionLabels.UPDATE_CMS_SAGA, updateCMSSaga)]);
}

export function* watchContactus() {
  yield all([
    takeLatest(actionLabels.GET_ALL_CONTACTUS_SAGA, getAllContactusSaga),
    takeLatest(actionLabels.EDIT_MARK_AS_RESOLVED_SAGA, editMarkAsResolvedSaga),
  ]);
}

export function* watchPayment() {
  yield all([
    takeLatest(
      actionLabels.GET_ALL_CHEF_PAYOUT_LIST_SAGA,
      getAllChefPayoutListSaga,
    ),
    takeLatest(actionLabels.GET_PAYMENT_HISTORY_SAGA, getPaymentHistorySaga),
    takeLatest(
      actionLabels.GET_PAYMENT_HISTORY_CHEF_SAGA,
      getPaymentHistoryChefSaga,
    ),
    takeLatest(
      actionLabels.GET_PAYMENT_HISTORY_DETAILS_SAGA,
      getPaymentHistoryDetailsSaga,
    ),
    takeLatest(actionLabels.PAY_PAYMENT_SAGA, payPaymentSaga),
    takeLatest(actionLabels.GET_PAYMENTTYPE_SAGA, getAllUserPaymentTypeSaga),

    takeLatest(actionLabels.ADD_PAYMENTTYPE_SAGA, addPackageTypeSaga),


  ]);
}

export function* watchNotification() {
  yield all([
    takeLatest(actionLabels.GET_ALL_NOTIFICATION_SAGA, getAllNotificationSaga),
    takeLatest(actionLabels.EDIT_MARK_AS_READ_SAGA, editMarkAsReadSaga),
    
    takeLatest(actionLabels.SEND_NOTIFICATION_TO_VENDOR_SAGA, sendNotificationToVendorSaga),
  ]);
}

export function* watchReports() {
  yield all([takeLatest(actionLabels.GET_REPORT_SAGA, getReportSaga)]);
}

// ----------------------- Promoters-------------------------
export function* watchPromoters() {
  yield all([
    takeLatest(actionLabels.GET_ALL_PROMOTERS_SAGA, getAllPromotersSaga),
    takeLatest(actionLabels.GET_PROMOTER_BY_ID_SAGA, getPromoterByIdSaga),
    takeLatest(actionLabels.EDIT_PROMOTER_SAGA, editPromoterSaga),
    takeLatest(actionLabels.DELETE_PROMOTER_SAGA, deletePromoterSaga),
    takeLatest(actionLabels.ADD_PROMOTER_SAGA, addPromoterSaga),
    takeLatest(
      actionLabels.EDIT_BLOCK_PROMOTER_STATUS_SAGA,
      editBlockPromoterStatusSaga,
    ),
    takeLatest(
      actionLabels.EDIT_PROMOTER_COMMISSION_SAGA,
      editPromoterCommissionSaga,
    ),
    takeLatest(
      actionLabels.GET_ALL_VENDORS_OF_PROMOTER_SAGA,
      getAllVendorsOfPromoterSaga,
    ),
    takeLatest(
      actionLabels.ADD_VENDOR_OF_PROMOTER_SAGA,
      addVendorOfPromoterSaga,
    ),
    takeLatest(
      actionLabels.EDIT_VENDOR_OF_PROMOTER_SAGA,
      editVendorOfPromoterSaga,
    ),
    takeLatest(
      actionLabels.DELETE_VENDOR_OF_PROMOTER_SAGA,
      editVendorOfPromoterSaga,
    ),
    takeLatest(actionLabels.PROMOTER_ADD_DRIVER_SAGA, promoterAddDriverSaga),
    takeLatest(
      actionLabels.PROMOTER_DRIVER_DETAILS_SAGA,
      promoterDriverDetailsSaga,
    ),
    takeLatest(actionLabels.PROMOTER_EDIT_DRIVER_SAGA, promoterEditDriverSaga),
    takeLatest(
      actionLabels.GET_ALL_VEHICLE_OF_PROMOTER_SAGA,
      getAllVehicleOfPromoterSaga,
    ),
    takeLatest(
      actionLabels.OWNER_VEHICLE_APPROVE_REJECT_SAGA,
      OwnerVehicleApproveRejectSaga,
    ),
    takeLatest(
      actionLabels.UNASSIGN_DRIVER_FROM_VEHICLE_SAGA,
      unassignDriverFromVehicleSaga,
    ),
    takeLatest(
      actionLabels.DELETE_DRIVER_FROM_VEHICLE_SAGA,
      deleteDriverFromVehicleSaga,
    ),

  ]);
}

// ----------------------- Vehicle Owners-------------------------
export function* watchVehicleOwners() {
  yield all([
    takeLatest(
      actionLabels.GET_ALL_VEHICLE_OWNERS_SAGA,
      getAllVehicleOwnersSaga,
    ),
    takeLatest(
      actionLabels.GET_VEHICLE_OWNER_BY_ID_SAGA,
      getVehicleOwnerByIdSaga,
    ),
    takeLatest(actionLabels.EDIT_VEHICLE_OWNER_SAGA, editVehicleOwnerSaga),
    takeLatest(actionLabels.ADD_VEHICLE_OWNER_SAGA, addVehicleOwnerSaga),
    takeLatest(
      actionLabels.EDIT_BLOCK_VEHICLE_OWNER_STATUS_SAGA,
      editBlockVehicleOwnerStatusSaga,
    ),
    takeLatest(
      actionLabels.EDIT_VEHICLE_OWNER_COMMISSION_SAGA,
      editVehicleOwnerCommissionSaga,
    ),
    takeLatest(actionLabels.DELETE_VEHICLE_OWNER_SAGA, deleteVehicleOwnerSaga),
  ]);
}

// ----------------------- Vehicles-------------------------

export function* watchVehicles() {
  yield all([
    takeLatest(actionLabels.GET_ALL_VEHICLES_SAGA, getAllVehiclesSaga),
    takeLatest(
      actionLabels.GET_VEHICLE_DETAIL_BY_ID_SAGA,
      getVehicleDetailByIdSaga,
    ),
    takeLatest(actionLabels.ADD_VEHICLE_SAGA, addVehicleSaga),
    takeLatest(actionLabels.EDIT_VEHICLE_SAGA, editVehicleSaga),
    takeLatest(
      actionLabels.EDIT_BLOCK_VEHICLE_STATUS_SAGA,
      editBlockVehicleStatusSaga,
    ),
    takeLatest(actionLabels.DELETE_VEHICLE_SAGA, deleteVehicleSaga),
    takeLatest(actionLabels.DELETE_VEHICLE_BY_VEHICLE_OWNER_SAGA, deleteVehicleByVhicleOwnerSaga),
  ]);
}
export function* watchOwnerVehicles() {
  yield all([
    takeLatest(
      actionLabels.GET_ALL_OWNER_VEHICLES_SAGA,
      getAllOwnerVehiclesSaga,
    ),
    takeLatest(
      actionLabels.GET_OWNER_VEHICLE_DETAIL_BY_ID_SAGA,
      getOwnerVehicleDetailByIdSaga,
    ),
    takeLatest(actionLabels.ADD_OWNER_VEHICLE_SAGA, addOwnerVehicleSaga),
    takeLatest(actionLabels.EDIT_OWNER_VEHICLE_SAGA, editOwnerVehicleSaga),
    takeLatest(
      actionLabels.EDIT_BLOCK_OWNER_VEHICLE_STATUS_SAGA,
      editBlockOwnerVehicleStatusSaga,
    ),
    takeLatest(actionLabels.DELETE_OWNER_VEHICLE_SAGA, deleteOwnerVehicleSaga),
    takeLatest(
      actionLabels.GET_VEHICLE_LIST_BY_OWNER_ID_SAGA,
      getVehicleListByOwnerIdSaga,
    ),
  ]);
}

// ----------------------- Services-------------------------
export function* watchServices() {
  yield all([
    takeLatest(actionLabels.GET_ALL_SERVICE_SAGA, getAllServiceSaga),
    takeLatest(actionLabels.ADD_SERVICE_SAGA, addServiceSaga),
    takeLatest(actionLabels.EDIT_SERVICE_SAGA, editServiceSaga),
    takeLatest(actionLabels.DELETE_SERVICE_SAGA, deleteServiceSaga),
    takeLatest(
      actionLabels.EDIT_BLOCK_SERVICE_STATUS_SAGA,
      editBlockServiceStatusSaga,
    ),
  ]);
}

// ----------------------- Services-------------------------
export function* watchModuleAccess() {
  yield all([
    takeLatest(actionLabels.GET_ALL_ACCESS_MODULE_SAGA, getAllAccessModuleSaga),
    takeLatest(actionLabels.ADD_ACCESS_MODULE_SAGA, addAccessModuleSaga),
    takeLatest(actionLabels.EDIT_ACCESS_MODULE_SAGA, editAccessModuleSaga),
    takeLatest(actionLabels.DELETE_ACCESS_MODULE_SAGA, deleteAccessModuleSaga),
    takeLatest(
      actionLabels.EDIT_BLOCK_ACCESS_MODULE_STATUS_SAGA,
      editBlockAccessModuleStatusSaga,
    ),
  ]);
}

// ----------------------- Store Types -------------------------

export function* watchTransportationModes() {
  yield all([
    takeLatest(
      actionLabels.GET_ALL_TRANSPORTATION_MODES_SAGA,
      getAllTransportationModesSaga,
    ),
    takeLatest(
      actionLabels.ADD_TRANSPORTATION_MODE_SAGA,
      addTransportationModeSaga,
    ),
    takeLatest(
      actionLabels.EDIT_TRANSPORTATION_MODE_SAGA,
      editTransportationModeSaga,
    ),
    takeLatest(
      actionLabels.EDIT_TRANSPORTATION_MODE_STATUS_SAGA,
      editTransportationModeStatusSaga,
    ),
    takeLatest(
      actionLabels.DELETE_TRANSPORTATION_MODE_SAGA,
      deleteTransportationModeSaga,
    ),
  ]);
}
// ----------------------- Store Types -------------------------

export function* watchStoreTypes() {
  yield all([
    takeLatest(actionLabels.GET_ALL_STORE_TYPES_SAGA, getAllStoreTypesSaga),
    takeLatest(actionLabels.ADD_STORE_TYPE_SAGA, addStoreTypeSaga),
    takeLatest(actionLabels.EDIT_STORE_TYPE_SAGA, editStoreTypeSaga),
    takeLatest(
      actionLabels.EDIT_STORE_TYPE_STATUS_SAGA,
      editStoreTypeStatusSaga,
    ),
    takeLatest(actionLabels.DELETE_STORE_TYPE_SAGA, deleteStoreTypeSaga),
  ]);
}
//------------------------Add Logistics---------------------//
export function* watchLogistics() {
  yield all([
    takeLatest(actionLabels.ADD_LOGISTIC_OF_PROMOTER_SAGA, addLogisticOfPromoterSaga),
    takeLatest(actionLabels.DELETE_LOGISTIC_OF_PROMOTER_SAGA, deleteLogisticOfPromoterSaga),
    takeLatest(actionLabels.GET_ALL_LOGISTIC_OF_PROMOTER_SAGA, getAllLogisticOfPromoterSaga),
    takeLatest(actionLabels.LOGISTICS_DETAILS_SAGA, logisticsDetailsSaga),
    takeLatest(actionLabels.APPROVE_REJECT_LOGISTICS_SAGA, approveRejectLogisticsSaga),
    takeLatest(actionLabels.GET_ALL_SUPPORT_REQUEST_LOGISTICS_SAGA, getAllSupportRequestLogisticsSaga),
    takeLatest(actionLabels.ADD_SUPPORT_REQUEST_LOGISTICS_SAGA, addSupportRequestLogisticsSaga),
    takeLatest(actionLabels.GET_ALL_LOGISTICS_PAYOUT_HISTORY_SAGA, getAllLogisticsPayoutHistorySaga),
    takeLatest(actionLabels.EDIT_BLOCK_LOGISTIC_STATUS_SAGA, editBlockLogisticStatusSaga),
    takeLatest(actionLabels.EDIT_LOGISTIC_DETAIL_SAGA, editLogisticDetailSaga),
    takeLatest(actionLabels.GET_ALL_ORDER_LIST_SAGA, getAllOrderListSaga),
    takeLatest(actionLabels.GET_ALL_DRIVER_ORDER_LIST_SAGA, getAllDriverOrderListSaga),
    takeLatest(actionLabels.GET_RECEPTION_LIST_SAGA, getReceptionListSaga),
    takeLatest(actionLabels.ASSIGN_TO_LOGISTIC_SAGA, assignToLogisticSaga),
    takeLatest(actionLabels.ACCEPT_ORDER_FOR_DELIVERY_SAGA, acceptOrderForDeliverySaga),

    takeLatest(actionLabels.DELETE_DRIVER_SAGA, deleteDriverSaga),

    takeLatest(actionLabels.GET_ALL_VEHICLE_LIST_SAGA, getAllVehicleListSaga),
    takeLatest(actionLabels.ARRIVED_DATA_TO_RECEPTION_SAGA, arrivedDataToReceptionSaga),
    takeLatest(actionLabels.GET_LAT_LONG_FOR_LOGISTIC_SAGA, getLatLongForLogisticSaga),
    takeLatest(actionLabels.GET_ALL_LOGISTIC_DETAIL_SAGA, getAllLogisticDetailSaga),
    takeLatest(actionLabels.SEND_PRODUCT_TO_LOGISTIC_SAGA, sendProductToLogisticSaga),
    takeLatest(actionLabels.GET_ALL_LOGISTIC_ORDER_SAGA, getAllLogisticOrderSaga),
    takeLatest(actionLabels.GET_ORDER_DETAIL_BY_ID_SAGA, getOrderDetailByIdSaga),
    takeLatest(actionLabels.ADD_NEW_EMPLOYEE_SAGA, addNewEmployeeSaga),
    takeLatest(actionLabels.EDIT_NEW_EMPLOYEE_SAGA, editNewEmployeeSaga),
    takeLatest(actionLabels.GET_ALL_EMPLOYEE_DATA_SAGA, getAllEmployeeDataSaga),
    takeLatest(actionLabels.EDIT_EMPLOYEE_STATUS_SAGA, editEmployeeStatusSaga),
    takeLatest(actionLabels.DELETE_LOGISTIC_EMPLOYEE_SAGA, deleteLogisticEmployeeSaga),
    takeLatest(actionLabels.GET_ALL_DESTINY_DATA_SAGA, getAllDestinyDataSaga),

  ]);
}
// ----------------------- Referrals-------------------------
export function* watchReferral() {
  yield all([
    takeLatest(actionLabels.GET_ALL_REFERRAL_SAGA, getAllReferralSaga),
  ]);
}

// ----------------------- Requests -------------------------
export function* watchRequests() {
  yield all([
    // takeLatest(actionLabels.GET_ALL_REQUESTS_SAGA, getAllRequestsSaga),
    // takeLatest(actionLabels.ADD_SERVICE_SAGA, addServiceSaga),
    // takeLatest(actionLabels.EDIT_SERVICE_SAGA, editServiceSaga),
  ]);
}
