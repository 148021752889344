import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  promocodes: { list: [], totalRecords: { count: 0 } },
  restaurants: [],
  promocodeDetail: {},
  isLoading: false,
  errorMsg: '',
  errorMsgAddPromoCode: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_PROMOCODE_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_PROMOCODE_SUCCESS: {
      const { promocodes } = payload;
      return { ...state, promocodes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_PROMOCODE_FAIL:
      return {
        ...state,
        promocodes: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_PROMOCODE_DETAIL_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_PROMOCODE_DETAIL_BY_ID_SUCCESS: {
      const { promocodeDetail } = payload;
      return { ...state, promocodeDetail, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_PROMOCODE_DETAIL_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.UPDATE_PROMOCODE_START:
      return { ...state, isLoading: true };
    case actionLabels.UPDATE_PROMOCODE_SUCCESS: {
      // const { promocodeDetail } = payload;
      return { ...state, isLoading: false, errorMsgAddPromoCode: '' };
    }
    case actionLabels.UPDATE_PROMOCODE_FAIL:
      return { ...state, isLoading: false, errorMsgAddPromoCode: payload };
    case actionLabels.GET_ALL_RESTAURANT_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_RESTAURANT_SUCCESS: {
      const { restaurants } = payload;
      return { ...state, restaurants, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_RESTAURANT_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.ADD_PROMOCODE_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_PROMOCODE_SUCCESS: {
      const { promocodes } = state;
      promocodes.list.unshift(payload);
      promocodes.totalRecords.count += 1;
      return {
        ...state,
        promocodes,
        isLoading: false,
        errorMsgAddPromoCode: '',
      };
    }
    case actionLabels.ADD_PROMOCODE_FAIL:
      return { ...state, isLoading: false, errorMsgAddPromoCode: payload };
    case actionLabels.EDIT_PROMOCODE_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_PROMOCODE_STATUS_SUCCESS: {
      const { promocodes } = state;
      const index = promocodes.list.findIndex(item => item._id === payload.id);
      if (index !== -1 && promocodes.list.length > index) {
        promocodes.list[index].status = !promocodes.list[index].status;
      }
      return { ...state, promocodes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_PROMOCODE_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_PROMOCODE_DETAIL:
      return {
        ...state,
        promocodeDetail: {},
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsgAddPromoCode: '' };
    }
    case actionLabels.DELETE_PROMOCODE_BY_ID_START:
      { return { ...state, isLoading: true }; }
    case actionLabels.DELETE_PROMOCODE_BY_ID_SUCCESS: {
      const promocodes = {
        ...state.promocodes,
      };
      console.log({promocodes})
      console.log({payload})
      promocodes.list = promocodes.list.filter(item => item._id !== payload);
      promocodes.totalRecords.count -= 1;
      return { ...state, promocodes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.DELETE_PROMOCODE_BY_ID_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }
    default:
      return state;
  }
};
