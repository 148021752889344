import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  TransportationModes: { list: [], totalRecords: { count: 0 } },
  TransportationMode: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_TRANSPORTATION_MODES_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_TRANSPORTATION_MODES_SUCCESS: {
      const { TransportationModes } = payload;
      return {
        ...state,
        TransportationModes,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.GET_ALL_TRANSPORTATION_MODES_FAIL:
      return {
        ...state,
        TransportationModes: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };

    case actionLabels.ADD_TRANSPORTATION_MODE_START:
      return { ...state, isLoading: true };

    case actionLabels.ADD_TRANSPORTATION_MODE_SUCCESS: {
      const { TransportationModes } = state;
      TransportationModes.list.unshift(payload);
      TransportationModes.totalRecords.count += 1;
      return { ...state, TransportationModes, isLoading: false, errorMsg: '' };
    }

    case actionLabels.ADD_TRANSPORTATION_MODE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Edit Category
    case actionLabels.EDIT_TRANSPORTATION_MODE_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_TRANSPORTATION_MODE_SUCCESS:
      return { ...state, isLoading: false, errorMsg: '' };
    case actionLabels.EDIT_TRANSPORTATION_MODE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // change status
    case actionLabels.EDIT_TRANSPORTATION_MODE_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_TRANSPORTATION_MODE_STATUS_SUCCESS: {
      const { TransportationModes } = state;
      const index = TransportationModes.list.findIndex(
        item => item._id === payload.id,
      );
      if (index !== -1 && TransportationModes.list.length > index) {
        TransportationModes.list[index].status = !TransportationModes.list[
          index
        ].status;
      }
      return { ...state, TransportationModes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_TRANSPORTATION_MODE_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    default:
      return state;
  }
};
