import * as actionLabels from '../../actions/actionLabels';

const intialState = {
  vehicles: { list: [], totalRecords: { count: 0 } },
  vehicle: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = intialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_OWNER_VEHICLES_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_OWNER_VEHICLES_SUCCESS: {
      const { vehicles } = payload;
      console.log('vehicle reducer data \n', vehicles);
      return { ...state, vehicles, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_OWNER_VEHICLES_FAIL:
      return {
        ...state,
        vehicles: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    // Get Vehicle By Id
    case actionLabels.GET_OWNER_VEHICLE_DETAIL_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_OWNER_VEHICLE_DETAIL_BY_ID_SUCCESS: {
      const { vehicle } = payload;
      return { ...state, vehicle, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_OWNER_VEHICLE_DETAIL_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Add Vehicle Owner
    case actionLabels.ADD_OWNER_VEHICLE_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_OWNER_VEHICLE_SUCCESS: {
      const { vehicles } = state;
      vehicles.list.unshift(payload);
      vehicles.totalRecords.count += 1;
      return { ...state, vehicles, isLoading: false, errorMsg: '' };
    }
    case actionLabels.ADD_OWNER_VEHICLE_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    // Block Unblock Status
    case actionLabels.EDIT_BLOCK_OWNER_VEHICLE_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BLOCK_OWNER_VEHICLE_STATUS_SUCCESS: {
      console.log('hitting ownerVehicleReducer');
      if (payload.id !== undefined) {
        const { vehicles } = state;
        const index = vehicles.list.findIndex(item => item._id === payload.id);
        console.log('vehicle index to block unvlock \n', index);
        if (index !== -1 && vehicles.list.length > index) {
          vehicles.list[index].isBlock = !vehicles.list[index].isBlock;
        }
        return { ...state, vehicles, isLoading: false, errorMsg: '' };
      }
      const { vehicle } = state;
      vehicle.isBlock = !vehicle.isBlock;
      return { ...state, vehicle, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_BLOCK_OWNER_VEHICLE_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.RESET_OWNER_VEHICLE_DETAILS:
      return {
        ...state,
        isLoading: false,
        vehicle: {},
        errorMsg: '',
      };

    case actionLabels.RESET_OWNER_VEHICLE_LIST:
      return {
        ...state,
        isLoading: false,
        vehicles: { list: [], totalRecords: { count: 0 } },
        errorMsg: '',
      };

    default:
      return state;
  }
};
