import firebase from 'firebase';

const initFirebase = async () => {
  try {
    firebase.initializeApp({
      apiKey: 'AIzaSyDvBDsG0mOdEfvaLQNKO4HkBAGgpv0MHXs',
      authDomain: 'wetaxidriver-1b134.firebaseapp.com',
      projectId: 'wetaxidriver-1b134',
      storageBucket: 'wetaxidriver-1b134.appspot.com',
      messagingSenderId: '19795795305',
      appId: '1:19795795305:web:9f1123dfd092321c2f31f8',
      measurementId: 'G-LTPN3LXHKM',
    });

    const messaging = firebase.messaging();

    if (firebase.messaging.isSupported()) {
      messaging.onMessage(payload => {
        console.log(payload);
        // toast(payload.data.message, {
        //   position: 'bottom-center',
        //   autoClose: false,
        // });
        const { title, message, icon, clickAction } = payload.data;
        new Notification(title, {
          message,
          icon,
        }).onclick = event => {
          event.preventDefault();
          window.location = clickAction;
        };
      });

      await Notification.requestPermission();
      const vapidKey =
        'BCVowOI3efHpJrJo50VAh1kOcouFMIP6anGtfkLqKRDBJPfzNozl0_DByXiKBnPSPmfh6sNd7BZRq641GiwjIh4';

      // await messaging.usePublicVapidKey(
      //   'BA8mZzyT0O61OHvcdGEkR5WjNjDlnFtUmM5is00EUtF00swwDEDK5efr5Da5tFV4IXoCIpHgfEVADoeNfn6uzTo',
      // );
      const token = await messaging.getToken({ vapidKey });
      if (localStorage.getItem('gtoken') !== token) {
        try {
          const authorizationToken = localStorage.getItem('token');
          if (authorizationToken != null) {
            // const response = await axios.put("http://dev.contestee.net/api/v1/users",
            //     { fcmToken: token },
            //     {
            //         headers: {
            //             "Content-Type": "application/json",
            //             "Authorization:": authorizationToken
            //         }
            //     }
            // );
          }
        } catch (error) {
          // console.log("initFirebase -> error", error)
        }
      }
      localStorage.setItem('gtoken', token);
    } else {
      localStorage.setItem('gtoken', false);
    }
  } catch (error) {
    console.log('Firebase notifications disabled');
  }
};

export default initFirebase;
