export const GET_ADMIN_DASHBOARD_START = 'GET_ADMIN_DASHBOARD_START';
export const GET_ADMIN_DASHBOARD_SAGA = 'GET_ADMIN_DASHBOARD_SAGA';
export const GET_ADMIN_DASHBOARD_SUCCESS = 'GET_ADMIN_DASHBOARD_SUCCESS';
export const GET_ADMIN_DASHBOARD_FAIL = 'GET_ADMIN_DASHBOARD_FAIL';

export const GET_OWNER_DASHBOARD_START = 'GET_OWNER_DASHBOARD_START';
export const GET_OWNER_DASHBOARD_SAGA = 'GET_OWNER_DASHBOARD_SAGA';
export const GET_OWNER_DASHBOARD_SUCCESS = 'GET_OWNER_DASHBOARD_SUCCESS';
export const GET_OWNER_DASHBOARD_FAIL = 'GET_OWNER_DASHBOARD_FAIL';

export const GET_CHEF_DASHBOARD_START = 'GET_CHEF_DASHBOARD_START';
export const GET_CHEF_DASHBOARD_SAGA = 'GET_CHEF_DASHBOARD_SAGA';
export const GET_CHEF_DASHBOARD_SUCCESS = 'GET_CHEF_DASHBOARD_SUCCESS';
export const GET_CHEF_DASHBOARD_FAIL = 'GET_CHEF_DASHBOARD_FAIL';

export const GET_PROMOTER_DASHBOARD_START = 'GET_PROMOTER_DASHBOARD_START';
export const GET_PROMOTER_DASHBOARD_SAGA = 'GET_PROMOTER_DASHBOARD_SAGA';
export const GET_PROMOTER_DASHBOARD_SUCCESS = 'GET_PROMOTER_DASHBOARD_SUCCESS';
export const GET_PROMOTER_DASHBOARD_FAIL = 'GET_PROMOTER_DASHBOARD_FAIL';

export const GET_VEHICLE_OWNER_DASHBOARD_START =
  'GET_VEHICLE_OWNER_DASHBOARD_START';
export const GET_VEHICLE_OWNER_DASHBOARD_SAGA =
  'GET_VEHICLE_OWNER_DASHBOARD_SAGA';
export const GET_VEHICLE_OWNER_DASHBOARD_SUCCESS =
  'GET_VEHICLE_OWNER_DASHBOARD_SUCCESS';
export const GET_VEHICLE_OWNER_DASHBOARD_FAIL =
  'GET_VEHICLE_OWNER_DASHBOARD_FAIL';
