import {
  GET_ALL_CONTACTUS_START,
  GET_ALL_CONTACTUS_SAGA,
  GET_ALL_CONTACTUS_SUCCESS,
  GET_ALL_CONTACTUS_FAIL,
  EDIT_MARK_AS_RESOLVED_START,
  EDIT_MARK_AS_RESOLVED_SAGA,
  EDIT_MARK_AS_RESOLVED_SUCCESS,
  EDIT_MARK_AS_RESOLVED_FAIL,
  RESET_CONTACTUS_LIST,
} from '../actionLabels';

export const getAllContactusStart = payload => ({
  type: GET_ALL_CONTACTUS_START,
  payload,
});

export const getAllContactusSaga = payload => ({
  type: GET_ALL_CONTACTUS_SAGA,
  payload,
});

export const getAllContactusSuccess = payload => ({
  type: GET_ALL_CONTACTUS_SUCCESS,
  payload,
});

export const getAllContactusFail = payload => ({
  type: GET_ALL_CONTACTUS_FAIL,
  payload,
});

export const editMarkAsResolvedStart = payload => ({
  type: EDIT_MARK_AS_RESOLVED_START,
  payload,
});

export const editMarkAsResolvedSaga = payload => ({
  type: EDIT_MARK_AS_RESOLVED_SAGA,
  payload,
});

export const editMarkAsResolvedSuccess = payload => ({
  type: EDIT_MARK_AS_RESOLVED_SUCCESS,
  payload,
});

export const editMarkAsResolvedFail = payload => ({
  type: EDIT_MARK_AS_RESOLVED_FAIL,
  payload,
});

export const resetContactUsList = () => ({
  type: RESET_CONTACTUS_LIST,
});
