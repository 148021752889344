import { put } from 'redux-saga/effects';
import {
    showModal,
    logout,
    deleteDriverByIdStart,
    deleteDriverByIdSuccess,
    deleteDriverByIdFail,
    // getAllDriverSaga,
    getDetailsOwnerDriverSaga,
} from "../../actions";
import axios from '../../../http/axios/axios_main';

// delete vendor type or Brand Type
export function* deleteDriverByIdSaga(action) {
    yield put(deleteDriverByIdStart());
    try {
        const { id, tableFilter, setModelDeleteOpen, route, status, isvehicleOwner } = action.payload;
        console.log(tableFilter);
        const response = yield axios.get(`/${route}/deleteDriver?driverId=${id}`);

        if (response.status === 200) {
            yield put(deleteDriverByIdSuccess(action.payload));
            yield setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    redirectURL:window.location.reload(),
                    message: response.data.message || 'Success',
                }),
            );
            yield put(getDetailsOwnerDriverSaga({ query: { tableFilter, status, isvehicleOwner }, route: "driver" }));
        } else {
            yield put(deleteDriverByIdFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(deleteDriverByIdFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(deleteDriverByIdFail(error.response.data.message));
            } else {
                yield put(deleteDriverByIdFail('Server error! Please try again.'));
            }
        } else {
            yield put(deleteDriverByIdFail('Something went wrong! Please try again.'));
        }
    }
};
