import {
  GET_ALL_COMMISSION_LIST_FAIL,
  GET_ALL_COMMISSION_LIST_SAGA,
  GET_ALL_COMMISSION_LIST_START,
  GET_ALL_COMMISSION_LIST_SUCCESS,
  GET_COMMISSION_DETAIL_FAIL,
  GET_COMMISSION_DETAIL_SAGA,
  GET_COMMISSION_DETAIL_START,
  GET_COMMISSION_DETAIL_SUCCESS,
  GET_TOTAL_COMMISSION_FAIL,
  GET_TOTAL_COMMISSION_SAGA,
  GET_TOTAL_COMMISSION_START,
  GET_TOTAL_COMMISSION_SUCCESS,
  GET_VENDOR_TOTAL_COMMISSION_FAIL,
  GET_VENDOR_TOTAL_COMMISSION_SAGA,
  GET_VENDOR_TOTAL_COMMISSION_START,
  GET_VENDOR_TOTAL_COMMISSION_SUCCESS,
  RESET_COMMISSION_DETAIL,
  RESET_COMMISSION_LIST,
} from '../actionLabels';

// Get All
export const getAllCommissionListStart = payload => ({
  type: GET_ALL_COMMISSION_LIST_START,
  payload,
});

export const getAllCommissionListSaga = payload => ({
  type: GET_ALL_COMMISSION_LIST_SAGA,
  payload,
});

export const getAllCommissionListSuccess = payload => ({
  type: GET_ALL_COMMISSION_LIST_SUCCESS,
  payload,
});

export const getAllCommissionListFail = payload => ({
  type: GET_ALL_COMMISSION_LIST_FAIL,
  payload,
});

// Reset All
export const resetCommissionList = () => ({
  type: RESET_COMMISSION_LIST,
});

//Get Single
export const getCommissionDetailStart = payload => ({
  type: GET_COMMISSION_DETAIL_START,
  payload,
});

export const getCommissionDetailSaga = payload => ({
  type: GET_COMMISSION_DETAIL_SAGA,
  payload,
});

export const getCommissionDetailSuccess = payload => ({
  type: GET_COMMISSION_DETAIL_SUCCESS,
  payload,
});

export const getCommissionDetailFail = payload => ({
  type: GET_COMMISSION_DETAIL_FAIL,
  payload,
});

// reset signle
export const resetCommissionDetail = () => ({
  type: RESET_COMMISSION_DETAIL,
});

//Get Total Commission
export const getTotalCommissionStart = payload => ({
  type: GET_TOTAL_COMMISSION_START,
  payload,
});

export const getTotalCommissionSaga = payload => ({
  type: GET_TOTAL_COMMISSION_SAGA,
  payload,
});

export const getTotalCommissionSuccess = payload => ({
  type: GET_TOTAL_COMMISSION_SUCCESS,
  payload,
});

export const getTotalCommissionFail = payload => ({
  type: GET_TOTAL_COMMISSION_FAIL,
  payload,
});

//Get Vendor's Total Commission
export const getVendorTotalCommissionStart = () => ({
  type: GET_VENDOR_TOTAL_COMMISSION_START,
});

export const getVendorTotalCommissionSaga = payload => ({
  type: GET_VENDOR_TOTAL_COMMISSION_SAGA,
  payload,
});

export const getVendorTotalCommissionSuccess = payload => ({
  type: GET_VENDOR_TOTAL_COMMISSION_SUCCESS,
  payload,
});

export const getVendorTotalCommissionFail = payload => ({
  type: GET_VENDOR_TOTAL_COMMISSION_FAIL,
  payload,
});
