export const GET_ALL_SUPPORT_REQUEST_START = 'GET_ALL_SUPPORT_REQUEST_START';
export const GET_ALL_SUPPORT_REQUEST_SAGA = 'GET_ALL_SUPPORT_REQUEST_SAGA';
export const GET_ALL_SUPPORT_REQUEST_SUCCESS =
  'GET_ALL_SUPPORT_REQUEST_SUCCESS';
export const GET_ALL_SUPPORT_REQUEST_FAIL = 'GET_ALL_SUPPORT_REQUEST_FAIL';

export const ADD_SUPPORT_REQUEST_START = 'ADD_SUPPORT_REQUEST_START';
export const ADD_SUPPORT_REQUEST_SAGA = 'ADD_SUPPORT_REQUEST_SAGA';
export const ADD_SUPPORT_REQUEST_SUCCESS = 'ADD_SUPPORT_REQUEST_SUCCESS';
export const ADD_SUPPORT_REQUEST_FAIL = 'ADD_SUPPORT_REQUEST_FAIL';
