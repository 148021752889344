export const GET_ALL_PROMOCODE_START = 'GET_ALL_PROMOCODE_START';
export const GET_ALL_PROMOCODE_SAGA = 'GET_ALL_PROMOCODE_SAGA';
export const GET_ALL_PROMOCODE_SUCCESS = 'GET_ALL_PROMOCODE_SUCCESS';
export const GET_ALL_PROMOCODE_FAIL = 'GET_ALL_PROMOCODE_FAIL';

export const ADD_PROMOCODE_START = 'ADD_PROMOCODE_START';
export const ADD_PROMOCODE_SAGA = 'ADD_PROMOCODE_SAGA';
export const ADD_PROMOCODE_SUCCESS = 'ADD_PROMOCODE_SUCCESS';
export const ADD_PROMOCODE_FAIL = 'ADD_PROMOCODE_FAIL';

export const GET_ALL_RESTAURANT_START = 'GET_ALL_RESTAURANT_START';
export const GET_ALL_RESTAURANT_SAGA = 'GET_ALL_RESTAURANT_SAGA';
export const GET_ALL_RESTAURANT_SUCCESS = 'GET_ALL_RESTAURANT_SUCCESS';
export const GET_ALL_RESTAURANT_FAIL = 'GET_ALL_RESTAURANT_FAIL';

export const GET_PROMOCODE_DETAIL_BY_ID_START =
  'GET_PROMOCODE_DETAIL_BY_ID_START';
export const GET_PROMOCODE_DETAIL_BY_ID_SAGA =
  'GET_PROMOCODE_DETAIL_BY_ID_SAGA';
export const GET_PROMOCODE_DETAIL_BY_ID_SUCCESS =
  'GET_PROMOCODE_DETAIL_BY_ID_SUCCESS';
export const GET_PROMOCODE_DETAIL_BY_ID_FAIL =
  'GET_PROMOCODE_DETAIL_BY_ID_FAIL';

export const UPDATE_PROMOCODE_START = 'UPDATE_PROMOCODE_START';
export const UPDATE_PROMOCODE_SAGA = 'UPDATE_PROMOCODE_SAGA';
export const UPDATE_PROMOCODE_SUCCESS = 'UPDATE_PROMOCODE_SUCCESS';
export const UPDATE_PROMOCODE_FAIL = 'UPDATE_PROMOCODE_FAIL';

export const EDIT_PROMOCODE_STATUS_START = 'EDIT_PROMOCODE_STATUS_START';
export const EDIT_PROMOCODE_STATUS_SAGA = 'EDIT_PROMOCODE_STATUS_SAGA';
export const EDIT_PROMOCODE_STATUS_SUCCESS = 'EDIT_PROMOCODE_STATUS_SUCCESS';
export const EDIT_PROMOCODE_STATUS_FAIL = 'EDIT_PROMOCODE_STATUS_FAIL';

export const RESET_PROMOCODE_DETAIL = 'RESET_PROMOCODE_DETAIL';

export const DELETE_PROMOCODE_BY_ID_SAGA = 'DELETE_PROMOCODE_BY_ID_SAGA';
export const DELETE_PROMOCODE_BY_ID_START = 'DELETE_PROMOCODE_BY_ID_START';
export const DELETE_PROMOCODE_BY_ID_SUCCESS = 'DELETE_PROMOCODE_BY_ID_SUCCESS';
export const DELETE_PROMOCODE_BY_ID_FAIL = 'DELETE_PROMOCODE_BY_ID_FAIL';

