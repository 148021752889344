export const GET_ALL_CMS_START = 'GET_ALL_CMS_START';
export const GET_ALL_CMS_SAGA = 'GET_ALL_CMS_SAGA';
export const GET_ALL_CMS_SUCCESS = 'GET_ALL_CMS_SUCCESS';
export const GET_ALL_CMS_FAIL = 'GET_ALL_CMS_FAIL';

export const GET_CMS_PAGE_START = 'GET_CMS_PAGE_START';
export const GET_CMS_PAGE_SAGA = 'GET_CMS_PAGE_SAGA';
export const GET_CMS_PAGE_SUCCESS = 'GET_CMS_PAGE_SUCCESS';
export const GET_CMS_PAGE_FAIL = 'GET_CMS_PAGE_FAIL';

export const GET_CMS_BY_ID_START = 'GET_CMS_BY_ID_START';
export const GET_CMS_BY_ID_SAGA = 'GET_CMS_BY_ID_SAGA';
export const GET_CMS_BY_ID_SUCCESS = 'GET_CMS_BY_ID_SUCCESS';
export const GET_CMS_BY_ID_FAIL = 'GET_CMS_BY_ID_FAIL';

export const UPDATE_CMS_START = 'UPDATE_CMS_START';
export const UPDATE_CMS_SAGA = 'UPDATE_CMS_SAGA';
export const UPDATE_CMS_SUCCESS = 'UPDATE_CMS_SUCCESS';
export const UPDATE_CMS_FAIL = 'UPDATE_CMS_FAIL';

export const RESET_CMS = 'RESET_CMS';
