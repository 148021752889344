/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-else-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Link } from 'react-router-dom';
import _, { head } from 'lodash';
import moment from 'moment';
import Popover from 'react-tiny-popover';
import { useTableHooks } from '../../customHooks';
import TablePagination from './TablePagination/TablePagination';
import CountDown from './CountDown/CountDown';
import dummyImage from '../../assets/img/dummy-image.png';
// import './TableFactory.scss';

const TableFactory = forwardRef((props, ref) => {

  const [mounted, setMounted] = useState(false);
  const {
    linkPropsName,
    headers,
    headerVisibility,
    tableData,
    getData,
    pageCount: totalPages,
    searchFeature,
    setSearchFlag,
    errorMsg,
    initialSortColumnName,
    initialSortOrderBy,
  } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(totalPages);
  const [searchFilter, setSearchFilter] = useState('');
  const [status, setStatus] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [alteredData, setAlteredData] = useState([]);
  const [sortObj, setSortObj] = useState({
    columnName: initialSortColumnName || 'name',
    orderBy: initialSortOrderBy || 'asc',
  });
  const [skip, setSkip] = useState(0);
  // const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverId, setIsPopoverId] = useState('');
  // eslint-disable-next-line no-use-before-define
  const debounceSearch = _.debounce(q => search(q), 500);

  useImperativeHandle(ref, () => ({
    setCurrentPageChild() {
      setCurrentPage(0);
      setSkip(0);
    },
  }));

  let content = <></>;

  const handlePageClick = useCallback(
    index => {
      const r = index * pageSize;
      setSkip(r);
      setCurrentPage(index);
    },
    [pageSize],
  );

  const renderStar = rating => {
    // let counter = 1;
    // while (counter < 6) {
    //   if (rating < counter) {
    //     if (counter - rating < 1) {
    //       ratingHTML.push(
    //         <i className="fa fa-star-half-o" aria-hidden="true" />,
    //       );
    //     } else {
    //       ratingHTML.push(<i className="fa fa-star-o" aria-hidden="true" />);
    //     }
    //   } else {
    //     ratingHTML.push(<i className="fa fa-star" aria-hidden="true" />);
    //   }
    //   counter += 1;
    // }

    return (
      // <div className="rating-love ">
      //   <span className="fa fa-heart-o" />
      //   <span className="fa fa-heart-o " />
      //   <span className="fa fa-heart-o" />
      //   <span className="fa fa-heart-o" />
      //   <span className="fa fa-heart-o" />
      //   <span className="rate" style={{ width: `${(rating * 100) / 5}%` }}>
      //     <span className="fa fa-heart full" />
      //     <span className="fa fa-heart full" />
      //     <span className="fa fa-heart full" />
      //     <span className="fa fa-heart full" />
      //     <span className="fa fa-heart full" />
      //   </span>
      // </div>
      <div className="btn btn-white btn-bitbucket btn-rating">
        <i className="fa fa-heart" />
        {Number.parseFloat(rating).toFixed(1)}
      </div>
    );
  };

  const renderDataCell = (record, header) => {
    if (header.isDate !== undefined && header.isDate === true) {
      return moment(record[header.key]).format('L') || '-';
    } else if (header.isDateTime !== undefined && header.isDateTime === true) {
      return moment(record[header.key]).format('DD/MM/YYYY hh:mm A ') || '-';
    } else if (header.isLogisticDateTime !== undefined && header.isLogisticDateTime === true) {
      if (record?.logisticsHub?.isReceived) {
        return moment(record?.logisticsHub?.receivedAt).format('DD/MM/YYYY hh:mm A ') || "-";
      }
    } else if (header.isReceiver !== undefined && header.isReceiver === true) {

      if (record.transportHistoryId) {
        return record.transportHistory?.receiverName;
      } else {
        return record?.orderDeliverAddress?.[header.key]

      }
      // return record?.orderDeliverAddress?.[header.key]

    } else if (header.isActive !== undefined && header.isActive === true) {
      return (
        <>
          <button
            type="button"
            className="btn btn-primary m-l-sm"
            data-dismiss="modal"
            onClick={() => console.log("clicked")}
          >
            <i className="fa fa-map-marker" aria-hidden="true" />
          </button>
        </>
      )
    }
    else if (header.isMedia !== undefined && header.isMedia === true) {
      if (header.mediaType === 'image') {
        if (header.imageInArray !== undefined && header.imageInArray === true) {
          return (
            <div className="table-image-container">
              <img
                src={`${record[header.key][0]}`}
                alt=""
                onError={e => (e.target.src = dummyImage)}
              />
            </div>
          );
        } else {
          return (
            <div className="table-image-container">
              <img
                src={`${record[header.key]}`}
                alt=""
                onError={e => (e.target.src = dummyImage)}
              />
            </div>
          );
        }
      } else if (header.mediaType === 'video') {
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video width="320" height="240" controls>
            <source src={`${record[header.key]}`} />
            Your browser does not support the video tag.
          </video>
        );
      } else return 'No support';
    } else if (header.isPickUpAddress === true && header.isPickUpAddress !== undefined) {
      return record[header.key][0].orderPickedUpAddress?.city

    } else if (header.isDeliveryAddress === true && header.isDeliveryAddress !== undefined) {
      return record[header.key][0]?.orderDeliverAddress?.city
    }
    else if (header.isRating !== undefined && header.isRating === true) {
      if (header.ratingType === 'star') {
        if (header.redirectLink !== undefined && header.redirectLink !== '') {
          return (
            <Link to={`${header.redirectLink}/${record[linkPropsName]}`}>
              {renderStar(record[header.key])}
            </Link>
          );
        } else {
          return renderStar(record[header.key]) || '-';
        }
      } else {
        return (
          <i
            className={record[header.key] ? 'fa fa-check' : 'fa fa-minus'}
            aria-hidden="true"
          />
        );
      }
    } else if (
      header.isConditional !== undefined &&
      header.isConditional === true
    ) {
      if (record[header.key]) {
        return header.positiveValue;
      } else {
        return header.negativeValue;
      }
    } else if (
      header.logisticsUserName !== undefined &&
      header.logisticsUserName === true
    ) 
    
    {

      if (record.transportHistoryId) {
        return record.transportHistory?.senderName;
      } else {
        return record[header.key]?.name;

      }
      // return record[header.key]?.name;
    } 
    
    
    
    else if (
      header.shippedsender !== undefined &&
      header.shippedsender === true
    ) 
    
    {

      if (record.userDetails) {
        return record.userDetails?.senderName;
      } 
      
      // else {
      //   return record[header.key]?.name;

      // }
      // return record[header.key]?.name;
    } 
    
    


    else if (
      header.shippedAddressNew !== undefined &&
      header.shippedAddressNew === true
    ) 
    
    {

      if (record?.userDetails) {
        return record.userDetails?.orderPickedUpAddress?.address;
      } 
      
      // else {
      //   return record[header.key]?.name;

      // }
      // return record[header.key]?.name;
    } 
    
    else if (
      header.receiverNameNew !== undefined &&
      header.receiverNameNew === true
    ) 
    
    {

      if (record?.userDetails) {
        return record.userDetails?.receiverName;
      } 
      
      // else {
      //   return record[header.key]?.name;

      // }
      // return record[header.key]?.name;
    } 
    

    else if (
      header.addressReceiverNew !== undefined &&
      header.addressReceiverNew === true
    ) 
    
    {

      if (record?.userDetails) {
        // return record.userDetails?.orderPickedUpAddress?.address;
        return record.userDetails?.orderDeliverAddress?.address;

      } 
      
      // else {
      //   return record[header.key]?.name;

      // }
      // return record[header.key]?.name;
    } 
    


    else if (
      header.finalDestinyNew !== undefined &&
      header.finalDestinyNew === true
    ) 
    
    {

      if (record?.userDetails) {
        return record.userDetails?.orderDeliverAddress.city;
      } 
      
      // else {
      //   return record[header.key]?.name;

      // }
      // return record[header.key]?.name;
    } 
    

    


    else if (
      header.originShippedNew !== undefined &&
      header.originShippedNew === true
    ) 
    
    {

      if (record?.userDetails) {
        return record.userDetails?.orderPickedUpAddress.city;
      } 
      
      // else {
      //   return record[header.key]?.name;

      // }
      // return record[header.key]?.name;
    } 


    else if (
      header.shippedDateNew !== undefined &&
      header.shippedDateNew === true
    ) 
    
    {

        return moment(record?.createdAt).format('DD/MM/YYYY') ;
    
    } 



    else if (
      header.logisticsUserNumber !== undefined &&
      header.logisticsUserNumber === true
    ) {
      return record[header.key]?.phone;
    } else if (
      header.logisticsUserState !== undefined &&
      header.logisticsUserState === true
    ) {

      if (record.transportHistoryId) {
        return record.orderDeliverAddress?.city;
      } else {
        return record[header.key]?.city;

      }
      // return record[header.key]?.city;

    }
    else if (
      header.logisticsChefState !== undefined &&
      header.logisticsChefState === true
    ) {
      if (record?.transportHistoryId) {
        return record.orderPickedUpAddress?.city;
      } else {
        return record[header.key]?.restaurantAddress?.city;

      }
      // return record[header.key]?.restaurantAddress?.city;

    }
    else if (
      header.isUserName !== undefined &&
      header.isUserName === true
    ) {
      return record[header.key][0]?.senderName;
    }
    else if (
      header.isUserPhone !== undefined &&
      header.isUserPhone === true
    ) {
      return record[header.key][0]?.senderPhone;
    }
    else if (
      header.isReceiverName !== undefined &&
      header.isReceiverName === true
    ) {
      return record[header.key][0]?.receiverName;
    }
    else if (
      header.isReceiverPhone !== undefined &&
      header.isReceiverPhone === true
    ) {
      return record[header.key][0]?.receiverPhone;
    }
    else if (
      header.deliveryCity !== undefined &&
      header.deliveryCity === true
    ) {
      if (record[header.key] !== undefined &&
        record[header.key] === true) {
        return "In City";
      } else {
        return "Out Of City";
      }
    }
    else if (
      header.isCapitalize !== undefined &&
      header.isCapitalize === true
    ) {
      return (
        `${record[header.key].charAt(0).toUpperCase()}${record[header.key]
          .slice(1)
          .replace(/([A-Z])/g, ' $1')
          .trim()}` || '-'
      );
    } 
    
    else if (
      header.allVehicleData !== undefined &&
      header.allVehicleData === true
    ) {
      return (
        `${record[header.key]?.name.charAt(0).toUpperCase()}${record[header.key]?.name
          .slice(1)
          .replace(/([A-Z])/g, ' $1')
          .trim()}` || '-'
      );
    } 
    
    
    else if (header.isStatus !== undefined && header.isStatus === true) {
      if (header.permissionRequired !== undefined
        && header.permissionRequired === true
        && header.keyToCheck !== undefined
        // && header.valueToCheck !== undefined
      ) {
        return (
          <label className="switch">
            <input
              type="checkbox"
              // disabled={!record[header.key] && record[header.keyToCheck] !== 'chef'}
              // eslint-disable-next-line no-nested-ternary
              disabled={record[header.key] === false && record[header.keyToCheck] !== "chef" ? true : record[header.key] === true && record[header.keyToCheck] === "chef" ? false : record[header.key] === false && record[header.keyToCheck] === "chef" ? false : record[header.key] === true && record[header.keyToCheck] !== "chef" ? false : true}
              checked={record[header.key] === header.positiveValue}
              onChange={() => {
                // if (!record[header.key] || record[header.keyToCheck] !== 'chef') {
                const payload = {
                  id: record._id,
                  status: !record[header.key],
                };
                header.action(payload);
                // } else {
                //   console.log("field disabled")
                // }
                // handleStatus(data, header.key);
              }}
            />
            <span className="slider round" />
          </label>
        );
      }
      else return (
        <label className="switch">
          <input
            type="checkbox"
            checked={record[header.key] === header.positiveValue}
            onChange={() => {
              const payload = {
                id: record._id,
                status: !record[header.key],
              };
              header.action(payload);
              // handleStatus(data, header.key);
            }}
          />
          <span className="slider round" />
        </label>
      );
    } else if (header.isEmpStatus !== undefined && header.isEmpStatus === true) {
      console.log(record[header.key])
      if (header.permissionRequired !== undefined
        && header.permissionRequired === true
      ) {
        return (
          <label className="switch">
            <input
              type="checkbox"
              // disabled={!record[header.key] && record[header.keyToCheck] !== 'chef'}
              // eslint-disable-next-line no-nested-ternary
              checked={record[header.key] === header.positiveValue}
              onChange={() => {
                // if (!record[header.key] || record[header.keyToCheck] !== 'chef') {
                const payload = {
                  id: record._id,
                  status: !record[header.key],
                };
                header.action(payload);
                // } else {
                //   console.log("field disabled")
                // }
                // handleStatus(data, header.key);
              }}
            />
            <span className="slider round" />
          </label>
        );
      }
      else return (
        <label className="switch">
          <input
            type="checkbox"
            checked={record[header.key] === header.positiveValue}
            onChange={() => {
              const payload = {
                id: record._id,
                status: !record[header.key],
              };
              header.action(payload);
              // handleStatus(data, header.key);
            }}
          />
          <span className="slider round" />
        </label>
      );
    } else if (header.assignedVehicle !== undefined && header.assignedVehicle === true) {
      // console.log(record[header.key])
      if (record[header.key] !== undefined && record[header.key] === true) {
        return 'Yes'
      } else {
        return 'No'
      }
    } else if (header.availableProduct !== undefined && header.availableProduct === true) {
      return record?.dishList?.map((item) => item.name)
    } else if (header.userPackage !== undefined && header.userPackage === true) {
      return record.transportHistoryId !== undefined && record?.transportHistoryId ? "User Package" : "Market Place Order"
    } else if (header.isActions !== undefined && header.isActions === true) {
      const { actions } = header;
      return (
        <div
          className="action-btn"
          style={
            header.width !== undefined && header.width !== ''
              ? { width: `${header.width}` }
              : {}
          }
        >
          {actions.map((item, idx) => {
            // record[header.conditionalKey] !== header.conditionalValue
            if (
              item.conditionalKey !== undefined &&
              item.conditionalKey !== '' &&
              record[item.conditionalKey] === item.conditionalValue
            ) {
              return null;
            } else {
              return typeof item.action === 'string' ? (
                <Link to={`${item.action}/${record[linkPropsName]}`} key={idx}>
                  {typeof item.view === 'function'
                    ? item.view(record)
                    : item.view}
                </Link>
              ) : (
                <button
                  type="button"
                  key={idx}
                  className="btn-no-bg action-btn-color"
                  onClick={() => item.action(record)}
                >
                  {typeof item.view === 'function'
                    ? item.view(record)
                    : item.view}
                </button>
              );
            }
          })}
        </div>
      );
    } else if (header.isActions !== undefined && header.isActions === false) {
      const { actions } = header;
      return (
        <>
          {record.logisticsHub?.priority === 1 ? (
            <div
              className="action-btn"
              style={
                header.width !== undefined && header.width !== ''
                  ? { width: `${header.width}` }
                  : {}
              }
            >
              {actions.map((item, idx) => {
                // record[header.conditionalKey] !== header.conditionalValue
                if (
                  item.conditionalKey !== undefined &&
                  item.conditionalKey !== '' &&
                  record[item.conditionalKey] === item.conditionalValue
                ) {
                  return null;
                } else {
                  return typeof item.action === 'string' ? (
                    <Link to={`${item.action}/${record[linkPropsName]}`} key={idx}>
                      {typeof item.view === 'function'
                        ? item.view(record)
                        : item.view}
                    </Link>
                  ) : (
                    <button
                      type="button"
                      key={idx}
                      className="btn-no-bg action-btn-color"
                      onClick={() => item.action(record)}
                    >
                      {typeof item.view === 'function'
                        ? item.view(record)
                        : item.view}
                    </button>
                  );
                }
              })}
            </div>
          )
            : "-"}
        </>
      );
    }
    else if (
      header.isCountDown !== undefined &&
      header.isCountDown === true
    ) {
      return <CountDown time={record[header.key]} />;
    } else if (header.countryCode !== undefined && header.key !== undefined) {
      return (record[header.countryCode] ? `(+${record[header.countryCode]})-${record[header.key]}` : record[header.key]);
    } else if (header.isDetailPresent !== undefined) {
      const driverDetail = record[header.isDetailPresent]?.map((value, inx) => inx).join();
      return (driverDetail ? 'Yes' : 'No');
    } else if (header.serviceType !== undefined && header.serviceType === true) {
      return record[header.key];
    }
    else if (header.isCurrency !== undefined && header.isCurrency === true) {
      return `$ ${record[header.key].toFixed(2)}`;
    } else if (header.hasArrayOfObjects !== undefined && header.hasArrayOfObjects === true) {
      return record[header.key]?.map((item) => item[header.keyToMap])?.join(' , ') || 'Not Assigned Yet'
    } else if (header.isDelivered !== undefined && header.isDelivered === true) {
      return record.isDelivered ? 'Delivered' : 'Not Delivered'
    }
    else {
      return record[header.key] || '-';
    }
  };

  const sort = useCallback(
    headerName => {
      const newObj = {
        columnName: headerName,
      };
      if (sortObj?.columnName === headerName) {
        if (sortObj.orderBy === 'desc') {
          newObj.orderBy = 'asc';
        } else {
          newObj.orderBy = 'desc';
        }
      } else {
        newObj.orderBy = 'desc';
      }
      setSortObj({ ...newObj });
      setCurrentPage(0);
      setSkip(0);
    },
    [sortObj],
  );

  const search = useCallback(
    filterVal => {
      console.log(filterVal,'searchyeee loooo')
      if (filterVal.length) {
        setSearchFilter(filterVal);
        setSearchFlag(true);
      } else {
        setSearchFilter(filterVal);
        setSkip(0);
        setCurrentPage(0);
        if (mounted) setSearchFlag(false);
      }
    },
    [setSearchFlag, setSearchFilter, mounted, setSkip, setCurrentPage],
  );

  // function deleteRecord({ _id }) {
  //   deleteAction({
  //     id: _id,
  //     columnName: sortObj.columnName,
  //     orderBy: sortObj.orderBy,
  //     searchFilter,
  //     skip,
  //     pageSize,
  //   });
  // }

  const setStatusColumn = useCallback(() => {
    const statusCopy = tableData.reduce((obj, item) => {
      const idString = `${item._id}-status`;
      // eslint-disable-next-line no-param-reassign
      obj[idString] = item?.isBlock;
      return obj;
    }, {});
    setStatus(statusCopy);
  }, [tableData]);

  useTableHooks(
    setMounted,
    setPageCount,
    totalPages,
    pageSize,
    setStatusColumn,
    currentPage,
    searchFilter,
    skip,
    sortObj,
    tableData,
    alteredData,
    setAlteredData,
    getData,
  );
  const headerSetter =
    headerVisibility === true || headerVisibility === undefined ? true : false;
  const searchFeatureSetter =
    searchFeature === true || searchFeature === undefined ? true : false;
  const toBeRenderedData = alteredData.length ? [...alteredData] : [];
  if (status) {
    content = (
      <>
        <div className="table-responsive coman-table">
          <table className="table dataTable">
            <thead>
              <tr>
                {headerSetter &&
                  headers.map((header, idx) => (
                    <th
                      key={idx}
                      className={
                        // eslint-disable-next-line no-nested-ternary
                        header.noSort === undefined || header.noSort === false
                          ? // eslint-disable-next-line no-nested-ternary
                          sortObj.columnName === header.key
                            ? sortObj.orderBy === 'asc'
                              ? 'sorting_asc'
                              : 'sorting_desc'
                            : 'sorting'
                          : ''
                      }
                      style={
                        header.width !== undefined && header.width !== ''
                          ? { width: `${header.width}` }
                          : {}
                      }
                      onClick={() => {
                        if (
                          header.noSort === undefined ||
                          header.noSort === false
                        ) {
                          sort(header.key);
                        }
                      }}
                    >
                      {header.displayName}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {/* eslint-disable-next-line no-extra-boolean-cast */}
              {!!toBeRenderedData.length ? (
                toBeRenderedData.map((data, idx) => (
                  <React.Fragment key={idx}>
                    <tr className="tableRow">
                      {headers.map((header, Idx) => (
                        <React.Fragment key={Idx}>
                          <td
                            style={
                              header.width !== undefined && header.width !== ''
                                ? { width: `${header.width}px` }
                                : {}
                            }
                            className={header.className ? header.className : ''}
                          >
                            {header.isMore !== true ? (
                              renderDataCell(data, header)
                            ) : (
                              <Popover
                                style={{ width: '300px' }}
                                isOpen={data[linkPropsName] === isPopoverId}
                                position="bottom" // preferred position
                                content={
                                  // eslint-disable-next-line react/jsx-wrap-multilines
                                  <div>
                                    <div className="table-popover-title">
                                      <button
                                        type="button"
                                        className="close table-popover-close"
                                        data-dismiss="modal"
                                        onClick={() => setIsPopoverId('')}
                                      >
                                        ×
                                      </button>
                                      {data[header.popoverTitleKey] ||
                                        'Message'}
                                    </div>
                                    <div className="table-popover-description">
                                      {data[header.popoverDescriptionKey] ||
                                        '-'}
                                    </div>
                                  </div>
                                }
                              >
                                <div
                                  className="table-ellipsis"
                                  style={{ width: `${header.width}px` }}
                                  onClick={() =>
                                    setIsPopoverId(
                                      isPopoverId &&
                                        isPopoverId === data[linkPropsName]
                                        ? ''
                                        : data[linkPropsName],
                                    )
                                  }
                                >
                                  {renderDataCell(data, header)}
                                </div>
                              </Popover>
                            )}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={headers.length}>
                    <div className="error-card-div">{errorMsg}</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="row" style={{ marginTop: '5px' }}>
          <div className="col-sm-5">
            <div
              className="dataTables_info"
              id="DataTables_Table_0_info"
              role="status"
              aria-live="polite"
            >
              {`Showing ${totalPages === 0 ? 0 : (skip / pageSize) * pageSize + 1
                } to ${(skip / pageSize) * pageSize + pageSize > totalPages
                  ? totalPages
                  : (skip / pageSize) * pageSize + pageSize
                } of ${totalPages} entries`}
            </div>
          </div>
          <div className="col-sm-7">
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="DataTables_Table_0_paginate"
            >
              <TablePagination
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                pageCount={pageCount}
                sliceStatus={pageCount !== 0}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="dataTables_wrapper">
      <div className="row">
        <div className="col-sm-6">
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label>
              Show
              <select
                name="DataTables_Table_0_length"
                className="form-control input-sm"
                onChange={e => {
                  setPageSize(e.target.value);
                  setSkip(0);
                  setCurrentPage(0);
                }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              Entries
            </label>
          </div>
        </div>

        {searchFeatureSetter && (
          <div className="col-sm-6">
            <div id="DataTables_Table_0_filter" className="dataTables_filter">
              <label>
                Search:
                <input
                  className="form-control input-sm"
                  label="Search"
                  onChange={event => {
                    debounceSearch(event.target.value);
                  }}
                />
              </label>
            </div>
          </div>
        )}
      </div>
      {content}
    </div>
  );
});

export default React.memo(TableFactory);
