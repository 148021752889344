export const RESET_VEHICLE_DETAILS = 'RESET_VEHICLE_DETAILS';
// Get All
export const GET_ALL_VEHICLES_START = 'GET_ALL_VEHICLES_START';
export const GET_ALL_VEHICLES_SAGA = 'GET_ALL_VEHICLES_SAGA';
export const GET_ALL_VEHICLES_SUCCESS = 'GET_ALL_VEHICLES_SUCCESS';
export const GET_ALL_VEHICLES_FAIL = 'GET_ALL_VEHICLES_FAIL';

// Get Single
export const GET_VEHICLE_DETAIL_BY_ID_START = 'GET_VEHICLE_DETAIL_BY_ID_START';
export const GET_VEHICLE_DETAIL_BY_ID_SAGA = 'GET_VEHICLE_DETAIL_BY_ID_SAGA';
export const GET_VEHICLE_DETAIL_BY_ID_SUCCESS =
  'GET_VEHICLE_DETAIL_BY_ID_SUCCESS';
export const GET_VEHICLE_DETAIL_BY_ID_FAIL = 'GET_VEHICLE_DETAIL_BY_ID_FAIL';

// Add
export const ADD_VEHICLE_START = 'ADD_VEHICLE_START';
export const ADD_VEHICLE_SAGA = 'ADD_VEHICLE_SAGA';
export const ADD_VEHICLE_SUCCESS = 'ADD_VEHICLE_SUCCESS';
export const ADD_VEHICLE_FAIL = 'ADD_VEHICLE_FAIL';

// Edit
export const EDIT_VEHICLE_START = 'EDIT_VEHICLE_START';
export const EDIT_VEHICLE_SAGA = 'EDIT_VEHICLE_SAGA';
export const EDIT_VEHICLE_SUCCESS = 'EDIT_VEHICLE_SUCCESS';
export const EDIT_VEHICLE_FAIL = 'EDIT_VEHICLE_FAIL';

// Block - Unblock
export const EDIT_BLOCK_VEHICLE_STATUS_START =
  'EDIT_BLOCK_VEHICLE_STATUS_START';
export const EDIT_BLOCK_VEHICLE_STATUS_SAGA = 'EDIT_BLOCK_VEHICLE_STATUS_SAGA';
export const EDIT_BLOCK_VEHICLE_STATUS_SUCCESS =
  'EDIT_BLOCK_VEHICLE_STATUS_SUCCESS';
export const EDIT_BLOCK_VEHICLE_STATUS_FAIL = 'EDIT_BLOCK_VEHICLE_STATUS_FAIL';

// Delete vehicle Type
export const DELETE_VEHICLE_START = 'DELETE_VEHICLE_START';
export const DELETE_VEHICLE_SAGA = 'DELETE_VEHICLE_SAGA';
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
export const DELETE_VEHICLE_FAIL = 'DELETE_VEHICLE_FAIL';

//Delete vehicle by vehicle owner
export const DELETE_VEHICLE_BY_VEHICLE_OWNER_START = 'DELETE_VEHICLE_BY_VEHICLE_OWNER_START';
export const DELETE_VEHICLE_BY_VEHICLE_OWNER_SAGA = 'DELETE_VEHICLE_BY_VEHICLE_OWNER_SAGA';
export const DELETE_VEHICLE_BY_VEHICLE_OWNER_SUCCESS = 'DELETE_VEHICLE_BY_VEHICLE_OWNER_SUCCESS';
export const DELETE_VEHICLE_BY_VEHICLE_OWNER_FAIL = 'DELETE_VEHICLE_BY_VEHICLE_OWNER_FAIL';

