import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import rootReducer from './store/reducer';
import {
  watchDummy,
  watchAuthentication,
  watchDashboard,
  watchUser,
  watchDish,
  watchChef,
  watchDriver,
  watchOrder,
  watchSetting,
  watchSubadmin,
  watchReview,
  watchPromocode,
  watchCMS,
  watchContactus,
  watchInquiry,
  watchSupportRequest,
  watchPayment,
  watchNotification,
  watchReports,
  watchPromoters,
  watchVehicleOwners,
  watchVehicles,
  watchServices,
  watchOwnerVehicles,
  watchCommission,
  watchReferral,
  watchStoreTypes,
  watchTransportationModes,
  watchModuleAccess,
  watchLogistics,
} from './store/sagas';
import App from './App';
import * as serviceWorker from './serviceWorker';

/* const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;  */

let composeEnhancers = null;
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(watchDummy);
sagaMiddleware.run(watchAuthentication);
sagaMiddleware.run(watchDashboard);
sagaMiddleware.run(watchUser);
sagaMiddleware.run(watchDish);
sagaMiddleware.run(watchChef);
sagaMiddleware.run(watchDriver); // Shashank Driver Mgmt
sagaMiddleware.run(watchOrder);
sagaMiddleware.run(watchSetting);
sagaMiddleware.run(watchSubadmin);
sagaMiddleware.run(watchReview);
sagaMiddleware.run(watchPromocode);
sagaMiddleware.run(watchCMS);
sagaMiddleware.run(watchContactus);
sagaMiddleware.run(watchInquiry);
sagaMiddleware.run(watchSupportRequest);
sagaMiddleware.run(watchPayment);
sagaMiddleware.run(watchNotification);
sagaMiddleware.run(watchReports);
sagaMiddleware.run(watchPromoters);
sagaMiddleware.run(watchVehicleOwners);
sagaMiddleware.run(watchVehicles);
sagaMiddleware.run(watchOwnerVehicles);
sagaMiddleware.run(watchServices);
sagaMiddleware.run(watchStoreTypes);
sagaMiddleware.run(watchLogistics);
sagaMiddleware.run(watchTransportationModes);
sagaMiddleware.run(watchCommission);
sagaMiddleware.run(watchReferral);
sagaMiddleware.run(watchModuleAccess);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister(); //
