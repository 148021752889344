import { put } from 'redux-saga/effects';
import { capitalize } from '../../../utils/capitalize';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';
import {
  addTransportationModeStart,
  addTransportationModeSuccess,
  addTransportationModeFail,
  getAllTransportationModesSaga as getAllTransportationModesSagaAction,
  getAllTransportationModesFail,
  getAllTransportationModesStart,
  getAllTransportationModesSuccess,
  logout,
  showModal,
  editTransportationModeStart,
  editTransportationModeSuccess,
  editTransportationModeFail,
  editTransportationModeStatusStart,
  editTransportationModeStatusSuccess,
  editTransportationModeStatusFail,
  deleteTransportationModeStart,
  deleteTransportationModeSuccess,
  deleteTransportationModeFail,
} from '../../actions';

export function* getAllTransportationModesSaga(action) {
  yield put(getAllTransportationModesStart());
  try {
    let response;
    if (action.payload.isPromoter) {
      delete action.payload.isPromoter;
      response = yield axios.get(
        urlPaginationGenerator(`/promoter/getAllTransportMode`, action.payload),
      );
    } else {
      response = yield axios.get(
        urlPaginationGenerator(`/admin/getAllTransportMode`, action.payload),
      );
    }
    if (response.status === 200) {
      yield put(
        getAllTransportationModesSuccess({
          TransportationModes: response.data.data,
        }),
      );
    } else {
      yield put(
        getAllTransportationModesFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllTransportationModesFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllTransportationModesFail(error.response.data.message));
      } else {
        yield put(
          getAllTransportationModesFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getAllTransportationModesFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}

// add Store Type
export function* addTransportationModeSaga(action) {
  const { setIsDisable } = action.payload;
  yield put(addTransportationModeStart());
  try {
    const {
      closeModel,
      tableFilter,
      name,
      photo,
      serviceAreas,
    } = action.payload;

    tableFilter.columnName = 'createdAt';
    tableFilter.orderBy = 'desc';

    const formData = new FormData();
    formData.append('name', name);
    formData.append('photo', photo);
    formData.append('serviceAreas', JSON.stringify(serviceAreas));

    // console.log(action.payload);
    // // for(let i of serviceAreas){
    // //   formData.append('ser',name)
    // // }

    // setIsDisable(false);
    // return;
    const response = yield axios.post(`/admin/addTransportMode`, formData);
    if (response.status === 200) {
      yield put(addTransportationModeSuccess(response.data.data));
      setIsDisable(false);
      closeModel();
      yield put(getAllTransportationModesSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: capitalize(response.data.message) || 'Success',
        }),
      );
    } else {
      yield put(
        addTransportationModeFail('Something went wrong! Please try again.'),
      );
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addTransportationModeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addTransportationModeFail(error.response.data.message));
      } else {
        yield put(addTransportationModeFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        addTransportationModeFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Edit Store Type
export function* editTransportationModeSaga(action) {
  yield put(editTransportationModeStart());
  try {
    const {
      closeModel,
      setIsDisable,
      tableFilter,
      id,
      name,
      photo,
      serviceAreas,
    } = action.payload;
    const formData = new FormData();
    formData.append('id', id);
    formData.append('name', name);
    formData.append('photo', photo);
    formData.append('serviceAreas', JSON.stringify(serviceAreas));
    const response = yield axios.post(`/admin/editTransportMode`, formData);
    if (response.status === 200) {
      yield put(editTransportationModeSuccess());
      setIsDisable(false);
      closeModel();
      yield put(getAllTransportationModesSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(
        editTransportationModeFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editTransportationModeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editTransportationModeFail(error.response.data.message));
      } else {
        yield put(
          editTransportationModeFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        editTransportationModeFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// change status
export function* editTransportationModeStatusSaga(action) {
  yield put(editTransportationModeStatusStart());
  try {
    const response = yield axios.post(
      `/admin/updateTransportationModeStatus`,
      action.payload,
    );

    if (response.status === 200) {
      yield put(editTransportationModeStatusSuccess(action.payload));
    } else {
      yield put(
        editTransportationModeStatusFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(
          editTransportationModeStatusFail(error.response.data.message),
        );
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(
          editTransportationModeStatusFail(error.response.data.message),
        );
      } else {
        yield put(
          editTransportationModeStatusFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        editTransportationModeStatusFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}

// Delete TransportationMode Start
export function* deleteTransportationModeSaga(action) {
  yield put(deleteTransportationModeStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    const response = yield axios.post(`/admin/deleteTransportMode`, {
      id,
    });

    if (response.status === 200) {
      yield put(deleteTransportationModeSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllTransportationModesSagaAction(tableFilter));
    } else {
      yield put(
        deleteTransportationModeFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 1,
          message: error.response.data.message || 'Error',
        }),
      );
      if (error.response.status === 400) {
        yield put(deleteTransportationModeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteTransportationModeFail(error.response.data.message));
      } else {
        yield put(
          deleteTransportationModeFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        deleteTransportationModeFail('Something went wrong! Please try again.'),
      );
    }
  }
}
