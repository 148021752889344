import { put } from 'redux-saga/effects';
import {
  getAllOrderStart,
  getAllOrderSuccess,
  getAllOrderFail,
  getAllChefOrderStart,
  getAllChefOrderSuccess,
  getAllChefOrderFail,
  getOrderByIdStart,
  getOrderByIdSuccess,
  getOrderByIdFail,
  getAllOrderByUserIdStart,
  getAllOrderByUserIdSuccess,
  getAllOrderByUserIdFail,
  getOrderCountSaga as getOrderCountSagaAction,
  getOrderCountStart,
  getOrderCountSuccess,
  getOrderCountFail,
  logout,
  getOrderTrackStart,
  getOrderTrackSuccess,
  getOrderTrackFail,
  getAllChefUpcomingOrderStart,
  getAllChefUpcomingOrderSuccess,
  getAllChefUpcomingOrderFail,
  editInstructionStart,
  editInstructionSuccess,
  editInstructionFail,
  getAllChefUpcomingOrderSaga as getAllChefUpcomingOrderSagaAction,
  showModal,
  editAcceptOrderStart,
  editAcceptOrderSuccess,
  editAcceptOrderFail,
  editRejectOrderStart,
  editRejectOrderSuccess,
  editRejectOrderFail,
  getAllChefOrderSaga as getAllChefOrderSagaAction,
  editOrderStatusStart,
  editOrderStatusSuccess,
  editOrderStatusFail,
  transactionHistoryStart,
  transactionHistorySuccess,
  transactionHistoryFail,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllOrderSaga(action) {
  yield put(getAllOrderStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllOrderList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllOrderSuccess({ orders: response.data.data }));
    } else {
      yield put(getAllOrderFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllOrderFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllOrderFail(error.response.data.message));
      } else {
        yield put(getAllOrderFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllOrderFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getOrderTrackSaga(action) {
  yield put(getOrderTrackStart());
  try {
    const response = yield axios.get(
      // `/admin/getOrderTracking?id=${action.payload.id}`,
      `/logistics/getOrderTracking?id=${action.payload.id}`,
    );
    if (response.status === 200) {
      yield put(getOrderTrackSuccess({ orderTrack: response.data.data }));
    } else {
      yield put(getOrderTrackFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getOrderTrackFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getOrderTrackFail(error.response.data.message));
      } else {
        yield put(getOrderTrackFail('Server error! Please try again.'));
      }
    } else {
      yield put(getOrderTrackFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getAllChefOrderSaga(action) {
  yield put(getAllChefOrderStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/chef/getAllOrderList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllChefOrderSuccess({ orders: response.data.data }));
    } else {
      yield put(getAllChefOrderFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllChefOrderFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllChefOrderFail(error.response.data.message));
      } else {
        yield put(getAllChefOrderFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllChefOrderFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getAllChefUpcomingOrderSaga(action) {
  yield put(getAllChefUpcomingOrderStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/chef/getAllOrderList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllChefUpcomingOrderSuccess({ orders: response.data.data }));
    } else {
      yield put(
        getAllChefUpcomingOrderFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllChefUpcomingOrderFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllChefUpcomingOrderFail(error.response.data.message));
      } else {
        yield put(
          getAllChefUpcomingOrderFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getAllChefUpcomingOrderFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getOrderTrackChefSaga(action) {
  yield put(getOrderTrackStart());
  try {
    const response = yield axios.get(
      `/${action.payload.isLogistic ? "logistics" : "chef"}/getOrderTracking?id=${action.payload.id}`,
    );
    if (response.status === 200) {
      yield put(getOrderTrackSuccess({ orderTrack: response.data.data }));
    } else {
      yield put(getOrderTrackFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getOrderTrackFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getOrderTrackFail(error.response.data.message));
      } else {
        yield put(getOrderTrackFail('Server error! Please try again.'));
      }
    } else {
      yield put(getOrderTrackFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getOrderByIdSaga(action) {
  yield put(getOrderByIdStart());
  try {
    const response = yield axios.get(
      `/chef/getOrderDetailByIdForChef?id=${action.payload.id}&isUpComing=true`,
    );
    if (response.status === 200) {
      yield put(getOrderByIdSuccess({ order: response.data.data }));
    } else {
      yield put(getOrderByIdFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getOrderByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getOrderByIdFail(error.response.data.message));
      } else {
        yield put(getOrderByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(getOrderByIdFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getAllOrderByUserIdSaga(action) {
  yield put(getAllOrderByUserIdStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllOrderListByUserId`, action.payload),
    );
    if (response.status === 200) {
      yield put(
        getAllOrderByUserIdSuccess({ ordersByUserId: response.data.data }),
      );
    } else {
      yield put(
        getAllOrderByUserIdFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllOrderByUserIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllOrderByUserIdFail(error.response.data.message));
      } else {
        yield put(getAllOrderByUserIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllOrderByUserIdFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// eslint-disable-next-line no-unused-vars
export function* getOrderCountSaga(action) {
  yield put(getOrderCountStart());
  try {
    const response = yield axios.get(`/chef/getOrderCountByStatus`);
    if (response.status === 200) {
      yield put(getOrderCountSuccess(response.data.data));
    } else {
      yield put(getOrderCountFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getOrderCountFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getOrderCountFail(error.response.data.message));
      } else {
        yield put(getOrderCountFail('Server error! Please try again.'));
      }
    } else {
      yield put(getOrderCountFail('Something went wrong! Please try again.'));
    }
  }
}

// eslint-disable-next-line no-unused-vars
export function* getChefOrderCountSaga(action) {
  yield put(getOrderCountStart());
  try {
    const response = yield axios.get(`/admin/getOrderCountByStatus`);
    if (response.status === 200) {
      yield put(getOrderCountSuccess(response.data.data));
    } else {
      yield put(getOrderCountFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getOrderCountFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getOrderCountFail(error.response.data.message));
      } else {
        yield put(getOrderCountFail('Server error! Please try again.'));
      }
    } else {
      yield put(getOrderCountFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editInstructionSaga(action) {
  yield put(editInstructionStart());
  const { setIsDisable } = action.payload;
  try {
    const response = yield axios.post(
      `/admin/updateOrderInstructions`,
      action.payload,
    );
    if (response.status === 200) {
      yield put(editInstructionSuccess(action.payload));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      setIsDisable(false);
    } else {
      yield put(editInstructionFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editInstructionFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editInstructionFail(error.response.data.message));
      } else {
        yield put(editInstructionFail('Server error! Please try again.'));
      }
    } else {
      yield put(editInstructionFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editAcceptOrderSaga(action) {
  yield put(editAcceptOrderStart());
  try {
    const { tableFilter, closeModel } = action.payload;
    console.log(action.payload)
    const reqBody = {
      ids: action.payload.ids,
    };
    const response = yield axios.post(`/chef/acceptOrder`, reqBody);
    if (response.status === 200) {
      if (closeModel) {
        yield put(editAcceptOrderSuccess());
        yield put(getAllChefUpcomingOrderSagaAction(tableFilter));
        closeModel();
      }
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vendors/orders',
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(editAcceptOrderFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editAcceptOrderFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editAcceptOrderFail(error.response.data.message));
      } else {
        yield put(editAcceptOrderFail('Server error! Please try again.'));
      }
    } else {
      yield put(editAcceptOrderFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editRejectOrderSaga(action) {
  yield put(editRejectOrderStart());
  try {
    const { closeModel, closeModelReject, tableFilter } = action.payload;
    const reqBody = {
      id: action.payload.isRejectedId,
      rejectReason: action.payload.reason,
    };
    const response = yield axios.post(`/chef/rejectOrder`, reqBody);
    if (response.status === 200) {
      yield put(editRejectOrderSuccess());
      closeModel();
      closeModelReject();
      yield put(getAllChefUpcomingOrderSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(editRejectOrderFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editRejectOrderFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editRejectOrderFail(error.response.data.message));
      } else {
        yield put(editRejectOrderFail('Server error! Please try again.'));
      }
    } else {
      yield put(editRejectOrderFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editOrderStatusSaga(action) {
  yield put(editOrderStatusStart());
  try {
    const { tableFilter, closeModel, setArrivedData } = action.payload;
    const reqBody = {
      status: action.payload.status,
      ids: action.payload.id,
    };
    const response = yield axios.post(`/chef/updateOrderStatus`, reqBody);
    if (response.status === 200) {
      yield put(editOrderStatusSuccess());
      yield put(getAllChefOrderSagaAction(tableFilter));
      yield put(getOrderCountSagaAction());
      closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          // redirectURL: '/vendors/upcoming-orders',
          message: response.data.message || 'Success',
        }),
      );
      setTimeout(() => {
        window.location.reload('/vendors/orders');
      },3000)
      setArrivedData([])

    } else {
      yield put(editOrderStatusFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editOrderStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editOrderStatusFail(error.response.data.message));
      } else {
        yield put(editOrderStatusFail('Server error! Please try again.'));
      }
    } else {
      yield put(editOrderStatusFail('Something went wrong! Please try again.'));
    }
  }
}


export function* transactionHistorySaga(action) {
  yield put(transactionHistoryStart());
  try {
    const { tableFilter, closeModel } = action.payload;
    console.log(action.payload)
    const reqBody = {
      id: action.payload.id,
    };
    const response = yield axios.post(`/chef/acceptOrder`, reqBody);
    if (response.status === 200) {
      yield put(transactionHistorySuccess());
      yield put(getAllChefUpcomingOrderSagaAction(tableFilter));
      closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(transactionHistoryFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(transactionHistoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(transactionHistoryFail(error.response.data.message));
      } else {
        yield put(transactionHistoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(transactionHistoryFail('Something went wrong! Please try again.'));
    }
  }
}
