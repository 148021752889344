import {
  GET_ALL_CMS_START,
  GET_ALL_CMS_SAGA,
  GET_ALL_CMS_SUCCESS,
  GET_ALL_CMS_FAIL,
  GET_CMS_PAGE_START,
  GET_CMS_PAGE_SAGA,
  GET_CMS_PAGE_SUCCESS,
  GET_CMS_PAGE_FAIL,
  GET_CMS_BY_ID_START,
  GET_CMS_BY_ID_SAGA,
  GET_CMS_BY_ID_SUCCESS,
  GET_CMS_BY_ID_FAIL,
  UPDATE_CMS_START,
  UPDATE_CMS_SAGA,
  UPDATE_CMS_SUCCESS,
  UPDATE_CMS_FAIL,
  RESET_CMS,
} from '../actionLabels';

export const getAllCMSStart = payload => ({
  type: GET_ALL_CMS_START,
  payload,
});

export const getAllCMSSaga = payload => ({
  type: GET_ALL_CMS_SAGA,
  payload,
});

export const getAllCMSSuccess = payload => ({
  type: GET_ALL_CMS_SUCCESS,
  payload,
});

export const getAllCMSFail = payload => ({
  type: GET_ALL_CMS_FAIL,
  payload,
});

export const getCMSPageStart = payload => ({
  type: GET_CMS_PAGE_START,
  payload,
});

export const getCMSPageSaga = payload => ({
  type: GET_CMS_PAGE_SAGA,
  payload,
});

export const getCMSPageSuccess = payload => ({
  type: GET_CMS_PAGE_SUCCESS,
  payload,
});

export const getCMSPageFail = payload => ({
  type: GET_CMS_PAGE_FAIL,
  payload,
});

export const getCMSByIdStart = payload => ({
  type: GET_CMS_BY_ID_START,
  payload,
});

export const getCMSByIdSaga = payload => ({
  type: GET_CMS_BY_ID_SAGA,
  payload,
});

export const getCMSByIdSuccess = payload => ({
  type: GET_CMS_BY_ID_SUCCESS,
  payload,
});

export const getCMSByIdFail = payload => ({
  type: GET_CMS_BY_ID_FAIL,
  payload,
});

export const updateCMSStart = payload => ({
  type: UPDATE_CMS_START,
  payload,
});

export const updateCMSSaga = payload => ({
  type: UPDATE_CMS_SAGA,
  payload,
});

export const updateCMSSuccess = payload => ({
  type: UPDATE_CMS_SUCCESS,
  payload,
});

export const updateCMSFail = payload => ({
  type: UPDATE_CMS_FAIL,
  payload,
});

export const resetCMS = payload => ({
  type: RESET_CMS,
  payload,
});
