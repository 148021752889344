import { put } from 'redux-saga/effects';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';
import { capitalize } from '../../../utils/capitalize';

import {
  getAllOwnerVehiclesSaga as getAllOwnerVehiclesSagaAction,
  getAllOwnerVehiclesStart,
  getAllOwnerVehiclesSuccess,
  getAllOwnerVehiclesFail,
  addOwnerVehicleStart,
  addOwnerVehicleSuccess,
  addOwnerVehicleFail,
  showModal,
  logout,
  editBlockOwnerVehicleStatusStart,
  editBlockOwnerVehicleStatusSuccess,
  editBlockOwnerVehicleStatusFail,
  getOwnerVehicleDetailByIdStart,
  getOwnerVehicleDetailByIdSuccess,
  getOwnerVehicleDetailByIdFail,
  editOwnerVehicleStart,
  editOwnerVehicleSuccess,
  editOwnerVehicleFail,
  deleteOwnerVehicleStart,
  deleteOwnerVehicleSuccess,
  deleteOwnerVehicleFail,
  // getOwnerVehicleDetailByIdStart,
  // getOwnerVehicleDetailByIdSuccess,
  // getOwnerVehicleDetailByIdFail,
  OwnerVehicleApproveRejectStart,
  OwnerVehicleApproveRejectSuccess,
  OwnerVehicleApproveRejectFail,
  unassignDriverFromVehicleStart,
  unassignDriverFromVehicleFail,
  deleteDriverFromVehicleStart,
  deleteDriverFromVehicleFail
} from '../../actions';

export function* getAllOwnerVehiclesSaga(action) {
  yield put(getAllOwnerVehiclesStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/driver/ownerVehicleList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllOwnerVehiclesSuccess({ vehicles: response.data.data }));
    } else {
      yield put(
        getAllOwnerVehiclesFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllOwnerVehiclesFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllOwnerVehiclesFail(error.response.data.message));
      } else {
        yield put(getAllOwnerVehiclesFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllOwnerVehiclesFail('Something went wrong! Please try again. 1'),
      );
    }
  }
}

// Get Vehicle Details By Id
export function* getOwnerVehicleDetailByIdSaga(action) {
  yield put(getOwnerVehicleDetailByIdStart());
  // @Refactoring code here so that same saga function can be used for two APIs;

  let urlRoute = 'driver';
  let payload = action.payload;

  if (action.payload.promoter !== undefined && action.payload.promoter) {
    urlRoute = 'promoter';
    payload = action.payload.id;
  }

  // console.log({ urlRoute, payload });
  try {
    const response = yield axios.get(
      `/${urlRoute}/getOwnerVehicleById?id=${payload}`,
    );
    console.log('vehicle details \n', response.data.data);
    if (response.status === 200) {
      yield put(
        getOwnerVehicleDetailByIdSuccess({ vehicle: response.data.data }),
      );
    } else {
      yield put(
        getOwnerVehicleDetailByIdFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getOwnerVehicleDetailByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getOwnerVehicleDetailByIdFail(error.response.data.message));
      } else {
        yield put(
          getOwnerVehicleDetailByIdFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getOwnerVehicleDetailByIdFail(
          'Something went wrong! Please try again. 1',
        ),
      );
    }
  }
}

// Add Vehicle
export function* addOwnerVehicleSaga(action) {
  yield put(addOwnerVehicleStart());
  const { setIsDisable } = action.payload;
  try {
    const { formValues, tableFilter } = action.payload;
    console.log(action.payload);
    const formdata = new FormData();
    formdata.append('name', formValues.name);
    formdata.append('modelName', formValues.modelName);
    formdata.append('ownerName', formValues.ownerName);
    formdata.append('mfgYear', formValues.mfgYear);
    formdata.append('colour', formValues.colour);
    formdata.append('vehicleManufacturer', formValues.vehicleManufacturer);
    formdata.append('fuelType', formValues.fuelType);
    formdata.append('serviceId', formValues.serviceId);
    formdata.append('vehicleTypeId', formValues.vehicleTypeId);
    formdata.append('vehicleNumber', formValues.vehicleNumber);
    formdata.append('insuranceNumber', formValues.insuranceNumber);
    formdata.append('insuranceStartDate', formValues.insuranceStartDate);
    formdata.append('insuranceEndDate', formValues.insuranceEndDate);
    formdata.append('insuranceImages', formValues.insuranceImages);
    formdata.append('vehicleImages', formValues.vehicleImages);
    formdata.append('pucImages', formValues.pucImages);
    formdata.append('ownerId', formValues.ownerId);
    formdata.append('driverId', (formValues.driverId) ? formValues.driverId : '');
    
    // formdata.append('registrationId', formValues.registrationId);

    // for (let i = 0; i < formValues.transportIds.length; i++) {
    // formdata.append('transportModeId', formValues.transportIds);
    // }

    for (let i = 0; i < formValues.insuranceImages.length; i++) {
      formdata.append('insuranceImages', formValues.insuranceImages[i]);
    }
    for (let i = 0; i < formValues.vehicleImages.length; i++) {
      formdata.append('vehicleImages', formValues.vehicleImages[i]);
    }
    for (let i = 0; i < formValues.pucImages.length; i++) {
      formdata.append('pucImages', formValues.pucImages[i]);
    }

    const response = yield axios.post('/promoter/addOwnerVehicle', formdata);
    if (response.status === 200 || response.statusCode === 200) {
      yield put(addOwnerVehicleSuccess(response.data.data));
      setIsDisable(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vehicle-owner/vehicles',
          // redirectURL: '/promoters/vehicles',
          message: capitalize(response.data.message) || 'Success',
        }),
      );
      yield put(getAllOwnerVehiclesSagaAction(tableFilter));
    } else {
      yield put(addOwnerVehicleFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addOwnerVehicleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addOwnerVehicleFail(error.response.data.message));
      } else {
        yield put(addOwnerVehicleFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        addOwnerVehicleFail('Something went wrong! Please try again. 1'),
      );
    }
  }
}

// Note : I Created THis Saga function when clint asked to add vehicle owner
// from driver side which is Driver Section in Admin Module So I commented Previous Saga
// and repplaced it with this next Saga

// export function* addOwnerVehicleSaga(action) {
//   yield put(addOwnerVehicleStart());
//   try {
//     const { formValues, tableFilter } = action.payload;
//     console.log(action.payload);
//     const formdata = new FormData();
//     formdata.append('name', formValues.name);
//     formdata.append('ownerId', formValues.ownerId);
//     formdata.append('colour', formValues.colour);
//     formdata.append('fuelType', formValues.fuelType);
//     formdata.append('insuranceEndDate', formValues.insuranceEndDate);
//     // formdata.append('insuranceImages', formValues.insuranceImages);
//     formdata.append('insuranceNumber', formValues.insuranceNumber);
//     formdata.append('insuranceStartDate', formValues.insuranceStartDate);
//     formdata.append('mfgYear', formValues.mfgYear);
//     formdata.append('modelName', formValues.modelName);
//     // formdata.append('vehicleImages', formValues.vehicleImages);
//     formdata.append('vehicleManufacturer', formValues.vehicleManufacturer);
//     formdata.append('ownerName', formValues.ownerName);
//     // formdata.append('pucImages', formValues.pucImages);
//     formdata.append('registrationId', formValues.registrationId);
//     formdata.append('transportModeId', formValues.transportModeId);
//     formdata.append('vehicleNumber', formValues.vehicleNumber);
//     formdata.append('vehicleTypeId', formValues.vehicleTypeId);

//     for (let i = 0; i < formValues.insuranceImages.length; i++) {
//       formdata.append('insuranceImages', formValues.insuranceImages[i]);
//     }
//     for (let i = 0; i < formValues.vehicleImages.length; i++) {
//       formdata.append('vehicleImages', formValues.vehicleImages[i]);
//     }
//     for (let i = 0; i < formValues.pucImages.length; i++) {
//       formdata.append('pucImages', formValues.pucImages[i]);
//     }

//     const response = yield axios.post('/promoter/addOwnerVehicle', formdata);
//     if (response.status === 200 || response.statusCode === 200) {
//       yield put(addOwnerVehicleSuccess(response.data.data));
//       yield put(
//         showModal({
//           open: true,
//           notifyType: 2,
//           redirectURL: 'vendors/drivers/add-driver',
//           message: capitalize(response.data.message) || 'Success',
//         }),
//       );
//       yield put(getAllOwnerVehiclesSagaAction(tableFilter));
//     } else {
//       yield put(addOwnerVehicleFail('Something went wrong! Please try again.'));
//     }
//   } catch (error) {
//     if (
//       error !== undefined &&
//       error.response !== undefined &&
//       error.response.status !== undefined
//     ) {
//       if (error.response.status === 400) {
//         yield put(addOwnerVehicleFail(error.response.data.message));
//       } else if (error.response.status === 401) {
//         yield put(logout());
//       } else if (
//         error.response.data.message !== undefined &&
//         error.response.data.message !== '' &&
//         typeof error.response.data.message === 'string'
//       ) {
//         yield put(addOwnerVehicleFail(error.response.data.message));
//       } else {
//         yield put(addOwnerVehicleFail('Server error! Please try again.'));
//       }
//     } else {
//       yield put(
//         addOwnerVehicleFail('Something went wrong! Please try again. 1'),
//       );
//     }
//   }
// }

// Block unblock vehicle status
export function* editBlockOwnerVehicleStatusSaga(action) {
  yield put(editBlockOwnerVehicleStatusStart());
  try {
    const response = yield axios.post(`/driver/status`, action.payload);
    console.log(response);
    if (response.status === 200) {
      console.log(action.payload);
      yield put(editBlockOwnerVehicleStatusSuccess(action.payload));
    } else {
      yield put(
        editBlockOwnerVehicleStatusFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBlockOwnerVehicleStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBlockOwnerVehicleStatusFail(error.response.data.message));
      } else {
        yield put(
          editBlockOwnerVehicleStatusFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        editBlockOwnerVehicleStatusFail(
          'Something went wrong! Please try again. 1',
        ),
      );
    }
  }
}

// Edit Vehicle
export function* editOwnerVehicleSaga(action) {
  yield put(editOwnerVehicleStart());
  try {
    const { formValues, tableFilter } = action.payload;
    console.log(action.payload);
    console.log(formValues);

    const formdata = new FormData();
    formdata.append('id', formValues.id);
    formdata.append('name', formValues.name);
    formdata.append('ownerId', formValues.ownerId);
    formdata.append('colour', formValues.colour);
    formdata.append('fuelType', formValues.fuelType);
    formdata.append('insuranceEndDate', formValues.insuranceEndDate);
    // formdata.append('insuranceImages', formValues.insuranceImages);
    formdata.append('insuranceNumber', formValues.insuranceNumber);
    formdata.append('insuranceStartDate', formValues.insuranceStartDate);
    formdata.append('mfgYear', formValues.mfgYear);
    formdata.append('modelName', formValues.modelName);
    // formdata.append('vehicleImages', formValues.vehicleImages);
    formdata.append('vehicleManufacturer', formValues.vehicleManufacturer);
    formdata.append('ownerName', formValues.ownerName);
    // formdata.append('pucImages', formValues.pucImages);
    formdata.append('registrationId', formValues.registrationId);
    formdata.append('serviceId', formValues.serviceId);
    formdata.append('vehicleNumber', formValues.vehicleNumber);
    formdata.append('vehicleTypeId', formValues.vehicleTypeId);

    if (formValues.vehicleImages) {
      for (let i = 0; i < formValues.vehicleImages.length; i++) {
        formdata.append('vehicleImages', formValues.vehicleImages[i]);
      }
    }

    if (formValues.insuranceImages) {
      for (let i = 0; i < formValues.insuranceImages.length; i++) {
        formdata.append('insuranceImages', formValues.insuranceImages[i]);
      }
    }

    if (formValues.pucImages) {
      for (let i = 0; i < formValues.pucImages.length; i++) {
        formdata.append('pucImages', formValues.pucImages[i]);
      }
    }

    const response = yield axios.post('/driver/updateOwnerVehicle', formdata);
    console.log('edit vehicle saga response \n', response);
    if (response.status === 200 || response.statusCode === 200) {
      yield put(editOwnerVehicleSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vehicle-owner/vehicles',
          message: capitalize(response.data.message) || 'Updated Successfully',
        }),
      );
      yield put(getAllOwnerVehiclesSagaAction(tableFilter));
    } else {
      yield put(
        editOwnerVehicleFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editOwnerVehicleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editOwnerVehicleFail(error.response.data.message));
      } else {
        yield put(editOwnerVehicleFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editOwnerVehicleFail('Something went wrong! Please try again. 1'),
      );
    }
  }
}

// Delete Vehicle
// Delete Category Start
export function* deleteOwnerVehicleSaga(action) {
  yield put(deleteOwnerVehicleStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    const response = yield axios.post(`/admin/deleteOwnerVehicle`, { id });

    if (response.status === 200) {
      yield put(deleteOwnerVehicleSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllOwnerVehiclesSagaAction(tableFilter));
    } else {
      yield put(
        deleteOwnerVehicleFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      if (error.response.status === 400) {
        yield put(deleteOwnerVehicleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteOwnerVehicleFail(error.response.data.message));
      } else {
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: 'Server error! Please try again.',
          }),
        );
        yield put(deleteOwnerVehicleFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        deleteOwnerVehicleFail('Something went wrong! Please try again.'),
      );
    }
  }
}

//  Get Owner Vehicle list by Vehicle Owner Id

export function* getVehicleListByOwnerIdSaga(action) {
  yield put(getAllOwnerVehiclesStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(
        `/promoter/getVehicleListByVehicleOwnerId`,
        action.payload,
      ),
    );
    if (response.status === 200) {
      yield put(getAllOwnerVehiclesSuccess({ vehicles: response.data.data }));
    } else {
      yield put(
        getAllOwnerVehiclesFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllOwnerVehiclesFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllOwnerVehiclesFail(error.response.data.message));
      } else {
        yield put(getAllOwnerVehiclesFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllOwnerVehiclesFail('Something went wrong! Please try again. 1'),
      );
    }
  }
}

export function* OwnerVehicleApproveRejectSaga(action) {
  console.log("ownerVehicleApproveRejectSaga",action)

  yield put(OwnerVehicleApproveRejectStart());
  try {
    const response = yield axios.post(
      // `/admin/approveOrRejectChef`,
       `promoter/approveRejectVehicle`,
      action.payload,
    );
    if (response.status === 200) {
      const { closeModel, promoterSubmitted } = action.payload;
      yield put(OwnerVehicleApproveRejectSuccess());

      const redirectURL = promoterSubmitted
        ? '/promoters/vendors'
        : '/vendors/chefs';
      const modalMessage = promoterSubmitted
        ? 'Vendor Profile is Verified & Sent To Administrator'
        : response.data.message;

      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL,
          message: modalMessage || 'Success',
        }),
      );
      if (closeModel) {
        closeModel();
      }
    } else {
      yield put(
        OwnerVehicleApproveRejectFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(OwnerVehicleApproveRejectFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(OwnerVehicleApproveRejectFail(error.response.data.message));
      } else {
        yield put(OwnerVehicleApproveRejectFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        OwnerVehicleApproveRejectFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* deleteDriverFromVehicleSaga(action) {
  yield put(deleteDriverFromVehicleStart());
  try {
    const { vehicleId, driverId, closeModel } = action.payload;
    console.log(driverId)
    const response = yield axios.post('promoter/unassignDriverToVehicle', {
      driverId,
      vehicleId,
    });
    if (response.status === 200) {
      if (closeModel) {
        closeModel();
      }
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: `/vendors/vehicle-owners`,
          message: capitalize(response.data.message) || 'Success',
        }),
      );
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(deleteDriverFromVehicleFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(deleteDriverFromVehicleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteDriverFromVehicleFail(error.response.data.message));
      } else {
        yield put(deleteDriverFromVehicleFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteDriverFromVehicleFail('Something went wrong! Please try again.'));
    }
  }
}
export function* unassignDriverFromVehicleSaga(action) {
  yield put(unassignDriverFromVehicleStart());
  try {
    const { vehicleId, driverId, closeModel } = action.payload;
    console.log(driverId)
    const response = yield axios.post('promoter/unassignDriverByID', {
      driverId,
      vehicleId,
    });
    if (response.status === 200) {
      if (closeModel) {
        closeModel();
      }
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: `/vendors/vehicle-owners`,
          message: capitalize(response.data.message) || 'Success',
        }),
      );
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(unassignDriverFromVehicleFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(unassignDriverFromVehicleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(unassignDriverFromVehicleFail(error.response.data.message));
      } else {
        yield put(unassignDriverFromVehicleFail('Server error! Please try again.'));
      }
    } else {
      yield put(unassignDriverFromVehicleFail('Something went wrong! Please try again.'));
    }
  }
}
