import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  subadmins: { list: [], totalRecords: { count: 0 } },
  pdf: null,
  subadmin: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_SUBADMIN_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_SUBADMIN_SUCCESS: {
      const { subadmins } = payload;
      return { ...state, subadmins, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_SUBADMIN_FAIL:
      return {
        ...state,
        subadmins: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_SUBADMIN_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_SUBADMIN_BY_ID_SUCCESS: {
      const { subadmin } = payload;
      return { ...state, subadmin, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_SUBADMIN_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.ADD_SUBADMIN_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_SUBADMIN_SUCCESS: {
      const { subadmins } = state;
      subadmins.list.unshift(payload);
      subadmins.totalRecords.count += 1;
      return { ...state, subadmins, isLoading: false, errorMsg: '' };
    }
    case actionLabels.ADD_SUBADMIN_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_SUBADMIN_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_SUBADMIN_SUCCESS: {
      return { ...state, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_SUBADMIN_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_SUBADMIN_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_SUBADMIN_STATUS_SUCCESS: {
      const { subadmins } = state;
      const index = subadmins.list.findIndex(item => item._id === payload.id);
      if (index !== -1 && subadmins.list.length > index) {
        subadmins.list[index].isBlock = !subadmins.list[index].isBlock;
      }
      return { ...state, subadmins, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_SUBADMIN_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GENERATE_PDF_START:
      return { ...state, isLoading: true };
    case actionLabels.GENERATE_PDF_SUCCESS: {
      const pdf = payload;
      return { ...state, pdf, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GENERATE_PDF_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }
    default:
      return state;
  }
};
