import {
  ACCEPT_ORDER_FOR_DELIVERY_FAIL,
  ACCEPT_ORDER_FOR_DELIVERY_SAGA,
  ACCEPT_ORDER_FOR_DELIVERY_START,
  ACCEPT_ORDER_FOR_DELIVERY_SUCCESS,
  ADD_LOGISTIC_OF_PROMOTER_FAIL,
  ADD_LOGISTIC_OF_PROMOTER_SAGA,
  ADD_LOGISTIC_OF_PROMOTER_START,
  ADD_LOGISTIC_OF_PROMOTER_SUCCESS,
  ADD_SUPPORT_REQUEST_LOGISTICS_FAIL,
  ADD_SUPPORT_REQUEST_LOGISTICS_SAGA,
  ADD_SUPPORT_REQUEST_LOGISTICS_START,
  ADD_SUPPORT_REQUEST_LOGISTICS_SUCCESS,
  APPROVE_REJECT_LOGISTICS_FAIL,
  APPROVE_REJECT_LOGISTICS_SAGA,
  APPROVE_REJECT_LOGISTICS_START,
  APPROVE_REJECT_LOGISTICS_SUCCESS,
  ARRIVED_DATA_TO_RECEPTION_FAIL,
  ARRIVED_DATA_TO_RECEPTION_SAGA,
  ARRIVED_DATA_TO_RECEPTION_START,
  ARRIVED_DATA_TO_RECEPTION_SUCCESS,
  DELETE_LOGISTIC_OF_PROMOTER_FAIL,
  DELETE_LOGISTIC_OF_PROMOTER_SAGA,
  DELETE_LOGISTIC_OF_PROMOTER_START,
  DELETE_LOGISTIC_OF_PROMOTER_SUCCESS,
  EDIT_BLOCK_LOGISTIC_STATUS_FAIL,
  EDIT_BLOCK_LOGISTIC_STATUS_SAGA,
  EDIT_BLOCK_LOGISTIC_STATUS_START,
  EDIT_BLOCK_LOGISTIC_STATUS_SUCCESS,
  EDIT_LOGISTIC_DETAIL_FAIL,
  EDIT_LOGISTIC_DETAIL_SAGA,
  EDIT_LOGISTIC_DETAIL_START,
  EDIT_LOGISTIC_DETAIL_SUCCESS,
  GET_ALL_LOGISTICS_PAYOUT_HISTORY_FAIL,
  GET_ALL_LOGISTICS_PAYOUT_HISTORY_SAGA,
  GET_ALL_LOGISTICS_PAYOUT_HISTORY_START,
  GET_ALL_LOGISTICS_PAYOUT_HISTORY_SUCCESS,
  GET_ALL_LOGISTIC_OF_PROMOTER_FAIL,
  GET_ALL_LOGISTIC_OF_PROMOTER_SAGA,
  GET_ALL_LOGISTIC_OF_PROMOTER_START,
  GET_ALL_LOGISTIC_OF_PROMOTER_SUCCESS,
  GET_ALL_ORDER_LIST_FAIL,
  GET_ALL_ORDER_LIST_SAGA,
  GET_ALL_ORDER_LIST_START,
  GET_ALL_ORDER_LIST_SUCCESS,
  GET_ALL_SUPPORT_REQUEST_LOGISTICS_FAIL,
  GET_ALL_SUPPORT_REQUEST_LOGISTICS_SAGA,
  GET_ALL_SUPPORT_REQUEST_LOGISTICS_START,
  GET_ALL_SUPPORT_REQUEST_LOGISTICS_SUCCESS,
  GET_ALL_VEHICLE_LIST_FAIL,
  GET_ALL_VEHICLE_LIST_SAGA,
  GET_ALL_VEHICLE_LIST_START,
  GET_ALL_VEHICLE_LIST_SUCCESS,
  GET_RECEPTION_LIST_FAIL,
  GET_RECEPTION_LIST_SAGA,
  GET_RECEPTION_LIST_START,
  GET_RECEPTION_LIST_SUCCESS,
  LOGISTICS_DETAILS_FAIL,
  LOGISTICS_DETAILS_SAGA,
  LOGISTICS_DETAILS_START,
  LOGISTICS_DETAILS_SUCCESS,
  GET_LAT_LONG_FROM_ADDRESS_START,
  GET_LAT_LONG_FROM_ADDRESS_SUCCESS,
  RESET_LAT_LONG_FROM_ADDRESS,
  GET_LAT_LONG_FROM_ADDRESS_SAGA,
  GET_ALL_BRAND_TYPES_START,
  GET_ALL_BRAND_TYPES_SAGA,
  GET_ALL_BRAND_TYPES_SUCCESS,
  GET_ALL_BRAND_TYPES_FAIL,
  GET_LAT_LONG_FOR_LOGISTIC_START,
  GET_LAT_LONG_FOR_LOGISTIC_SAGA,
  GET_LAT_LONG_FOR_LOGISTIC_SUCCESS,
  GET_LAT_LONG_FOR_LOGISTIC_FAIL,
  GET_ALL_LOGISTIC_DETAIL_START,
  GET_ALL_LOGISTIC_DETAIL_SAGA,
  GET_ALL_LOGISTIC_DETAIL_SUCCESS,
  GET_ALL_LOGISTIC_DETAIL_FAIL,
  SEND_PRODUCT_TO_LOGISTIC_START,
  SEND_PRODUCT_TO_LOGISTIC_SAGA,
  SEND_PRODUCT_TO_LOGISTIC_SUCCESS,
  SEND_PRODUCT_TO_LOGISTIC_FAIL,
  GET_ALL_LOGISTIC_ORDER_START,
  GET_ALL_LOGISTIC_ORDER_SAGA,
  GET_ALL_LOGISTIC_ORDER_SUCCESS,
  GET_ALL_LOGISTIC_ORDER_FAIL,
  GET_ORDER_DETAIL_BY_ID_START,
  GET_ORDER_DETAIL_BY_ID_SAGA,
  GET_ORDER_DETAIL_BY_ID_SUCCESS,
  GET_ORDER_DETAIL_BY_ID_FAIL,
  ADD_NEW_EMPLOYEE_START,
  ADD_NEW_EMPLOYEE_SAGA,
  ADD_NEW_EMPLOYEE_SUCCESS,
  ADD_NEW_EMPLOYEE_FAIL,
  GET_ALL_EMPLOYEE_DATA_START,
  GET_ALL_EMPLOYEE_DATA_SAGA,
  GET_ALL_EMPLOYEE_DATA_SUCCESS,
  GET_ALL_EMPLOYEE_DATA_FAIL,
  EDIT_EMPLOYEE_STATUS_START,
  EDIT_EMPLOYEE_STATUS_SAGA,
  EDIT_EMPLOYEE_STATUS_SUCCESS,
  EDIT_EMPLOYEE_STATUS_FAIL,
  DELETE_LOGISTIC_EMPLOYEE_START,
  DELETE_LOGISTIC_EMPLOYEE_SAGA,
  DELETE_LOGISTIC_EMPLOYEE_SUCCESS,
  DELETE_LOGISTIC_EMPLOYEE_FAIL,
  EDIT_NEW_EMPLOYEE_START,
  EDIT_NEW_EMPLOYEE_SAGA,
  EDIT_NEW_EMPLOYEE_SUCCESS,
  EDIT_NEW_EMPLOYEE_FAIL,
  GET_ALL_DESTINY_DATA_START,
  GET_ALL_DESTINY_DATA_FAIL,
  GET_ALL_DESTINY_DATA_SAGA,
  GET_ALL_DESTINY_DATA_SUCCESS,
  GET_ALL_DRIVER_ORDER_LIST_SAGA,
  GET_ALL_DRIVER_ORDER_LIST_START,
  GET_ALL_DRIVER_ORDER_LIST_SUCCESS,
  GET_ALL_DRIVER_ORDER_LIST_FAIL,


  DELETE_DRIVER_FAIL,
  DELETE_DRIVER_SAGA,
  DELETE_DRIVER_START,
  DELETE_DRIVER_SUCCESS,
} from "../actionLabels";



export const addLogisticOfPromoterSaga = payload => ({
  type: ADD_LOGISTIC_OF_PROMOTER_SAGA,
  payload,
});

export const addLogisticOfPromoterSuccess = payload => ({
  type: ADD_LOGISTIC_OF_PROMOTER_SUCCESS,
  payload,
});

export const addLogisticOfPromoterStart = payload => ({
  type: ADD_LOGISTIC_OF_PROMOTER_START,
  payload,
});

export const addLogisticOfPromoterFail = payload => ({
  type: ADD_LOGISTIC_OF_PROMOTER_FAIL,
  payload,
});

export const deleteLogisticOfPromoterSaga = payload => ({
  type: DELETE_LOGISTIC_OF_PROMOTER_SAGA,
  payload,
});

export const deleteLogisticOfPromoterSuccess = payload => ({
  type: DELETE_LOGISTIC_OF_PROMOTER_SUCCESS,
  payload,
});

export const deleteLogisticOfPromoterStart = payload => ({
  type: DELETE_LOGISTIC_OF_PROMOTER_START,
  payload,
});

export const deleteLogisticOfPromoterFail = payload => ({
  type: DELETE_LOGISTIC_OF_PROMOTER_FAIL,
  payload,
});

export const logisticsDetailsSaga = payload => ({
  type: LOGISTICS_DETAILS_SAGA,
  payload,
});

export const logisticsDetailsSuccess = payload => ({
  type: LOGISTICS_DETAILS_SUCCESS,
  payload,
});

export const logisticsDetailsStart = payload => ({
  type: LOGISTICS_DETAILS_START,
  payload,
});

export const logisticsDetailsFail = payload => ({
  type: LOGISTICS_DETAILS_FAIL,
  payload,
});

export const approveRejectLogisticsSaga = payload => ({
  type: APPROVE_REJECT_LOGISTICS_SAGA,
  payload,
});

export const approveRejectLogisticsSuccess = payload => ({
  type: APPROVE_REJECT_LOGISTICS_SUCCESS,
  payload,
});

export const approveRejectLogisticsStart = payload => ({
  type: APPROVE_REJECT_LOGISTICS_START,
  payload,
});

export const approveRejectLogisticsFail = payload => ({
  type: APPROVE_REJECT_LOGISTICS_FAIL,
  payload,
});
export const getAllLogisticOfPromoterSaga = payload => ({
  type: GET_ALL_LOGISTIC_OF_PROMOTER_SAGA,
  payload,
});

export const getAllLogisticOfPromoterSuccess = payload => ({
  type: GET_ALL_LOGISTIC_OF_PROMOTER_SUCCESS,
  payload,
});

export const getAllLogisticOfPromoterStart = payload => ({
  type: GET_ALL_LOGISTIC_OF_PROMOTER_START,
  payload,
});

export const getAllLogisticOfPromoterFail = payload => ({
  type: GET_ALL_LOGISTIC_OF_PROMOTER_FAIL,
  payload,
});

export const getAllSupportRequestLogisticsSaga = payload => ({
  type: GET_ALL_SUPPORT_REQUEST_LOGISTICS_SAGA,
  payload,
});

export const getAllSupportRequestLogisticsSuccess = payload => ({
  type: GET_ALL_SUPPORT_REQUEST_LOGISTICS_SUCCESS,
  payload,
});

export const getAllSupportRequestLogisticsStart = payload => ({
  type: GET_ALL_SUPPORT_REQUEST_LOGISTICS_START,
  payload,
});

export const getAllSupportRequestLogisticsFail = payload => ({
  type: GET_ALL_SUPPORT_REQUEST_LOGISTICS_FAIL,
  payload,
});

export const addSupportRequestLogisticsSaga = payload => ({
  type: ADD_SUPPORT_REQUEST_LOGISTICS_SAGA,
  payload,
});

export const addSupportRequestLogisticsSuccess = payload => ({
  type: ADD_SUPPORT_REQUEST_LOGISTICS_SUCCESS,
  payload,
});

export const addSupportRequestLogisticsStart = payload => ({
  type: ADD_SUPPORT_REQUEST_LOGISTICS_START,
  payload,
});

export const addSupportRequestLogisticsFail = payload => ({
  type: ADD_SUPPORT_REQUEST_LOGISTICS_FAIL,
  payload,
});

export const getAllLogisticsPayoutHistorySaga = payload => ({
  type: GET_ALL_LOGISTICS_PAYOUT_HISTORY_SAGA,
  payload,
});

export const getAllLogisticsPayoutHistorySuccess = payload => ({
  type: GET_ALL_LOGISTICS_PAYOUT_HISTORY_SUCCESS,
  payload,
});

export const getAllLogisticsPayoutHistoryStart = payload => ({
  type: GET_ALL_LOGISTICS_PAYOUT_HISTORY_START,
  payload,
});

export const getAllLogisticsPayoutHistoryFail = payload => ({
  type: GET_ALL_LOGISTICS_PAYOUT_HISTORY_FAIL,
  payload,
});

export const editBlockLogisticStatusSaga = payload => ({
  type: EDIT_BLOCK_LOGISTIC_STATUS_SAGA,
  payload,
})
export const editBlockLogisticStatusStart = payload => ({
  type: EDIT_BLOCK_LOGISTIC_STATUS_START,
  payload,
})
export const editBlockLogisticStatusSuccess = payload => ({
  type: EDIT_BLOCK_LOGISTIC_STATUS_SUCCESS,
  payload,
})
export const editBlockLogisticStatusSFail = payload => ({
  type: EDIT_BLOCK_LOGISTIC_STATUS_FAIL,
  payload,
})

export const editLogisticDetailSaga = payload => ({
  type: EDIT_LOGISTIC_DETAIL_SAGA,
  payload,
})
export const editLogisticDetailStart = payload => ({
  type: EDIT_LOGISTIC_DETAIL_START,
  payload,
})
export const editLogisticDetailSuccess = payload => ({
  type: EDIT_LOGISTIC_DETAIL_SUCCESS,
  payload,
})
export const editLogisticDetailFail = payload => ({
  type: EDIT_LOGISTIC_DETAIL_FAIL,
  payload,
})
export const getAllOrderListSaga = payload => ({
  type: GET_ALL_ORDER_LIST_SAGA,
  payload,
})
export const getAllOrderListStart = payload => ({
  type: GET_ALL_ORDER_LIST_START,
  payload,
})
export const getAllOrderListSuccess = payload => ({
  type: GET_ALL_ORDER_LIST_SUCCESS,
  payload,
})
export const getAllOrderListFail = payload => ({
  type: GET_ALL_ORDER_LIST_FAIL,
  payload,
})
export const getAllDriverOrderListSaga = payload => ({
  type: GET_ALL_DRIVER_ORDER_LIST_SAGA,
  payload,
})
export const getAllDriverOrderListStart = payload => ({
  type: GET_ALL_DRIVER_ORDER_LIST_START,
  payload,
})
export const getAllDriverOrderListSuccess = payload => ({
  type: GET_ALL_DRIVER_ORDER_LIST_SUCCESS,
  payload,
})
export const getAllDriverOrderListFail = payload => ({
  type: GET_ALL_DRIVER_ORDER_LIST_FAIL,
  payload,
})
export const getReceptionListSaga = payload => ({
  type: GET_RECEPTION_LIST_SAGA,
  payload,
})
export const getReceptionListStart = payload => ({
  type: GET_RECEPTION_LIST_START,
  payload,
})
export const getReceptionListSuccess = payload => ({
  type: GET_RECEPTION_LIST_SUCCESS,
  payload,
})
export const getReceptionListFail = payload => ({
  type: GET_RECEPTION_LIST_FAIL,
  payload,
})

export const acceptOrderForDeliverySaga = payload => ({
  type: ACCEPT_ORDER_FOR_DELIVERY_SAGA,
  payload,
})
export const acceptOrderForDeliveryStart = payload => ({
  type: ACCEPT_ORDER_FOR_DELIVERY_START,
  payload,
})
export const acceptOrderForDeliverySuccess = payload => ({
  type: ACCEPT_ORDER_FOR_DELIVERY_SUCCESS,
  payload,
})
export const acceptOrderForDeliveryFail = payload => ({
  type: ACCEPT_ORDER_FOR_DELIVERY_FAIL,
  payload,
})

export const getAllVehicleListSaga = payload => ({
  type: GET_ALL_VEHICLE_LIST_SAGA,
  payload,
})
export const getAllVehicleListStart = payload => ({
  type: GET_ALL_VEHICLE_LIST_START,
  payload,
})
export const getAllVehicleListSuccess = payload => ({
  type: GET_ALL_VEHICLE_LIST_SUCCESS,
  payload,
})
export const getAllVehicleListFail = payload => ({
  type: GET_ALL_VEHICLE_LIST_FAIL,
  payload,
})

export const arrivedDataToReceptionSaga = payload => ({
  type: ARRIVED_DATA_TO_RECEPTION_SAGA,
  payload,
})
export const arrivedDataToReceptionStart = payload => ({
  type: ARRIVED_DATA_TO_RECEPTION_START,
  payload,
})
export const arrivedDataToReceptionSuccess = payload => ({
  type: ARRIVED_DATA_TO_RECEPTION_SUCCESS,
  payload,
})
export const arrivedDataToReceptionFail = payload => ({
  type: ARRIVED_DATA_TO_RECEPTION_FAIL,
  payload,
})
export const getLatLongFromAddressStart = payload => ({
  type: GET_LAT_LONG_FROM_ADDRESS_START,
  payload,
});

export const getLatLongFromAddressSaga = payload => ({
  type: GET_LAT_LONG_FROM_ADDRESS_SAGA,
  payload,
});

export const getLatLongFromAddressSuccess = payload => ({
  type: GET_LAT_LONG_FROM_ADDRESS_SUCCESS,
  payload,
});

export const resetLatLongFromAddress = payload => ({
  type: RESET_LAT_LONG_FROM_ADDRESS,
  payload,
});

export const getAllBrandTypesStart = () => ({
  type: GET_ALL_BRAND_TYPES_START,
});

export const getAllBrandTypesSaga = payload => ({
  type: GET_ALL_BRAND_TYPES_SAGA,
  payload,
});

export const getAllBrandTypesSuccess = payload => ({
  type: GET_ALL_BRAND_TYPES_SUCCESS,
  payload,
});

export const getAllBrandTypesFail = payload => ({
  type: GET_ALL_BRAND_TYPES_FAIL,
  payload,
});

export const getLatLongForLogisticStart = () => ({
  type: GET_LAT_LONG_FOR_LOGISTIC_START,
});

export const getLatLongForLogisticSaga = payload => ({
  type: GET_LAT_LONG_FOR_LOGISTIC_SAGA,
  payload,
});

export const getLatLongForLogisticSuccess = payload => ({
  type: GET_LAT_LONG_FOR_LOGISTIC_SUCCESS,
  payload,
});

export const getLatLongForLogisticFail = payload => ({
  type: GET_LAT_LONG_FOR_LOGISTIC_FAIL,
  payload,
});

export const getAllLogisticDetailStart = () => ({
  type: GET_ALL_LOGISTIC_DETAIL_START,
});

export const getAllLogisticDetailSaga = payload => ({
  type: GET_ALL_LOGISTIC_DETAIL_SAGA,
  payload,
});

export const getAllLogisticDetailSuccess = payload => ({
  type: GET_ALL_LOGISTIC_DETAIL_SUCCESS,
  payload,
});

export const getAllLogisticDetailFail = payload => ({
  type: GET_ALL_LOGISTIC_DETAIL_FAIL,
  payload,
});

export const sendProductToLogisticStart = () => ({
  type: SEND_PRODUCT_TO_LOGISTIC_START,
});

export const sendProductToLogisticSaga = payload => ({
  type: SEND_PRODUCT_TO_LOGISTIC_SAGA,
  payload,
});

export const sendProductToLogisticSuccess = payload => ({
  type: SEND_PRODUCT_TO_LOGISTIC_SUCCESS,
  payload,
});

export const sendProductToLogisticFail = payload => ({
  type: SEND_PRODUCT_TO_LOGISTIC_FAIL,
  payload,
});

export const getAllLogisticOrderStart = () => ({
  type: GET_ALL_LOGISTIC_ORDER_START,
});

export const getAllLogisticOrderSaga = payload => ({
  type: GET_ALL_LOGISTIC_ORDER_SAGA,
  payload,
});

export const getAllLogisticOrderSuccess = payload => ({
  type: GET_ALL_LOGISTIC_ORDER_SUCCESS,
  payload,
});

export const getAllLogisticOrderFail = payload => ({
  type: GET_ALL_LOGISTIC_ORDER_FAIL,
  payload,
});

export const getOrderDetailByIdStart = () => ({
  type: GET_ORDER_DETAIL_BY_ID_START,
});

export const getOrderDetailByIdSaga = payload => ({
  type: GET_ORDER_DETAIL_BY_ID_SAGA,
  payload,
});

export const getOrderDetailByIdSuccess = payload => ({
  type: GET_ORDER_DETAIL_BY_ID_SUCCESS,
  payload,
});

export const getOrderDetailByIdFail = payload => ({
  type: GET_ORDER_DETAIL_BY_ID_FAIL,
  payload,
});

export const addNewEmployeeStart = () => ({
  type: ADD_NEW_EMPLOYEE_START,
});

export const addNewEmployeeSaga = payload => ({
  type: ADD_NEW_EMPLOYEE_SAGA,
  payload,
});

export const addNewEmployeeSuccess = payload => ({
  type: ADD_NEW_EMPLOYEE_SUCCESS,
  payload,
});

export const addNewEmployeeFail = payload => ({
  type: ADD_NEW_EMPLOYEE_FAIL,
  payload,
});

export const editNewEmployeeStart = () => ({
  type: EDIT_NEW_EMPLOYEE_START,
});

export const editNewEmployeeSaga = payload => ({
  type: EDIT_NEW_EMPLOYEE_SAGA,
  payload,
});

export const editNewEmployeeSuccess = payload => ({
  type: EDIT_NEW_EMPLOYEE_SUCCESS,
  payload,
});

export const editNewEmployeeFail = payload => ({
  type: EDIT_NEW_EMPLOYEE_FAIL,
  payload,
});

export const getAllEmployeeDataStart = () => ({
  type: GET_ALL_EMPLOYEE_DATA_START,
});

export const getAllEmployeeDataSaga = payload => ({
  type: GET_ALL_EMPLOYEE_DATA_SAGA,
  payload,
});

export const getAllEmployeeDataSuccess = payload => ({
  type: GET_ALL_EMPLOYEE_DATA_SUCCESS,
  payload,
});

export const getAllEmployeeDataFail = payload => ({
  type: GET_ALL_EMPLOYEE_DATA_FAIL,
  payload,
});

export const editEmployeeStatusStart = () => ({
  type: EDIT_EMPLOYEE_STATUS_START,
});

export const editEmployeeStatusSaga = payload => ({
  type: EDIT_EMPLOYEE_STATUS_SAGA,
  payload,
});

export const editEmployeeStatusSuccess = payload => ({
  type: EDIT_EMPLOYEE_STATUS_SUCCESS,
  payload,
});

export const editEmployeeStatusFail = payload => ({
  type: EDIT_EMPLOYEE_STATUS_FAIL,
  payload,
});

export const deleteLogisticEmployeeStart = () => ({
  type: DELETE_LOGISTIC_EMPLOYEE_START,
});

export const deleteLogisticEmployeeSaga = payload => ({
  type: DELETE_LOGISTIC_EMPLOYEE_SAGA,
  payload,
});

export const deleteLogisticEmployeeSuccess = payload => ({
  type: DELETE_LOGISTIC_EMPLOYEE_SUCCESS,
  payload,
});

export const deleteLogisticEmployeeFail = payload => ({
  type: DELETE_LOGISTIC_EMPLOYEE_FAIL,
  payload,
});


// DESTING FILTER GET DATA


export const getAllDestinyDataStart = () => ({
  type: GET_ALL_DESTINY_DATA_START,
});

export const getAllDestinyDataSaga = payload => ({
  type: GET_ALL_DESTINY_DATA_SAGA,
  payload,
});

export const getAllDestinyDataSuccess = payload => ({
  type: GET_ALL_DESTINY_DATA_SUCCESS,
  payload,
});

export const getAllDestinyDataFail = payload => ({
  type: GET_ALL_DESTINY_DATA_FAIL,
  payload,
});


///deletedriver

export const deleteDriverSaga = payload => ({
  type: DELETE_DRIVER_SAGA,
  payload,
})
export const deleteDriverStart = payload => ({
  type: DELETE_DRIVER_START,
  payload,
})
export const deleteDriverSuccess = payload => ({
  type: DELETE_DRIVER_SUCCESS,
  payload,
})
export const deleteDriverFail = payload => ({
  type: DELETE_DRIVER_FAIL,
  payload,
})
