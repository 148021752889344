export const RESET_PROMOTERS_LIST = 'RESET_PROMOTERS_LIST';
export const RESET_PROMOTER_DETAIL = 'RESET_PROMOTER_DETAIL';

// Get All
export const GET_ALL_PROMOTERS_START = 'GET_ALL_PROMOTERS_START';
export const GET_ALL_PROMOTERS_SAGA = 'GET_ALL_PROMOTERS_SAGA';
export const GET_ALL_PROMOTERS_SUCCESS = 'GET_ALL_PROMOTERS_SUCCESS';
export const GET_ALL_PROMOTERS_FAIL = 'GET_ALL_PROMOTERS_FAIL';

// Add
export const ADD_PROMOTER_START = 'ADD_PROMOTER_START';
export const ADD_PROMOTER_SAGA = 'ADD_PROMOTER_SAGA';
export const ADD_PROMOTER_SUCCESS = 'ADD_PROMOTER_SUCCESS';
export const ADD_PROMOTER_FAIL = 'ADD_PROMOTER_FAIL';

// Edit
export const EDIT_PROMOTER_START = 'EDIT_PROMOTER_START';
export const EDIT_PROMOTER_SAGA = 'EDIT_PROMOTER_SAGA';
export const EDIT_PROMOTER_SUCCESS = 'EDIT_PROMOTER_SUCCESS';
export const EDIT_PROMOTER_FAIL = 'EDIT_PROMOTER_FAIL';

// Delete
export const DELETE_PROMOTER_START = 'DELETE_PROMOTER_START';
export const DELETE_PROMOTER_SAGA = 'DELETE_PROMOTER_SAGA';
export const DELETE_PROMOTER_SUCCESS = 'DELETE_PROMOTER_SUCCESS';
export const DELETE_PROMOTER_FAIL = 'DELETE_PROMOTER_FAIL';

// SIngle Single
export const GET_PROMOTER_BY_ID_START = 'GET_PROMOTER_BY_ID_START';
export const GET_PROMOTER_BY_ID_SAGA = 'GET_PROMOTER_BY_ID_SAGA';
export const GET_PROMOTER_BY_ID_SUCCESS = 'GET_PROMOTER_BY_ID_SUCCESS';
export const GET_PROMOTER_BY_ID_FAIL = 'GET_PROMOTER_BY_ID_FAIL';

// Block Unblock
export const EDIT_BLOCK_PROMOTER_STATUS_START =
  'EDIT_BLOCK_PROMOTER_STATUS_START';
export const EDIT_BLOCK_PROMOTER_STATUS_SAGA =
  'EDIT_BLOCK_PROMOTER_STATUS_SAGA';
export const EDIT_BLOCK_PROMOTER_STATUS_SUCCESS =
  'EDIT_BLOCK_PROMOTER_STATUS_SUCCESS';
export const EDIT_BLOCK_PROMOTER_STATUS_FAIL =
  'EDIT_BLOCK_PROMOTER_STATUS_FAIL';

// Edit Commission
export const EDIT_PROMOTER_COMMISSION_START = 'EDIT_PROMOTER_COMMISSION_START';
export const EDIT_PROMOTER_COMMISSION_SAGA = 'EDIT_PROMOTER_COMMISSION_SAGA';
export const EDIT_PROMOTER_COMMISSION_SUCCESS =
  'EDIT_PROMOTER_COMMISSION_SUCCESS';
export const EDIT_PROMOTER_COMMISSION_FAIL = 'EDIT_PROMOTER_COMMISSION_FAIL';

// Promoter Login Side
// =============== Vendor Promoter Side =================

// Get All Vendors
export const GET_ALL_VENDORS_OF_PROMOTER_START =
  'GET_ALL_VENDORS_OF_PROMOTER_START';
export const GET_ALL_VENDORS_OF_PROMOTER_SAGA =
  'GET_ALL_VENDORS_OF_PROMOTER_SAGA';
export const GET_ALL_VENDORS_OF_PROMOTER_SUCCESS =
  'GET_ALL_VENDORS_OF_PROMOTER_SUCCESS';
export const GET_ALL_VENDORS_OF_PROMOTER_FAIL =
  'GET_ALL_VENDORS_OF_PROMOTER_FAIL';

// Add Vendor
export const ADD_VENDOR_OF_PROMOTER_START = 'ADD_VENDOR_OF_PROMOTER_START';
export const ADD_VENDOR_OF_PROMOTER_SAGA = 'ADD_VENDOR_OF_PROMOTER_SAGA';
export const ADD_VENDOR_OF_PROMOTER_SUCCESS = 'ADD_VENDOR_OF_PROMOTER_SUCCESS';
export const ADD_VENDOR_OF_PROMOTER_FAIL = 'ADD_VENDOR_OF_PROMOTER_FAIL';

// GET Vendor of promoter
export const GET_VENDOR_DETAIS_OF_PROMOTER_START =
  'GET_VENDOR_DETAIS_OF_PROMOTER_START';
export const GET_VENDOR_DETAIS_OF_PROMOTER_SAGA =
  'GET_VENDOR_DETAIS_OF_PROMOTER_SAGA';
export const GET_VENDOR_DETAIS_OF_PROMOTER_SUCCESS =
  'GET_VENDOR_DETAIS_OF_PROMOTER_SUCCESS';
export const GET_VENDOR_DETAIS_OF_PROMOTER_FAIL =
  'GET_VENDOR_DETAIS_OF_PROMOTER_FAIL';

// EDIT Vendor
export const EDIT_VENDOR_OF_PROMOTER_START = 'EDIT_VENDOR_OF_PROMOTER_START';
export const EDIT_VENDOR_OF_PROMOTER_SAGA = 'EDIT_VENDOR_OF_PROMOTER_SAGA';
export const EDIT_VENDOR_OF_PROMOTER_SUCCESS =
  'EDIT_VENDOR_OF_PROMOTER_SUCCESS';
export const EDIT_VENDOR_OF_PROMOTER_FAIL = 'EDIT_VENDOR_OF_PROMOTER_FAIL';

// DELETE Vendor
export const DELETE_VENDOR_OF_PROMOTER_START =
  'DELETE_VENDOR_OF_PROMOTER_START';
export const DELETE_VENDOR_OF_PROMOTER_SAGA = 'DELETE_VENDOR_OF_PROMOTER_SAGA';
export const DELETE_VENDOR_OF_PROMOTER_SUCCESS =
  'DELETE_VENDOR_OF_PROMOTER_SUCCESS';
export const DELETE_VENDOR_OF_PROMOTER_FAIL = 'DELETE_VENDOR_OF_PROMOTER_FAIL';

// =============== Driver Promoter Side =================
// Get All Driver Added By Promoter
export const PROMOTER_GET_DRIVERS_START = 'PROMOTER_GET_DRIVERS_START';
export const PROMOTER_GET_DRIVERS_SAGA = 'PROMOTER_GET_DRIVERS_SAGA';
export const PROMOTER_GET_DRIVERS_SUCCESS = 'PROMOTER_GET_DRIVERS_SUCCESS';
export const PROMOTER_GET_DRIVERS_FAIL = 'PROMOTER_GET_DRIVERS_FAIL';

// Delete Driver Request From Promoter
export const PROMOTER_DELETE_DRIVER_START = 'PROMOTER_DELETE_DRIVER_START';
export const PROMOTER_DELETE_DRIVER_SAGA = 'PROMOTER_DELETE_DRIVER_SAGA';
export const PROMOTER_DELETE_DRIVER_SUCCESS = 'PROMOTER_DELETE_DRIVER_SUCCESS';
export const PROMOTER_DELETE_DRIVER_FAIL = 'PROMOTER_DELETE_DRIVER_FAIL';

// =============== Vehicle Owner Promoter Side =================

// Get all Vehicle Owner Added By Promoter
export const GET_PROMOTER_VEHICLE_OWNERS_START =
  'GET_PROMOTER_VEHICLE_OWNERS_START';
export const GET_PROMOTER_VEHICLE_OWNERS_SAGA =
  'GET_PROMOTER_VEHICLE_OWNERS_SAGA';
export const GET_PROMOTER_VEHICLE_OWNERS_SUCCESS =
  'GET_PROMOTER_VEHICLE_OWNERS_SUCCESS';
export const GET_PROMOTER_VEHICLE_OWNERS_FAIL =
  'GET_PROMOTER_VEHICLE_OWNERS_FAIL';

// Delete Vehicle Owner From Promoter
export const PROMOTER_DELETE_VEHICLE_OWNER_START =
  'PROMOTER_DELETE_VEHICLE_OWNER_START';
export const PROMOTER_DELETE_VEHICLE_OWNER_SAGA =
  'PROMOTER_DELETE_VEHICLE_OWNER_SAGA';
export const PROMOTER_DELETE_VEHICLE_OWNER_SUCCESS =
  'PROMOTER_DELETE_VEHICLE_OWNER_SUCCESS';
export const PROMOTER_DELETE_VEHICLE_OWNER_FAIL =
  'PROMOTER_DELETE_VEHICLE_OWNER_FAIL';

// =============== Vehicles Promoter Side =================

// Hariom work starts here
// Get all Vehicle Added By Promoter by
export const GET_PROMOTER_VEHICLE_START = 'GET_PROMOTER_VEHICLE_START';
export const GET_PROMOTER_VEHICLE_SAGA = 'GET_PROMOTER_VEHICLE_SAGA';
export const GET_PROMOTER_VEHICLE_SUCCESS = 'GET_PROMOTER_VEHICLE_SUCCESS';
export const GET_PROMOTER_VEHICLE_FAIL = 'GET_PROMOTER_VEHICLE_FAIL';

// Delete Vehicle From Promoter
export const PROMOTER_DELETE_VEHICLE_START = 'PROMOTER_DELETE_VEHICLE_START';
export const PROMOTER_DELETE_VEHICLE_SAGA = 'PROMOTER_DELETE_VEHICLE_SAGA';
export const PROMOTER_DELETE_VEHICLE_SUCCESS =
  'PROMOTER_DELETE_VEHICLE_SUCCESS';
export const PROMOTER_DELETE_VEHICLE_FAIL = 'PROMOTER_DELETE_VEHICLE_FAIL';

// Hariom work Ends here

export const GET_ALL_VEHICLE_OF_PROMOTER_SAGA =
  'GET_ALL_VEHICLE_OF_PROMOTER_SAGA';
// getAllVehicleOfPromoterSaga
