/* eslint-disable import/prefer-default-export */
import { put } from 'redux-saga/effects';
import {
  getAdminDashboardStart,
  getAdminDashboardSuccess,
  getAdminDashboardFail,
  logout,
  getChefDashboardStart,
  getChefDashboardFail,
  getChefDashboardSuccess,
  getPromoterDashboardStart,
  getPromoterDashboardSuccess,
  getPromoterDashboardFail,
  getOwnerDashboardStart,
  getOwnerDashboardSuccess,
  getOwnerDashboardFail,
} from '../../actions';
import axios from '../../../http/axios/axios_main';

// eslint-disable-next-line no-unused-vars
export function* getAdminDashboardSaga(action) {
  yield put(getAdminDashboardStart());
  try {
    const response = yield axios.get(
      `/admin/getAdminDashboardCount?type=${action.payload.type}`,
    );
    if (response.status === 200) {
      yield put(
        getAdminDashboardSuccess({ adminDashboard: response.data.data }),
      );
    } else {
      yield put(
        getAdminDashboardFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAdminDashboardFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAdminDashboardFail(error.response.data.message));
      } else {
        yield put(getAdminDashboardFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAdminDashboardFail('Something went wrong! Please try again.'),
      );
    }
  }
}
export function* getOwnerDashboardSaga(action) {
  yield put(getOwnerDashboardStart());
  try {
    console.log(action.payload)
    const response = yield axios.get(
      `/admin/getOwnerDashboardCount`,
    );
    console.log(response)
    if (response.status === 200) {
      yield put(
        getOwnerDashboardSuccess({ adminDashboard: response.data.data }),
      );
    } else {
      yield put(
        getOwnerDashboardFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getOwnerDashboardFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getOwnerDashboardFail(error.response.data.message));
      } else {
        yield put(getOwnerDashboardFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getOwnerDashboardFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getChefDashboardSaga(action) {
  yield put(getChefDashboardStart());
  try {
    const response = yield axios.get(
      `/logistics/getDashboardData?type=${action.payload.type}`,
    );
    if (response.status === 200) {
      yield put(getChefDashboardSuccess({ chefDashboard: response.data.data }));
    } else {
      yield put(
        getChefDashboardFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getChefDashboardFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getChefDashboardFail(error.response.data.message));
      } else {
        yield put(getChefDashboardFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getChefDashboardFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getPromoterDashboardSaga(action) {
  yield put(getPromoterDashboardStart());
  try {
    console.log(action.payload)
    const response = yield axios.get(
      // `/promoter/getPromoterDashboardCount?type=${action.payload.type}`,
      `/promoter/PromoterDashboardCount`,
    );
    console.log(response)
    if (response.status === 200) {
      yield put(
        getPromoterDashboardSuccess({ promoterDashboard: response.data.data }),
      );
    } else {
      yield put(
        getPromoterDashboardFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getPromoterDashboardFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getPromoterDashboardFail(error.response.data.message));
      } else {
        yield put(getPromoterDashboardFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getPromoterDashboardFail('Something went wrong! Please try again.'),
      );
    }
  }
}
