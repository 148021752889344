export const GET_ALL_CONTACTUS_START = 'GET_ALL_CONTACTUS_START';
export const GET_ALL_CONTACTUS_SAGA = 'GET_ALL_CONTACTUS_SAGA';
export const GET_ALL_CONTACTUS_SUCCESS = 'GET_ALL_CONTACTUS_SUCCESS';
export const GET_ALL_CONTACTUS_FAIL = 'GET_ALL_CONTACTUS_FAIL';

export const EDIT_MARK_AS_RESOLVED_START = 'EDIT_MARK_AS_RESOLVED_START';
export const EDIT_MARK_AS_RESOLVED_SAGA = 'EDIT_MARK_AS_RESOLVED_SAGA';
export const EDIT_MARK_AS_RESOLVED_SUCCESS = 'EDIT_MARK_AS_RESOLVED_SUCCESS';
export const EDIT_MARK_AS_RESOLVED_FAIL = 'EDIT_MARK_AS_RESOLVED_FAIL';

export const RESET_CONTACTUS_LIST = 'RESET_CONTACTUS_LIST';
