import axios from 'axios';

const axiosMain = axios.create({
  baseURL: 'http://103.76.250.120:3001/',
  // process.env.NODE_ENV === 'development'
  //   ? process.env.REACT_APP_END_POINT_URL_DEV
  //   : process.env.REACT_APP_END_POINT_URL_PROD,
  headers: {
    'Content-Type': 'application/json',
  },
});
export const BASE_URL = 'http://103.76.250.120:3001/';

export default axiosMain;
