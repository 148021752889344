import {
  GET_ALL_CHEF_PAYOUT_LIST_START,
  GET_ALL_CHEF_PAYOUT_LIST_SAGA,
  GET_ALL_CHEF_PAYOUT_LIST_SUCCESS,
  GET_ALL_CHEF_PAYOUT_LIST_FAIL,
  GET_PAYMENT_HISTORY_START,
  GET_PAYMENT_HISTORY_SAGA,
  GET_PAYMENT_HISTORY_CHEF_SAGA,
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_PAYMENT_HISTORY_FAIL,
  GET_PAYMENT_HISTORY_DETAILS_START,
  GET_PAYMENT_HISTORY_DETAILS_SAGA,
  GET_PAYMENT_HISTORY_DETAILS_SUCCESS,
  GET_PAYMENT_HISTORY_DETAILS_FAIL,
  PAY_PAYMENT_START,
  PAY_PAYMENT_SAGA,
  PAY_PAYMENT_SUCCESS,
  PAY_PAYMENT_FAIL,
  GET_PAYMENTTYPE_START,
  GET_PAYMENTTYPE_SAGA,
  GET_PAYMENTTYPE_SUCCESS,
  GET_PAYMENTTYPE_FAIL,

  ADD_PAYMENTTYPE_START,
  ADD_PAYMENTTYPE_SAGA,
  ADD_PAYMENTTYPE_SUCCESS,
  ADD_PAYMENTTYPE_FAIL,

} from '../actionLabels';

export const getAllChefPayoutListStart = () => ({
  type: GET_ALL_CHEF_PAYOUT_LIST_START,
});

export const getAllChefPayoutListSaga = payload => ({
  type: GET_ALL_CHEF_PAYOUT_LIST_SAGA,
  payload,
});

export const getAllChefPayoutListSuccess = payload => ({
  type: GET_ALL_CHEF_PAYOUT_LIST_SUCCESS,
  payload,
});

export const getAllChefPayoutListFail = payload => ({
  type: GET_ALL_CHEF_PAYOUT_LIST_FAIL,
  payload,
});

export const getPaymentHistoryStart = () => ({
  type: GET_PAYMENT_HISTORY_START,
});

export const getPaymentHistorySaga = payload => ({
  type: GET_PAYMENT_HISTORY_SAGA,
  payload,
});

export const getPaymentHistoryChefSaga = payload => ({
  type: GET_PAYMENT_HISTORY_CHEF_SAGA,
  payload,
});

export const getPaymentHistorySuccess = payload => ({
  type: GET_PAYMENT_HISTORY_SUCCESS,
  payload,
});

export const getPaymentHistoryFail = payload => ({
  type: GET_PAYMENT_HISTORY_FAIL,
  payload,
});

export const getPaymentHistoryDetailsStart = payload => ({
  type: GET_PAYMENT_HISTORY_DETAILS_START,
  payload,
});

export const getPaymentHistoryDetailsSaga = payload => ({
  type: GET_PAYMENT_HISTORY_DETAILS_SAGA,
  payload,
});

export const getPaymentHistoryDetailsSuccess = payload => ({
  type: GET_PAYMENT_HISTORY_DETAILS_SUCCESS,
  payload,
});

export const getPaymentHistoryDetailsFail = payload => ({
  type: GET_PAYMENT_HISTORY_DETAILS_FAIL,
  payload,
});

export const payPaymentStart = payload => ({
  type: PAY_PAYMENT_START,
  payload,
});

export const payPaymentSaga = payload => ({
  type: PAY_PAYMENT_SAGA,
  payload,
});

export const payPaymentSuccess = payload => ({
  type: PAY_PAYMENT_SUCCESS,
  payload,
});

export const payPaymentFail = payload => ({
  type: PAY_PAYMENT_FAIL,
  payload,
});


export const getAllUserPaymentTypeSaga = payload => ({
  type: GET_PAYMENTTYPE_SAGA,
  payload,
})

export const getAllUserPaymentTypeStart = () => ({
  type: GET_PAYMENTTYPE_START,
})
export const getAllUserPaymentTypeSuccess = payload => ({
  type: GET_PAYMENTTYPE_SUCCESS,
  payload,
})

export const getAllUserPaymentTypeFail = payload => ({
  type: GET_PAYMENTTYPE_FAIL,
  payload,
})




export const addAllUserPaymentTypeSaga = payload => ({
  type: ADD_PAYMENTTYPE_SAGA,
  payload,
})
export const addAllUserPaymentTypeStart = () => ({
  type: ADD_PAYMENTTYPE_START,
})
export const addAllUserPaymentTypeSuccess = payload => ({
  type: ADD_PAYMENTTYPE_SUCCESS,
  payload,
})

export const addAllUserPaymentTypeFail = payload => ({
  type: ADD_PAYMENTTYPE_FAIL,
  payload,
})
