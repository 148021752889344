export const RESET_OWNER_VEHICLE_DETAILS = 'RESET_OWNER_VEHICLE_DETAILS';
export const RESET_OWNER_VEHICLE_LIST = 'RESET_OWNER_VEHICLE_LIST';
// Get All
export const GET_ALL_OWNER_VEHICLES_START = 'GET_ALL_OWNER_VEHICLES_START';
export const GET_ALL_OWNER_VEHICLES_SAGA = 'GET_ALL_OWNER_VEHICLES_SAGA';
export const GET_ALL_OWNER_VEHICLES_SUCCESS = 'GET_ALL_OWNER_VEHICLES_SUCCESS';
export const GET_ALL_OWNER_VEHICLES_FAIL = 'GET_ALL_OWNER_VEHICLES_FAIL';

// Get Owner Vehicle List by Id For Admin/promoter side
export const GET_VEHICLE_LIST_BY_OWNER_ID_SAGA =
  'GET_VEHICLE_LIST_BY_OWNER_ID_SAGA';

// Get Single
export const GET_OWNER_VEHICLE_DETAIL_BY_ID_START =
  'GET_OWNER_VEHICLE_DETAIL_BY_ID_START';
export const GET_OWNER_VEHICLE_DETAIL_BY_ID_SAGA =
  'GET_OWNER_VEHICLE_DETAIL_BY_ID_SAGA';
export const GET_OWNER_VEHICLE_DETAIL_BY_ID_SUCCESS =
  'GET_OWNER_VEHICLE_DETAIL_BY_ID_SUCCESS';
export const GET_OWNER_VEHICLE_DETAIL_BY_ID_FAIL =
  'GET_OWNER_VEHICLE_DETAIL_BY_ID_FAIL';

// Add
export const ADD_OWNER_VEHICLE_START = 'ADD_OWNER_VEHICLE_START';
export const ADD_OWNER_VEHICLE_SAGA = 'ADD_OWNER_VEHICLE_SAGA';
export const ADD_OWNER_VEHICLE_SUCCESS = 'ADD_OWNER_VEHICLE_SUCCESS';
export const ADD_OWNER_VEHICLE_FAIL = 'ADD_OWNER_VEHICLE_FAIL';

// Edit
export const EDIT_OWNER_VEHICLE_START = 'EDIT_OWNER_VEHICLE_START';
export const EDIT_OWNER_VEHICLE_SAGA = 'EDIT_OWNER_VEHICLE_SAGA';
export const EDIT_OWNER_VEHICLE_SUCCESS = 'EDIT_OWNER_VEHICLE_SUCCESS';
export const EDIT_OWNER_VEHICLE_FAIL = 'EDIT_OWNER_VEHICLE_FAIL';

// Delte
export const DELETE_OWNER_VEHICLE_START = 'DELETE_OWNER_VEHICLE_START';
export const DELETE_OWNER_VEHICLE_SAGA = 'DELETE_OWNER_VEHICLE_SAGA';
export const DELETE_OWNER_VEHICLE_SUCCESS = 'DELETE_OWNER_VEHICLE_SUCCESS';
export const DELETE_OWNER_VEHICLE_FAIL = 'DELETE_OWNER_VEHICLE_FAIL';

// Block - Unblock
export const EDIT_BLOCK_OWNER_VEHICLE_STATUS_START =
  'EDIT_BLOCK_OWNER_VEHICLE_STATUS_START';
export const EDIT_BLOCK_OWNER_VEHICLE_STATUS_SAGA =
  'EDIT_BLOCK_OWNER_VEHICLE_STATUS_SAGA';
export const EDIT_BLOCK_OWNER_VEHICLE_STATUS_SUCCESS =
  'EDIT_BLOCK_OWNER_VEHICLE_STATUS_SUCCESS';
export const EDIT_BLOCK_OWNER_VEHICLE_STATUS_FAIL =
  'EDIT_BLOCK_OWNER_VEHICLE_STATUS_FAIL';

// accept-reject

export const OWNER_VEHICLE_APPROVE_REJECT_START = 'OWNER_VEHICLE_APPROVE_REJECT_START';
export const OWNER_VEHICLE_APPROVE_REJECT_SAGA = 'OWNER_VEHICLE_APPROVE_REJECT_SAGA';
export const OWNER_VEHICLE_APPROVE_REJECT_SUCCESS = 'OWNER_VEHICLE_APPROVE_REJECT_SUCCESS';
export const OWNER_VEHICLE_APPROVE_REJECT_FAIL = 'OWNER_VEHICLE_APPROVE_REJECT_FAIL';

// OWNER VEHICLE DRIVER ASSIGN
export const OWNER_VEHICLE_DRIVER_ASSIGN_START = 'OWNER_VEHICLE_DRIVER_ASSIGN_START';
export const OWNER_VEHICLE_DRIVER_ASSIGN_SAGA = 'OWNER_VEHICLE_DRIVER_ASSIGN_SAGA';
export const OWNER_VEHICLE_DRIVER_ASSIGN_SUCCESS = 'OWNER_VEHICLE_DRIVER_ASSIGN_SUCCESS';
export const OWNER_VEHICLE_DRIVER_ASSIGN_FAIL = 'OWNER_VEHICLE_DRIVER_ASSIGN_FAIL';

// Unassign driver from vehicle

export const UNASSIGN_DRIVER_FROM_VEHICLE_START = 'UNASSIGN_DRIVER_FROM_VEHICLE_START';
export const UNASSIGN_DRIVER_FROM_VEHICLE_SAGA = 'UNASSIGN_DRIVER_FROM_VEHICLE_SAGA';
export const UNASSIGN_DRIVER_FROM_VEHICLE_SUCCESS = 'UNASSIGN_DRIVER_FROM_VEHICLE_SUCCESS';
export const UNASSIGN_DRIVER_FROM_VEHICLE_FAIL = 'UNASSIGN_DRIVER_FROM_VEHICLE_FAIL';

// Delete driver from vehicle

export const DELETE_DRIVER_FROM_VEHICLE_START = 'DELETE_DRIVER_FROM_VEHICLE_START';
export const DELETE_DRIVER_FROM_VEHICLE_SAGA = 'DELETE_DRIVER_FROM_VEHICLE_SAGA';
export const DELETE_DRIVER_FROM_VEHICLE_SUCCESS = 'DELETE_DRIVER_FROM_VEHICLE_SUCCESS';
export const DELETE_DRIVER_FROM_VEHICLE_FAIL = 'DELETE_DRIVER_FROM_VEHICLE_FAIL';
