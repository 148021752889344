import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  adminDashboard: {
    chefCount: '-',
    orderCount: '-',
    orderTotal: '-',
    productCount: '-',
    driverCount: '-',
    promoterCount: '-',
    orderTotalForToday: '-',
    orderCountForToday: {
      orderCountDelivered: '-',
      orderCountPending: '-',
      orderCountRejected: '-',
      orderCountTotal: '-',
    },
    ToalVehicleCount: '',
    ownerAddedDriverCount: '',
    userCount: '-',
  },
  chefDashboard: {
    deliveredOrderCount: '-',
    rejectedOrderCount: '-',
    totalOrderCount: '-',
    todayAcceptedOrderCount: '-',
    todayRejectedOrderCount: '-',
    todayPendingOrderCount: '-',
    todayDeliveredOrderCount: '-',
    todayTotalOrderCount: '-',
    orderTotal: '-',
    orderTotalForToday: '-',
  },
  promoterDashboard: {
    chefCount: '-',
    driverCount: '-',
    vehicleOwnerCount: '-',
    orderTotalForToday: '-',
    countForToday: {
      countDrivers: '-',
      countChefs: '-',
      countVehicleOwners: '-',
      countTotal: '-',
    },
    userCount: '-',
  },
  totalPromoterDriver: '_',
  totalPromotersVendor: '_',
  totalPromotersOwner: '_',
  ToalVehicleCount: "_",
  ownerAddedDriverCount: "_",
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ADMIN_DASHBOARD_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ADMIN_DASHBOARD_SUCCESS: {
      const { adminDashboard } = payload;
      return { ...state, adminDashboard, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ADMIN_DASHBOARD_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GET_OWNER_DASHBOARD_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_OWNER_DASHBOARD_SUCCESS: {
      console.log({ payload })
      const { ToalVehicleCount, ownerAddedDriverCount } = payload.adminDashboard;
      return { ...state, ownerAddedDriverCount, ToalVehicleCount, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_OWNER_DASHBOARD_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GET_CHEF_DASHBOARD_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_CHEF_DASHBOARD_SUCCESS: {
      const { chefDashboard } = payload;
      return { ...state, chefDashboard, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_CHEF_DASHBOARD_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.GET_PROMOTER_DASHBOARD_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_PROMOTER_DASHBOARD_SUCCESS: {
      console.log(payload)
      const { totalPromoterDriver, totalPromotersOwner, totalPromotersVendor } = payload.promoterDashboard;
      return { ...state, totalPromoterDriver, totalPromotersOwner, totalPromotersVendor, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_PROMOTER_DASHBOARD_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }
    default:
      return state;
  }
};
