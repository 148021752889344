import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  referrals: { list: [], totalRecords: { count: 0 } },
  referral: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // Get All services
    case actionLabels.GET_ALL_REFERRAL_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_REFERRAL_SUCCESS: {
      const { referrals } = payload;
      return {
        ...state,
        referrals,
        isLoading: false,
      };
    }
    case actionLabels.GET_ALL_REFERRAL_FAIL:
      return {
        ...state,
        referrals: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
