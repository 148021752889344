import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  inquiries: { list: [], totalRecords: { count: 0 } },
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_INQUIRIES_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_INQUIRIES_SUCCESS: {
      const { inquiries } = payload;
      return { ...state, inquiries, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_INQUIRIES_FAIL:
      return {
        ...state,
        inquiries: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.EDIT_MARK_AS_APPROVED_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_MARK_AS_APPROVED_SUCCESS: {
      return { ...state, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_MARK_AS_APPROVED_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_MARK_AS_REJECTED_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_MARK_AS_REJECTED_SUCCESS: {
      return { ...state, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_MARK_AS_REJECTED_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_INQUIRY_LIST: {
      return {
        ...state,
        inquiries: { list: [], totalRecords: { count: 0 } },
      };
    }
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }
    default:
      return state;
  }
};
