import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  users: { list: [], totalRecords: { count: 0 } },
  userOrder: { list: [], totalRecords: { count: 0 } },
  userOrderDetail: {},
  user: {},
  userCount: {
    activeUserCount: 0,
    inActiveUCount: 0,
  },
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_USER_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_USER_SUCCESS: {
      const { users } = payload;
      return { ...state, users, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_USER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GET_USER_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_USER_BY_ID_SUCCESS: {
      const { user } = payload;
      return { ...state, user, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_USER_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GET_ACTIVE_INACTIVE_USER_COUNT_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ACTIVE_INACTIVE_USER_COUNT_SUCCESS: {
      const { userCount } = payload;
      return { ...state, userCount, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ACTIVE_INACTIVE_USER_COUNT_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.DELETE_USER_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_USER_SUCCESS: {
      const users = {
        ...state.users,
      };
      users.list = users.list.filter(item => item._id !== payload.id);
      users.totalRecords.count -= 1;
      return { ...state, users, isLoading: false, errorMsg: '' };
    }
    case actionLabels.DELETE_USER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_BLOCK_USER_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BLOCK_USER_STATUS_SUCCESS: {
      if (payload.isDetail !== true) {
        const { users, userCount } = state;
        const index = users.list.findIndex(item => item._id === payload.id);
        if (index !== -1 && users.list.length > index) {
          if (users.list[index].isBlock === true) {
            userCount.activeUserCount += 1;
            userCount.inActiveUCount -= 1;
          } else {
            userCount.activeUserCount -= 1;
            userCount.inActiveUCount += 1;
          }
          users.list[index].isBlock = !users.list[index].isBlock;
        }
        return { ...state, users, userCount, isLoading: false, errorMsg: '' };
      }
      const { user } = state;
      user.isBlock = !user.isBlock;
      return { ...state, user, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_BLOCK_USER_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_USER_DETAIL:
      return { ...state, user: {}, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }
    case actionLabels.GET_ALL_USER_ORDER_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_USER_ORDER_SUCCESS: {
      console.log({ payload })
      const orders = {
        list: payload.list,
        totalRecords: { count: payload.totalRecords.count }
      };
      return { ...state, userOrder: orders, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_USER_ORDER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GET_ALL_USER_ORDER_DETAIL_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_USER_ORDER_DETAIL_SUCCESS: {
      console.log({ payload })
      const orderDetail = payload;
      return { ...state, userOrderDetail: orderDetail, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_USER_ORDER_DETAIL_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    default:
      return state;
  }
};
