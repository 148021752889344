import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  storeTypes: { list: [], totalRecords: { count: 0 } },
  storeType: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_STORE_TYPES_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_STORE_TYPES_SUCCESS: {
      const { storeTypes } = payload;
      return {
        ...state,
        storeTypes,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.GET_ALL_STORE_TYPES_FAIL:
      return {
        ...state,
        storeTypes: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };

    case actionLabels.ADD_STORE_TYPE_START:
      return { ...state, isLoading: true };

    case actionLabels.ADD_STORE_TYPE_SUCCESS: {
      const { storeTypes } = state;
      storeTypes.list.unshift(payload);
      storeTypes.totalRecords.count += 1;
      return { ...state, storeTypes, isLoading: false, errorMsg: '' };
    }

    case actionLabels.ADD_STORE_TYPE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Edit Category
    case actionLabels.EDIT_STORE_TYPE_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_STORE_TYPE_SUCCESS:
      return { ...state, isLoading: false, errorMsg: '' };
    case actionLabels.EDIT_STORE_TYPE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // change status
    case actionLabels.EDIT_STORE_TYPE_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_STORE_TYPE_STATUS_SUCCESS: {
      const { storeTypes } = state;
      const index = storeTypes.list.findIndex(item => item._id === payload.id);
      if (index !== -1 && storeTypes.list.length > index) {
        storeTypes.list[index].status = !storeTypes.list[index].status;
      }
      return { ...state, storeTypes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_STORE_TYPE_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.CLEAR_STORE_TYPE_ERROR_MSG: {
      return { ...state, isLoading: false, errorMsg: '' };
    }
    default:
      return state;
  }
};
