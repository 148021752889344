import { put } from 'redux-saga/effects';
import {
  getAllCMSStart,
  getAllCMSSuccess,
  getAllCMSFail,
  getCMSPageStart,
  getCMSPageSuccess,
  getCMSPageFail,
  logout,
  getCMSByIdStart,
  getCMSByIdSuccess,
  getCMSByIdFail,
  updateCMSStart,
  updateCMSSuccess,
  updateCMSFail,
  showModal,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllCMSSaga(action) {
  yield put(getAllCMSStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllCMSList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllCMSSuccess({ CMSs: response.data.data }));
    } else {
      yield put(getAllCMSFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllCMSFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllCMSFail(error.response.data.message));
      } else {
        yield put(getAllCMSFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllCMSFail('Something went wrong! Please try again.'));
    }
  }
}
export function* getCMSPageSaga(action) {
  yield put(getCMSPageStart());
  try {
    const response = yield axios.get(
      `guest/getCMSByTitle?title=${action.payload}`,
    );
    if (response.status === 200) {
      yield put(getCMSPageSuccess({ CMSContent: response.data.data }));
    } else {
      yield put(getCMSPageFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getCMSPageFail(error.response.data.message));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getCMSPageFail(error.response.data.message));
      } else {
        yield put(getCMSPageFail('Server error! Please try again.'));
      }
    } else {
      yield put(getCMSPageFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getCMSByIdSaga(action) {
  yield put(getCMSByIdStart());
  try {
    const response = yield axios.get(
      `/admin/getCMSByTitle?title=${action.payload.id}`,
    );
    if (response.status === 200) {
      yield put(getCMSByIdSuccess({ CMS: response.data.data }));
    } else {
      yield put(getCMSByIdFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getCMSByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getCMSByIdFail(error.response.data.message));
      } else {
        yield put(getCMSByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(getCMSByIdFail('Something went wrong! Please try again.'));
    }
  }
}

export function* updateCMSSaga(action) {
  yield put(updateCMSStart());
  const { setIsDisable } = action.payload;
  try {
    const reqData = {
      title: action.payload.title,
      content: action.payload.content,
    };
    const response = yield axios.post('/admin/updateCMS', reqData);

    if (response.status === 200) {
      yield put(updateCMSSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vendors/cms',
          message: response.data.message || 'Success',
        }),
      );
      setIsDisable(false);
    } else {
      yield put(updateCMSFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(updateCMSFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(updateCMSFail(error.response.data.message));
      } else {
        yield put(updateCMSFail('Server error! Please try again.'));
      }
    } else {
      yield put(updateCMSFail('Something went wrong! Please try again.'));
    }
  }
}
