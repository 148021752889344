import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  reviews: { list: [], totalRecords: { count: 0 } },
  logisticsReviews: { list: [], totalRecords: { count: 0 } },
  avgRating: 0,
  restaurantName: '',
  restaurantEmail: '',
  retsaurantContact: '',
  totalRating: 0,
  totalRatingCount: 0,
  review: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_CHEF_REVIEW_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_CHEF_REVIEW_SUCCESS: {
      const { reviews } = payload;
      return { ...state, reviews, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_CHEF_REVIEW_FAIL:
      return {
        ...state,
        reviews: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_ALL_RATING_REVIEW_BY_CHEF_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_RATING_REVIEW_BY_CHEF_ID_SUCCESS: {
      const {
        reviews,
        avgRating,
        restaurantName,
        restaurantEmail,
        retsaurantContact,
        totalRating,
        totalRatingCount,
      } = payload;
      return {
        ...state,
        reviews,
        avgRating,
        restaurantName,
        restaurantEmail,
        retsaurantContact,
        totalRating,
        totalRatingCount,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.GET_ALL_RATING_REVIEW_BY_CHEF_ID_FAIL: {
      if (
        typeof payload !== 'string' &&
        payload.status !== undefined &&
        payload.status === 404
      ) {
        const { error, data } = payload;
        const {
          avgRating,
          restaurantName,
          restaurantEmail,
          retsaurantContact,
          totalRating,
          totalRatingCount,
        } = data;
        return {
          ...state,
          reviews: { list: [], totalRecords: { count: 0 } },
          avgRating,
          restaurantName,
          restaurantEmail,
          retsaurantContact,
          totalRating,
          totalRatingCount,
          isLoading: false,
          errorMsg: error,
        };
      }
      return {
        ...state,
        reviews: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    }
    case actionLabels.GET_REVIEW_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_REVIEW_BY_ID_SUCCESS: {
      const { review } = payload;
      return { ...state, review, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_REVIEW_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GET_ALL_LOGISTICS_RATING_REVIEW_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_LOGISTICS_RATING_REVIEW_SUCCESS: {
      const { logisticsReviews } = payload;
      return { ...state, logisticsReviews, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_LOGISTICS_RATING_REVIEW_FAIL:
      return {
        ...state,
        logisticsReviews: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }
    default:
      return state;
  }
};
