export const GET_ALL_CHEF_START = 'GET_ALL_CHEF_START';
export const GET_ALL_CHEF_SAGA = 'GET_ALL_CHEF_SAGA';
export const GET_ALL_CHEF_SUCCESS = 'GET_ALL_CHEF_SUCCESS';
export const GET_ALL_CHEF_FAIL = 'GET_ALL_CHEF_FAIL';

export const GET_CHEF_BY_ID_START = 'GET_CHEF_BY_ID_START';
export const GET_CHEF_BY_ID_SAGA = 'GET_CHEF_BY_ID_SAGA';
export const GET_CHEF_BY_ID_SUCCESS = 'GET_CHEF_BY_ID_SUCCESS';
export const GET_CHEF_BY_ID_FAIL = 'GET_CHEF_BY_ID_FAIL';

export const EDIT_BLOCK_CHEF_STATUS_START = 'EDIT_BLOCK_CHEF_STATUS_START';
export const EDIT_BLOCK_CHEF_STATUS_SAGA = 'EDIT_BLOCK_CHEF_STATUS_SAGA';
export const EDIT_BLOCK_CHEF_STATUS_SUCCESS = 'EDIT_BLOCK_CHEF_STATUS_SUCCESS';
export const EDIT_BLOCK_CHEF_STATUS_FAIL = 'EDIT_BLOCK_CHEF_STATUS_FAIL';

export const ADD_BANK_DETAIL_START = 'ADD_BANK_DETAIL_START';
export const ADD_BANK_DETAIL_SAGA = 'ADD_BANK_DETAIL_SAGA';
export const ADD_BANK_DETAIL_SUCCESS = 'ADD_BANK_DETAIL_SUCCESS';
export const ADD_BANK_DETAIL_FAIL = 'ADD_BANK_DETAIL_FAIL';

export const EDIT_BANK_ACCOUNT_START = 'EDIT_BANK_ACCOUNT_START';
export const EDIT_BANK_ACCOUNT_SAGA = 'EDIT_BANK_ACCOUNT_SAGA';
export const EDIT_BANK_ACCOUNT_SUCCESS = 'EDIT_BANK_ACCOUNT_SUCCESS';
export const EDIT_BANK_ACCOUNT_FAIL = 'EDIT_BANK_ACCOUNT_FAIL';

export const EDIT_BANK_ACCOUNT_STATUS_START = 'EDIT_BANK_ACCOUNT_STATUS_START';
export const EDIT_BANK_ACCOUNT_STATUS_SAGA = 'EDIT_BANK_ACCOUNT_STATUS_SAGA';
export const EDIT_BANK_ACCOUNT_STATUS_SUCCESS =
  'EDIT_BANK_ACCOUNT_STATUS_SUCCESS';
export const EDIT_BANK_ACCOUNT_STATUS_FAIL = 'EDIT_BANK_ACCOUNT_STATUS_FAIL';

export const DELETE_BANK_ACCOUNT_START = 'DELETE_BANK_ACCOUNT_START';
export const DELETE_BANK_ACCOUNT_SAGA = 'DELETE_BANK_ACCOUNT_SAGA';
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS';
export const DELETE_BANK_ACCOUNT_FAIL = 'DELETE_BANK_ACCOUNT_FAIL';

export const ADD_ADDRESS_START = 'ADD_ADDRESS_START';
export const ADD_ADDRESS_SAGA = 'ADD_ADDRESS_SAGA';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAIL = 'ADD_ADDRESS_FAIL';

export const GET_ACCEPTING_ORDER_START = 'GET_ACCEPTING_ORDER_START';
export const GET_ACCEPTING_ORDER_SAGA = 'GET_ACCEPTING_ORDER_SAGA';
export const GET_ACCEPTING_ORDER_SUCCESS = 'GET_ACCEPTING_ORDER_SUCCESS';
export const GET_ACCEPTING_ORDER_FAIL = 'GET_ACCEPTING_ORDER_FAIL';

export const EDIT_ACCEPTING_ORDER_CHEF_START =
  'EDIT_ACCEPTING_ORDER_CHEF_START';
export const EDIT_ACCEPTING_ORDER_CHEF_SAGA = 'EDIT_ACCEPTING_ORDER_CHEF_SAGA';
export const EDIT_ACCEPTING_ORDER_CHEF_SUCCESS =
  'EDIT_ACCEPTING_ORDER_CHEF_SUCCESS';
export const EDIT_ACCEPTING_ORDER_CHEF_FAIL = 'EDIT_ACCEPTING_ORDER_CHEF_FAIL';

export const EDIT_LOCATION_START = 'EDIT_LOCATION_START';
export const EDIT_LOCATION_SAGA = 'EDIT_LOCATION_SAGA';
export const EDIT_LOCATION_SUCCESS = 'EDIT_LOCATION_SUCCESS';
export const EDIT_LOCATION_FAIL = 'EDIT_LOCATION_FAIL';

export const CHEF_APPROVE_REJECT_START = 'CHEF_APPROVE_REJECT_START';
export const CHEF_APPROVE_REJECT_SAGA = 'CHEF_APPROVE_REJECT_SAGA';
export const CHEF_APPROVE_REJECT_SUCCESS = 'CHEF_APPROVE_REJECT_SUCCESS';
export const CHEF_APPROVE_REJECT_FAIL = 'CHEF_APPROVE_REJECT_FAIL';

export const EDIT_CHEF_COMMISSION_START = 'EDIT_CHEF_COMMISSION_START';
export const EDIT_CHEF_COMMISSION_SAGA = 'EDIT_CHEF_COMMISSION_SAGA';
export const EDIT_CHEF_COMMISSION_SUCCESS = 'EDIT_CHEF_COMMISSION_SUCCESS';
export const EDIT_CHEF_COMMISSION_FAIL = 'EDIT_CHEF_COMMISSION_FAIL';

export const RESET_CHEF_LIST = 'RESET_CHEF_LIST';

export const GET_CHEF_PROFILE_START = 'GET_CHEF_PROFILE_START';
export const GET_CHEF_PROFILE_SAGA = 'GET_CHEF_PROFILE_SAGA';
export const GET_CHEF_PROFILE_SUCCESS = 'GET_CHEF_PROFILE_SUCCESS';
export const GET_CHEF_PROFILE_FAIL = 'GET_CHEF_PROFILE_FAIL';

export const GET_LAT_LONG_FROM_ADDRESS_START =
  'GET_LAT_LONG_FROM_ADDRESS_START';
export const GET_LAT_LONG_FROM_ADDRESS_SAGA = 'GET_LAT_LONG_FROM_ADDRESS_SAGA';
export const GET_LAT_LONG_FROM_ADDRESS_SUCCESS =
  'GET_LAT_LONG_FROM_ADDRESS_SUCCESS';
export const RESET_LAT_LONG_FROM_ADDRESS = 'RESET_LAT_LONG_FROM_ADDRESS';

export const EDIT_PERSONAL_DETAIL_START = 'EDIT_PERSONAL_DETAIL_START';
export const EDIT_PERSONAL_DETAIL_SAGA = 'EDIT_PERSONAL_DETAIL_SAGA';
export const EDIT_PERSONAL_DETAIL_SUCCESS = 'EDIT_PERSONAL_DETAIL_SUCCESS';
export const EDIT_PERSONAL_DETAIL_FAIL = 'EDIT_PERSONAL_DETAIL_FAIL';

export const EDIT_ORDER_SCHEDULE_START = 'EDIT_ORDER_SCHEDULE_START';
export const EDIT_ORDER_SCHEDULE_SAGA = 'EDIT_ORDER_SCHEDULE_SAGA';
export const EDIT_ORDER_SCHEDULE_SUCCESS = 'EDIT_ORDER_SCHEDULE_SUCCESS';
export const EDIT_ORDER_SCHEDULE_FAIL = 'EDIT_ORDER_SCHEDULE_FAIL';

export const EDIT_RESTAURANT_DETAIL_START = 'EDIT_RESTAURANT_DETAIL_START';
export const EDIT_RESTAURANT_DETAIL_SAGA = 'EDIT_RESTAURANT_DETAIL_SAGA';
export const EDIT_RESTAURANT_DETAIL_SUCCESS = 'EDIT_RESTAURANT_DETAIL_SUCCESS';
export const EDIT_RESTAURANT_DETAIL_FAIL = 'EDIT_RESTAURANT_DETAIL_FAIL';

export const GET_ALL_BRAND_TYPES_START = 'GET_ALL_BRAND_TYPES_START';
export const GET_ALL_BRAND_TYPES_SAGA = 'GET_ALL_BRAND_TYPES_SAGA';
export const GET_ALL_BRAND_TYPES_SUCCESS = 'GET_ALL_BRAND_TYPES_SUCCESS';
export const GET_ALL_BRAND_TYPES_FAIL = 'GET_ALL_BRAND_TYPES_FAIL';

export const EDIT_MEDIA_START = 'EDIT_MEDIA_START';
export const EDIT_MEDIA_SAGA = 'EDIT_MEDIA_SAGA';
export const EDIT_MEDIA_SUCCESS = 'EDIT_MEDIA_SUCCESS';
export const EDIT_MEDIA_FAIL = 'EDIT_MEDIA_FAIL';

export const DELETE_MEDIA_START = 'DELETE_MEDIA_START';
export const DELETE_MEDIA_SAGA = 'DELETE_MEDIA_SAGA';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAIL = 'DELETE_MEDIA_FAIL';

export const EDIT_ID_PROOF_START = 'EDIT_ID_PROOF_START';
export const EDIT_ID_PROOF_SAGA = 'EDIT_ID_PROOF_SAGA';
export const EDIT_ID_PROOF_SUCCESS = 'EDIT_ID_PROOF_SUCCESS';
export const EDIT_ID_PROOF_FAIL = 'EDIT_ID_PROOF_FAIL';

export const DELETE_ID_PROOF_START = 'DELETE_ID_PROOF_START';
export const DELETE_ID_PROOF_SAGA = 'DELETE_ID_PROOF_SAGA';
export const DELETE_ID_PROOF_SUCCESS = 'DELETE_ID_PROOF_SUCCESS';
export const DELETE_ID_PROOF_FAIL = 'DELETE_ID_PROOF_FAIL';

export const EDIT_CHEF_FEATURE_START = 'EDIT_CHEF_FEATURE_START';
export const EDIT_CHEF_FEATURE_SAGA = 'EDIT_CHEF_FEATURE_SAGA';
export const EDIT_CHEF_FEATURE_SUCCESS = 'EDIT_CHEF_FEATURE_SUCCESS';
export const EDIT_CHEF_FEATURE_FAIL = 'EDIT_CHEF_FEATURE_FAIL';

export const EDIT_PROFILE_SUBMIT_START = 'EDIT_PROFILE_SUBMIT_START';
export const EDIT_PROFILE_SUBMIT_SAGA = 'EDIT_PROFILE_SUBMIT_SAGA';
export const EDIT_PROFILE_SUBMIT_SUCCESS = 'EDIT_PROFILE_SUBMIT_SUCCESS';
export const EDIT_PROFILE_SUBMIT_FAIL = 'EDIT_PROFILE_SUBMIT_FAIL';

export const RESET_CHEF_DETAIL = 'RESET_CHEF_DETAIL';

export const GET_CHEF_BRAND_TYPES_START = 'GET_CHEF_BRAND_TYPES_START';
export const GET_CHEF_BRAND_TYPES_SAGA = 'GET_CHEF_BRAND_TYPES_SAGA';
export const GET_CHEF_BRAND_TYPES_SUCCESS = 'GET_CHEF_BRAND_TYPES_SUCCESS';
export const GET_CHEF_BRAND_TYPES_FAIL = 'GET_CHEF_BRAND_TYPES_FAIL';

export const RESET_CHEF_BRAND_TYPES = 'RESET_CHEF_BRAND_TYPES';

export const GET_OTHER_VENDORS_SAGA = 'GET_OTHER_VENDORS_SAGA';

export const ENABLE_DISABLE_OTHER_VENDOR_SAGA =
  'ENABLE_DISABLE_OTHER_VENDOR_SAGA';
export const ENABLE_DISABLE_OTHER_VENDOR_START =
  'ENABLE_DISABLE_OTHER_VENDOR_START';
export const ENABLE_DISABLE_OTHER_VENDOR_SUCCESS =
  'ENABLE_DISABLE_OTHER_VENDOR_SUCCESS';
export const ENABLE_DISABLE_OTHER_VENDOR_FAIL =
  'ENABLE_DISABLE_OTHER_VENDOR_FAIL';

export const GET_ALL_PRODUCER_SAGA = 'GET_ALL_PRODUCER_SAGA';

// assign to logistic
export const ASSIGN_TO_LOGISTIC_SAGA = 'ASSIGN_TO_LOGISTIC_SAGA';
export const ASSIGN_TO_LOGISTIC_START = 'ASSIGN_TO_LOGISTIC_START';
export const ASSIGN_TO_LOGISTIC_SUCCESS = 'ASSIGN_TO_LOGISTIC_SUCCESS';
export const ASSIGN_TO_LOGISTIC_FAIL = 'ASSIGN_TO_LOGISTIC_FAIL';