import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  drivers: { list: [], totalRecords: { count: 0 } },
  driver: {},
  // driverVehicles: { },
  driverAddVehicle: {},
  brandTypes: {},
  bankDetail: {},
  address: {},
  location: {
    latitude: 0,
    longitude: 0,
  },
  latitudeFromApi: 0,
  longitudeFromApi: 0,
  acceptOrders: null,
  isApproved: null,
  isRejected: null,
  isProfileSubmitted: null,
  isLoading: false,
  isMapLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_DRIVER_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_DRIVER_SUCCESS: {
      const { drivers } = payload;
      return { ...state, drivers, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_DRIVER_FAIL:
      return {
        ...state,
        drivers: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    // Driver Details by id
    case actionLabels.GET_DRIVER_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_DRIVER_BY_ID_SUCCESS: {
      const { driver } = payload;
      // const res = payload.driver;
      // const driver = res.list[0];
      return { ...state, driver, isLoading: false, errorMsg: '' };
    }
    // case actionLabels.GET_DRIVER_BY_ID_SUCCESS: {
    //   // const { driver } = payload;
    //   const res = payload.driver;
    //   const driverVehicles = res;
    //   const driverDetail = res.list[0]['driverDetail'];
    //   return { ...state, driver: driverDetail, driverVehicles, isLoading: false, errorMsg: '' };
    // }
    case actionLabels.GET_DRIVER_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    // Driver Details from Owner
    case actionLabels.DETAILS_OWNER_DRIVER_START:
      return { ...state, isLoading: true };
    case actionLabels.DETAILS_OWNER_DRIVER_SUCCESS: {
      const { drivers } = payload;
      console.log(drivers)
      return { ...state, drivers, isLoading: false, errorMsg: '' };
    }
    case actionLabels.DETAILS_OWNER_DRIVER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Add Driver
    case actionLabels.ADD_DRIVER_START:
      return { ...state, isLoading: true };
    // case actionLabels.ADD_DRIVER_SUCCESS: {
    //   const { driver } = payload;
    //   return { ...state, driver, isLoading: false, errorMsg: '' };
    // }
    case actionLabels.ADD_DRIVER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Block Unblock Status
    case actionLabels.EDIT_BLOCK_DRIVER_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BLOCK_DRIVER_STATUS_SUCCESS: {
      if (payload.isDetail !== true) {
        const { drivers } = state;
        const index = drivers.list.findIndex(item => item._id === payload.id);
        if (index !== -1 && drivers.list.length > index) {
          drivers.list[index].isBlock = !drivers.list[index].isBlock;
        }
        return { ...state, drivers, isLoading: false, errorMsg: '' };
      }
      const { driver } = state;
      driver.isBlock = !driver.isBlock;
      return { ...state, driver, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_BLOCK_DRIVER_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.EDIT_DRIVER_SERVICE_ASSIGNED_START:
      return { ...state, isLoading: false };
    case actionLabels.EDIT_DRIVER_SERVICE_ASSIGNED_SUCCESS: {
      const { driver } = state;
      driver.serviceId = payload.serviceId;
      driver.serviceName = payload.serviceName;
      return { ...state, driver, isLoading: false, errorMsg: '' };
    }

    case actionLabels.EDIT_DRIVER_SERVICE_ASSIGNED_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.RESET_DRIVER_LIST:
      return {
        ...state,
        drivers: { list: [], totalRecords: { count: 0 } },
      };

    // clear available driver list
    case actionLabels.CLEAR_AVAILABLE_DRIVER_LIST:
      return {
        ...state,
        drivers: { list: [], totalRecords: { count: 0 } },
      };
    case 'PROMOTER_DRIVER_ADD_VEHICLE':
      return {
        ...state,
        driverAddVehicle: payload,
      };

    case actionLabels.RESET_DRIVER_DETAILS: {
      console.log('reset action');
      return {
        ...state,
        isLoading: false,
        driver: {},
        errorMsg: '',
      };
    }
    case actionLabels.PROMOTER_OWNERS_VEHICLE_START: {
      return { ...state, isLoading: true };
    }
    case actionLabels.PROMOTER_OWNERS_VEHICLE_SUCCESS: {
      console.log(payload.data)
      const drivers = payload.data;
      console.log({drivers})
      return { ...state, drivers, isLoading: false, errorMsg: '' };
    }
    case actionLabels.PROMOTER_OWNERS_VEHICLE_FAIL: {
      return {
        ...state,
        drivers: { list: [], totalRecords: { count: 0 } },
        isLoading: false, errorMsg: payload
      };
    }
    default:
      return state;
  }
};
