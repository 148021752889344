export const GET_ALL_TRANSPORTATION_MODES_START =
  'GET_ALL_TRANSPORTATION_MODES_START';
export const GET_ALL_TRANSPORTATION_MODES_SAGA =
  'GET_ALL_TRANSPORTATION_MODES_SAGA';
export const GET_ALL_TRANSPORTATION_MODES_SUCCESS =
  'GET_ALL_TRANSPORTATION_MODES_SUCCESS';
export const GET_ALL_TRANSPORTATION_MODES_FAIL =
  'GET_ALL_TRANSPORTATION_MODES_FAIL';

export const ADD_TRANSPORTATION_MODE_START = 'ADD_TRANSPORTATION_MODE_START';
export const ADD_TRANSPORTATION_MODE_SAGA = 'ADD_TRANSPORTATION_MODE_SAGA';
export const ADD_TRANSPORTATION_MODE_SUCCESS =
  'ADD_TRANSPORTATION_MODE_SUCCESS';
export const ADD_TRANSPORTATION_MODE_FAIL = 'ADD_TRANSPORTATION_MODE_FAIL';

export const EDIT_TRANSPORTATION_MODE_START = 'EDIT_TRANSPORTATION_MODE_START';
export const EDIT_TRANSPORTATION_MODE_SAGA = 'EDIT_TRANSPORTATION_MODE_SAGA';
export const EDIT_TRANSPORTATION_MODE_SUCCESS =
  'EDIT_TRANSPORTATION_MODE_SUCCESS';
export const EDIT_TRANSPORTATION_MODE_FAIL = 'EDIT_TRANSPORTATION_MODE_FAIL';

export const EDIT_TRANSPORTATION_MODE_STATUS_START =
  'EDIT_TRANSPORTATION_MODE_STATUS_START';
export const EDIT_TRANSPORTATION_MODE_STATUS_SAGA =
  'EDIT_TRANSPORTATION_MODE_STATUS_SAGA';
export const EDIT_TRANSPORTATION_MODE_STATUS_SUCCESS =
  'EDIT_TRANSPORTATION_MODE_STATUS_SUCCESS';
export const EDIT_TRANSPORTATION_MODE_STATUS_FAIL =
  'EDIT_TRANSPORTATION_MODE_STATUS_FAIL';

export const DELETE_TRANSPORTATION_MODE_START =
  'DELETE_TRANSPORTATION_MODE_START';
export const DELETE_TRANSPORTATION_MODE_SAGA =
  'DELETE_TRANSPORTATION_MODE_SAGA';
export const DELETE_TRANSPORTATION_MODE_SUCCESS =
  'DELETE_TRANSPORTATION_MODE_SUCCESS';
export const DELETE_TRANSPORTATION_MODE_FAIL =
  'DELETE_TRANSPORTATION_MODE_FAIL';
