import {
  GET_ALL_SETTING_START,
  GET_ALL_SETTING_SAGA,
  GET_ALL_SETTING_SUCCESS,
  GET_ALL_SETTING_FAIL,
  EDIT_ALL_SETTINGS_START,
  EDIT_ALL_SETTINGS_SAGA,
  EDIT_ALL_SETTINGS_SUCCESS,
  EDIT_ALL_SETTINGS_FAIL,
  EDIT_DEFAULT_FOOD_DISH_IMAGE_START,
  EDIT_DEFAULT_FOOD_DISH_IMAGE_SAGA,
  EDIT_DEFAULT_FOOD_DISH_IMAGE_SUCCESS,
  EDIT_DEFAULT_FOOD_DISH_IMAGE_FAIL,
  SEND_NOTIFICATION_START,
  SEND_NOTIFICATION_SAGA,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAIL,
  EDIT_ALL_SETTINGS_COMMISSION_SAGA,
} from '../actionLabels';

export const getAllSettingStart = payload => ({
  type: GET_ALL_SETTING_START,
  payload,
});

export const getAllSettingSaga = payload => ({
  type: GET_ALL_SETTING_SAGA,
  payload,
});

export const getAllSettingSuccess = payload => ({
  type: GET_ALL_SETTING_SUCCESS,
  payload,
});

export const getAllSettingFail = payload => ({
  type: GET_ALL_SETTING_FAIL,
  payload,
});

export const editAllSettingsStart = payload => ({
  type: EDIT_ALL_SETTINGS_START,
  payload,
});

export const editAllSettingsSaga = payload => ({
  type: EDIT_ALL_SETTINGS_SAGA,
  payload,
});

export const editAllSettingsSuccess = payload => ({
  type: EDIT_ALL_SETTINGS_SUCCESS,
  payload,
});

export const editAllSettingsFail = payload => ({
  type: EDIT_ALL_SETTINGS_FAIL,
  payload,
});

export const editDefaultFoodDishImageStart = payload => ({
  type: EDIT_DEFAULT_FOOD_DISH_IMAGE_START,
  payload,
});

export const editDefaultFoodDishImageSaga = payload => ({
  type: EDIT_DEFAULT_FOOD_DISH_IMAGE_SAGA,
  payload,
});

export const editDefaultFoodDishImageSuccess = payload => ({
  type: EDIT_DEFAULT_FOOD_DISH_IMAGE_SUCCESS,
  payload,
});

export const editDefaultFoodDishImageFail = payload => ({
  type: EDIT_DEFAULT_FOOD_DISH_IMAGE_FAIL,
  payload,
});

export const sendNotificationStart = payload => ({
  type: SEND_NOTIFICATION_START,
  payload,
});

export const sendNotificationSaga = payload => ({
  type: SEND_NOTIFICATION_SAGA,
  payload,
});

export const sendNotificationSuccess = payload => ({
  type: SEND_NOTIFICATION_SUCCESS,
  payload,
});

export const sendNotificationFail = payload => ({
  type: SEND_NOTIFICATION_FAIL,
  payload,
});

export const editAllSettingsCommissionSaga = payload => ({
  type: EDIT_ALL_SETTINGS_COMMISSION_SAGA,
  payload,
});
