import { put } from 'redux-saga/effects';

import {
  logout,
  getAllCommissionListFail,
  getAllCommissionListStart,
  getAllCommissionListSuccess,
  getTotalCommissionStart,
  getTotalCommissionSuccess,
  getTotalCommissionFail,
  getCommissionDetailStart,
  getCommissionDetailSuccess,
  getCommissionDetailFail,
  getVendorTotalCommissionStart,
  getVendorTotalCommissionSuccess,
  getVendorTotalCommissionFail,
} from '../../actions';

import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllCommissionListSaga(action) {
  yield put(getAllCommissionListStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getComissionList`, action.payload),
    );
    if (response.status === 200) {
      yield put(
        getAllCommissionListSuccess({ commissions: response.data.data }),
      );
    } else {
      yield put(
        getAllCommissionListFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllCommissionListFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllCommissionListFail(error.response.data.message));
      } else {
        yield put(getAllCommissionListFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllCommissionListFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Get Total Commission
export function* getTotalCommissionSaga() {
  yield put(getTotalCommissionStart());
  try {
    const response = yield axios.get(`/admin/getTotalCommision`);
    if (response.status === 200) {
      yield put(
        getTotalCommissionSuccess({
          totalCommission: response.data.data.totalCommision,
        }),
      );
    } else {
      yield put(
        getTotalCommissionFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getTotalCommissionFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getTotalCommissionFail(error.response.data.message));
      } else {
        yield put(getTotalCommissionFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getTotalCommissionFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Get Commission Details by Vendor Id
export function* getCommissionDetailSaga(action) {
  yield put(getCommissionDetailStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(
        // `/admin/getCommisionDetailByVendorId`,
        `/admin/getCommissionHistory`,
        action.payload,
      ),
    );
    console.log(response)
    if (response.status === 200) {
      yield put(
        getCommissionDetailSuccess({
          vendorCommission: response.data.data,
        }),
      );
    } else {
      yield put(
        getCommissionDetailFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getCommissionDetailFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getCommissionDetailFail(error.response.data.message));
      } else {
        yield put(getCommissionDetailFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getCommissionDetailFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Get Vendor Total Commission
export function* getVendorTotalCommissionSaga(action) {
  yield put(getVendorTotalCommissionStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getCommisionHeaderDetail`, action.payload),
    );
    if (response.status === 200) {
      yield put(
        getVendorTotalCommissionSuccess({
          vendorCommissionHeader: response.data.data,
        }),
      );
    } else {
      yield put(
        getVendorTotalCommissionFail('Something went wrong! Please try again.'),
      );
    }
    console.log(response)
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getVendorTotalCommissionFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getVendorTotalCommissionFail(error.response.data.message));
      } else {
        yield put(
          getVendorTotalCommissionFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getVendorTotalCommissionFail('Something went wrong! Please try again.'),
      );
    }
  }
}
