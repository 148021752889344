import { combineReducers } from 'redux';
import auth from './auth/auth';
import dashboard from './dashboard/dashboard';
import user from './user/user';
import chef from './user/chef';
import driver from './user/driver'; // shashank
import commission from './commission/commission'; // commission
import subadmin from './user/subadmin';
import dish from './dish/dish';
import promocode from './promocode/promocode';
import order from './order/order';
import review from './review/review';
import contactus from './contactus/contactus';
import cms from './cms/cms';
import setting from './setting/setting';
import supportRequest from './supportRequest/supportRequest';
import dummyReducer from './dummy/dummy';
import inquiry from './inquiry/inquiry';
import changeState from './sideBarOpen/sideBarOpen';
import modal from './modal/modal';
import payment from './payment/payment';
import notification from './notification/notification';
import reports from './reports/reports';
// Shashank Edited
import promoters from './promoters/promoters';
import vehicleOwner from './user/vehicleOwner';
import vehicleTypes from './vehicle/vehicleTypes';
import ownervehicle from './vehicle/ownerVehicles';
import service from './services/services';
import referral from './referral/referral';
import storeTypes from './storeTypes/storeTypes';
import transportaitonModes from './transportationModes/TransportationModes';
import moduleAccess from './moduleAccess/moduleAccess';
import activeTab from './activeTab';
import logistics from './logistics/logistics';


const allReducers = combineReducers({
  activeTab,
  auth,
  dashboard,
  moduleAccess,
  user,
  chef,
  driver, // SS
  subadmin,
  dish,
  commission,
  promoters, // SS
  ownervehicle,
  transportationModes: transportaitonModes,
  vehicleTypes,
  vehicleOwner, // SS
  service,
  storeTypes,
  promocode,
  order,
  review,
  inquiry,
  referral,
  contactus,
  cms,
  setting,
  supportRequest,
  dummyReducer,
  changeState,
  modal,
  payment,
  notification,
  reports,
  logistics,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
