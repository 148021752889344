import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  services: { list: [], totalRecords: { count: 0 } },
  service: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // Get All services
    case actionLabels.GET_ALL_SERVICE_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_SERVICE_SUCCESS: {
      const { services } = payload;
      return {
        ...state,
        services,
        isLoading: false,
      };
    }
    case actionLabels.GET_ALL_SERVICE_FAIL:
      return {
        ...state,
        services: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };

    // Get Single service
    case actionLabels.GET_SERVICE_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_SERVICE_BY_ID_SUCCESS: {
      const { service } = payload;
      return { ...state, service, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_SERVICE_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // add service
    case actionLabels.ADD_SERVICE_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_SERVICE_SUCCESS: {
      const { services } = state;
      services.list.unshift(payload);
      services.totalRecords.count += 1;
      return { ...state, services, isLoading: false, errorMsg: '' };
    }
    case actionLabels.ADD_SERVICE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Edit service
    case actionLabels.EDIT_SERVICE_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_SERVICE_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.EDIT_SERVICE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Delete Service
    case actionLabels.DELETE_SERVICE_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_SERVICE_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.DELETE_SERVICE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Change Service Status
    case actionLabels.EDIT_BLOCK_SERVICE_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BLOCK_SERVICE_STATUS_SUCCESS: {
      if (payload.isDetail !== true) {
        const { services } = state;
        const index = services.list.findIndex(item => item._id === payload.id);
        if (index !== -1 && services.list.length > index) {
          services.list[index].status = !services.list[index].status;
        }
        return { ...state, services, isLoading: false, errorMsg: '' };
      }
      const { service } = state;
      service.status = !service.status;
      return { ...state, service, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_BLOCK_SERVICE_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    default:
      return state;
  }
};
