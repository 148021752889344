import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  accessModules: { list: [], totalRecords: { count: 0 } },
  accessModule: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // Get All accessModules
    case actionLabels.GET_ALL_ACCESS_MODULE_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_ACCESS_MODULE_SUCCESS: {
      const { accessModules } = payload;
      return {
        ...state,
        accessModules,
        isLoading: false,
      };
    }
    case actionLabels.GET_ALL_ACCESS_MODULE_FAIL:
      return {
        ...state,
        accessModules: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };

    // Get Single accessModule
    case actionLabels.GET_ACCESS_MODULE_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ACCESS_MODULE_BY_ID_SUCCESS: {
      const { accessModule } = payload;
      return { ...state, accessModule, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ACCESS_MODULE_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // add accessModule
    case actionLabels.ADD_ACCESS_MODULE_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_ACCESS_MODULE_SUCCESS: {
      const { accessModules } = state;
      accessModules.list.unshift(payload);
      accessModules.totalRecords.count += 1;
      return { ...state, accessModules, isLoading: false, errorMsg: '' };
    }
    case actionLabels.ADD_ACCESS_MODULE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Edit accessModule
    case actionLabels.EDIT_ACCESS_MODULE_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_ACCESS_MODULE_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.EDIT_ACCESS_MODULE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Delete accessModule
    case actionLabels.DELETE_ACCESS_MODULE_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_ACCESS_MODULE_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.DELETE_ACCESS_MODULE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Change accessModule Status
    case actionLabels.EDIT_BLOCK_ACCESS_MODULE_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BLOCK_ACCESS_MODULE_STATUS_SUCCESS: {
      if (payload.isDetail !== true) {
        const { accessModules } = state;
        const index = accessModules.list.findIndex(
          item => item._id === payload.id,
        );
        if (index !== -1 && accessModules.list.length > index) {
          accessModules.list[index].status = !accessModules.list[index].status;
        }
        return { ...state, accessModules, isLoading: false, errorMsg: '' };
      }
      const { accessModule } = state;
      accessModule.status = !accessModule.status;
      return { ...state, accessModule, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_BLOCK_ACCESS_MODULE_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // RESET All
    case actionLabels.RESET_ALL_ACCESS_MODULES:
      return {
        ...state,
        accessModules: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: '',
      };
    default:
      return state;
  }
};
