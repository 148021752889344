import { put } from 'redux-saga/effects';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';
import {
  getAllReferralFail,
  getAllReferralStart,
  getAllReferralSuccess,
  logout,
} from '../../actions';

// Get All
export function* getAllReferralSaga(action) {
  yield put(getAllReferralStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/referAndEarnDetail`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllReferralSuccess({ referrals: response.data.data }));
    } else {
      yield put(getAllReferralFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllReferralFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllReferralFail(error.response.data.message));
      } else {
        yield put(getAllReferralFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllReferralFail('Something went wrong! Please try again.'));
    }
  }
}
