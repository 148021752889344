import React from 'react';

export const logisticRoutes = [
 
  
  {
    path: '/logistics/logistic-hub',
    name: 'Profile',
    component: React.lazy(() => import('../../views/LogisticHub/Dashboard/Dashboard.jsx')),
  },
  {
    path: '/logistics/new-orders',
    name: 'Profile',
    component: React.lazy(() => import('../../views/LogisticHub/NewOrders/NewOrders')),
  },
  {
    path: '/logistics/reception',
    name: 'Profile',
    component: React.lazy(() => import('../../views/LogisticHub/Reception/Reception')),
  },
  {
    path: '/logistics/add-shipping',
    name: 'Profile',
    component: React.lazy(() => import('../../views/LogisticHub/Shipping/AddShipping')),
  },

  {
    path: '/logistics/logisticroute',
    name: 'Logistic Route',
    component: React.lazy(() => import('../../views/LogisticHub/Shipping/LogisticRoute')),
  },
  {
    path: '/logistics/arrival-order-list',
    name: 'Profile',
    component: React.lazy(() => import('../../views/LogisticHub/Reception/ArrivalOrderList')),
  },
  {
    path: '/logistics/shipping',
    name: 'Profile',
    component: React.lazy(() => import('../../views/LogisticHub/Shipping/Shipping')),
  },
  {
    path: '/logistics/package',
    name: 'Profile',
    component: React.lazy(() => import('../../views/LogisticHub/Package/Package')),
  },
  {
    path: '/logistics/shippingDetail',
    name: 'Profile',
    component: React.lazy(() => import('../../views/LogisticHub/Shipping/ShippingDetails')),
  },
  {
    path: '/logistics/PayoutHistory',
    name: 'Payout History',
    component: React.lazy(() => import('../../views/LogisticHub/LogisticPayoutHistory/LogisticPayoutHistory')),
  },
  {
    path: '/logistics/LogisticSupportRequest',
    name: 'Support Request',
    component: React.lazy(() => import('../../views/LogisticHub/LogisticSupportRequest/LogisticSupportRequest')),
  },
  {
    path: '/logistics/AddLogisticSupportRequestModal',
    name: 'Add Supoort Request',
    component: React.lazy(() => import('../../views/LogisticHub/LogisticSupportRequest/AddLogiticSupportRequestModal')),
  },
  
  {
    path: '/logistics/Profile',
    name: 'Profile',
    component: React.lazy(() => import('../../views/LogisticHub/Profile/Profile')),
  },

  {
    path: '/logistics/ChangePasswordLogistic',
    name: 'Logistic Change Password',
    component: React.lazy(() => import('../../views/LogisticHub/Profile/LogisticChangePasswordModal')),
  },

  {
    path: '/logistics/register-new-employee',
    name: 'Logistic new registration',
    component: React.lazy(() => import('../../views/LogisticHub/LogisticNewRegistration/NewRegistration.jsx')),
  },

  {
    path: '/logistics/new-employee',
    name: 'Logistic new registration',
    component: React.lazy(() => import('../../views/LogisticHub/LogisticNewRegistration/EmployeeList.jsx')),
  },
  {
    path: '/logistics/register-new-employee/:id',
    exact: true,
    name: 'Edit Employee Detail',
    component: React.lazy(() =>
      import('../../views/LogisticHub/LogisticNewRegistration/NewRegistration.jsx'),
    ),
  },
  {
    path: '/logistics/employee-detail-page',
    name: 'Logistic new registration',
    component: React.lazy(() => import('../../views/LogisticHub/LogisticNewRegistration/EmployeeDetailPage.jsx')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/logistics/logistic-hub',
    // rootRedirect: true,
  },
];
