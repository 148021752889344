import { put } from 'redux-saga/effects';
import {
  getAllSupportRequestStart,
  getAllSupportRequestSuccess,
  getAllSupportRequestFail,
  getAllSupportRequestSaga as getAllSupportRequestSagaAction,
  addSupportRequestStart,
  addSupportRequestSuccess,
  addSupportRequestFail,
  logout,
  showModal,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllSupportRequestSaga(action) {
  yield put(getAllSupportRequestStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/chef/getAllSupportRequest`, action.payload),
    );
    if (response.status === 200) {
      yield put(
        getAllSupportRequestSuccess({ supportRequests: response.data.data }),
      );
    } else {
      yield put(
        getAllSupportRequestFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllSupportRequestFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllSupportRequestFail(error.response.data.message));
      } else {
        yield put(getAllSupportRequestFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllSupportRequestFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* addSupportRequestSaga(action) {
  yield put(addSupportRequestStart());
  const { setIsDisable } = action.payload;
  try {
    const { closeModel, tableFilter } = action.payload;
    const reqBody = {
      message: action.payload.message,
    };
    const response = yield axios.post(`/chef/addSupportRequest`, reqBody);
    if (response.status === 200) {
      yield put(
        addSupportRequestSuccess({ supportRequests: response.data.data }),
      );
      closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllSupportRequestSagaAction(tableFilter));
    } else {
      yield put(
        addSupportRequestFail('Something went wrong! Please try again.'),
      );
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addSupportRequestFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addSupportRequestFail(error.response.data.message));
      } else {
        yield put(addSupportRequestFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        addSupportRequestFail('Something went wrong! Please try again.'),
      );
    }
  }
}
