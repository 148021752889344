export const GET_ALL_CHEF_REVIEW_START = 'GET_ALL_CHEF_REVIEW_START';
export const GET_ALL_CHEF_REVIEW_SAGA = 'GET_ALL_CHEF_REVIEW_SAGA';
export const GET_ALL_CHEF_REVIEW_SUCCESS = 'GET_ALL_CHEF_REVIEW_SUCCESS';
export const GET_ALL_CHEF_REVIEW_FAIL = 'GET_ALL_CHEF_REVIEW_FAIL';

export const GET_REVIEW_BY_ID_START = 'GET_REVIEW_BY_ID_START';
export const GET_REVIEW_BY_ID_SAGA = 'GET_REVIEW_BY_ID_SAGA';
export const GET_REVIEW_BY_ID_SUCCESS = 'GET_REVIEW_BY_ID_SUCCESS';
export const GET_REVIEW_BY_ID_FAIL = 'GET_REVIEW_BY_ID_FAIL';

export const GET_ALL_LOGISTICS_RATING_REVIEW_START =
  'GET_ALL_LOGISTICS_RATING_REVIEW_START';
export const GET_ALL_LOGISTICS_RATING_REVIEW_SAGA =
  'GET_ALL_LOGISTICS_RATING_REVIEW_SAGA';
export const GET_ALL_LOGISTICS_RATING_REVIEW_SUCCESS =
  'GET_ALL_LOGISTICS_RATING_REVIEW_SUCCESS';
export const GET_ALL_LOGISTICS_RATING_REVIEW_FAIL =
  'GET_ALL_LOGISTICS_RATING_REVIEW_FAIL';

export const GET_ALL_RATING_REVIEW_BY_CHEF_ID_START =
  'GET_ALL_RATING_REVIEW_BY_CHEF_ID_START';
export const GET_ALL_RATING_REVIEW_BY_CHEF_ID_SAGA =
  'GET_ALL_RATING_REVIEW_BY_CHEF_ID_SAGA';
export const GET_ALL_RATING_REVIEW_BY_CHEF_ID_SUCCESS =
  'GET_ALL_RATING_REVIEW_BY_CHEF_ID_SUCCESS';
export const GET_ALL_RATING_REVIEW_BY_CHEF_ID_FAIL =
  'GET_ALL_RATING_REVIEW_BY_CHEF_ID_FAIL';
