export const GET_ALL_CATEGORY_START = 'GET_ALL_CATEGORY_START';
export const GET_ALL_CATEGORY_SAGA = 'GET_ALL_CATEGORY_SAGA';
export const GET_ALL_CATEGORY_CHEF_SAGA = 'GET_ALL_CATEGORY_CHEF_SAGA';
export const GET_ALL_CATEGORY_SUCCESS = 'GET_ALL_CATEGORY_SUCCESS';
export const GET_ALL_CATEGORY_FAIL = 'GET_ALL_CATEGORY_FAIL';
export const GET_ALL_CATEGORY_CHEF_FAIL = 'GET_ALL_CATEGORY_CHEF_FAIL';

export const GET_CATEGORY_BY_VENDOR_TYPE_SAGA =
  'GET_CATEGORY_BY_VENDOR_TYPE_SAGA';

export const GET_ALL_SUB_CATEGORY_START = 'GET_ALL_SUB_CATEGORY_START';
export const GET_ALL_SUB_CATEGORY_SAGA = 'GET_ALL_SUB_CATEGORY_SAGA';
export const GET_ALL_SUB_CATEGORY_CHEF_SAGA = 'GET_ALL_SUB_CATEGORY_CHEF_SAGA';
export const GET_ALL_SUB_CATEGORY_SUCCESS = 'GET_ALL_SUB_CATEGORY_SUCCESS';
export const GET_ALL_SUB_CATEGORY_FAIL = 'GET_ALL_SUB_CATEGORY_FAIL';
export const GET_ALL_SUB_CATEGORY_CHEF_FAIL = 'GET_ALL_CATEGORY_CHEF_FAIL';

export const GET_SUB_CATEGORY_BY_CATEGORY_SAGA =
  'GET_SUB_CATEGORY_BY_CATEGORY_SAGA';

export const GET_CATEGORY_BY_ID_START = 'GET_CATEGORY_BY_ID_START';
export const GET_CATEGORY_BY_ID_SAGA = 'GET_CATEGORY_BY_ID_SAGA';
export const GET_CATEGORY_BY_ID_SUCCESS = 'GET_CATEGORY_BY_ID_SUCCESS';
export const GET_CATEGORY_BY_ID_FAIL = 'GET_CATEGORY_BY_ID_FAIL';

export const ADD_CATEGORY_START = 'ADD_CATEGORY_START';
export const ADD_CATEGORY_SAGA = 'ADD_CATEGORY_SAGA';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAIL = 'ADD_CATEGORY_FAIL';

// Delete category start
export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_SAGA = 'DELETE_CATEGORY_SAGA';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

export const ADD_SUB_CATEGORY_START = 'ADD_SUB_CATEGORY_START';
export const ADD_SUB_CATEGORY_SAGA = 'ADD_SUB_CATEGORY_SAGA';
export const ADD_SUB_CATEGORY_SUCCESS = 'ADD_SUB_CATEGORY_SUCCESS';
export const ADD_SUB_CATEGORY_FAIL = 'ADD_SUB_CATEGORY_FAIL';

// delete subcategory start
export const DELETE_SUB_CATEGORY_START = 'DELETE_SUB_CATEGORY_START';
export const DELETE_SUB_CATEGORY_SAGA = 'DELETE_SUB_CATEGORY_SAGA';
export const DELETE_SUB_CATEGORY_SUCCESS = 'DELETE_SUB_CATEGORY_SUCCESS';
export const DELETE_SUB_CATEGORY_FAIL = 'DELETE_SUB_CATEGORY_FAIL';

export const EDIT_CATEGORY_START = 'EDIT_CATEGORY_START';
export const EDIT_CATEGORY_SAGA = 'EDIT_CATEGORY_SAGA';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_FAIL = 'EDIT_CATEGORY_FAIL';

export const EDIT_SUB_CATEGORY_START = 'EDIT_SUB_CATEGORY_START';
export const EDIT_SUB_CATEGORY_SAGA = 'EDIT_SUB_CATEGORY_SAGA';
export const EDIT_SUB_CATEGORY_SUCCESS = 'EDIT_SUB_CATEGORY_SUCCESS';
export const EDIT_SUB_CATEGORY_FAIL = 'EDIT_SUB_CATEGORY_FAIL';

export const EDIT_CATEGORY_STATUS_START = 'EDIT_CATEGORY_STATUS_START';
export const EDIT_CATEGORY_STATUS_SAGA = 'EDIT_CATEGORY_STATUS_SAGA';
export const EDIT_CATEGORY_STATUS_SUCCESS = 'EDIT_CATEGORY_STATUS_SUCCESS';
export const EDIT_CATEGORY_STATUS_FAIL = 'EDIT_CATEGORY_STATUS_FAIL';

export const EDIT_SUB_CATEGORY_STATUS_START = 'EDIT_SUB_CATEGORY_STATUS_START';
export const EDIT_SUB_CATEGORY_STATUS_SAGA = 'EDIT_SUB_CATEGORY_STATUS_SAGA';
export const EDIT_SUB_CATEGORY_STATUS_SUCCESS =
  'EDIT_SUB_CATEGORY_STATUS_SUCCESS';
export const EDIT_SUB_CATEGORY_STATUS_FAIL = 'EDIT_SUB_CATEGORY_STATUS_FAIL';

export const GET_ALL_DISH_START = 'GET_ALL_DISH_START';
export const GET_ALL_DISH_SAGA = 'GET_ALL_DISH_SAGA';
export const GET_ALL_DISH_ADMIN_SAGA = 'GET_ALL_DISH_ADMIN_SAGA';
export const GET_ALL_DISH_SUCCESS = 'GET_ALL_DISH_SUCCESS';
export const GET_ALL_DISH_FAIL = 'GET_ALL_DISH_FAIL';

export const ADD_DISH_START = 'ADD_DISH_START';
export const ADD_DISH_SAGA = 'ADD_DISH_SAGA';
export const ADD_DISH_SUCCESS = 'ADD_DISH_SUCCESS';
export const ADD_DISH_FAIL = 'ADD_DISH_FAIL';

// Add Admin dish
export const ADD_ADMIN_DISH_START = 'ADD_ADMIN_DISH_START';
export const ADD_ADMIN_DISH_SAGA = 'ADD_ADMIN_DISH_SAGA';
export const ADD_ADMIN_DISH_SUCCESS = 'ADD_ADMIN_DISH_SUCCESS';
export const ADD_ADMIN_DISH_FAIL = 'ADD_ADMIN_DISH_FAIL';

export const EDIT_DISH_START = 'EDIT_DISH_START';
export const EDIT_DISH_SAGA = 'EDIT_DISH_SAGA';
export const EDIT_DISH_SUCCESS = 'EDIT_DISH_SUCCESS';
export const EDIT_DISH_FAIL = 'EDIT_DISH_FAIL';

// Edit Admin Dish
export const EDIT_ADMIN_DISH_START = 'EDIT_ADMIN_DISH_START';
export const EDIT_ADMIN_DISH_SAGA = 'EDIT_ADMIN_DISH_SAGA';
export const EDIT_ADMIN_DISH_SUCCESS = 'EDIT_ADMIN_DISH_SUCCESS';
export const EDIT_ADMIN_DISH_FAIL = 'EDIT_ADMIN_DISH_FAIL';

export const EDIT_DISH_PHOTO_START = 'EDIT_DISH_PHOTO_START';
export const EDIT_DISH_PHOTO_SAGA = 'EDIT_DISH_PHOTO_SAGA';
export const EDIT_DISH_PHOTO_SUCCESS = 'EDIT_DISH_PHOTO_SUCCESS';
export const EDIT_DISH_PHOTO_FAIL = 'EDIT_DISH_PHOTO_FAIL';

export const EDIT_DISH_PUBLISH_STATUS_START = 'EDIT_DISH_PUBLISH_STATUS_START';
export const EDIT_DISH_PUBLISH_STATUS_SAGA = 'EDIT_DISH_PUBLISH_STATUS_SAGA';
export const EDIT_DISH_PUBLISH_STATUS_SUCCESS =
  'EDIT_DISH_PUBLISH_STATUS_SUCCESS';
export const EDIT_DISH_PUBLISH_STATUS_FAIL = 'EDIT_DISH_PUBLISH_STATUS_FAIL';

export const DELETE_DISH_START = 'DELETE_DISH_START';
export const DELETE_DISH_SAGA = 'DELETE_DISH_SAGA';
export const DELETE_DISH_SUCCESS = 'DELETE_DISH_SUCCESS';
export const DELETE_DISH_FAIL = 'DELETE_DISH_FAIL';

// Admin Dish Delete
export const DELETE_ADMIN_DISH_START = 'DELETE_ADMIN_DISH_START';
export const DELETE_ADMIN_DISH_SAGA = 'DELETE_ADMIN_DISH_SAGA';
export const DELETE_ADMIN_DISH_SUCCESS = 'DELETE_ADMIN_DISH_SUCCESS';
export const DELETE_ADMIN_DISH_FAIL = 'DELETE_ADMIN_DISH_FAIL';

export const GET_ALL_ADMIN_BRAND_TYPES_START =
  'GET_ALL_ADMIN_BRAND_TYPES_START';
export const GET_ALL_ADMIN_BRAND_TYPES_SAGA = 'GET_ALL_ADMIN_BRAND_TYPES_SAGA';
export const GET_ALL_ADMIN_BRAND_TYPES_SUCCESS =
  'GET_ALL_ADMIN_BRAND_TYPES_SUCCESS';
export const GET_ALL_ADMIN_BRAND_TYPES_FAIL = 'GET_ALL_ADMIN_BRAND_TYPES_FAIL';

export const ADD_BRAND_TYPE_START = 'ADD_BRAND_TYPE_START';
export const ADD_BRAND_TYPE_SAGA = 'ADD_BRAND_TYPE_SAGA';
export const ADD_BRAND_TYPE_SUCCESS = 'ADD_BRAND_TYPE_SUCCESS';
export const ADD_BRAND_TYPE_FAIL = 'ADD_BRAND_TYPE_FAIL';

export const EDIT_BRAND_TYPE_START = 'EDIT_BRAND_TYPE_START';
export const EDIT_BRAND_TYPE_SAGA = 'EDIT_BRAND_TYPE_SAGA';
export const EDIT_BRAND_TYPE_SUCCESS = 'EDIT_BRAND_TYPE_SUCCESS';
export const EDIT_BRAND_TYPE_FAIL = 'EDIT_BRAND_TYPE_FAIL';

export const DELETE_BRAND_TYPE_START = 'DELETE_BRAND_TYPE_START';
export const DELETE_BRAND_TYPE_SAGA = 'DELETE_BRAND_TYPE_SAGA';
export const DELETE_BRAND_TYPE_SUCCESS = 'DELETE_BRAND_TYPE_SUCCESS';
export const DELETE_BRAND_TYPE_FAIL = 'DELETE_CATEGORY_FAIL';

export const EDIT_BRAND_TYPE_STATUS_START = 'EDIT_BRAND_TYPE_STATUS_START';
export const EDIT_BRAND_TYPE_STATUS_SAGA = 'EDIT_BRAND_TYPE_STATUS_SAGA';
export const EDIT_BRAND_TYPE_STATUS_SUCCESS = 'EDIT_BRAND_TYPE_STATUS_SUCCESS';
export const EDIT_BRAND_TYPE_STATUS_FAIL = 'EDIT_BRAND_TYPE_STATUS_FAIL';

export const ADD_DARK_HOME_VENDOR_PRODUCT_START =
  'ADD_DARK_HOME_VENDOR_PRODUCT_START';
export const ADD_DARK_HOME_VENDOR_PRODUCT_SAGA =
  'ADD_DARK_HOME_VENDOR_PRODUCT_SAGA';
export const ADD_DARK_HOME_VENDOR_PRODUCT_SUCCESS =
  'ADD_DARK_HOME_VENDOR_PRODUCT_SUCCESS';
export const ADD_DARK_HOME_VENDOR_PRODUCT_FAIL =
  'ADD_DARK_HOME_VENDOR_PRODUCT_FAIL';

export const EDIT_DARK_HOME_VENDOR_PRODUCT_START =
  'EDIT_DARK_HOME_VENDOR_PRODUCT_START';
export const EDIT_DARK_HOME_VENDOR_PRODUCT_SAGA =
  'EDIT_DARK_HOME_VENDOR_PRODUCT_SAGA';
export const EDIT_DARK_HOME_VENDOR_PRODUCT_SUCCESS =
  'EDIT_DARK_HOME_VENDOR_PRODUCT_SUCCESS';
export const EDIT_DARK_HOME_VENDOR_PRODUCT_FAIL =
  'EDIT_DARK_HOME_VENDOR_PRODUCT_FAIL';

export const DELETE_DARK_HOME_VENDOR_PRODUCT_START =
  'DELETE_DARK_HOME_VENDOR_PRODUCT_START';
export const DELETE_DARK_HOME_VENDOR_PRODUCT_SAGA =
  'DELETE_DARK_HOME_VENDOR_PRODUCT_SAGA';
export const DELETE_DARK_HOME_VENDOR_PRODUCT_SUCCESS =
  'DELETE_DARK_HOME_VENDOR_PRODUCT_SUCCESS';
export const DELETE_DARK_HOME_VENDOR_PRODUCT_FAIL =
  'DELETE_DARK_HOME_VENDOR_PRODUCT_FAIL';

export const EDIT_DARK_HOME_VENDOR_PRODUCT_PUBLISH_STATUS_START =
  'EDIT_DARK_HOME_VENDOR_PRODUCT_PUBLISH_STATUS_START';
export const EDIT_DARK_HOME_VENDOR_PRODUCT_PUBLISH_STATUS_SAGA =
  'EDIT_DARK_HOME_VENDOR_PRODUCT_PUBLISH_STATUS_SAGA';
export const EDIT_DARK_HOME_VENDOR_PRODUCT_PUBLISH_STATUS_SUCCESS =
  'EDIT_DARK_HOME_VENDOR_PRODUCT_PUBLISH_STATUS_SUCCESS';
export const EDIT_DARK_HOME_VENDOR_PRODUCT_PUBLISH_STATUS_FAIL =
  'EDIT_DARK_HOME_VENDOR_PRODUCT_PUBLISH_STATUS_FAIL';

export const GET_ALL_DISH_LIST_PROMOTER_START =
  'GET_ALL_DISH_LIST_PROMOTER_START';
export const GET_ALL_DISH_LIST_PROMOTER_SAGA =
  'GET_ALL_DISH_LIST_PROMOTER_SAGA';
export const GET_ALL_DISH_LIST_PROMOTER_SUCCESS =
  'GET_ALL_DISH_LIST_PROMOTER_SUCCESS';
export const GET_ALL_DISH_LIST_PROMOTER_FAIL =
  'GET_ALL_DISH_LIST_PROMOTER_FAIL';


export const DELETE_PRODUCT_BY_PROMOTER_START =
  'DELETE_PRODUCT_BY_PROMOTER_START';
export const DELETE_PRODUCT_BY_PROMOTER_SAGA =
  'DELETE_PRODUCT_BY_PROMOTER_SAGA';
export const DELETE_PRODUCT_BY_PROMOTER_SUCCESS =
  'DELETE_PRODUCT_BY_PROMOTER_SUCCESS';
export const DELETE_PRODUCT_BY_PROMOTER_FAIL =
  'DELETE_PRODUCT_BY_PROMOTER_FAIL'; 