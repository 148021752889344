import { put } from 'redux-saga/effects';
import { capitalize } from '../../../utils/capitalize';
import {
  // eslint-disable-next-line no-unused-vars
  getAllDriverSaga as getAllDriversSagaAction,
  getAllDriverStart,
  getAllDriverSuccess,
  getAllDriverFail,
  logout,
  editBlockDriverStatusStart,
  editBlockDriverStatusSuccess,
  editBlockDriverStatusFail,
  addDriverStart,
  addDriverSuccess,
  addDriverFail,
  editDriverStart,
  editDriverSuccess,
  editDriverFail,
  showModal,
  getDriverByIdStart,
  getDriverByIdSuccess,
  getDriverByIdFail,
  driverApproveRejectSaga as driverApproveRejectSagaAction,
  driverApproveRejectStart,
  driverApproveRejectSuccess,
  driverApproveRejectFail,
  editDriverCommissionStart,
  editDriverCommissionSuccess,
  editDriverCommissionFail,
  editDriverServiceAssignedStart,
  editDriverServiceAssignedSuccess,
  editDriverServiceAssignedFail,
  addVehicleOwnerStart,
  addVehicleOwnerSuccess,
  addVehicleOwnerFail,
  unassignDriverFromVehicleStart,
  unassignDriverFromVehicleFail,
  getDetailsOwnerDriverStart,
  getDetailsOwnerDriverSuccess,
  getDetailsOwnerDriverFail,
  promoterOwnersVehicleStart,
  promoterOwnersVehicleSuccess,
  promoterOwnersVehicleFail,
} from '../../actions';

import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

// eslint-disable import/prefer-default-export
export function* getAllDriverSaga(action) {
  yield put(getAllDriverStart());
  try {
    const { route, query } = action.payload;
    const response = yield axios.get(
      urlPaginationGenerator(`/${route}/getalldriver`, query),
    );
    if (response.status === 200) {
      yield put(getAllDriverSuccess({ drivers: response.data.data }));
    } else {
      yield put(getAllDriverFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllDriverFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllDriverFail(error.response.data.message));
      } else {
        yield put(getAllDriverFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllDriverFail('Something went wrong! Please try again.'));
    }
  }
}
export function* getDetailsOwnerDriverSaga(action) {
  // console.log(action.payload)
  yield put(getDetailsOwnerDriverStart());
  try {
    const { route, query } = action.payload;
    const response = yield axios.get(
      urlPaginationGenerator(`/${route}/getalldriverForVehicleOwner`, query),
    );
    console.log(response.data.data)
    if (response.status === 200) {
      yield put(getDetailsOwnerDriverSuccess({ drivers: response.data.data }));
    } else {
      yield put(getDetailsOwnerDriverFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getDetailsOwnerDriverFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getDetailsOwnerDriverFail(error.response.data.message));
      } else {
        yield put(getDetailsOwnerDriverFail('Server error! Please try again.'));
      }
    } else {
      yield put(getDetailsOwnerDriverFail('Something went wrong! Please try again.'));
    }
  }
}

// Get Driver Details
export function* getDriverByIdSaga(action) {
  yield put(getDriverByIdStart());
  try {
    const payload = action.payload.id;
    const response = yield axios.get(
      // `/admin/getDriverDetail?userId=${payload}`,
      `/${action.payload.route}/getAssignedVehicleDetailListByDriverId?userId=${payload}`,
    );
    if (response.status === 200) {
      yield put(getDriverByIdSuccess({ driver: response.data.data }));
    } else {
      yield put(getDriverByIdFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getDriverByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getDriverByIdFail(error.response.data.message));
      } else {
        yield put(getDriverByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(getDriverByIdFail('Something went wrong! Please try again.'));
    }
  }
}

// Add Driver
export function* addDriverSaga(action) {
  yield put(addDriverStart());
  try {
    const { tableFilter } = action.payload;
    console.log(action.payload.formValues);
    const formdata = new FormData();
    formdata.append('name', action.payload.formValues.name);
    formdata.append('email', action.payload.formValues.email);
    formdata.append('profileimage', action.payload.formValues.profileimage);
    formdata.append('frontimage', action.payload.formValues.frontimage);
    formdata.append('backimage', action.payload.formValues.backimage);
    formdata.append('licenseNumber', action.payload.formValues.licenseNumber);
    formdata.append('gender', action.payload.formValues.gender);
    formdata.append('phone', action.payload.formValues.phone);
    formdata.append('dob', action.payload.formValues.dob);
    formdata.append('password', action.payload.formValues.password);
    formdata.append('deviceType', action.payload.formValues.deviceType);
    formdata.append('country', action.payload.formValues.country);
    formdata.append('countryCode', action.payload.formValues.countryCode);
    formdata.append('state', action.payload.formValues.state);
    formdata.append('city', action.payload.formValues.city);
    if (action.payload.formValues.driverCommission) {
      formdata.append('driverCommission', action.payload.formValues.driverCommission);
    }
    formdata.append('deviceToken', action.payload.formValues.deviceToken);
    // formdata.append('driverCommission', action.payload.formValues.driverCommission);
    const response = yield axios.post('/driverauth/signUp', formdata);
    if (response.status === 200) {
      yield put(addDriverSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          // redirectURL: 'vehicle-owner/drivers',
          redirectURL: '/vendors/drivers',
          message: capitalize(response.data.message) || 'Success',
        }),
      );
      yield put(getAllDriversSagaAction(tableFilter));
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(addDriverFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        console.log('driver 400 fail')
        yield put(
        showModal({
          open: true,
          notifyType: 1,
          // redirectURL: 'vehicle-owner/drivers',
          // redirectURL: '/vendors/drivers',
          message: capitalize(error.response.data.message) || 'Error',
        }) )
        yield put(addDriverFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addDriverFail(error.response.data.message));
      } else {
        yield put(addDriverFail('Server error! Please try again.'));
      }
    } else {
      yield put(addDriverFail('Something went wrong! Please try again.'));
    }
  }
}

export function* promoterAddDriverSaga(action) {
  yield put(addDriverStart());
  const { setIsDisable, tableFilter } = action.payload;
  try {
    console.log(action.payload.formValues);
    const formdata = new FormData();
    formdata.append('name', action.payload.formValues.name);
    formdata.append('email', action.payload.formValues.email);
    formdata.append('profileimage', action.payload.formValues.profileimage);
    formdata.append('frontimage', action.payload.formValues.frontimage);
    formdata.append('backimage', action.payload.formValues.backimage);
    formdata.append('licenseNumber', action.payload.formValues.licenseNumber);
    formdata.append('gender', action.payload.formValues.gender);
    // formdata.append('phone', action.payload.formValues.phoneNumber);
    formdata.append('phone', action.payload.formValues.phone);
    formdata.append('dob', action.payload.formValues.dob);
    formdata.append('password', action.payload.formValues.password);
    formdata.append('deviceType', action.payload.formValues.deviceType);
    formdata.append('countryCode', action.payload.formValues.countryCode);
    formdata.append('state', action.payload.formValues.state);
    formdata.append('city', action.payload.formValues.city);
    formdata.append('driverCommission', action.payload.formValues.commission);
    formdata.append('deviceToken', action.payload.formValues.deviceToken);
    formdata.append('country', action.payload.formValues.country);

    console.log({ formdata })
    const response = yield axios.post('/promoter/addDriver', formdata);
    if (response.status === 200) {
      yield put(addDriverSuccess(response.data.data));
      setIsDisable(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/promoter/drivers',
          message: capitalize(response.data.message) || 'Success',
        }),
      );
      yield put(getAllDriversSagaAction(tableFilter));
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(addDriverFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {

        yield put(
          showModal({
              open: true,
              notifyType: 1,
              message: error.response.data.message || 'Error',
          }),
      );
        yield put(addDriverFail(error.response.data.message));

        
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addDriverFail(error.response.data.message));
      } else {
        yield put(addDriverFail('Server error! Please try again.'));
      }
    } else {
      yield put(addDriverFail('Something went wrong! Please try again.'));
    }
  }
}
// Block Unblock Driver Saga
export function* editBlockDriverStatusSaga(action) {
  yield put(editBlockDriverStatusStart());
  try {
    const payload = {
      userId: action.payload.id,
      isBlock: action.payload.isBlock,
    };
    const response = yield axios.post(`/driver/statusChanged`, payload);
    console.log(response);
    if (response.status === 200) {
      yield put(editBlockDriverStatusSuccess(action.payload));
    } else {
      yield put(
        editBlockDriverStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    console.log(error.message);
  }
}

// Promoter Functionalites
export function* promoterDriverDetailsSaga(action) {
  yield put(getDriverByIdStart());
  try {
    const response = yield axios.post(`promoter/getDriverById`, action.payload);
    if (response.status === 200) {
      yield put(getDriverByIdSuccess({ driver: response.data.data }));
    } else {
      yield put(getDriverByIdFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getDriverByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getDriverByIdFail(error.response.data.message));
      } else {
        yield put(getDriverByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(getDriverByIdFail('Something went wrong! Please try again.'));
    }
  }
}

export function* promoterEditDriverSaga(action) {
  yield put(editDriverStart());
  try {
    const { tableFilter, formValues } = action.payload;
    console.log(action.payload);
    const formdata = new FormData();

    formdata.append('id', formValues.id);
    formdata.append('name', formValues.name);
    formdata.append('email', formValues.email);
    formdata.append('licenseNumber', formValues.licenseNumber);
    formdata.append('gender', formValues.gender);
    formdata.append('phone', formValues.phone);
    formdata.append('dob', formValues.dob);
    formdata.append('password', formValues.password);
    formdata.append('deviceType', formValues.deviceType);
    formdata.append('deviceToken', formValues.deviceToken);
    formdata.append('city', formValues.city);
    formdata.append('state', formValues?.state);
    formdata.append('country', formValues?.country);
    if (formValues.profileimage)
      formdata.append('profileimage', formValues.profileimage);
    if (formValues.frontimage)
      formdata.append('frontimage', formValues.frontimage);
    if (formValues.backimage)
      formdata.append('backimage', formValues.backimage);

    const response = yield axios.post('/promoter/updateDriver', formdata);
    if (response.status === 200) {
      yield put(editDriverSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/promoters/drivers',
          message: capitalize(response.data.message) || 'Success',
        }),
      );
      yield put(getAllDriversSagaAction(tableFilter));
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(editDriverFail('Something went wrong! Please try again.'));
    }
  } catch (error) {

    yield put(
      showModal({
        open: true,
        notifyType: 1,
        // redirectURL: '/promoters/drivers',
        message: capitalize(error.response.data.message) || 'Error',
      }),
    );
    console.log(error.message);
  }
}

export function* driverApproveRejectSaga(action) {
  let defaultPath = action.payload.path || 'admin';
  yield put(driverApproveRejectStart());
  try {
    const response = yield axios.post(
      `/${defaultPath}/approveRejectDriver`,
      action.payload,
    );
    if (response.status === 200) {
      const { closeModel } = action.payload;
      yield put(driverApproveRejectSuccess());
      if (closeModel) {
        closeModel();
      }
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: !action.payload.path
            ? '/vendors/drivers'
            : '/promoters/drivers',
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(
        driverApproveRejectFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(driverApproveRejectFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(driverApproveRejectFail(error.response.data.message));
      } else {
        yield put(driverApproveRejectFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        driverApproveRejectFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Edit driver commission admin side
export function* editDriverCommissionSaga(action) {
  yield put(editDriverCommissionStart());
  const { setIsSubmitted } = action.payload;
  try {
    const response = yield axios.post(
      `/admin/updateDriverCommission`,
      action.payload,
    );
    if (response.status === 200) {
      const { closeModel, tableFilter } = action.payload;
      yield put(editDriverCommissionSuccess(action.payload));
      if (action.payload.isUpdated === true) {
        if (closeModel) {
          closeModel();
        }
        yield put(
          showModal({
            open: true,
            notifyType: 2,
            message: response.data.message || 'Success',
          }),
        );
        yield put(
          getAllDriversSagaAction({ route: 'driver', query: tableFilter }),
        );
      } else {
        yield put(
          driverApproveRejectSagaAction({
            id: action.payload.id,
            isVerified: true,
            isRejected: false,
            rejectReason: '',
            closeModel,
          }),
        );
      }
    } else {
      yield put(
        editDriverCommissionFail('Something went wrong! Please try again.'),
      );
      setIsSubmitted(false);
    }
  } catch (error) {
    setIsSubmitted(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editDriverCommissionFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editDriverCommissionFail(error.response.data.message));
      } else {
        yield put(editDriverCommissionFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editDriverCommissionFail('Something went wrong! Please try again.'),
      );
    }
  }
}
// Edit driver Service Assigned admin side
export function* editDriverServiceAssignedSaga(action) {
  yield put(editDriverServiceAssignedStart());
  console.log(action);
  try {
    const response = yield axios.post(`/admin/assignService`, action.payload);
    if (response.status === 200) {
      const { closeModel } = action.payload;
      action.payload.serviceName =
        response.data?.data?.serviceName || 'Refresh To See';
      yield put(editDriverServiceAssignedSuccess(action.payload));
      if (closeModel) {
        closeModel();
      }
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(
        editDriverServiceAssignedFail(
          'SomethinG went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editDriverServiceAssignedFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editDriverServiceAssignedFail(error.response.data.message));
      } else {
        yield put(
          editDriverServiceAssignedFail(
            'Server error! Please try again after sometime.',
          ),
        );
      }
    } else {
      yield put(
        editDriverServiceAssignedFail(
          'Something went wrong! Please try again later.',
        ),
      );
    }
  }
}

// Add Owner from admin in driver list
export function* addOwnerInDriverSaga(action) {
  yield put(addVehicleOwnerStart());
  try {
    const { redirectUrl, formValues } = action.payload;
    const response = yield axios.post(`promoter/addVehicleOwner`, formValues);
    if (response.status === 200) {
      yield put(addVehicleOwnerSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: redirectUrl,
          message: capitalize(response.data.message) || 'Success',
        }),
      );
    } else {
      yield put(addVehicleOwnerFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addVehicleOwnerFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addVehicleOwnerFail(error.response.data.message));
      } else {
        yield put(addVehicleOwnerFail('Server error! Please try again.'));
      }
    } else {
      yield put(addVehicleOwnerFail('Something went wrong! Please try again.'));
    }
  }
}

// eslint-disable import/prefer-default-export
export function* getAvailableDriverToAssignVehicleSaga(action) {
  yield put(getAllDriverStart());
  try {
    const { query } = action.payload;
    const response = yield axios.get(
      urlPaginationGenerator(
        `/promoter/getAvailableDriverToAssignVehicle`,
        query,
      ),
    );
    console.log(response)
    if (response.status === 200) {
      yield put(getAllDriverSuccess({ drivers: response.data.data.data }));
    } else {
      yield put(getAllDriverFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllDriverFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllDriverFail(error.response.data.message));
      } else {
        yield put(getAllDriverFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllDriverFail('Something went wrong! Please try again.'));
    }
  }
}

export function* assignVehicleToADriverSaga(action) {
  yield put(addDriverStart());
  try {
    const { vehicleId, driverId, closeModel } = action.payload;
    console.log(driverId)
    const response = yield axios.post('/promoter/assignVehicleToADriver', {
      driverId,
      vehicleId,
    });
    if (response.status === 200) {
      if (closeModel) {
        closeModel();
        window.location.reload()
      }
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: `/promoters/vehicles`,
          message: capitalize(response.data.message) || 'Success',
        
       
        }),
      );
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(addDriverFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addDriverFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addDriverFail(error.response.data.message));
      } else {
        yield put(addDriverFail('Server error! Please try again.'));
      }
    } else {
      yield put(addDriverFail('Something went wrong! Please try again.'));
    }
  }
}
export function* promoterOwnernsVehicleSaga(action) {
  yield put(promoterOwnersVehicleStart());
  console.log(action.payload.query);
  const { query, apiEndPoint } = action.payload;
  const api = apiEndPoint || 'getalldriver'
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`promoter/${api}`, query),
    );
    if (response.status === 200) {
      yield put(promoterOwnersVehicleSuccess(response.data));
    } else {
      yield put(promoterOwnersVehicleFail('Something went wrong! Please try again.'));
    }
    // console.log(response.data.data);
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(promoterOwnersVehicleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(promoterOwnersVehicleFail(error.response.data.message));
      } else {
        yield put(promoterOwnersVehicleFail('Server error! Please try again.'));
      }
    } else {
      yield put(promoterOwnersVehicleFail('Something went wrong! Please try again.'));
    }
  }
}
