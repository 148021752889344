import {
  GET_ALL_CHEF_REVIEW_START,
  GET_ALL_CHEF_REVIEW_SAGA,
  GET_ALL_CHEF_REVIEW_SUCCESS,
  GET_ALL_CHEF_REVIEW_FAIL,
  GET_REVIEW_BY_ID_START,
  GET_REVIEW_BY_ID_SAGA,
  GET_REVIEW_BY_ID_SUCCESS,
  GET_REVIEW_BY_ID_FAIL,
  GET_ALL_LOGISTICS_RATING_REVIEW_START,
  GET_ALL_LOGISTICS_RATING_REVIEW_SAGA,
  GET_ALL_LOGISTICS_RATING_REVIEW_SUCCESS,
  GET_ALL_LOGISTICS_RATING_REVIEW_FAIL,
  GET_ALL_RATING_REVIEW_BY_CHEF_ID_START,
  GET_ALL_RATING_REVIEW_BY_CHEF_ID_SAGA,
  GET_ALL_RATING_REVIEW_BY_CHEF_ID_SUCCESS,
  GET_ALL_RATING_REVIEW_BY_CHEF_ID_FAIL,
} from '../actionLabels';

export const getAllChefReviewStart = payload => ({
  type: GET_ALL_CHEF_REVIEW_START,
  payload,
});

export const getAllChefReviewSaga = payload => ({
  type: GET_ALL_CHEF_REVIEW_SAGA,
  payload,
});

export const getAllChefReviewSuccess = payload => ({
  type: GET_ALL_CHEF_REVIEW_SUCCESS,
  payload,
});

export const getAllChefReviewFail = payload => ({
  type: GET_ALL_CHEF_REVIEW_FAIL,
  payload,
});

export const getReviewByIdStart = payload => ({
  type: GET_REVIEW_BY_ID_START,
  payload,
});

export const getReviewByIdSaga = payload => ({
  type: GET_REVIEW_BY_ID_SAGA,
  payload,
});

export const getReviewByIdSuccess = payload => ({
  type: GET_REVIEW_BY_ID_SUCCESS,
  payload,
});

export const getReviewByIdFail = payload => ({
  type: GET_REVIEW_BY_ID_FAIL,
  payload,
});

export const getAllLogisticsRatingReviewStart = payload => ({
  type: GET_ALL_LOGISTICS_RATING_REVIEW_START,
  payload,
});

export const getAllLogisticsRatingReviewSaga = payload => ({
  type: GET_ALL_LOGISTICS_RATING_REVIEW_SAGA,
  payload,
});

export const getAllLogisticsRatingReviewSuccess = payload => ({
  type: GET_ALL_LOGISTICS_RATING_REVIEW_SUCCESS,
  payload,
});

export const getAllLogisticsRatingReviewFail = payload => ({
  type: GET_ALL_LOGISTICS_RATING_REVIEW_FAIL,
  payload,
});

export const getAllRatingReviewByChefIdStart = payload => ({
  type: GET_ALL_RATING_REVIEW_BY_CHEF_ID_START,
  payload,
});

export const getAllRatingReviewByChefIdSaga = payload => ({
  type: GET_ALL_RATING_REVIEW_BY_CHEF_ID_SAGA,
  payload,
});

export const getAllRatingReviewByChefIdSuccess = payload => ({
  type: GET_ALL_RATING_REVIEW_BY_CHEF_ID_SUCCESS,
  payload,
});

export const getAllRatingReviewByChefIdFail = payload => ({
  type: GET_ALL_RATING_REVIEW_BY_CHEF_ID_FAIL,
  payload,
});
