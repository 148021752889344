import {
  GET_ALL_OWNER_VEHICLES_START,
  GET_ALL_OWNER_VEHICLES_SAGA,
  GET_ALL_OWNER_VEHICLES_SUCCESS,
  GET_ALL_OWNER_VEHICLES_FAIL,
  ADD_OWNER_VEHICLE_START,
  ADD_OWNER_VEHICLE_SAGA,
  ADD_OWNER_VEHICLE_SUCCESS,
  ADD_OWNER_VEHICLE_FAIL,
  EDIT_BLOCK_OWNER_VEHICLE_STATUS_START,
  EDIT_BLOCK_OWNER_VEHICLE_STATUS_SAGA,
  EDIT_BLOCK_OWNER_VEHICLE_STATUS_SUCCESS,
  EDIT_BLOCK_OWNER_VEHICLE_STATUS_FAIL,
  GET_OWNER_VEHICLE_DETAIL_BY_ID_START,
  GET_OWNER_VEHICLE_DETAIL_BY_ID_SAGA,
  GET_OWNER_VEHICLE_DETAIL_BY_ID_SUCCESS,
  GET_OWNER_VEHICLE_DETAIL_BY_ID_FAIL,
  EDIT_OWNER_VEHICLE_START,
  EDIT_OWNER_VEHICLE_SAGA,
  EDIT_OWNER_VEHICLE_SUCCESS,
  EDIT_OWNER_VEHICLE_FAIL,
  RESET_OWNER_VEHICLE_DETAILS,
  DELETE_OWNER_VEHICLE_START,
  DELETE_OWNER_VEHICLE_SAGA,
  DELETE_OWNER_VEHICLE_SUCCESS,
  DELETE_OWNER_VEHICLE_FAIL,
  GET_VEHICLE_LIST_BY_OWNER_ID_SAGA,
  RESET_OWNER_VEHICLE_LIST,
  OWNER_VEHICLE_APPROVE_REJECT_START,
  OWNER_VEHICLE_APPROVE_REJECT_SAGA,
  OWNER_VEHICLE_APPROVE_REJECT_SUCCESS,
  OWNER_VEHICLE_APPROVE_REJECT_FAIL,
  OWNER_VEHICLE_DRIVER_ASSIGN_SAGA,
  OWNER_VEHICLE_DRIVER_ASSIGN_START,
  OWNER_VEHICLE_DRIVER_ASSIGN_SUCCESS,
  OWNER_VEHICLE_DRIVER_ASSIGN_FAIL,
  UNASSIGN_DRIVER_FROM_VEHICLE_START,
  UNASSIGN_DRIVER_FROM_VEHICLE_SAGA,
  UNASSIGN_DRIVER_FROM_VEHICLE_SUCCESS,
  UNASSIGN_DRIVER_FROM_VEHICLE_FAIL,
  DELETE_DRIVER_FROM_VEHICLE_START,
  DELETE_DRIVER_FROM_VEHICLE_SAGA,
  DELETE_DRIVER_FROM_VEHICLE_SUCCESS,
  DELETE_DRIVER_FROM_VEHICLE_FAIL,
} from '../actionLabels';

export const resetOwnerVehicleDetail = () => ({
  type: RESET_OWNER_VEHICLE_DETAILS,
});

export const resetOwnerVehicleList = () => ({
  type: RESET_OWNER_VEHICLE_LIST,
});
export const getAllOwnerVehiclesStart = () => ({
  type: GET_ALL_OWNER_VEHICLES_START,
});

export const getAllOwnerVehiclesSaga = payload => ({
  type: GET_ALL_OWNER_VEHICLES_SAGA,
  payload,
});

export const getAllOwnerVehiclesSuccess = payload => ({
  type: GET_ALL_OWNER_VEHICLES_SUCCESS,
  payload,
});

export const getAllOwnerVehiclesFail = payload => ({
  type: GET_ALL_OWNER_VEHICLES_FAIL,
  payload,
});

// Get Vehicle Details Start
export const getOwnerVehicleDetailByIdStart = () => ({
  type: GET_OWNER_VEHICLE_DETAIL_BY_ID_START,
});

export const getOwnerVehicleDetailByIdSaga = payload => ({
  type: GET_OWNER_VEHICLE_DETAIL_BY_ID_SAGA,
  payload,
});

export const getOwnerVehicleDetailByIdSuccess = payload => ({
  type: GET_OWNER_VEHICLE_DETAIL_BY_ID_SUCCESS,
  payload,
});

export const getOwnerVehicleDetailByIdFail = payload => ({
  type: GET_OWNER_VEHICLE_DETAIL_BY_ID_FAIL,
  payload,
});
// Get Vehicle Details End

// Add Vehicles Start
export const addOwnerVehicleStart = () => ({
  type: ADD_OWNER_VEHICLE_START,
});

export const addOwnerVehicleSaga = payload => ({
  type: ADD_OWNER_VEHICLE_SAGA,
  payload,
});

export const addOwnerVehicleSuccess = payload => ({
  type: ADD_OWNER_VEHICLE_SUCCESS,
  payload,
});

export const addOwnerVehicleFail = payload => ({
  type: ADD_OWNER_VEHICLE_FAIL,
  payload,
});
// Add Vehicles End

// Edit Vehicles Start
export const editOwnerVehicleStart = () => ({
  type: EDIT_OWNER_VEHICLE_START,
});

export const editOwnerVehicleSaga = payload => ({
  type: EDIT_OWNER_VEHICLE_SAGA,
  payload,
});

export const editOwnerVehicleSuccess = payload => ({
  type: EDIT_OWNER_VEHICLE_SUCCESS,
  payload,
});

export const editOwnerVehicleFail = payload => ({
  type: EDIT_OWNER_VEHICLE_FAIL,
  payload,
});
// Edit Vehicles End

// Block Vehicle status Start
export const editBlockOwnerVehicleStatusStart = () => ({
  type: EDIT_BLOCK_OWNER_VEHICLE_STATUS_START,
});

export const editBlockOwnerVehicleStatusSaga = payload => ({
  type: EDIT_BLOCK_OWNER_VEHICLE_STATUS_SAGA,
  payload,
});

export const editBlockOwnerVehicleStatusSuccess = payload => ({
  type: EDIT_BLOCK_OWNER_VEHICLE_STATUS_SUCCESS,
  payload,
});

export const editBlockOwnerVehicleStatusFail = payload => ({
  type: EDIT_BLOCK_OWNER_VEHICLE_STATUS_FAIL,
  payload,
});
// Block Vehicle status End

// Delete Owner Vehicle Start
export const deleteOwnerVehicleStart = () => ({
  type: DELETE_OWNER_VEHICLE_START,
});

export const deleteOwnerVehicleSaga = payload => ({
  type: DELETE_OWNER_VEHICLE_SAGA,
  payload,
});

export const deleteOwnerVehicleSuccess = payload => ({
  type: DELETE_OWNER_VEHICLE_SUCCESS,
  payload,
});

export const deleteOwnerVehicleFail = payload => ({
  type: DELETE_OWNER_VEHICLE_FAIL,
  payload,
});
// Delete Owner Vehicle ENd

// Get owner vehicle list by owner id for admin/promoter side
export const getVehicleListByOwnerIdSaga = payload => ({
  type: GET_VEHICLE_LIST_BY_OWNER_ID_SAGA,
  payload,
});

// accept-reject vehicle

export const OwnerVehicleApproveRejectSaga = payload => ({
  type: OWNER_VEHICLE_APPROVE_REJECT_SAGA,
  payload,
});

export const OwnerVehicleApproveRejectStart = payload => ({
  type: OWNER_VEHICLE_APPROVE_REJECT_START,
  payload,
});


export const OwnerVehicleApproveRejectSuccess = payload => ({
  type: OWNER_VEHICLE_APPROVE_REJECT_SUCCESS,
  payload,
});

export const OwnerVehicleApproveRejectFail = payload => ({
  type: OWNER_VEHICLE_APPROVE_REJECT_FAIL,
  payload,
});

// owner vehicle driver assign
export const OwnerVehicleDriverAssignSaga = payload => ({
  type: OWNER_VEHICLE_DRIVER_ASSIGN_SAGA,
  payload,
});

export const OwnerVehicleDriverAssignStart = payload => ({
  type: OWNER_VEHICLE_DRIVER_ASSIGN_START,
  payload,
});


export const OwnerVehicleDriverAssignSuccess = payload => ({
  type: OWNER_VEHICLE_DRIVER_ASSIGN_SUCCESS,
  payload,
});

export const OwnerVehicleDriverAssignFail = payload => ({
  type: OWNER_VEHICLE_DRIVER_ASSIGN_FAIL,
  payload,
});

// unassign driver from assignvehicle

export const unassignDriverFromVehicleStart = payload => ({
  type: UNASSIGN_DRIVER_FROM_VEHICLE_START,
  payload,
});

export const unassignDriverFromVehicleSaga = payload => ({
  type: UNASSIGN_DRIVER_FROM_VEHICLE_SAGA,
  payload,
});

export const unassignDriverFromVehicleSuccess = payload => ({
  type: UNASSIGN_DRIVER_FROM_VEHICLE_SUCCESS,
  payload,
});

export const unassignDriverFromVehicleFail = payload => ({
  type: UNASSIGN_DRIVER_FROM_VEHICLE_FAIL,
  payload,
});

// delete driver from assignvehicle

export const deleteDriverFromVehicleStart = payload => ({
  type: DELETE_DRIVER_FROM_VEHICLE_START,
  payload,
});

export const deleteDriverFromVehicleSaga = payload => ({
  type: DELETE_DRIVER_FROM_VEHICLE_SAGA,
  payload,
});

export const deleteDriverFromVehicleSuccess = payload => ({
  type: DELETE_DRIVER_FROM_VEHICLE_SUCCESS,
  payload,
});

export const deleteDriverFromVehicleFail = payload => ({
  type: DELETE_DRIVER_FROM_VEHICLE_FAIL,
  payload,
});
