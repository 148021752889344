import {
  ADD_ACCESS_MODULE_FAIL,
  ADD_ACCESS_MODULE_SAGA,
  ADD_ACCESS_MODULE_START,
  ADD_ACCESS_MODULE_SUCCESS,
  EDIT_ACCESS_MODULE_FAIL,
  EDIT_ACCESS_MODULE_SAGA,
  EDIT_ACCESS_MODULE_START,
  EDIT_ACCESS_MODULE_SUCCESS,
  GET_ALL_ACCESS_MODULE_FAIL,
  GET_ALL_ACCESS_MODULE_SAGA,
  GET_ALL_ACCESS_MODULE_START,
  GET_ALL_ACCESS_MODULE_SUCCESS,
  GET_ACCESS_MODULE_BY_ID_FAIL,
  GET_ACCESS_MODULE_BY_ID_SAGA,
  GET_ACCESS_MODULE_BY_ID_START,
  GET_ACCESS_MODULE_BY_ID_SUCCESS,
  EDIT_BLOCK_ACCESS_MODULE_STATUS_SAGA,
  EDIT_BLOCK_ACCESS_MODULE_STATUS_START,
  EDIT_BLOCK_ACCESS_MODULE_STATUS_SUCCESS,
  EDIT_BLOCK_ACCESS_MODULE_STATUS_FAIL,
  DELETE_ACCESS_MODULE_START,
  DELETE_ACCESS_MODULE_SAGA,
  DELETE_ACCESS_MODULE_SUCCESS,
  DELETE_ACCESS_MODULE_FAIL,
  RESET_ALL_ACCESS_MODULES,
} from '../actionLabels';

// RESET A:: ACCESS Modules
export const resetAllAccessModules = () => ({
  type: RESET_ALL_ACCESS_MODULES,
});

// GET ALL ACCESS_MODULES START
export const getAllAccessModuleStart = () => ({
  type: GET_ALL_ACCESS_MODULE_START,
});

export const getAllAccessModuleSaga = payload => ({
  type: GET_ALL_ACCESS_MODULE_SAGA,
  payload,
});

export const getAllAccessModuleSuccess = payload => ({
  type: GET_ALL_ACCESS_MODULE_SUCCESS,
  payload,
});

export const getAllAccessModuleFail = payload => ({
  type: GET_ALL_ACCESS_MODULE_FAIL,
  payload,
});
// GET ALL ACCESS_MODULES END

// GET ACCESS_MODULES By Id Start
export const getAccessModuleByIdStart = () => ({
  type: GET_ACCESS_MODULE_BY_ID_START,
});

export const getAccessModuleByIdSaga = payload => ({
  type: GET_ACCESS_MODULE_BY_ID_SAGA,
  payload,
});

export const getAccessModuleByIdSuccess = payload => ({
  type: GET_ACCESS_MODULE_BY_ID_SUCCESS,
  payload,
});

export const getAccessModuleByIdFail = payload => ({
  type: GET_ACCESS_MODULE_BY_ID_FAIL,
  payload,
});
// GET ACCESS_MODULES By Id END

// add ACCESS_MODULE start

export const addAccessModuleStart = () => ({
  type: ADD_ACCESS_MODULE_START,
});

export const addAccessModuleSaga = payload => ({
  type: ADD_ACCESS_MODULE_SAGA,
  payload,
});

export const addAccessModuleSuccess = payload => ({
  type: ADD_ACCESS_MODULE_SUCCESS,
  payload,
});

export const addAccessModuleFail = payload => ({
  type: ADD_ACCESS_MODULE_FAIL,
  payload,
});
// add ACCESS_MODULE end

// edit ACCESS_MODULE start
export const editAccessModuleStart = () => ({
  type: EDIT_ACCESS_MODULE_START,
});

export const editAccessModuleSaga = payload => ({
  type: EDIT_ACCESS_MODULE_SAGA,
  payload,
});

export const editAccessModuleSuccess = payload => ({
  type: EDIT_ACCESS_MODULE_SUCCESS,
  payload,
});

export const editAccessModuleFail = payload => ({
  type: EDIT_ACCESS_MODULE_FAIL,
  payload,
});
// edit ACCESS_MODULE end

// Block ACCESS_MODULE status Start
export const editBlockAccessModuleStatusStart = () => ({
  type: EDIT_BLOCK_ACCESS_MODULE_STATUS_START,
});

export const editBlockAccessModuleStatusSaga = payload => ({
  type: EDIT_BLOCK_ACCESS_MODULE_STATUS_SAGA,
  payload,
});

export const editBlockAccessModuleStatusSuccess = payload => ({
  type: EDIT_BLOCK_ACCESS_MODULE_STATUS_SUCCESS,
  payload,
});

export const editBlockAccessModuleStatusFail = payload => ({
  type: EDIT_BLOCK_ACCESS_MODULE_STATUS_FAIL,
  payload,
});
// Block ACCESS_MODULE status End

// delete ACCESS_MODULE start
export const deleteAccessModuleStart = () => ({
  type: DELETE_ACCESS_MODULE_START,
});

export const deleteAccessModuleSaga = payload => ({
  type: DELETE_ACCESS_MODULE_SAGA,
  payload,
});

export const deleteAccessModuleSuccess = payload => ({
  type: DELETE_ACCESS_MODULE_SUCCESS,
  payload,
});

export const deleteAccessModuleFail = payload => ({
  type: DELETE_ACCESS_MODULE_FAIL,
  payload,
});
// delete ACCESS_MODULE end
