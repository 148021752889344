import * as actionLabels from '../actions/actionLabels';

const intialState = {
  currentTab: '',
};

export default (state = intialState, { type, payload }) => {
  switch (type) {
    case actionLabels.CHANGE_CURRENT_TAB:
      return {
        ...state,
        currentTab: payload,
      };

    case actionLabels.RESET_CURRENT_TAB:
      return {
        ...state,
        currentTab: '',
      };
    default:
      return state;
  }
};
