import { put } from 'redux-saga/effects';
import { capitalize } from '../../../utils/capitalize';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';
import {
  addStoreTypeStart,
  addStoreTypeSuccess,
  addStoreTypeFail,
  getAllStoreTypesSaga as getAllStoreTypesSagaAction,
  getAllStoreTypesFail,
  getAllStoreTypesStart,
  getAllStoreTypesSuccess,
  logout,
  showModal,
  editStoreTypeStart,
  editStoreTypeSuccess,
  editStoreTypeFail,
  editStoreTypeStatusStart,
  editStoreTypeStatusSuccess,
  editStoreTypeStatusFail,
  deleteStoreTypeStart,
  deleteStoreTypeSuccess,
  deleteStoreTypeFail,
} from '../../actions';

export function* getAllStoreTypesSaga(action) {
  yield put(getAllStoreTypesStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllStoreTypeList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllStoreTypesSuccess({ storeTypes: response.data.data }));
    } else {
      yield put(
        getAllStoreTypesFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllStoreTypesFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllStoreTypesFail(error.response.data.message));
      } else {
        yield put(getAllStoreTypesFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllStoreTypesFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// add Store Type
export function* addStoreTypeSaga(action) {
  const { setIsDisable } = action.payload;
  yield put(addStoreTypeStart());
  try {
    const { closeModel, tableFilter } = action.payload;
    const formdata = new FormData();
    formdata.append('name', action.payload.name);
    formdata.append('file', action.payload.file);
    const response = yield axios.post(`/admin/addStoreType`, formdata);
    if (response.status === 200) {
      yield put(addStoreTypeSuccess(response.data.data));
      setIsDisable(false);
      closeModel();
      yield put(getAllStoreTypesSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: capitalize(response.data.message) || 'Success',
        }),
      );
    } else {
      yield put(addStoreTypeFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addStoreTypeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addStoreTypeFail(error.response.data.message));
      } else {
        yield put(addStoreTypeFail('Server error! Please try again.'));
      }
    } else {
      yield put(addStoreTypeFail('Something went wrong! Please try again.'));
    }
  }
}

// Edit Store Type
export function* editStoreTypeSaga(action) {
  yield put(editStoreTypeStart());
  try {
    const formdata = new FormData();
    const { closeModel, setIsDisable, tableFilter } = action.payload;
    formdata.append('id', action.payload.id);
    formdata.append('name', action.payload.name);
    formdata.append('file', action.payload.file);

    const response = yield axios.post(`/admin/editStoreType`, formdata);
    if (response.status === 200) {
      yield put(editStoreTypeSuccess());
      setIsDisable(false);
      closeModel();
      yield put(getAllStoreTypesSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(editStoreTypeFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editStoreTypeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editStoreTypeFail(error.response.data.message));
      } else {
        yield put(editStoreTypeFail('Server error! Please try again.'));
      }
    } else {
      yield put(editStoreTypeFail('Something went wrong! Please try again.'));
    }
  }
}

// change status
export function* editStoreTypeStatusSaga(action) {
  yield put(editStoreTypeStatusStart());
  try {
    const response = yield axios.post(
      `/admin/updateStoreTypeStatus`,
      action.payload,
    );

    if (response.status === 200) {
      yield put(editStoreTypeStatusSuccess(action.payload));
    } else {
      yield put(
        editStoreTypeStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editStoreTypeStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editStoreTypeStatusFail(error.response.data.message));
      } else {
        yield put(editStoreTypeStatusFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editStoreTypeStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Delete StoreType Start
export function* deleteStoreTypeSaga(action) {
  yield put(deleteStoreTypeStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    const response = yield axios.post(`/admin/deleteStoreType`, { id });

    if (response.status === 200) {
      yield put(deleteStoreTypeSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllStoreTypesSagaAction(tableFilter));
    } else {
      yield put(deleteStoreTypeFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 1,
          message: error.response.data.message || 'Error',
        }),
      );
      if (error.response.status === 400) {
        yield put(deleteStoreTypeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteStoreTypeFail(error.response.data.message));
      } else {
        yield put(deleteStoreTypeFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteStoreTypeFail('Something went wrong! Please try again.'));
    }
  }
}
