export const GET_ALL_NOTIFICATION_START = 'GET_ALL_NOTIFICATION_START';
export const GET_ALL_NOTIFICATION_SAGA = 'GET_ALL_NOTIFICATION_SAGA';
export const GET_ALL_NOTIFICATION_SUCCESS = 'GET_ALL_NOTIFICATION_SUCCESS';
export const GET_ALL_NOTIFICATION_FAIL = 'GET_ALL_NOTIFICATION_FAIL';

export const EDIT_MARK_AS_READ_START = 'EDIT_MARK_AS_READ_START';
export const EDIT_MARK_AS_READ_SAGA = 'EDIT_MARK_AS_READ_SAGA';
export const EDIT_MARK_AS_READ_SUCCESS = 'EDIT_MARK_AS_READ_SUCCESS';
export const EDIT_MARK_AS_READ_FAIL = 'EDIT_MARK_AS_READ_FAIL';

export const RESET_NOTIFICATION_LIST = 'RESET_NOTIFICATION_LIST';

export const SEND_NOTIFICATION_TO_VENDOR_START = 'SEND_NOTIFICATION_TO_VENDOR_START';
export const SEND_NOTIFICATION_TO_VENDOR_SAGA = 'SEND_NOTIFICATION_TO_VENDOR_SAGA';
export const SEND_NOTIFICATION_TO_VENDOR_SUCCESS = 'SEND_NOTIFICATION_TO_VENDOR_SUCCESS';
export const SEND_NOTIFICATION_TO_VENDOR_FAIL = 'SEND_NOTIFICATION_TO_VENDOR_FAIL';