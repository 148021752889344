import { put } from 'redux-saga/effects';
import {
  getAllPromocodeStart,
  getAllPromocodeSuccess,
  getAllPromocodeFail,
  addPromocodeStart,
  addPromocodeSuccess,
  addPromocodeFail,
  editPromocodeStatusStart,
  editPromocodeStatusSuccess,
  editPromocodeStatusFail,
  logout,
  getAllRestaurantStart,
  getAllRestaurantSuccess,
  getAllRestaurantFail,
  getPromocodeDetailByIdStart,
  getPromocodeDetailByIdSuccess,
  getPromocodeDetailByIdFail,
  updatePromocodeStart,
  updatePromocodeSuccess,
  updatePromocodeFail,
  showModal,
  deletePromocodeByIdStart,
  deletePromocodeByIdSuccess,
  deletePromocodeByIdFail,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllPromocodeSaga(action) {
  yield put(getAllPromocodeStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllPromocodeList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllPromocodeSuccess({ promocodes: response.data.data }));
    } else {
      yield put(getAllPromocodeFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllPromocodeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllPromocodeFail(error.response.data.message));
      } else {
        yield put(getAllPromocodeFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllPromocodeFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getPromocodeDetailByIdSaga(action) {
  yield put(getPromocodeDetailByIdStart());
  try {
    const response = yield axios.get(
      `/admin/getPromocodeDetailById?id=${action.payload.id}`,
    );
    if (response.status === 200) {
      yield put(
        getPromocodeDetailByIdSuccess({ promocodeDetail: response.data.data }),
      );
    } else {
      yield put(
        getPromocodeDetailByIdFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getPromocodeDetailByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getPromocodeDetailByIdFail(error.response.data.message));
      } else {
        yield put(
          getPromocodeDetailByIdFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getPromocodeDetailByIdFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* addPromocodeSaga(action) {
  yield put(addPromocodeStart());
  const { setIsDisable, requestBody } = action.payload;
  try {
    const formdata = new FormData();
    if (!requestBody.isFlat) {
      formdata.append('percentage', requestBody.percentage);
    }
    if (!requestBody.forAllChef) {
      formdata.append('chefIds', requestBody.chefIds);
    }
    formdata.append('forAllChef', requestBody.forAllChef);
    formdata.append('isFlat', requestBody.isFlat);
    formdata.append('minimumCartValue', requestBody.minimumCartValue);
    formdata.append('maxDiscount', requestBody.maxDiscount);
    formdata.append('code', requestBody.code);
    formdata.append('status', requestBody.status);
    formdata.append('photo', requestBody.photo);
    formdata.append('startDate', requestBody.startDate);
    formdata.append('endDate', requestBody.endDate);

    const response = yield axios.post(`/admin/addPromocode`, formdata);
    if (response.status === 200) {
      yield put(addPromocodeSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vendors/promocodes',
          message: response.data.message || 'Success',
        }),
      );
      setIsDisable(false);
    } else {
      yield put(addPromocodeFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addPromocodeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addPromocodeFail(error.response.data.message));
      } else {
        yield put(addPromocodeFail('Server error! Please try again.'));
      }
    } else {
      yield put(addPromocodeFail('Something went wrong! Please try again.'));
    }
  }
}

export function* updatePromocodeSaga(action) {
  yield put(updatePromocodeStart());
  const { setIsDisable, requestBody } = action.payload;
  try {
    const formdata = new FormData();
    if (!requestBody.isFlat) {
      formdata.append('percentage', requestBody.percentage);
    }
    if (!requestBody.forAllChef) {
      formdata.append('chefIds', requestBody.chefIds);
    }
    if (requestBody.photo) {
      formdata.append('photo', requestBody.photo);
    }
    formdata.append('forAllChef', requestBody.forAllChef);
    formdata.append('isFlat', requestBody.isFlat);
    formdata.append('minimumCartValue', requestBody.minimumCartValue);
    formdata.append('maxDiscount', requestBody.maxDiscount);
    formdata.append('code', requestBody.code);
    formdata.append('status', requestBody.status);
    formdata.append('startDate', requestBody.startDate);
    formdata.append('endDate', requestBody.endDate);
    formdata.append('id', requestBody.id);

    if (requestBody.addedChefIds) {
      if (requestBody.addedChefIds.length > 0) {
        formdata.append('addedChefIds', requestBody.addedChefIds);
      }
    }

    if (requestBody.deletedChefIds) {
      if (requestBody.deletedChefIds.length > 0) {
        formdata.append('deletedChefIds', requestBody.deletedChefIds);
      }
    }

    const response = yield axios.post(`/admin/updatePromocode`, formdata);
    if (response.status === 200) {
      yield put(updatePromocodeSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vendors/promocodes',
          message: response.data.message || 'Success',
        }),
      );
      setIsDisable(false);
    } else {
      yield put(updatePromocodeFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(updatePromocodeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(updatePromocodeFail(error.response.data.message));
      } else {
        yield put(updatePromocodeFail('Server error! Please try again.'));
      }
    } else {
      yield put(updatePromocodeFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getAllRestaurantSaga() {
  yield put(getAllRestaurantStart());
  try {
    const response = yield axios.get('/admin/getAllRestaurantList');
    if (response.status === 200) {
      yield put(getAllRestaurantSuccess({ restaurants: response.data.data }));
    } else {
      yield put(
        getAllRestaurantFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllRestaurantFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllRestaurantFail(error.response.data.message));
      } else {
        yield put(getAllRestaurantFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllRestaurantFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* editPromocodeStatusSaga(action) {
  yield put(editPromocodeStatusStart());
  try {
    const response = yield axios.post(
      `/admin/updatePromocodeStatus`,
      action.payload,
    );
    if (response.status === 200) {
      yield put(editPromocodeStatusSuccess(action.payload));
    } else {
      yield put(
        editPromocodeStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editPromocodeStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editPromocodeStatusFail(error.response.data.message));
      } else {
        yield put(editPromocodeStatusFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editPromocodeStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}
export function* deletePromocodeByIdSaga(action) {
  yield put(deletePromocodeByIdStart());
  try {
    const { id, setModelDeleteOpen ,tableFilter} = action.payload;
    // console.log(action.payload);
    // To-DO change API address
    const response = yield axios.post(`/admin/deletePromoCode`, { id });
    if (response.status === 200) {
      yield put(deletePromocodeByIdSuccess(id));
      setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      // yield put(getAllPromocodeByIdsSagaAction(tableFilter));
    } else {
      yield put(deletePromocodeByIdFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      if (error.response.status === 400) {
        yield put(deletePromocodeByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deletePromocodeByIdFail(error.response.data.message));
      } else {
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: 'Server error! Please try again.',
          }),
        );
        yield put(deletePromocodeByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(deletePromocodeByIdFail('Something went wrong! Please try again.'));
    }
  }
}