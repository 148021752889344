export const GET_ALL_INQUIRIES_START = 'GET_ALL_INQUIRIES_START';
export const GET_ALL_INQUIRIES_SAGA = 'GET_ALL_INQUIRIES_SAGA';
export const GET_ALL_INQUIRIES_SUCCESS = 'GET_ALL_INQUIRIES_SUCCESS';
export const GET_ALL_INQUIRIES_FAIL = 'GET_ALL_INQUIRIES_FAIL';

export const EDIT_MARK_AS_APPROVED_START = 'EDIT_MARK_AS_APPROVED_START';
export const EDIT_MARK_AS_APPROVED_SAGA = 'EDIT_MARK_AS_APPROVED_SAGA';
export const EDIT_MARK_AS_APPROVED_SUCCESS = 'EDIT_MARK_AS_APPROVED_SUCCESS';
export const EDIT_MARK_AS_APPROVED_FAIL = 'EDIT_MARK_AS_APPROVED_FAIL';

export const EDIT_MARK_AS_REJECTED_START = 'EDIT_MARK_AS_REJECTED_START';
export const EDIT_MARK_AS_REJECTED_SAGA = 'EDIT_MARK_AS_REJECTED_SAGA';
export const EDIT_MARK_AS_REJECTED_SUCCESS = 'EDIT_MARK_AS_REJECTED_SUCCESS';
export const EDIT_MARK_AS_REJECTED_FAIL = 'EDIT_MARK_AS_REJECTED_FAIL';

export const RESET_INQUIRY_LIST = 'RESET_INQUIRY_LIST';
