import * as actionLabels from '../../actions/actionLabels';

const intialState = {
  promoters: { list: [], totalRecords: { count: 0 } },
  promoter: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = intialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_PROMOTERS_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_PROMOTERS_SUCCESS: {
      const { promoters } = payload;
      console.log('promoter reducer data', promoters);
      return { ...state, promoters, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_PROMOTERS_FAIL:
      return {
        ...state,
        promoters: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };

    // Get Promoter By Id
    case actionLabels.GET_PROMOTER_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_PROMOTER_BY_ID_SUCCESS: {
      const { promoter } = payload;
      return { ...state, promoter, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_PROMOTER_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Reset Promoter Detail
    case actionLabels.RESET_PROMOTER_DETAIL:
      return { ...state, isLoading: false, promoter: {}, errorMsg: '' };
    // Add PRomoter
    case actionLabels.ADD_PROMOTER_START:
      return { ...state, isLoading: true };
    // case actionLabels.ADD_PROMOTER_SUCCESS: {
    //   const { promtoers } = state;
    //   promtoers.list.unshift(payload);
    //   promtoers.totalRecords.count += 1;
    //   return { ...state, promtoers, isLoading: false, errorMsg: '' };
    // }
    case actionLabels.ADD_PROMOTER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Edit Promoter Details
    case actionLabels.EDIT_PROMOTER_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_PROMOTER_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.EDIT_PROMOTER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Delete Promoter
    case actionLabels.DELETE_PROMOTER_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_PROMOTER_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.DELETE_PROMOTER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Block Unblock Status
    case actionLabels.EDIT_BLOCK_PROMOTER_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BLOCK_PROMOTER_STATUS_SUCCESS: {
      if (payload.isDetail !== true) {
        const { promoters } = state;
        const index = promoters.list.findIndex(item => item._id === payload.id);
        if (index !== -1 && promoters.list.length > index) {
          promoters.list[index].isBlock = !promoters.list[index].isBlock;
        }
        return { ...state, promoters, isLoading: false, errorMsg: '' };
      }
      const { promoter } = state;
      promoter.isBlock = !promoter.isBlock;
      return { ...state, promoter, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_BLOCK_PROMOTER_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // add vendor from promoter
    case actionLabels.ADD_VENDOR_OF_PROMOTER_START:
      return { ...state, isLoading: true };

    case actionLabels.ADD_VENDOR_OF_PROMOTER_SUCCESS:
      return { ...state, isLoading: false, errorMsg: '' };

    case actionLabels.ADD_VENDOR_OF_PROMOTER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
   
    case 'CLEAR_PROMOTER_ADD_VENDOR_ERROR_MSG':
      return { ...state, isLoading: false, errorMsg: '' };
    default:
      return state;
  }
};
