import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  chefs: { list: [], totalRecords: { count: 0 } },
  chef: {},
  brandTypes: {},
  chefBrandTypes: {},
  bankDetail: [],
  address: {},
  location: {
    latitude: 0,
    longitude: 0,
  },
  latitudeFromApi: 0,
  longitudeFromApi: 0,
  acceptOrders: null,
  isApproved: null,
  isRejected: null,
  isProfileSubmitted: null,
  isLoading: false,
  isMapLoading: false,
  errorMsg: '',
  errorBankAccountMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_CHEF_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_CHEF_SUCCESS: {
      const { chefs } = payload;
      return { ...state, chefs, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_CHEF_FAIL:
      return {
        ...state,
        chefs: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };

    // GET_ALL_VENDORS_OF_PROMOTER_SAGA
    case actionLabels.GET_ALL_VENDORS_OF_PROMOTER_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_VENDORS_OF_PROMOTER_SUCCESS: {
      const { chefs } = payload;
      return { ...state, chefs, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_VENDORS_OF_PROMOTER_FAIL:
      return {
        ...state,
        chefs: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.ASSIGN_TO_LOGISTIC_START:
      return { ...state, isLoading: true };
    case actionLabels.ASSIGN_TO_LOGISTIC_SUCCESS: {
      return { ...state, isLoading: false, errorMsg: '' };
    }
    case actionLabels.ASSIGN_TO_LOGISTIC_FAIL:
      return {
        ...state,
        isLoading: false,
        errorMsg: payload,
      };

    case actionLabels.GET_ALL_BRAND_TYPES_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_BRAND_TYPES_SUCCESS: {
      const { brandTypes } = payload;
      return { ...state, brandTypes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_BRAND_TYPES_FAIL:
      return {
        ...state,
        brandTypes: {},
        isLoading: false,
        errorMsg: payload,
      };

    // get chef brand types list
    case actionLabels.GET_CHEF_BRAND_TYPES_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_CHEF_BRAND_TYPES_SUCCESS: {
      const { chefBrandTypes } = payload;
      return { ...state, chefBrandTypes, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_CHEF_BRAND_TYPES_FAIL:
      return {
        ...state,
        chefBrandTypes: {},
        isLoading: false,
        errorMsg: payload,
      };

    case actionLabels.GET_CHEF_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_CHEF_BY_ID_SUCCESS: {
      const { chef } = payload;
      const bankDetail = chef.bankDetails;
      return {
        ...state,
        chef,
        bankDetail,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.GET_CHEF_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_BLOCK_CHEF_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BLOCK_CHEF_STATUS_SUCCESS: {
      console.log({ payload })
      if (payload.isDetail !== true) {
        console.log({ state })
        const { chefs } = state;
        const index = chefs.list.findIndex(item => item._id === payload.id);
        if (index !== -1 && chefs.list.length > index) {
          chefs.list[index].isBlock = !chefs.list[index].isBlock;
        }
        return { ...state, chefs, isLoading: false, errorMsg: '' };
      }
      const { chef } = state;
      chef.isBlock = !chef.isBlock;
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_BLOCK_CHEF_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_CHEF_FEATURE_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_CHEF_FEATURE_SUCCESS: {
      // if (payload.isDetail !== true) {
      const { chefs } = state;
      const index = chefs.list.findIndex(item => item._id === payload.id);
      if (index !== -1 && chefs.list.length > index) {
        chefs.list[index].isFeatured = !chefs.list[index].isFeatured;
      }
      return { ...state, chefs, isLoading: false, errorMsg: '' };
      // }
      // const { chef } = state;
      // chef.isFeatured = !chef.isFeatured;
      // return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_CHEF_FEATURE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Added By Shashank
    case actionLabels.EDIT_CHEF_COMMISSION_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_CHEF_COMMISSION_SUCCESS: {
      const { chefs } = state;
      const index = chefs.list.findIndex(item => item._id === payload.id);
      console.log(index);
      return { ...state };
    }

    case actionLabels.ADD_BANK_DETAIL_START:
      return { ...state, isLoading: true, errorBankAccountMsg: '' };
    case actionLabels.ADD_BANK_DETAIL_SUCCESS: {
      const accountsArray = [];
      const { bankDetail, isApproved } = state;
      if (isApproved) {
        bankDetail.push(payload);
        return {
          ...state,
          bankDetail,
          isLoading: false,
          errorMsg: '',
          errorBankAccountMsg: '',
        };
      } else {
        accountsArray.push(payload.value);
        return {
          ...state,
          bankDetail: accountsArray,
          isLoading: false,
          errorMsg: '',
        };
      }
    }
    case actionLabels.ADD_BANK_DETAIL_FAIL: {
      const { isApproved } = state;
      if (isApproved) {
        return { ...state, isLoading: false, errorBankAccountMsg: payload };
      } else {
        return { ...state, isLoading: false, errorMsg: payload };
      }
    }

    case actionLabels.EDIT_BANK_ACCOUNT_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BANK_ACCOUNT_SUCCESS: {
      const { bankDetail } = state;
      const index = bankDetail.findIndex(item => item.id === payload.id);
      if (index !== -1 && bankDetail.length > index) {
        bankDetail[index] = payload;
      }
      return {
        ...state,
        isLoading: false,
        bankDetail,
        errorBankAccountMsg: '',
        errorMsg: '',
      };
    }
    case actionLabels.EDIT_BANK_ACCOUNT_FAIL:
      return { ...state, isLoading: false, errorBankAccountMsg: payload };

    case actionLabels.EDIT_BANK_ACCOUNT_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BANK_ACCOUNT_STATUS_SUCCESS: {
      const { bankDetail } = state;
      let newBankDetail = [];
      const index = bankDetail.findIndex(item => item.id === payload.id);
      if (index !== -1 && bankDetail.length > index) {
        newBankDetail = bankDetail.map((item, i) => {
          if (i === index) {
            return { ...item, isActive_Account: true };
          }
          return { ...item, isActive_Account: false };
        });
      }
      return {
        ...state,
        isLoading: false,
        bankDetail: newBankDetail,
        errorBankAccountMsg: '',
        errorMsg: '',
      };
    }
    case actionLabels.EDIT_BANK_ACCOUNT_STATUS_FAIL:
      return { ...state, isLoading: false, errorBankAccountMsg: payload };

    case actionLabels.DELETE_BANK_ACCOUNT_START:
      return { ...state, isLoading: true, errorBankAccountMsg: '' };
    case actionLabels.DELETE_BANK_ACCOUNT_SUCCESS: {
      const { bankDetail } = state;
      const newBankDetail = bankDetail.filter(
        item => Number(item.id) !== Number(payload),
      );
      return {
        ...state,
        bankDetail: newBankDetail,
        isLoading: false,
        errorBankAccountMsg: '',
        errorMsg: '',
      };
    }
    case actionLabels.DELETE_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        isLoading: false,
        errorBankAccountMsg: payload,
      };

    case actionLabels.ADD_ADDRESS_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_ADDRESS_SUCCESS: {
      const { address } = payload;
      return { ...state, address, isLoading: false, errorMsg: '' };
    }
    case actionLabels.ADD_ADDRESS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GET_ACCEPTING_ORDER_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ACCEPTING_ORDER_SUCCESS: {
      const {
        isAcceptingOrders,
        isApproved,
        isRejected,
        isProfileSubmitted,
      } = payload;

      return {
        ...state,
        acceptOrders: isAcceptingOrders,
        isApproved,
        isRejected,
        isProfileSubmitted,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.GET_ACCEPTING_ORDER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_ACCEPTING_ORDER_CHEF_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_ACCEPTING_ORDER_CHEF_SUCCESS: {
      let { acceptOrders } = state;
      acceptOrders = !acceptOrders;
      return { ...state, acceptOrders, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_ACCEPTING_ORDER_CHEF_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_LOCATION_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_LOCATION_SUCCESS: {
      const { location } = state;
      location.latitude = payload.latitude;
      location.longitude = payload.longitude;
      return { ...state, location, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_LOCATION_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_CHEF_LIST:
      return {
        ...state,
        chefs: { list: [], totalRecords: { count: 0 } },
      };
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }
    case actionLabels.GET_CHEF_PROFILE_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_CHEF_PROFILE_SUCCESS: {
      const { chef } = payload;
      const bankDetail = chef.bankDetails;

      return {
        ...state,
        chef,
        bankDetail,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.GET_CHEF_PROFILE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GET_LAT_LONG_FROM_ADDRESS_START:
      return { ...state, isMapLoading: true };
    case actionLabels.GET_LAT_LONG_FROM_ADDRESS_SUCCESS: {
      const { lat, long } = payload;
      const latitudeFromApi = lat;
      const longitudeFromApi = long;
      return {
        ...state,
        latitudeFromApi,
        longitudeFromApi,
        isMapLoading: false,
      };
    }
    case actionLabels.RESET_LAT_LONG_FROM_ADDRESS:
      return {
        ...state,
        latitudeFromApi: '',
        longitudeFromApi: '',
        isLoading: false,
        errorMsg: '',
      };
    case actionLabels.EDIT_PERSONAL_DETAIL_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_PERSONAL_DETAIL_SUCCESS: {
      const chef = {
        ...state.chef,
        ...payload.values,
      };
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_PERSONAL_DETAIL_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_ORDER_SCHEDULE_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_ORDER_SCHEDULE_SUCCESS: {
      const chef = {
        ...state.chef,
        ...payload,
      };
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_ORDER_SCHEDULE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_RESTAURANT_DETAIL_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_RESTAURANT_DETAIL_SUCCESS: {
      const chef = {
        ...state.chef,
        ...payload,
        brandTypeIds: payload.brandType,
        lat: payload.latitude,
        long: payload.longitude,
      };
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_RESTAURANT_DETAIL_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_MEDIA_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_MEDIA_SUCCESS: {
      const { media } = payload;
      // media.splice(payload.index, 0, payload.media);
      const chef = {
        ...state.chef,
        media,
      };
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_MEDIA_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.DELETE_MEDIA_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_MEDIA_SUCCESS: {
      const { media } = state.chef;
      media.splice(payload.index, 1);
      const chef = {
        ...state.chef,
        media,
      };
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.DELETE_MEDIA_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_ID_PROOF_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_ID_PROOF_SUCCESS: {
      const { idProof } = payload;
      // idProof.splice(payload.index, 0, payload.idProof);
      const chef = {
        ...state.chef,
        idProof,
      };
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_ID_PROOF_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.DELETE_ID_PROOF_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_ID_PROOF_SUCCESS: {
      const { idProof } = state.chef;
      idProof.splice(payload.index, 1);
      const chef = {
        ...state.chef,
        idProof,
      };
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.DELETE_ID_PROOF_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_ACCEPTING_ORDER_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_ACCEPTING_ORDER_SUCCESS: {
      const { chef } = state;
      chef.isAcceptingOrders = !chef.isAcceptingOrders;
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_ACCEPTING_ORDER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.ENABLE_DISABLE_OTHER_VENDOR_START:
      return { ...state, isLoading: true };
    case actionLabels.ENABLE_DISABLE_OTHER_VENDOR_SUCCESS: {
      if (payload.isDetail !== true) {
        const { chefs } = state;
        const index = chefs.list.findIndex(item => item._id === payload.id);
        if (index !== -1 && chefs.list.length > index) {
          chefs.list[index].enableForAdvertisment = !chefs.list[index]
            .enableForAdvertisment;
        }
        return { ...state, chefs, isLoading: false, errorMsg: '' };
      }
      const { chef } = state;
      chef.enableForAdvertisment = !chef.enableForAdvertisment;
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.ENABLE_DISABLE_OTHER_VENDOR_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.RESET_CHEF_DETAIL:
      return {
        ...state,
        isLoading: false,
        chef: {},
        bankDetail: [],
        errorMsg: '',
      };
    // case actionLabels.RESET_CHEF_BRAND_TYPES:
    //   return {
    //     ...state,
    //     brandTypes: {},
    //   };
    default:
      return state;
  }
};
