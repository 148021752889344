import {
  GET_ALL_PROMOCODE_START,
  GET_ALL_PROMOCODE_SAGA,
  GET_ALL_PROMOCODE_SUCCESS,
  GET_ALL_PROMOCODE_FAIL,
  ADD_PROMOCODE_START,
  ADD_PROMOCODE_SAGA,
  ADD_PROMOCODE_SUCCESS,
  ADD_PROMOCODE_FAIL,
  EDIT_PROMOCODE_STATUS_START,
  EDIT_PROMOCODE_STATUS_SAGA,
  EDIT_PROMOCODE_STATUS_SUCCESS,
  EDIT_PROMOCODE_STATUS_FAIL,
  GET_ALL_RESTAURANT_START,
  GET_ALL_RESTAURANT_SAGA,
  GET_ALL_RESTAURANT_SUCCESS,
  GET_ALL_RESTAURANT_FAIL,
  GET_PROMOCODE_DETAIL_BY_ID_START,
  GET_PROMOCODE_DETAIL_BY_ID_SAGA,
  GET_PROMOCODE_DETAIL_BY_ID_SUCCESS,
  GET_PROMOCODE_DETAIL_BY_ID_FAIL,
  UPDATE_PROMOCODE_START,
  UPDATE_PROMOCODE_SAGA,
  UPDATE_PROMOCODE_SUCCESS,
  UPDATE_PROMOCODE_FAIL,
  RESET_PROMOCODE_DETAIL,
  DELETE_PROMOCODE_BY_ID_START,
  DELETE_PROMOCODE_BY_ID_SAGA,
  DELETE_PROMOCODE_BY_ID_SUCCESS,
  DELETE_PROMOCODE_BY_ID_FAIL,
} from '../actionLabels';

export const getAllPromocodeStart = payload => ({
  type: GET_ALL_PROMOCODE_START,
  payload,
});

export const getAllPromocodeSaga = payload => ({
  type: GET_ALL_PROMOCODE_SAGA,
  payload,
});

export const getAllPromocodeSuccess = payload => ({
  type: GET_ALL_PROMOCODE_SUCCESS,
  payload,
});

export const getAllPromocodeFail = payload => ({
  type: GET_ALL_PROMOCODE_FAIL,
  payload,
});

export const addPromocodeStart = payload => ({
  type: ADD_PROMOCODE_START,
  payload,
});

export const addPromocodeSaga = payload => ({
  type: ADD_PROMOCODE_SAGA,
  payload,
});

export const addPromocodeSuccess = payload => ({
  type: ADD_PROMOCODE_SUCCESS,
  payload,
});

export const addPromocodeFail = payload => ({
  type: ADD_PROMOCODE_FAIL,
  payload,
});

export const getPromocodeDetailByIdStart = payload => ({
  type: GET_PROMOCODE_DETAIL_BY_ID_START,
  payload,
});

export const getPromocodeDetailByIdSaga = payload => ({
  type: GET_PROMOCODE_DETAIL_BY_ID_SAGA,
  payload,
});

export const getPromocodeDetailByIdSuccess = payload => ({
  type: GET_PROMOCODE_DETAIL_BY_ID_SUCCESS,
  payload,
});

export const getPromocodeDetailByIdFail = payload => ({
  type: GET_PROMOCODE_DETAIL_BY_ID_FAIL,
  payload,
});

export const updatePromocodeStart = payload => ({
  type: UPDATE_PROMOCODE_START,
  payload,
});

export const updatePromocodeSaga = payload => ({
  type: UPDATE_PROMOCODE_SAGA,
  payload,
});

export const updatePromocodeSuccess = payload => ({
  type: UPDATE_PROMOCODE_SUCCESS,
  payload,
});

export const updatePromocodeFail = payload => ({
  type: UPDATE_PROMOCODE_FAIL,
  payload,
});

export const getAllRestaurantStart = payload => ({
  type: GET_ALL_RESTAURANT_START,
  payload,
});

export const getAllRestaurantSaga = payload => ({
  type: GET_ALL_RESTAURANT_SAGA,
  payload,
});

export const getAllRestaurantSuccess = payload => ({
  type: GET_ALL_RESTAURANT_SUCCESS,
  payload,
});

export const getAllRestaurantFail = payload => ({
  type: GET_ALL_RESTAURANT_FAIL,
  payload,
});

export const editPromocodeStatusStart = payload => ({
  type: EDIT_PROMOCODE_STATUS_START,
  payload,
});

export const editPromocodeStatusSaga = payload => ({
  type: EDIT_PROMOCODE_STATUS_SAGA,
  payload,
});

export const editPromocodeStatusSuccess = payload => ({
  type: EDIT_PROMOCODE_STATUS_SUCCESS,
  payload,
});

export const editPromocodeStatusFail = payload => ({
  type: EDIT_PROMOCODE_STATUS_FAIL,
  payload,
});

export const resetPromocodeDetail = payload => ({
  type: RESET_PROMOCODE_DETAIL,
  payload,
});


export const deletePromocodeByIdStart = payload => ({
  type: DELETE_PROMOCODE_BY_ID_START,
  payload,
});

export const deletePromocodeByIdSaga = payload => ({
  type: DELETE_PROMOCODE_BY_ID_SAGA,
  payload,
});

export const deletePromocodeByIdSuccess = payload => ({
  type: DELETE_PROMOCODE_BY_ID_SUCCESS,
  payload,
});

export const deletePromocodeByIdFail = payload => ({
  type: DELETE_PROMOCODE_BY_ID_FAIL,
  payload,
});