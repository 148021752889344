
// Get All LOGISTIC
export const GET_ALL_LOGISTIC_OF_PROMOTER_START =
  'GET_ALL_LOGISTIC_OF_PROMOTER_START';
export const GET_ALL_LOGISTIC_OF_PROMOTER_SAGA =
  'GET_ALL_LOGISTIC_OF_PROMOTER_SAGA';
export const GET_ALL_LOGISTIC_OF_PROMOTER_SUCCESS =
  'GET_ALL_LOGISTIC_OF_PROMOTER_SUCCESS';
export const GET_ALL_LOGISTIC_OF_PROMOTER_FAIL =
  'GET_ALL_LOGISTIC_OF_PROMOTER_FAIL';

// Add Logistic
export const ADD_LOGISTIC_OF_PROMOTER_START = 'ADD_LOGISTIC_OF_PROMOTER_START';
export const ADD_LOGISTIC_OF_PROMOTER_SAGA = 'ADD_LOGISTIC_OF_PROMOTER_SAGA';
export const ADD_LOGISTIC_OF_PROMOTER_SUCCESS = 'ADD_LOGISTIC_OF_PROMOTER_SUCCESS';
export const ADD_LOGISTIC_OF_PROMOTER_FAIL = 'ADD_LOGISTIC_OF_PROMOTER_FAIL';

// Delete Logistic
export const DELETE_LOGISTIC_OF_PROMOTER_START = 'DELETE_LOGISTIC_OF_PROMOTER_START';
export const DELETE_LOGISTIC_OF_PROMOTER_SAGA = 'DELETE_LOGISTIC_OF_PROMOTER_SAGA';
export const DELETE_LOGISTIC_OF_PROMOTER_SUCCESS = 'DELETE_LOGISTIC_OF_PROMOTER_SUCCESS';
export const DELETE_LOGISTIC_OF_PROMOTER_FAIL = 'DELETE_LOGISTIC_OF_PROMOTER_FAIL';

// Logistic Details
export const LOGISTICS_DETAILS_START = 'LOGISTICS_DETAILS_START';
export const LOGISTICS_DETAILS_SAGA = 'LOGISTICS_DETAILS_SAGA';
export const LOGISTICS_DETAILS_SUCCESS = 'LOGISTICS_DETAILS_SUCCESS';
export const LOGISTICS_DETAILS_FAIL = 'LOGISTICS_DETAILS_FAIL';


// Approve and Reject Logistic
export const APPROVE_REJECT_LOGISTICS_START = 'APPROVE_REJECT_LOGISTICS_START';
export const APPROVE_REJECT_LOGISTICS_SAGA = 'APPROVE_REJECT_LOGISTICS_SAGA';
export const APPROVE_REJECT_LOGISTICS_SUCCESS = 'APPROVE_REJECT_LOGISTICS_SUCCESS';
export const APPROVE_REJECT_LOGISTICS_FAIL = 'APPROVE_REJECT_LOGISTICS_FAIL';


// Get All support Request Logistic
export const GET_ALL_SUPPORT_REQUEST_LOGISTICS_START = 'GET_ALL_SUPPORT_REQUEST_LOGISTICS_START';
export const GET_ALL_SUPPORT_REQUEST_LOGISTICS_SAGA = 'GET_ALL_SUPPORT_REQUEST_LOGISTICS_SAGA';
export const GET_ALL_SUPPORT_REQUEST_LOGISTICS_SUCCESS = 'GET_ALL_SUPPORT_REQUEST_LOGISTICS_SUCCESS';
export const GET_ALL_SUPPORT_REQUEST_LOGISTICS_FAIL = 'GET_ALL_SUPPORT_REQUEST_LOGISTICS_FAIL';


// Add support Request Logistic
export const ADD_SUPPORT_REQUEST_LOGISTICS_START = 'ADD_SUPPORT_REQUEST_LOGISTICS_START';
export const ADD_SUPPORT_REQUEST_LOGISTICS_SAGA = 'ADD_SUPPORT_REQUEST_LOGISTICS_SAGA';
export const ADD_SUPPORT_REQUEST_LOGISTICS_SUCCESS = 'ADD_SUPPORT_REQUEST_LOGISTICS_SUCCESS';
export const ADD_SUPPORT_REQUEST_LOGISTICS_FAIL = 'ADD_SUPPORT_REQUEST_LOGISTICS_FAIL';


// Add Payout  Logistic
export const GET_ALL_LOGISTICS_PAYOUT_HISTORY_START = 'GET_ALL_LOGISTICS_PAYOUT_HISTORY_START';
export const GET_ALL_LOGISTICS_PAYOUT_HISTORY_SAGA = 'GET_ALL_LOGISTICS_PAYOUT_HISTORY_SAGA';
export const GET_ALL_LOGISTICS_PAYOUT_HISTORY_SUCCESS = 'GET_ALL_LOGISTICS_PAYOUT_HISTORY_SUCCESS';
export const GET_ALL_LOGISTICS_PAYOUT_HISTORY_FAIL = 'GET_ALL_LOGISTICS_PAYOUT_HISTORY_FAIL';


export const EDIT_BLOCK_LOGISTIC_STATUS_START = 'EDIT_BLOCK_LOGISTIC_STATUS_START';
export const EDIT_BLOCK_LOGISTIC_STATUS_SAGA = 'EDIT_BLOCK_LOGISTIC_STATUS_SAGA';
export const EDIT_BLOCK_LOGISTIC_STATUS_SUCCESS = 'EDIT_BLOCK_LOGISTIC_STATUS_SUCCESS';
export const EDIT_BLOCK_LOGISTIC_STATUS_FAIL = 'EDIT_BLOCK_LOGISTIC_STATUS_FAIL';


// EDIT Logistic Detail
export const EDIT_LOGISTIC_DETAIL_START = 'EDIT_LOGISTIC_DETAIL_START';
export const EDIT_LOGISTIC_DETAIL_SAGA = 'EDIT_LOGISTIC_DETAIL_SAGA';
export const EDIT_LOGISTIC_DETAIL_SUCCESS = 'EDIT_LOGISTIC_DETAIL_SUCCESS';
export const EDIT_LOGISTIC_DETAIL_FAIL = 'EDIT_LOGISTIC_DETAIL_FAIL';

export const GET_ALL_ORDER_LIST_START = 'GET_ALL_ORDER_LIST_START';
export const GET_ALL_ORDER_LIST_SUCCESS = 'GET_ALL_ORDER_LIST_SUCCESS';
export const GET_ALL_ORDER_LIST_SAGA = 'GET_ALL_ORDER_LIST_SAGA';
export const GET_ALL_ORDER_LIST_FAIL = 'GET_ALL_ORDER_LIST_FAIL';
export const GET_ALL_DRIVER_ORDER_LIST_SAGA = 'GET_ALL_DRIVER_ORDER_LIST_SAGA';
export const GET_ALL_DRIVER_ORDER_LIST_START = 'GET_ALL_DRIVER_ORDER_LIST_START';
export const GET_ALL_DRIVER_ORDER_LIST_SUCCESS = 'GET_ALL_DRIVER_ORDER_LIST_SUCCESS';
export const GET_ALL_DRIVER_ORDER_LIST_FAIL = 'GET_ALL_DRIVER_ORDER_LIST_FAIL';

export const GET_RECEPTION_LIST_SAGA = 'GET_RECEPTION_LIST_SAGA';
export const GET_RECEPTION_LIST_START = 'GET_RECEPTION_LIST_START';
export const GET_RECEPTION_LIST_SUCCESS = 'GET_RECEPTION_LIST_SUCCESS';
export const GET_RECEPTION_LIST_FAIL = 'GET_RECEPTION_LIST_FAIL';

export const ACCEPT_ORDER_FOR_DELIVERY_SAGA = 'ACCEPT_ORDER_FOR_DELIVERY_SAGA';
export const ACCEPT_ORDER_FOR_DELIVERY_START = 'ACCEPT_ORDER_FOR_DELIVERY_START';
export const ACCEPT_ORDER_FOR_DELIVERY_SUCCESS = 'ACCEPT_ORDER_FOR_DELIVERY_SUCCESS';
export const ACCEPT_ORDER_FOR_DELIVERY_FAIL = 'ACCEPT_ORDER_FOR_DELIVERY_FAIL';

export const GET_ALL_VEHICLE_LIST_SAGA = 'GET_ALL_VEHICLE_LIST_SAGA';
export const GET_ALL_VEHICLE_LIST_START = 'GET_ALL_VEHICLE_LIST_START';
export const GET_ALL_VEHICLE_LIST_SUCCESS = 'GET_ALL_VEHICLE_LIST_SUCCESS';
export const GET_ALL_VEHICLE_LIST_FAIL = 'GET_ALL_VEHICLE_LIST_FAIL';

export const ARRIVED_DATA_TO_RECEPTION_SAGA = 'ARRIVED_DATA_TO_RECEPTION_SAGA';
export const ARRIVED_DATA_TO_RECEPTION_START = 'ARRIVED_DATA_TO_RECEPTION_START';
export const ARRIVED_DATA_TO_RECEPTION_SUCCESS = 'ARRIVED_DATA_TO_RECEPTION_SUCCESS';
export const ARRIVED_DATA_TO_RECEPTION_FAIL = 'ARRIVED_DATA_TO_RECEPTION_FAIL';

export const EDIT_MEDIA_START = 'EDIT_MEDIA_START';
export const EDIT_MEDIA_SAGA = 'EDIT_MEDIA_SAGA';
export const EDIT_MEDIA_SUCCESS = 'EDIT_MEDIA_SUCCESS';
export const EDIT_MEDIA_FAIL = 'EDIT_MEDIA_FAIL';

export const DELETE_MEDIA_START = 'DELETE_MEDIA_START';
export const DELETE_MEDIA_SAGA = 'DELETE_MEDIA_SAGA';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAIL = 'DELETE_MEDIA_FAIL';

export const GET_LAT_LONG_FOR_LOGISTIC_START = 'GET_LAT_LONG_FOR_LOGISTIC_START';
export const GET_LAT_LONG_FOR_LOGISTIC_SAGA = 'GET_LAT_LONG_FOR_LOGISTIC_SAGA';
export const GET_LAT_LONG_FOR_LOGISTIC_SUCCESS = 'GET_LAT_LONG_FOR_LOGISTIC_SUCCESS';
export const GET_LAT_LONG_FOR_LOGISTIC_FAIL = 'GET_LAT_LONG_FOR_LOGISTIC_FAIL';



export const GET_ALL_LOGISTIC_DETAIL_FAIL = 'GET_ALL_LOGISTIC_DETAIL_FAIL';
export const GET_ALL_LOGISTIC_DETAIL_SAGA = 'GET_ALL_LOGISTIC_DETAIL_SAGA';
export const GET_ALL_LOGISTIC_DETAIL_START = 'GET_ALL_LOGISTIC_DETAIL_START';
export const GET_ALL_LOGISTIC_DETAIL_SUCCESS = 'GET_ALL_LOGISTIC_DETAIL_SUCCESS';



export const SEND_PRODUCT_TO_LOGISTIC_FAIL = 'SEND_PRODUCT_TO_LOGISTIC_FAIL';
export const SEND_PRODUCT_TO_LOGISTIC_SAGA = 'SEND_PRODUCT_TO_LOGISTIC_SAGA';
export const SEND_PRODUCT_TO_LOGISTIC_START = 'SEND_PRODUCT_TO_LOGISTIC_START';
export const SEND_PRODUCT_TO_LOGISTIC_SUCCESS = 'SEND_PRODUCT_TO_LOGISTIC_SUCCESS';




export const GET_ALL_LOGISTIC_ORDER_FAIL = 'GET_ALL_LOGISTIC_ORDER_FAIL';
export const GET_ALL_LOGISTIC_ORDER_SAGA = 'GET_ALL_LOGISTIC_ORDER_SAGA';
export const GET_ALL_LOGISTIC_ORDER_START = 'GET_ALL_LOGISTIC_ORDER_START';
export const GET_ALL_LOGISTIC_ORDER_SUCCESS = 'GET_ALL_LOGISTIC_ORDER_SUCCESS';



export const GET_ORDER_DETAIL_BY_ID_FAIL = 'GET_ORDER_DETAIL_BY_ID_FAIL';
export const GET_ORDER_DETAIL_BY_ID_SAGA = 'GET_ORDER_DETAIL_BY_ID_SAGA';
export const GET_ORDER_DETAIL_BY_ID_START = 'GET_ORDER_DETAIL_BY_ID_START';
export const GET_ORDER_DETAIL_BY_ID_SUCCESS = 'GET_ORDER_DETAIL_BY_ID_SUCCESS';



export const ADD_NEW_EMPLOYEE_FAIL = 'ADD_NEW_EMPLOYEE_FAIL';
export const ADD_NEW_EMPLOYEE_SAGA = 'ADD_NEW_EMPLOYEE_SAGA';
export const ADD_NEW_EMPLOYEE_START = 'ADD_NEW_EMPLOYEE_START';
export const ADD_NEW_EMPLOYEE_SUCCESS = 'ADD_NEW_EMPLOYEE_SUCCESS';

export const EDIT_NEW_EMPLOYEE_FAIL = 'EDIT_NEW_EMPLOYEE_FAIL';
export const EDIT_NEW_EMPLOYEE_SAGA = 'EDIT_NEW_EMPLOYEE_SAGA';
export const EDIT_NEW_EMPLOYEE_START = 'EDIT_NEW_EMPLOYEE_START';
export const EDIT_NEW_EMPLOYEE_SUCCESS = 'EDIT_NEW_EMPLOYEE_SUCCESS';

export const GET_ALL_EMPLOYEE_DATA_FAIL = 'GET_ALL_EMPLOYEE_DATA_FAIL';
export const GET_ALL_EMPLOYEE_DATA_SAGA = 'GET_ALL_EMPLOYEE_DATA_SAGA';
export const GET_ALL_EMPLOYEE_DATA_START = 'GET_ALL_EMPLOYEE_DATA_START';
export const GET_ALL_EMPLOYEE_DATA_SUCCESS = 'GET_ALL_EMPLOYEE_DATA_SUCCESS';


export const EDIT_EMPLOYEE_STATUS_FAIL = 'EDIT_EMPLOYEE_STATUS_FAIL';
export const EDIT_EMPLOYEE_STATUS_SAGA = 'EDIT_EMPLOYEE_STATUS_SAGA';
export const EDIT_EMPLOYEE_STATUS_START = 'EDIT_EMPLOYEE_STATUS_START';
export const EDIT_EMPLOYEE_STATUS_SUCCESS = 'EDIT_EMPLOYEE_STATUS_SUCCESS';


export const DELETE_LOGISTIC_EMPLOYEE_FAIL = 'DELETE_LOGISTIC_EMPLOYEE_FAIL';
export const DELETE_LOGISTIC_EMPLOYEE_SAGA = 'DELETE_LOGISTIC_EMPLOYEE_SAGA';
export const DELETE_LOGISTIC_EMPLOYEE_START = 'DELETE_LOGISTIC_EMPLOYEE_START';
export const DELETE_LOGISTIC_EMPLOYEE_SUCCESS = 'DELETE_LOGISTIC_EMPLOYEE_SUCCESS';

export const GET_ALL_DESTINY_DATA_FAIL = 'GET_ALL_DESTINY_DATA_FAIL';
export const GET_ALL_DESTINY_DATA_SAGA = 'GET_ALL_DESTINY_DATA_SAGA';
export const GET_ALL_DESTINY_DATA_START = 'GET_ALL_DESTINY_DATA_START';
export const GET_ALL_DESTINY_DATA_SUCCESS = 'GET_ALL_DESTINY_DATA_SUCCESS';


export const DELETE_DRIVER_SAGA = 'DELETE_DRIVER_SAGA';
export const DELETE_DRIVER_START = 'DELETE_DRIVER_START';
export const DELETE_DRIVER_SUCCESS = 'DELETE_DRIVER_SUCCESS';
export const DELETE_DRIVER_FAIL = 'DELETE_DRIVER_FAIL';