import React from 'react';

export const vehicleOwnerRoutes = [
  {
    path: '/vehicle-owner/dashboard',
    exact: true,
    name: 'Dashboard',
    component: React.lazy(() =>
      import('../../views/vehicleOwner/Dashboard/Dashboard'),
    ),
  },
  {
    path: '/vehicle-owner/profile',
    name: 'Profile',
    component: React.lazy(() => import('../../views/admin/Profile/Profile')),
  },
  // {
  //   path: '/vendors/users',
  //   exact: true,
  //   name: 'Users',
  //   component: React.lazy(() => import('../../views/admin/Users/Users')),
  // },
  // {
  //   path: '/vendors/users/user-detail/:id',
  //   name: 'User Detail',
  //   component: React.lazy(() => import('../../views/admin/Users/UserDetail')),
  // },
  // {
  //   path: '/vendors/chefs',
  //   exact: true,
  //   name: 'Vendors',
  //   component: React.lazy(() => import('../../views/admin/Chefs/Chefs')),
  // },
  // {
  //   path: '/vendors/chefs/chef-detail/:id',
  //   name: 'Vendors Detail',
  //   component: React.lazy(() => import('../../views/admin/Chefs/ChefDetail')),
  // },
  // {
  //   path: '/vendors/chefs/rating-detail/:id',
  //   name: 'Rating Detail',
  //   component: React.lazy(() => import('../../views/admin/Chefs/RatingDetail')),
  // },
  // Drivers , SS
  {
    path: '/vehicle-owner/drivers',
    exact: true,
    name: 'Drivers',
    component: React.lazy(() =>
      import('../../views/vehicleOwner/Drivers/Drivers'),
    ),
  },
  {
    path: '/vehicle-owner/drivers/add-driver',
    exact: true,
    name: 'Add Driver',
    component: React.lazy(() =>
      import('../../views/vehicleOwner/Drivers/AddDriver'),
    ),
  },
  {
    path: '/vehicle-owner/drivers/driver-detail/:id',
    name: 'Driver Details',
    component: React.lazy(() =>
      import('../../views/vehicleOwner/Drivers/DriverDetails'),
    ),
  },
  // Product , SS ====================================================
  // {
  //   path: '/vendors/products',
  //   exact: true,
  //   name: 'Products',
  //   component: React.lazy(() => import('../../views/admin/Products/Products')),
  // },
  // {
  //   path: '/vendors/productss/products-detail/:id',
  //   name: 'Product Details',
  //   component: React.lazy(() =>
  //     import('../../views/admin/Drivers/DriverDetails'),
  //   ),
  // },

  // All Vehicles ==========================
  {
    path: '/vehicle-owner/vehicles',
    exact: true,
    name: 'Vehicle Types',
    component: React.lazy(() =>
      import('../../views/vehicleOwner/Vehicles/Vehicles'),
    ),
  },
  {
    path: '/vehicle-owner/vehicles/add-owner-vehicle',
    exact: true,
    name: 'Add Vehicle',
    component: React.lazy(() =>
      import('../../views/vehicleOwner/Vehicles/AddVehicle'),
    ),
  },
  {
    path: '/vehicle-owner/vehicles/edit-owner-vehicle/:id',
    exact: true,
    name: 'Edit Vehicle',
    component: React.lazy(() =>
      import('../../views/vehicleOwner/Vehicles/EditVehicle'),
    ),
  },
  {
    path: '/vehicle-owner/vehicles/owner-vehicle-details/:id',
    exact: true,
    name: 'Edit Vehicle',
    component: React.lazy(() =>
      import('../../views/vehicleOwner/Vehicles/VehicleDetail'),
    ),
  },
  // Vehicle Types ==========================
  {
    path: '/vehicle-owner/vehicle-types',
    exact: true,
    name: 'Vehicle Types',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/VehiclesTypes'),
    ),
  },
  {
    path: '/vehicle-owner/vehicles/add-vehicle',
    exact: true,
    name: 'Add Vehicle Type',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/AddVehicleType'),
    ),
  },
  {
    path: '/vehicle-owner/vehicles/edit-vehicle/:id',
    exact: true,
    name: 'Edit Vehicle Type',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/EditVehicleType'),
    ),
  },

  // Vehicle Owners==========================
  // {
  //   path: '/vendors/vehicle-owners',
  //   exact: true,
  //   name: 'Vehicle Owners',
  //   component: React.lazy(() =>
  //     import('../../views/admin/vehicleOwners/vehicleOwners'),
  //   ),
  // },

  // {
  //   path: '/vendors/vehicle-owners/add-vehicle-owner',
  //   exact: true,
  //   name: 'Vehicle Owners',
  //   component: React.lazy(() =>
  //     import('../../views/admin/vehicleOwners/AddVehicleOwner'),
  //   ),
  // },
  // {
  //   path: '/vendors/orders',
  //   name: 'Orders',
  //   component: React.lazy(() => import('../../views/admin/Orders/Orders')),
  // },
  // {
  //   path: '/vendors/rating-management',
  //   exact: true,
  //   name: 'Rating Management',
  //   component: React.lazy(() =>
  //     import('../../views/admin/RatingManagement/RatingManagement'),
  //   ),
  // },
  // {
  //   path: '/vendors/inquiries',
  //   exact: true,
  //   name: 'Enquiries',
  //   component: React.lazy(() =>
  //     import('../../views/admin/Inquiries/Inquiries'),
  //   ),
  // },
  // {
  //   path: '/vendors/inquiries/inquiry-detail/:id',
  //   name: 'Enquiry Detail',
  //   component: React.lazy(() =>
  //     import('../../views/admin/Inquiries/InquiryDetail'),
  //   ),
  // },
  // {
  //   path: '/vendors/menu-categories',
  //   name: 'Products',
  //   component: React.lazy(() =>
  //     import('../../views/admin/MenuCategories/MenuCategories'),
  //   ),
  // },
  // {
  //   path: '/vendors/brand-types',
  //   name: 'Vendor Types',
  //   component: React.lazy(() =>
  //     import('../../views/admin/BrandTypes/BrandTypes'),
  //   ),
  // },
  // {
  //   path: '/vendors/menu-sub-categories',
  //   name: 'Sub-Products',
  //   component: React.lazy(() =>
  //     import('../../views/admin/MenuSubCategories/MenuSubCategories'),
  //   ),
  // },
  {
    path: '/vendors/contact-us',
    name: 'Contact Us',
    component: React.lazy(() =>
      import('../../views/admin/ContactUs/ContactUs'),
    ),
  },
  {
    path: '/vehicle-owner/commission',
    name: 'Commission',
    component: React.lazy(() =>
      import('../../views/vehicleOwner/Commission/CommissionOwner'),
    ),
  },
  {
    path: '/vendors/settings',
    name: 'Settings',
    component: React.lazy(() => import('../../views/admin/Settings/Settings')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/vehicle-owner/dashboard',
    // rootRedirect: true,
  },
];
