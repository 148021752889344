export const RESET_APP = 'RESET_APP';
export const LOGIN_SAGA = 'LOGIN_SAGA';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SAGA = 'LOGOUT_SAGA';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const EDIT_USER_PROFILE_SAGA = 'EDIT_USER_PROFILE_SAGA';
export const EDIT_USER_PROFILE_START = 'EDIT_USER_PROFILE_START';
export const EDIT_USER_PROFILE_SUCCESS = 'EDIT_USER_PROFILE_SUCCESS';
export const EDIT_USER_PROFILE_FAIL = 'EDIT_USER_PROFILE_FAIL';
export const CHANGE_PASSWORD_SAGA = 'CHANGE_PASSWORD_SAGA';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const CHANGE_PASSWORD_CHEF_SAGA = 'CHANGE_PASSWORD_CHEF_SAGA';
export const CHANGE_PASSWORD_CHEF_START = 'CHANGE_PASSWORD_CHEF_START';
export const CHANGE_PASSWORD_CHEF_SUCCESS = 'CHANGE_PASSWORD_CHEF_SUCCESS';
export const CHANGE_PASSWORD_CHEF_FAIL = 'CHANGE_PASSWORD_CHEF_FAIL';
export const AUTHENTICATION_VALIDATOR = 'AUTHENTICATION_VALIDATOR';
export const RESET_ERROR_MSG = 'RESET_ERROR_MSG';
export const REGISTER_VERIFY_SAGA = 'REGISTER_VERIFY_SAGA';
export const REGISTER_VERIFY_START = 'REGISTER_VERIFY_START';
export const REGISTER_VERIFY_SUCCESS = 'REGISTER_VERIFY_SUCCESS';
export const REGISTER_VERIFY_FAIL = 'REGISTER_VERIFY_FAIL';
export const REGISTER_SAGA = 'REGISTER_SAGA';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const OTP_SAGA = 'OTP_SAGA';
export const OTP_START = 'OTP_START';
export const OTP_SUCCESS = 'OTP_SUCCESS';
export const OTP_FAIL = 'OTP_FAIL';

export const RESET_PASSWORD_TOKEN_VERIFICATION_SAGA =
  'RESET_PASSWORD_TOKEN_VERIFICATION_SAGA';
export const RESET_PASSWORD_TOKEN_VERIFICATION_START =
  'RESET_PASSWORD_TOKEN_VERIFICATION_START';
export const RESET_PASSWORD_TOKEN_VERIFICATION_SUCCESS =
  'RESET_PASSWORD_TOKEN_VERIFICATION_SUCCESS';
export const RESET_PASSWORD_TOKEN_VERIFICATION_FAIL =
  'RESET_PASSWORD_TOKEN_VERIFICATION_FAIL';

export const RESET_PASSWORD_SAGA = 'RESET_PASSWORD_SAGA';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const FORGOT_PASSWORD_SAGA = 'FORGOT_PASSWORD_SAGA';
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const EMAIL_VERIFICATION_TOKEN_VERIFICATION_SAGA =
  'EMAIL_VERIFICATION_TOKEN_VERIFICATION_SAGA';
export const EMAIL_VERIFICATION_TOKEN_VERIFICATION_START =
  'EMAIL_VERIFICATION_TOKEN_VERIFICATION_START';
export const EMAIL_VERIFICATION_TOKEN_VERIFICATION_SUCCESS =
  'EMAIL_VERIFICATION_TOKEN_VERIFICATION_SUCCESS';
export const EMAIL_VERIFICATION_TOKEN_VERIFICATION_FAIL =
  'EMAIL_VERIFICATION_TOKEN_VERIFICATION_FAIL';
