import { put } from 'redux-saga/effects';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';
import {
  addServiceFail,
  addServiceStart,
  addServiceSuccess,
  getAllServiceSaga as getAllServiceSagaAction,
  getAllServiceFail,
  getAllServiceStart,
  getAllServiceSuccess,
  editServiceStart,
  editServiceSuccess,
  editServiceFail,
  showModal,
  logout,
  editBlockServiceStatusStart,
  editBlockServiceStatusSuccess,
  editBlockServiceStatusFail,
  deleteServiceStart,
  deleteServiceSuccess,
  deleteServiceFail,
} from '../../actions';

// Get All
export function* getAllServiceSaga(action) {
  yield put(getAllServiceStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllServiceList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllServiceSuccess({ services: response.data.data }));
    } else {
      yield put(getAllServiceFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllServiceFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllServiceFail(error.response.data.message));
      } else {
        yield put(getAllServiceFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllServiceFail('Something went wrong! Please try again.'));
    }
  }
}

// Get Single

// Add Service
export function* addServiceSaga(action) {
  const { setIsDisable } = action.payload;
  yield put(addServiceStart());
  try {
    const { closeModel, tableFilter } = action.payload;
    const payload = { name: action.payload.name };

    const response = yield axios.post(`/admin/addService`, payload);
    if (response.status === 200) {
      yield put(addServiceSuccess(response.data.data));
      setIsDisable(false);
      closeModel();
      yield put(getAllServiceSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(addServiceFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addServiceFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addServiceFail(error.response.data.message));
      } else {
        yield put(addServiceFail('Server error! Please try again.'));
      }
    } else {
      yield put(addServiceFail('Something went wrong! Please try again.'));
    }
  }
}

// Edit Service
export function* editServiceSaga(action) {
  yield put(editServiceStart());
  try {
    const { id, name, closeModel, setIsDisable, tableFilter } = action.payload;
    const payload = { id, name };

    const response = yield axios.post(`/admin/editService`, payload);
    if (response.status === 200) {
      yield put(editServiceSuccess());
      setIsDisable(false);
      closeModel();
      yield put(getAllServiceSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(editServiceFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editServiceFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editServiceFail(error.response.data.message));
      } else {
        yield put(editServiceFail('Server error! Please try again.'));
      }
    } else {
      yield put(editServiceFail('Something went wrong! Please try again.'));
    }
  }
}

// Block Service Status
export function* editBlockServiceStatusSaga(action) {
  yield put(editBlockServiceStatusStart());
  try {
    const response = yield axios.post(
      `/admin/UpdateServiceStatus`,
      action.payload,
    );
    console.log(response);
    if (response.status === 200) {
      yield put(editBlockServiceStatusSuccess(action.payload));
    } else {
      yield put(
        editBlockServiceStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBlockServiceStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBlockServiceStatusFail(error.response.data.message));
      } else {
        yield put(
          editBlockServiceStatusFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        editBlockServiceStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Delete Service
export function* deleteServiceSaga(action) {
  yield put(deleteServiceStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    console.log(action.payload);
    const response = yield axios.post(`/admin/deleteService`, { id });
    if (response.status === 200) {
      yield put(deleteServiceSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllServiceSagaAction(tableFilter));
    } else {
      yield put(deleteServiceFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      if (error.response.status === 400) {
        yield put(deleteServiceFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteServiceFail(error.response.data.message));
      } else {
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: 'Server error! Please try again.',
          }),
        );
        yield put(deleteServiceFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteServiceFail('Something went wrong! Please try again.'));
    }
  }
}
