import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import firebase from 'firebase';
import { ToastContainer, toast } from 'react-toastify';

import { authenticationValidator } from './store/actions';
// import FreshChat from 'react-freshchat';

import { Spinner } from './components';
import initFirebase from './firebase';
import { guestRoutes } from './routes';

import './assets/js/custom';
import 'bootstrap/dist/js/bootstrap';
import './assets/js/plugins/slimscroll/jquery.slimscroll.min';
import './assets/js/plugins/metisMenu/jquery.metisMenu';
import './assets/js/plugins/pace/pace.min';
import 'react-toastify/dist/ReactToastify.css';

// import './assets/js/scrollbar';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/font-awesome/css/font-awesome.css';
import './assets/css/c3.min.css';
import './assets/css/animate.css';
import './assets/css/style.css';
import './assets/css/mystyle-coman.css';
import './assets/css/admin-style.css';
import './assets/css/media.css';
import './assets/css/datatables.min.css';
import './assets/css/daterangepicker-bs3.css';
import './assets/css/jquery.scrollbar.css';
import './assets/css/svg-icons-animate.css';
import './assets/css/dev.css';

// const token = 'e586de6c-e142-4705-aa6a-0e76a97339b3';
// const host = 'https://wchat.in.freshchat.com';
// const fcWidget = window.fcWidget;
function App() {
  const tokenPresent = !!useSelector(state => state.auth.authToken);
  const role = !!useSelector(state => state.auth.userRole);
  const userRole = useSelector(state => state.auth.userRole);
  const freshChatRef = React.createRef();
  const pathname = window.location.pathname.split('/')[2];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticationValidator());
    if (!firebase.apps.length) {
      initFirebase();
    }
  }, []);

  useEffect(() => {
    if (freshChatRef.current != null) {
      // fcWidget.hide();
    }
  }, [userRole]);

  let mainContent = (
    <>
      {guestRoutes.map(route => (
        <Route
          key={route.name}
          path={route.path}
          exact={route.exact}
          name={route.name}
        >
          <route.component />
        </Route>
      ))}
      {/* <Route
        exact
        path="/"
        component={React.lazy(() => import('./views/Login/Login'))}
      />
      <Route
        exact
        path="/inquiry"
        component={React.lazy(() => import('./views/Inquiry/Inquiry'))}
      />
      <Route
        exact
        path="/reset-password/:token"
        component={React.lazy(() =>
          import('./views/ResetPassword/ResetPassword'),
        )}
      />
      <Route
        exact
        path="/register"
        component={React.lazy(() => import('./views/Register/Register'))}
      />
      <Route
        exact
        path="/email-verification/:token"
        component={React.lazy(() =>
          import('./views/EmailVerification/EmailVerification'),
        )}
      />
      <Route
        exact
        path="/otp-verification"
        component={React.lazy(() =>
          import('./views/OTPVerification/OTPVerification'),
        )}
      />

      <Route
        exact
        path="/forgot-password"
        component={React.lazy(() =>
          import('./views/ForgotPassword/ForgotPassword.jsx'),
        )}
      /> */}
      {pathname !== 'inquiry' &&
        pathname !== 'reset-password' &&
        pathname !== 'register' &&
        pathname !== 'otp-verification' &&
        pathname !== 'forgot-password' &&
        pathname !== 'email-verification' &&
        pathname !== 'privacy-policy' &&
        pathname !== 'term-condition' &&
        pathname !== 'marketing' &&
        localStorage.getItem('userData') == null && <Redirect to="/vendors/" />}
    </>
  );
  if (tokenPresent && role) {
    mainContent = (
      <>
        <Route
          path="/"
          component={React.lazy(() =>
            import('./views/MainContainer/MainContainer'),
          )}
        />
      </>
    );
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <Switch>
          {/* <Route
            exact
            path="/"
            component={React.lazy(() => import('./views/Login/Login'))}
          /> */}
          {mainContent}
        </Switch>
        {/* {console.log('Role', window.fcWidget)}
        {userRole === 'chef' && (
          <FreshChat
            ref={freshChatRef}
            token={token}
            host={host}
            onInit={widget => {
              widget.user.setProperties({
                email: 'ronak@solulab.co ',
                first_name: 'Ronak',
                last_name: 'Gandhi',
                phone: '9033275224',
              });
            }}
          />
        )} */}
      </BrowserRouter>
      <div
        style={{ position: 'fixed', top: '92px', right: '92px', zIndex: '3' }}
      >
        <ToastContainer containerId="appLayoutToaster" />
      </div>
    </React.Suspense>

    
  );
}

export default App;
