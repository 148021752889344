import { put } from 'redux-saga/effects';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';
import {
  addAccessModuleFail,
  addAccessModuleStart,
  addAccessModuleSuccess,
  getAllAccessModuleSaga as getAllAccessModuleSagaAction,
  getAllAccessModuleFail,
  getAllAccessModuleStart,
  getAllAccessModuleSuccess,
  editAccessModuleStart,
  editAccessModuleSuccess,
  editAccessModuleFail,
  showModal,
  logout,
  editBlockAccessModuleStatusStart,
  editBlockAccessModuleStatusSuccess,
  editBlockAccessModuleStatusFail,
  deleteAccessModuleStart,
  deleteAccessModuleSuccess,
  deleteAccessModuleFail,
} from '../../actions';

// Get All
export function* getAllAccessModuleSaga(action) {
  yield put(getAllAccessModuleStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllAccesstype`, action.payload),
    );
    if (response.status === 200) {
      yield put(
        getAllAccessModuleSuccess({ accessModules: response.data.data }),
      );
    } else {
      yield put(
        getAllAccessModuleFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllAccessModuleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllAccessModuleFail(error.response.data.message));
      } else {
        yield put(getAllAccessModuleFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllAccessModuleFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Get Single

// Add AccessModule
export function* addAccessModuleSaga(action) {
  const { setIsDisable } = action.payload;
  yield put(addAccessModuleStart());
  try {
    const { closeModel, tableFilter } = action.payload;
    const payload = { modulename: action.payload.modulename };

    const response = yield axios.post(`/admin/addAccessType`, payload);
    if (response.status === 200) {
      yield put(addAccessModuleSuccess(response.data.data));
      setIsDisable(false);
      closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success ',
        }),
      );
      yield put(getAllAccessModuleSagaAction(tableFilter));
    } else {
      yield put(addAccessModuleFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addAccessModuleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addAccessModuleFail(error.response.data.message));
      } else {
        yield put(addAccessModuleFail('Server error! Please try again.'));
      }
    } else {
      yield put(addAccessModuleFail('Something went wrong! Please try again.'));
    }
  }
}

// Edit AccessModule
export function* editAccessModuleSaga(action) {
  yield put(editAccessModuleStart());
  try {
    const {
      id,
      modulename,
      closeModel,
      setIsDisable,
      tableFilter,
    } = action.payload;
    const payload = { id, modulename };

    const response = yield axios.post(`/admin/editAccesstype`, payload);
    if (response.status === 200) {
      yield put(editAccessModuleSuccess());
      setIsDisable(false);
      closeModel();
      yield put(getAllAccessModuleSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success ',
        }),
      );
    } else {
      yield put(
        editAccessModuleFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editAccessModuleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editAccessModuleFail(error.response.data.message));
      } else {
        yield put(editAccessModuleFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editAccessModuleFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Block AccessModule Status
export function* editBlockAccessModuleStatusSaga(action) {
  yield put(editBlockAccessModuleStatusStart());
  try {
    const response = yield axios.post(
      `/admin/statusChangedAccesstype`,
      action.payload,
    );
    if (response.status === 200) {
      yield put(editBlockAccessModuleStatusSuccess(action.payload));
    } else {
      yield put(
        editBlockAccessModuleStatusFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBlockAccessModuleStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBlockAccessModuleStatusFail(error.response.data.message));
      } else {
        yield put(
          editBlockAccessModuleStatusFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        editBlockAccessModuleStatusFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}

// Delete AccessModule
export function* deleteAccessModuleSaga(action) {
  yield put(deleteAccessModuleStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    console.log(action.payload);
    const response = yield axios.post(`/admin/deleteAccesstype`, { id });
    if (response.status === 200) {
      yield put(deleteAccessModuleSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success ',
        }),
      );
      yield put(getAllAccessModuleSagaAction(tableFilter));
    } else {
      yield put(
        deleteAccessModuleFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      if (error.response.status === 400) {
        yield put(deleteAccessModuleFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteAccessModuleFail(error.response.data.message));
      } else {
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: 'Server error! Please try again.',
          }),
        );
        yield put(deleteAccessModuleFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        deleteAccessModuleFail('Something went wrong! Please try again.'),
      );
    }
  }
}
