import {
  GET_ALL_VEHICLES_START,
  GET_ALL_VEHICLES_SAGA,
  GET_ALL_VEHICLES_SUCCESS,
  GET_ALL_VEHICLES_FAIL,
  ADD_VEHICLE_START,
  ADD_VEHICLE_SAGA,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_FAIL,
  EDIT_BLOCK_VEHICLE_STATUS_START,
  EDIT_BLOCK_VEHICLE_STATUS_SAGA,
  EDIT_BLOCK_VEHICLE_STATUS_SUCCESS,
  EDIT_BLOCK_VEHICLE_STATUS_FAIL,
  GET_VEHICLE_DETAIL_BY_ID_START,
  GET_VEHICLE_DETAIL_BY_ID_SAGA,
  GET_VEHICLE_DETAIL_BY_ID_SUCCESS,
  GET_VEHICLE_DETAIL_BY_ID_FAIL,
  EDIT_VEHICLE_START,
  EDIT_VEHICLE_SAGA,
  EDIT_VEHICLE_SUCCESS,
  EDIT_VEHICLE_FAIL,
  RESET_VEHICLE_DETAILS,
  DELETE_VEHICLE_START,
  DELETE_VEHICLE_SAGA,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
  DELETE_VEHICLE_BY_VEHICLE_OWNER_START,
  DELETE_VEHICLE_BY_VEHICLE_OWNER_SAGA,
  DELETE_VEHICLE_BY_VEHICLE_OWNER_SUCCESS,
  DELETE_VEHICLE_BY_VEHICLE_OWNER_FAIL,
} from '../actionLabels';

export const resetVehicleDetail = () => ({
  type: RESET_VEHICLE_DETAILS,
});
export const getAllVehiclesStart = () => ({
  type: GET_ALL_VEHICLES_START,
});

export const getAllVehiclesSaga = payload => ({
  type: GET_ALL_VEHICLES_SAGA,
  payload,
});

export const getAllVehiclesSuccess = payload => ({
  type: GET_ALL_VEHICLES_SUCCESS,
  payload,
});

export const getAllVehiclesFail = payload => ({
  type: GET_ALL_VEHICLES_FAIL,
  payload,
});

// Get Vehicle Details Start
export const getVehicleDetailByIdStart = () => ({
  type: GET_VEHICLE_DETAIL_BY_ID_START,
});

export const getVehicleDetailByIdSaga = payload => ({
  type: GET_VEHICLE_DETAIL_BY_ID_SAGA,
  payload,
});

export const getVehicleDetailByIdSuccess = payload => ({
  type: GET_VEHICLE_DETAIL_BY_ID_SUCCESS,
  payload,
});

export const getVehicleDetailByIdFail = payload => ({
  type: GET_VEHICLE_DETAIL_BY_ID_FAIL,
  payload,
});
// Get Vehicle Details End

// Add Vehicles Start
export const addVehicleStart = () => ({
  type: ADD_VEHICLE_START,
});

export const addVehicleSaga = payload => ({
  type: ADD_VEHICLE_SAGA,
  payload,
});

export const addVehicleSuccess = payload => ({
  type: ADD_VEHICLE_SUCCESS,
  payload,
});

export const addVehicleFail = payload => ({
  type: ADD_VEHICLE_FAIL,
  payload,
});
// Add Vehicles End

// Edit Vehicles Start
export const editVehicleStart = () => ({
  type: EDIT_VEHICLE_START,
});

export const editVehicleSaga = payload => ({
  type: EDIT_VEHICLE_SAGA,
  payload,
});

export const editVehicleSuccess = payload => ({
  type: EDIT_VEHICLE_SUCCESS,
  payload,
});

export const editVehicleFail = payload => ({
  type: EDIT_VEHICLE_FAIL,
  payload,
});
// Edit Vehicles End

// Block Vehicle status Start
export const editBlockVehicleStatusStart = () => ({
  type: EDIT_BLOCK_VEHICLE_STATUS_START,
});

export const editBlockVehicleStatusSaga = payload => ({
  type: EDIT_BLOCK_VEHICLE_STATUS_SAGA,
  payload,
});

export const editBlockVehicleStatusSuccess = payload => ({
  type: EDIT_BLOCK_VEHICLE_STATUS_SUCCESS,
  payload,
});

export const editBlockVehicleStatusFail = payload => ({
  type: EDIT_BLOCK_VEHICLE_STATUS_FAIL,
  payload,
});
// Block Vehicle status End

// delete Vehicle start
export const deleteVehicleStart = () => ({
  type: DELETE_VEHICLE_START,
});

export const deleteVehicleSaga = payload => ({
  type: DELETE_VEHICLE_SAGA,
  payload,
});

export const deleteVehicleSuccess = payload => ({
  type: DELETE_VEHICLE_SUCCESS,
  payload,
});

export const deleteVehicleFail = payload => ({
  type: DELETE_VEHICLE_FAIL,
  payload,
});

// delete Vehicle by owner

export const deleteVehicleByVhicleOwnerStart = payload => ({
  type: DELETE_VEHICLE_BY_VEHICLE_OWNER_START,
  payload,
});

export const deleteVehicleByVhicleOwnerSaga = payload => ({
  type: DELETE_VEHICLE_BY_VEHICLE_OWNER_SAGA,
  payload,
});

export const deleteVehicleByVhicleOwnerSuccess = payload => ({
  type: DELETE_VEHICLE_BY_VEHICLE_OWNER_SUCCESS,
  payload,
});

export const deleteVehicleByVhicleOwnerFail = payload => ({
  type: DELETE_VEHICLE_BY_VEHICLE_OWNER_FAIL,
  payload,
});

