import React from 'react';

export const chefRoutes = [
  {
    path: '/vendors/dashboard',
    exact: true,
    name: 'Dashboard',
    component: React.lazy(() => import('../../views/chef/Dashboard/Dashboard')),
  },

  {
    path: '/vendors/logistic-Dashboard',
    exact: true,
    name: 'logisticDashboard',
    component: React.lazy(() => import('../../views/LogisticHub/Dashboard/Dashboard')),
  },




  {
    path: '/vendors/orders',
    name: 'Orders',
    component: React.lazy(() => import('../../views/chef/Orders/Orders')),
  },
  {
    path: '/vendors/upcoming-orders',
    exact: true,
    name: 'Upcoming Orders',
    component: React.lazy(() =>
      import('../../views/chef/UpcomingOrders/UpcomingOrders'),
    ),
  },
  {
    path: '/vendors/upcoming-orders/:id',
    name: 'Upcoming Orders',
    component: React.lazy(() =>
      import('../../views/chef/UpcomingOrders/UpcomingOrders'),
    ),
  },
  // {
  //   path: '/vendors/menu-categories',
  //   name: 'Menu Categories',
  //   component: React.lazy(() =>
  //     import('../../views/chef/MenuCategories/MenuCategories'),
  //   ),
  // },
  {
    path: '/vendors/menu-items',
    name: 'Menu Items',
    component: React.lazy(() => import('../../views/chef/MenuItems/MenuItems')),
  },
  {
    path: '/vendors/reviews',
    name: 'Reviews',
    component: React.lazy(() => import('../../views/chef/Reviews/Reviews')),
  },
  {
    path: '/vendors/other-vendors',
    name: 'Reviews',
    component: React.lazy(() =>
      import('../../views/chef/OtherVendors/OtherVendors'),
    ),
  },
  {
    path: '/vendors/payment-history',
    name: 'Payment History',
    component: React.lazy(() =>
      import('../../views/chef/PaymentHistory/PaymentHistory'),
    ),
  },
  {
    path: '/vendors/payment-history-detail',
    name: 'Customer Componsation',
    component: React.lazy(() =>
      import('../../views/chef/PaymentHistoryDetail/PaymentHistoryDetail'),
    ),
  },
  {
    path: '/vendors/support-request',
    name: 'Support Request',
    component: React.lazy(() =>
      import('../../views/chef/SupportRequest/SupportRequest'),
    ),
  },
  {
    path: '/vendors/profile',
    name: 'Profile',
    component: React.lazy(() => import('../../views/chef/Profile/Profile')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/vendors/dashboard',
    // rootRedirect: true,
  },
];
