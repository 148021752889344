/* eslint-disable import/named */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { put, call } from 'redux-saga/effects';
import firebase from 'firebase';
import {
  loginSuccess,
  loginFail,
  loginStart,
  logout,
  logoutStart,
  logoutSuccess,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileFail,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFail,
  showModal,
  changePasswordChefStart,
  changePasswordChefSuccess,
  changePasswordChefFail,
  registerSuccess,
  registerFail,
  registerStart,
  otpFail,
  otpStart,
  otpSuccess,
  resetApp,
  resetPasswordTokenVerificationStart,
  resetPasswordTokenVerificationSuccess,
  resetPasswordTokenVerificationFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFail,
  resetErrorMsg,
  editAcceptingOrderChefSaga,
  emailVerificationTokenVerificationStart,
  emailVerificationTokenVerificationSuccess,
  emailVerificationTokenVerificationFail,
  registerVerifyStart,
  registerVerifySuccess,
  registerVerifyFail,
} from '../../actions';
import axios from '../../../http/axios/axios_main';

export function* loginSaga(action) {
  yield put(loginStart());
  try {
    const response = yield axios.post(`/adminauth/login`, action.payload);
    if (response.status === 200) {
      yield call(
        [localStorage, 'setItem'],
        'authToken',
        response.data.data.userToken,
      );
      yield call(
        [localStorage, 'setItem'],
        'userRole',
        response.data.data.userRole,
      );
      if (response.data.data?.taxi_authToken) {
        yield call(
          [localStorage, 'setItem'],
          'socketToken',
          response.data.data?.taxi_authToken,
        );
      }
      yield call(
        [localStorage, 'setItem'],
        'userData',
        JSON.stringify(response.data.data),
      );
      yield put(loginSuccess(response.data.data));
    } else {
      yield put(loginFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(loginFail(error.response.data.message));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(loginFail(error.response.data.message));
      } else {
        yield put(loginFail('Server error! Please try again.'));
      }
    } else {
      yield put(loginFail('Something went wrong! Please try again.'));
    }
  }
}

export function* updateProfileSaga(action) {
  yield put(updateProfileStart());
  const { setIsDisable, requestBody } = action.payload;
  try {
    const formdata = new FormData();
    formdata.append('firstName', requestBody.firstName);
    formdata.append('lastName', requestBody.lastName);
    formdata.append('phone', requestBody.phone);
    formdata.append('photo', requestBody.profilePic);
    // if (requestBody.profilePic) {
    // }
    const response = yield axios.post(`/admin/updateProfile`, formdata);

    console.log(response.data.data,'main nd promoter profile')
    if (response.status === 200) {
      let userData = yield JSON.parse(localStorage.getItem('userData'));

      console.log(response.data.data,'@@@@@@@@@@@@@@@@@@@@@')
      userData = {
        ...userData,
        ...response.data.data,
      };
      yield call(
        [localStorage, 'setItem'],
        'userData',
        JSON.stringify(userData),
      );
      yield put(updateProfileSuccess(response.data.data));
      yield put(resetErrorMsg());
      setIsDisable(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(updateProfileFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(updateProfileFail(error.response.data.message));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(updateProfileFail(error.response.data.message));
      } else {
        yield put(updateProfileFail('Server error! Please try again.'));
      }
    } else {
      yield put(updateProfileFail('Something went wrong! Please try again.'));
    }
  }
}

export function* changePasswordSaga(action) {
  yield put(changePasswordStart());
  const { setIsDisable } = action.payload;
  try {
    const { closeModel } = action.payload;
    const response = yield axios.post(`/admin/changePassword`, action.payload);
    if (response.status === 200) {
      yield put(changePasswordSuccess(response.data.data));
      closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          // message: response.data.message || 'Success',
          message: 'Password changed  Successfully',
        }),
      );
    } else {
      yield put(changePasswordFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(changePasswordFail(error.response.data.message));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(changePasswordFail(error.response.data.message));
      } else {
        yield put(changePasswordFail('Server error! Please try again.'));
      }
    } else {
      yield put(changePasswordFail('Something went wrong! Please try again.'));
    }
  }
}

export function* changePasswordChefSaga(action) {
  yield put(changePasswordChefStart());
  const { setIsDisable } = action.payload;
  try {
    const { closeModel } = action.payload;
    const response = yield axios.post(`/chef/changePassword`, action.payload);
    if (response.status === 200) {
      yield put(changePasswordChefSuccess(response.data.data));
      closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          // message: response.data.message || 'Success',
          message: 'Password changed Successfully',
        }),
      );
    } else {
      yield put(
        changePasswordChefFail('Something went wrong! Please try again.'),
      );
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(changePasswordChefFail(error.response.data.message));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(changePasswordChefFail(error.response.data.message));
      } else {
        yield put(changePasswordChefFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        changePasswordChefFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// eslint-disable-next-line no-unused-vars
export function* logoutSaga(action) {
  yield put(logoutStart());
  let gtoken = null;
  try {
    if (action.payload && action.payload.fromAuth) {
      gtoken = yield localStorage.getItem('gtoken');
    } else if (firebase.messaging.isSupported()) {
      yield put(
        showModal({
          open: true,
          notifyType: 0,
          withButton: false,
          message: `Please wait... Logging out...`,
        }),
      );
      const response = yield Promise.all([
        firebase.messaging().deleteToken(),
        firebase.messaging().getToken(),
      ]);
      const [deleted, token] = response;
      gtoken = token;
    } else {
      gtoken = null;
    }
  } catch (err) {
    gtoken = null;
  }
  yield call([localStorage, 'clear']);
  yield call([localStorage, 'setItem'], 'gtoken', gtoken);
  yield put(resetApp());
  yield put(logoutSuccess());
}

// eslint-disable-next-line no-unused-vars
export function* authenticationValidatorSaga(action) {
  yield put(loginStart());
  const token = yield localStorage.getItem('authToken');
  const userData = yield JSON.parse(localStorage.getItem('userData'));
  if (!token) {
    yield put(logout({ fromAuth: true }));
  } else {
    const userRole = yield localStorage.getItem('userRole');
    yield put(loginSuccess({ userToken: token, userRole, ...userData }));
  }
}

export function* registerVerifySaga(action) {
  yield put(registerVerifyStart());
  try {
    const response = yield axios.get(
      `/adminauth/verifyEmailInquiry/${action.payload.token}`,
    );
    if (response.status === 200) {
      yield put(registerVerifySuccess(response.data.data));
    } else {
      yield put(registerVerifyFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(registerVerifyFail(error.response.data.message));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(registerVerifyFail(error.response.data.message));
      } else {
        yield put(registerVerifyFail('Server error! Please try again.'));
      }
    } else {
      yield put(registerVerifyFail('Something went wrong! Please try again.'));
    }
  }
}

export function* registerSaga(action) {
  yield put(registerStart());
  try {
    const response = yield axios.post(`/adminauth/register`, action.payload);
    if (response.status === 200) {
      yield put(registerSuccess(response));
    } else {
      yield put(registerFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(registerFail(error.response.data.message));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(registerFail(error.response.data.message));
      } else {
        yield put(registerFail('Server error! Please try again.'));
      }
    } else {
      yield put(registerFail('Something went wrong! Please try again.'));
    }
  }
}

export function* otpSaga(action) {
  yield put(otpStart());
  try {
    const response = true;
    yield put(otpSuccess(response));
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(otpFail(error.response.data.message));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(otpFail(error.response.data.message));
      } else {
        yield put(otpFail('Server error! Please try again.'));
      }
    } else {
      yield put(otpFail('Something went wrong! Please try again.'));
    }
  }
}

export function* checkResetPasswordToken(action) {
  yield put(resetPasswordTokenVerificationStart());
  try {
    const response = yield axios.get(`/adminauth/checkToken/${action.payload}`);
    if (response.status === 200) {
      yield put(resetPasswordTokenVerificationSuccess(action.payload));
    } else {
      yield put(
        resetPasswordTokenVerificationFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(
          resetPasswordTokenVerificationFail(error.response.data.message),
        );
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(
          resetPasswordTokenVerificationFail(error.response.data.message),
        );
      } else {
        yield put(
          resetPasswordTokenVerificationFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        resetPasswordTokenVerificationFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}

export function* resetPasswordSaga(action) {
  yield put(resetPasswordStart());
  const { setIsDisable } = action.payload;
  try {
    const response = yield axios.post(
      `/adminauth/resetPassword`,
      action.payload.requestData,
    );
    if (response.status === 200) {
      yield put(resetPasswordSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vendors/',
          message: response.data.message || 'Success',
        }),
      );
      setIsDisable(false);
    } else {
      yield put(resetPasswordFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(resetPasswordFail(error.response.data.message));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(resetPasswordFail(error.response.data.message));
      } else {
        yield put(resetPasswordFail('Server error! Please try again.'));
      }
    } else {
      yield put(resetPasswordFail('Something went wrong! Please try again.'));
    }
  }
}

export function* forgotPasswordSaga(action) {
  yield put(forgotPasswordStart());
  try {
    const response = yield axios.post(
      `/adminauth/forgotPassword`,
      action.payload,
    );
    if (response.status === 200) {
      yield put(forgotPasswordSuccess(response.data.message));
    } else {
      yield put(forgotPasswordFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(forgotPasswordFail(error.response.data.message));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(forgotPasswordFail(error.response.data.message));
      } else {
        yield put(forgotPasswordFail('Server error! Please try again.'));
      }
    } else {
      yield put(forgotPasswordFail('Something went wrong! Please try again.'));
    }
  }
}

export function* checkEmailVerificationToken(action) {
  yield put(emailVerificationTokenVerificationStart());
  try {
    const response = yield axios.get(
      `/adminauth/verifyEmail/${action.payload}`,
    );
    if (response.status === 200) {
      yield put(emailVerificationTokenVerificationSuccess(action.payload));
    } else {
      yield put(
        resetPasswordTokenVerificationFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(
          emailVerificationTokenVerificationFail(error.response.data.message),
        );
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(
          emailVerificationTokenVerificationFail(error.response.data.message),
        );
      } else {
        yield put(
          emailVerificationTokenVerificationFail(
            'Server error! Please try again.',
          ),
        );
      }
    } else {
      yield put(
        emailVerificationTokenVerificationFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}
