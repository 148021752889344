import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  commissions: { list: [], totalRecords: { count: 0 } },
  vendorCommission: { list: [], totalRecords: { count: 0 } },
  vendorCommissionHeader: {},
  totalCommission: '-',
  isLoading: false,
  errorMsg: '',
  errorMsgVendorCommission: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // Get All
    case actionLabels.GET_ALL_COMMISSION_LIST_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_COMMISSION_LIST_SUCCESS: {
      const { commissions } = payload;
      return { ...state, commissions, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_COMMISSION_LIST_FAIL:
      return {
        ...state,
        commissions: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };

    // Get Single Vendor's Commission Details
    case actionLabels.GET_COMMISSION_DETAIL_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_COMMISSION_DETAIL_SUCCESS: {
      const { vendorCommission } = payload;
      return {
        ...state,
        vendorCommission,
        isLoading: false,
        errorMsgVendorCommission: '',
      };
    }
    case actionLabels.GET_COMMISSION_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        errorMsgVendorCommission: payload,
        vendorCommission: { list: [], totalRecords: { count: 0 } },
      };

    // Get Total Commission
    case actionLabels.GET_TOTAL_COMMISSION_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_TOTAL_COMMISSION_SUCCESS: {
      const { totalCommission } = payload;
      return { ...state, totalCommission, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_TOTAL_COMMISSION_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Get Vendor's Total Commission
    case actionLabels.GET_VENDOR_TOTAL_COMMISSION_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_VENDOR_TOTAL_COMMISSION_SUCCESS: {
      const { vendorCommissionHeader } = payload;
      return {
        ...state,
        vendorCommissionHeader,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.GET_VENDOR_TOTAL_COMMISSION_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Reset All
    case actionLabels.RESET_COMMISSION_LIST:
      return {
        ...state,
        commissions: { list: [], totalRecords: { count: 0 } },
      };

    // Reset Single
    case actionLabels.RESET_COMMISSION_DETAIL:
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        vendorCommission: { list: [], totalRecords: { count: 0 } },
      };
    default:
      return state;
  }
};
