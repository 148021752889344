const jQuery = require('jquery');

window.$ = jQuery;
window.jquery = require('jquery');
window.jQuery = require('jquery');
// window.slimScroll = window.slimscroll;

// jQuery(document).ready(function () {
//   jQuery('.scrollbar-inner').scrollbar();
// });
