import { put } from 'redux-saga/effects';
import {
  getAllChefPayoutListStart,
  getAllChefPayoutListSaga as getAllChefPayoutListSagaAction,
  getAllChefPayoutListSuccess,
  getAllChefPayoutListFail,
  getPaymentHistoryStart,
  getPaymentHistorySuccess,
  getPaymentHistoryFail,
  logout,
  getPaymentHistoryDetailsStart,
  getPaymentHistoryDetailsSuccess,
  getPaymentHistoryDetailsFail,
  payPaymentStart,
  payPaymentSuccess,
  payPaymentFail,
  showModal,
  getAllUserPaymentTypeStart,
  getAllUserPaymentTypeSuccess,
  getAllUserPaymentTypeFail,

  addAllUserPaymentTypeSaga,
  addAllUserPaymentTypeStart,
  addAllUserPaymentTypeSuccess,
  addAllUserPaymentTypeFail,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllChefPayoutListSaga() {
  yield put(getAllChefPayoutListStart());
  try {
    const response = yield axios.get(`/admin/getChefPayouts`);
    if (response.status === 200) {
      yield put(getAllChefPayoutListSuccess(response.data.data));
    } else {
      yield put(
        getAllChefPayoutListFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllChefPayoutListFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllChefPayoutListFail(error.response.data.message));
      } else {
        yield put(getAllChefPayoutListFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllChefPayoutListFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getPaymentHistorySaga(action) {
  yield put(getPaymentHistoryStart());
  try {
    const { month, year, chefId } = action.payload;
    const response = yield axios.get(
      `/admin/getPayoutDetails?month=${month}&year=${year}&chefId=${chefId}`,
    );
    if (response.status === 200) {
      yield put(
        getPaymentHistorySuccess({ paymentHistory: response.data.data }),
      );
    } else {
      yield put(
        getPaymentHistoryFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getPaymentHistoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getPaymentHistoryFail(error.response.data.message));
      } else {
        yield put(getPaymentHistoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getPaymentHistoryFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getPaymentHistoryChefSaga(action) {
  yield put(getPaymentHistoryStart());
  try {
    const { month, year } = action.payload;
    const response = yield axios.get(
      `/chef/getPayoutDetails?month=${month}&year=${year}`,
    );
    if (response.status === 200) {
      yield put(
        getPaymentHistorySuccess({ paymentHistory: response.data.data }),
      );
    } else {
      yield put(
        getPaymentHistoryFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getPaymentHistoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getPaymentHistoryFail(error.response.data.message));
      } else {
        yield put(getPaymentHistoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getPaymentHistoryFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getPaymentHistoryDetailsSaga(action) {
  yield put(getPaymentHistoryDetailsStart());
  try {
    const response = yield axios.get(
      `/chef/getPaymentHistoryDetails?id=${action.payload.id}`,
    );
    if (response.status === 200) {
      yield put(
        getPaymentHistoryDetailsSuccess({ inquiries: response.data.data }),
      );
    } else {
      yield put(
        getPaymentHistoryDetailsFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getPaymentHistoryDetailsFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getPaymentHistoryDetailsFail(error.response.data.message));
      } else {
        yield put(
          getPaymentHistoryDetailsFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getPaymentHistoryDetailsFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* payPaymentSaga(action) {
  yield put(payPaymentStart());
  const {
    transactionId,
    payoutId,
    note,
    tableFilter,
    setIsDisable,
    closeModel,
  } = action.payload;
  try {
    const response = yield axios.post(`/admin/updatePayout`, {
      transactionId,
      payoutId,
      note,
    });
    if (response.status === 200) {
      closeModel();
      setIsDisable(false);
      yield put(
        payPaymentSuccess({
          payoutId,
          isList: tableFilter !== undefined,
          transactionId,
        }),
      );
      if (tableFilter) {
        yield put(getAllChefPayoutListSagaAction(tableFilter));
      }
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(payPaymentFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      setIsDisable(false);
      if (error.response.status === 400) {
        yield put(payPaymentFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(payPaymentFail(error.response.data.message));
      } else {
        yield put(payPaymentFail('Server error! Please try again.'));
      }
    } else {
      yield put(payPaymentFail('Something went wrong! Please try again.'));
    }
  }
}
export function* getAllUserPaymentTypeSaga(action) {
  yield put(getAllUserPaymentTypeStart());
  try {
    const response = yield axios.get(urlPaginationGenerator(`/user/packagetypes`, action.payload));
    if (response.status === 200) {
      yield put(
        getAllUserPaymentTypeSuccess(response.data),
      );
    } else {
      yield put(
        getAllUserPaymentTypeFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllUserPaymentTypeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllUserPaymentTypeFail(error.response.data.message));
      } else {
        yield put(
          getAllUserPaymentTypeFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getAllUserPaymentTypeFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}


export function* addPackageTypeSaga(action) {
  yield put(addAllUserPaymentTypeStart());
  const {
    packageType,
    tableFilter,
    setIsDisable,
    closeModel,

  } = action.payload;
  const data = {
    packageType,

  };
  try {
    const response = yield axios.post(`/admin/addpackagetype`, data);
    if (response.status === 200) {
      yield put(addAllUserPaymentTypeSuccess(response.data.data));
      yield closeModel();
      yield put(
        // addAllUserPaymentTypeSaga
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      setIsDisable(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000)
      yield put(addAllUserPaymentTypeSaga(tableFilter));
    } else {
      yield put(addAllUserPaymentTypeFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addAllUserPaymentTypeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addAllUserPaymentTypeFail(error.response.data.message));
      } else {
        yield put(addAllUserPaymentTypeFail('Server error! Please try again.'));
      }
    } else {
      yield put(addAllUserPaymentTypeFail('Something went wrong! Please try again.'));
    }
  }
}
