import { put } from 'redux-saga/effects';

import {
  getAllVendorsOfPromoterSaga as getAllVendorsOfPromoterSagaAction,
  getAllPromotersSaga as getAllPromotersSagaAction,
  getAllPromotersStart,
  getAllPromotersSuccess,
  getAllPromotersFail,
  addPromoterStart,
  addPromoterSuccess,
  addPromoterFail,
  showModal,
  editBlockPromoterStatusStart,
  editBlockPromoterStatusSuccess,
  editBlockPromoterStatusFail,
  logout,
  getPromoterByIdStart,
  getPromoterByIdSuccess,
  getPromoterByIdFail,
  editPromoterCommissionStart,
  editPromoterCommissionSuccess,
  editPromoterCommissionFail,
  editPromoterStart,
  editPromoterSuccess,
  editPromoterFail,
  deletePromoterStart,
  deletePromoterSuccess,
  deletePromoterFail,
  addVendorOfPromoterStart,
  addVendorofPromoterSuccess,
  addVendorofPromoterFail,
  editVendorOfPromoterStart,
  editVendorOfPromoterSuccess,
  editVendorOfPromoterFail,
  deleteVendorOfPromoterStart,
  deleteVendorOfPromoterSuccess,
  deleteVendorOfPromoterFail,
  getAllVendorsOfPromoterStart,
  getAllVendorsOfPromoterSuccess,
  getAllVendorsOfPromoterFail,
  getAllVehiclesStart,
  getAllVehiclesSuccess,
  getAllVehiclesFail,
  getAllOwnerVehiclesStart,
  getAllOwnerVehiclesSuccess,
  getAllOwnerVehiclesFail,

} from '../../actions';

import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllPromotersSaga(action) {
  yield put(getAllPromotersStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllPromoterList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllPromotersSuccess({ promoters: response.data.data }));
    } else {
      yield put(getAllPromotersFail('Something went wrong! Please try again.'));
    }
    // console.log(response.data.data);
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllPromotersFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllPromotersFail(error.response.data.message));
      } else {
        yield put(getAllPromotersFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllPromotersFail('Something went wrong! Please try again.'));
    }
  }
}

// Get Promoter By id Saga
export function* getPromoterByIdSaga(action) {
  yield put(getPromoterByIdStart());
  try {
    const response = yield axios.get(
      `/admin/getPromoterDetailById?id=${action.payload}`,
    );
    // console.log('get promoter by id saga response \n', response);
    if (response.status === 200) {
      yield put(getPromoterByIdSuccess({ promoter: response.data.data }));
    } else {
      yield put(getPromoterByIdFail('Something went wrong! Please try again.'));
    }
    // console.log(response.data.data);
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getPromoterByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getPromoterByIdFail(error.response.data.message));
      } else {
        yield put(getPromoterByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(getPromoterByIdFail('Something went wrong! Please try again.'));
    }
  }
}

export function* addPromoterSaga(action) {
  yield put(addPromoterStart());
  try {
    const { formValues, tableFilter } = action.payload;
    // console.log(action.payload);
    const response = yield axios.post('/admin/addPromoter', formValues);
    if (response.status === 200 || response.statusCode === 200) {
      yield put(addPromoterSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vendors/promoters',
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllPromotersSagaAction(tableFilter));
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(addPromoterFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addPromoterFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addPromoterFail(error.response.data.message));
      } else {
        yield put(addPromoterFail('Server error! Please try again.'));
      }
    } else {
      yield put(addPromoterFail('Something went wrong! Please try again.'));
    }
  }
}

// Block / unblock vehicle status
export function* editBlockPromoterStatusSaga(action) {
  yield put(editBlockPromoterStatusStart());
  try {
    const response = yield axios.post(
      `/admin/updatePromoterStatus`,
      action.payload,
    );
    // console.log(response);
    if (response.status === 200) {
      yield put(editBlockPromoterStatusSuccess(action.payload));
    } else {
      yield put(
        editBlockPromoterStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBlockPromoterStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBlockPromoterStatusFail(error.response.data.message));
      } else {
        yield put(
          editBlockPromoterStatusFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        editBlockPromoterStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Edit Promoter commission Start
export function* editPromoterCommissionSaga(action) {
  yield put(editPromoterCommissionStart());
  const { setIsSubmitted } = action.payload;
  try {
    const response = yield axios.post(
      `admin/editPromoterCommission`,
      action.payload,
    );
    if (response.status === 200) {
      const { closeModel, tableFilter } = action.payload;
      yield put(editPromoterCommissionSuccess());
      if (action.payload.isUpdated === true) {
        if (closeModel) {
          closeModel();
        }
        yield put(
          showModal({
            open: true,
            notifyType: 2,
            message: response.data.message || 'Success',
          }),
        );
        yield put(getAllPromotersSagaAction(tableFilter));
      }
    } else {
      yield put(
        editPromoterCommissionFail('Something went wrong! Please try again.'),
      );
      setIsSubmitted(false);
    }
  } catch (error) {
    setIsSubmitted(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editPromoterCommissionFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editPromoterCommissionFail(error.response.data.message));
      } else {
        yield put(
          editPromoterCommissionFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        editPromoterCommissionFail('Something went wrong! Please try again.'),
      );
    }
  }
}
// Edit Promoter commission Fail

// Edit PRomoter Details start
export function* editPromoterSaga(action) {
  yield put(editPromoterStart());
  try {
    const { formValues, tableFilter } = action.payload;
    console.log(action.payload);
    const response = yield axios.post('/admin/editPromoter', formValues);
    if (response.status === 200 || response.statusCode === 200) {
      yield put(editPromoterSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vendors/promoters',
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllPromotersSagaAction(tableFilter));
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(editPromoterFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editPromoterFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editPromoterFail(error.response.data.message));
      } else {
        yield put(editPromoterFail('Server error! Please try again.'));
      }
    } else {
      yield put(editPromoterFail('Something went wrong! Please try again.'));
    }
  }
}
// Edit PRomoter Details end

// Delete PRomoter Details start
export function* deletePromoterSaga(action) {
  yield put(deletePromoterStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    // console.log(action.payload);
    // To-DO change API address
    const response = yield axios.post(`/admin/deletePromoter`, { ownerid: id });
    if (response.status === 200) {
      yield put(deletePromoterSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllPromotersSagaAction(tableFilter));
    } else {
      yield put(deletePromoterFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      if (error.response.status === 400) {
        yield put(deletePromoterFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deletePromoterFail(error.response.data.message));
      } else {
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: 'Server error! Please try again.',
          }),
        );
        yield put(deletePromoterFail('Server error! Please try again.'));
      }
    } else {
      yield put(deletePromoterFail('Something went wrong! Please try again.'));
    }
  }
}
// Delete PRomoter Details end

// Promoter Login Side
// Get All Vendors added by promoter
export function* getAllVendorsOfPromoterSaga(action) {
  yield put(getAllVendorsOfPromoterStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/promoter/getAllChefList`, action.payload),
    );
    console.log('Response from get all vendors of promoter saga \n', response);
    if (response.status === 200) {
      yield put(getAllVendorsOfPromoterSuccess({ chefs: response.data.data }));
    } else {
      yield put(
        getAllVendorsOfPromoterFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllVendorsOfPromoterFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllVendorsOfPromoterFail(error.response.data.message));
      } else {
        yield put(
          getAllVendorsOfPromoterFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getAllVendorsOfPromoterFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Add Vendor
export function* addVendorOfPromoterSaga(action) {
  yield put(addVendorOfPromoterStart());
  try {
    const { formValues, tableFilter } = action.payload;
    console.log(action.payload);
    const response = yield axios.post('/promoter/addChef', formValues);
    if (response.status === 200 || response.statusCode === 200) {
      yield put(addVendorofPromoterSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/promoters/vendors',
          // message: response.data.message || 'Success',
          message: "Vendor added successfully",
        }),
      );
      yield put(getAllVendorsOfPromoterSagaAction(tableFilter));
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(
        addVendorofPromoterFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addVendorofPromoterFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addVendorofPromoterFail(error.response.data.message));
      } else {
        yield put(addVendorofPromoterFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        addVendorofPromoterFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Edit Vendor Of Promoter
export function* editVendorOfPromoterSaga(action) {
  yield put(editVendorOfPromoterStart());
  try {
    const { formValues, tableFilter } = action.payload;
    console.log(action.payload);
    const response = yield axios.post('/promoter/editChef', formValues);
    if (response.status === 200 || response.statusCode === 200) {
      yield put(editVendorOfPromoterSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: '/vendors/promoters',
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllVendorsOfPromoterSagaAction(tableFilter));
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(
        editVendorOfPromoterFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editVendorOfPromoterFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editVendorOfPromoterFail(error.response.data.message));
      } else {
        yield put(editVendorOfPromoterFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editVendorOfPromoterFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Delete Vendor added by promoter
export function* deleteVendorOfPromoterSaga(action) {
  yield put(deleteVendorOfPromoterStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    console.log(action.payload);
    // To-DO change API address
    const response = yield axios.post(`/admin/deleteVendorOfPromoter`, {
      vendorId: id,
    });
    if (response.status === 200) {
      yield put(deleteVendorOfPromoterSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllVendorsOfPromoterSagaAction(tableFilter));
    } else {
      yield put(
        deleteVendorOfPromoterFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      if (error.response.status === 400) {
        yield put(deleteVendorOfPromoterFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteVendorOfPromoterFail(error.response.data.message));
      } else {
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: 'Server error! Please try again.',
          }),
        );
        yield put(
          deleteVendorOfPromoterFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        deleteVendorOfPromoterFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getAllVehicleOfPromoterSaga(action) {
  yield put(getAllOwnerVehiclesStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/promoter/ownerVehicleList`, action.payload),
    );
    console.log('promoter vehicle list \n', response);
    if (response.status === 200) {
      // <<<<<<< shivam
      yield put(getAllOwnerVehiclesSuccess({ vehicles: response.data.data }));
      // =======
      //       yield put(getAllOwnerVehiclesSuccess({ vehicles: response.data.data }));
      // >>>>>>> master
    } else {
      yield put(
        getAllOwnerVehiclesFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllOwnerVehiclesFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllOwnerVehiclesFail(error.response.data.message));
      } else {
        yield put(getAllOwnerVehiclesFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllOwnerVehiclesFail('Something went wrong! Please try again.'),
      );
    }
  }
}
