import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  notification: { list: [], totalRecords: { count: 0 } },
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_NOTIFICATION_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_NOTIFICATION_SUCCESS: {
      const { notification } = payload;
      return { ...state, notification, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_NOTIFICATION_FAIL:
      return {
        ...state,
        notification: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.EDIT_MARK_AS_READ_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_MARK_AS_READ_SUCCESS: {
      const { notification } = state;
      const index = notification.list.findIndex(
        item => item._id === payload.id,
      );
      if (index !== -1 && notification.list.length > index) {
        notification.list[index].isRead = true;
      }
      return { ...state, notification, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_MARK_AS_READ_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_NOTIFICATION_LIST: {
      return {
        ...state,
        notification: { list: [], totalRecords: { count: 0 } },
      };
    }
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }
    default:
      return state;
  }
};
