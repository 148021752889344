import {
  GET_ALL_ORDER_START,
  GET_ALL_ORDER_SAGA,
  GET_ALL_ORDER_SUCCESS,
  GET_ALL_ORDER_FAIL,
  GET_ORDER_TRACK_START,
  GET_ORDER_TRACK_SAGA,
  GET_ORDER_TRACK_SUCCESS,
  GET_ORDER_TRACK_FAIL,
  GET_ALL_CHEF_ORDER_START,
  GET_ALL_CHEF_ORDER_SAGA,
  GET_ALL_CHEF_ORDER_SUCCESS,
  GET_ALL_CHEF_ORDER_FAIL,
  GET_ORDER_BY_ID_START,
  GET_ORDER_BY_ID_SAGA,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ORDER_BY_ID_FAIL,
  GET_ALL_ORDER_BY_USER_ID_START,
  GET_ALL_ORDER_BY_USER_ID_SAGA,
  GET_ALL_ORDER_BY_USER_ID_SUCCESS,
  GET_ALL_ORDER_BY_USER_ID_FAIL,
  GET_ORDER_COUNT_START,
  GET_ORDER_COUNT_SAGA,
  GET_CHEF_ORDER_COUNT_SAGA,
  GET_ORDER_COUNT_SUCCESS,
  GET_ORDER_COUNT_FAIL,
  EDIT_INSTRUCTION_START,
  EDIT_INSTRUCTION_SAGA,
  EDIT_INSTRUCTION_SUCCESS,
  EDIT_INSTRUCTION_FAIL,
  RESET_ORDER_TRACK,
  GET_ORDER_TRACK_CHEF_SAGA,
  GET_ALL_CHEF_UPCOMING_ORDER_START,
  GET_ALL_CHEF_UPCOMING_ORDER_SAGA,
  GET_ALL_CHEF_UPCOMING_ORDER_SUCCESS,
  GET_ALL_CHEF_UPCOMING_ORDER_FAIL,
  RESET_ORDER_LIST,
  EDIT_ACCEPT_ORDER_FAIL,
  EDIT_REJECT_ORDER_START,
  EDIT_REJECT_ORDER_SAGA,
  EDIT_REJECT_ORDER_FAIL,
  EDIT_REJECT_ORDER_SUCCESS,
  EDIT_ACCEPT_ORDER_START,
  EDIT_ACCEPT_ORDER_SAGA,
  EDIT_ACCEPT_ORDER_SUCCESS,
  EDIT_ORDER_STATUS_START,
  EDIT_ORDER_STATUS_SAGA,
  EDIT_ORDER_STATUS_SUCCESS,
  EDIT_ORDER_STATUS_FAIL,
  RESET_ORDERS_LIST_CHEF,
  TRANSACTION_HISTORY_START,
  TRANSACTION_HISTORY_SAGA,
  TRANSACTION_HISTORY_SUCCESS,
  TRANSACTION_HISTORY_FAIL,
} from '../actionLabels';

export const getAllOrderStart = payload => ({
  type: GET_ALL_ORDER_START,
  payload,
});

export const getAllOrderSaga = payload => ({
  type: GET_ALL_ORDER_SAGA,
  payload,
});

export const getAllOrderSuccess = payload => ({
  type: GET_ALL_ORDER_SUCCESS,
  payload,
});

export const getAllOrderFail = payload => ({
  type: GET_ALL_ORDER_FAIL,
  payload,
});

export const getOrderTrackStart = payload => ({
  type: GET_ORDER_TRACK_START,
  payload,
});

export const getOrderTrackSaga = payload => ({
  type: GET_ORDER_TRACK_SAGA,
  payload,
});

export const getOrderTrackChefSaga = payload => ({
  type: GET_ORDER_TRACK_CHEF_SAGA,
  payload,
});

export const getOrderTrackSuccess = payload => ({
  type: GET_ORDER_TRACK_SUCCESS,
  payload,
});

export const getOrderTrackFail = payload => ({
  type: GET_ORDER_TRACK_FAIL,
  payload,
});

export const getAllChefOrderStart = payload => ({
  type: GET_ALL_CHEF_ORDER_START,
  payload,
});

export const getAllChefOrderSaga = payload => ({
  type: GET_ALL_CHEF_ORDER_SAGA,
  payload,
});

export const getAllChefOrderSuccess = payload => ({
  type: GET_ALL_CHEF_ORDER_SUCCESS,
  payload,
});

export const getAllChefOrderFail = payload => ({
  type: GET_ALL_CHEF_ORDER_FAIL,
  payload,
});

export const getOrderByIdStart = () => ({
  type: GET_ORDER_BY_ID_START,
});

export const getOrderByIdSaga = payload => ({
  type: GET_ORDER_BY_ID_SAGA,
  payload,
});

export const getOrderByIdSuccess = payload => ({
  type: GET_ORDER_BY_ID_SUCCESS,
  payload,
});

export const getOrderByIdFail = payload => ({
  type: GET_ORDER_BY_ID_FAIL,
  payload,
});

export const getAllOrderByUserIdStart = () => ({
  type: GET_ALL_ORDER_BY_USER_ID_START,
});

export const getAllOrderByUserIdSaga = payload => ({
  type: GET_ALL_ORDER_BY_USER_ID_SAGA,
  payload,
});

export const getAllOrderByUserIdSuccess = payload => ({
  type: GET_ALL_ORDER_BY_USER_ID_SUCCESS,
  payload,
});

export const getAllOrderByUserIdFail = payload => ({
  type: GET_ALL_ORDER_BY_USER_ID_FAIL,
  payload,
});

export const getOrderCountStart = () => ({
  type: GET_ORDER_COUNT_START,
});

export const getOrderCountSaga = payload => ({
  type: GET_ORDER_COUNT_SAGA,
  payload,
});

export const getChefOrderCountSaga = payload => ({
  type: GET_CHEF_ORDER_COUNT_SAGA,
  payload,
});

export const getOrderCountSuccess = payload => ({
  type: GET_ORDER_COUNT_SUCCESS,
  payload,
});

export const getOrderCountFail = payload => ({
  type: GET_ORDER_COUNT_FAIL,
  payload,
});

export const editInstructionStart = () => ({
  type: EDIT_INSTRUCTION_START,
});

export const editInstructionSaga = payload => ({
  type: EDIT_INSTRUCTION_SAGA,
  payload,
});

export const editInstructionSuccess = payload => ({
  type: EDIT_INSTRUCTION_SUCCESS,
  payload,
});

export const editInstructionFail = payload => ({
  type: EDIT_INSTRUCTION_FAIL,
  payload,
});

export const resetOrderTrack = payload => ({
  type: RESET_ORDER_TRACK,
  payload,
});

export const getAllChefUpcomingOrderStart = payload => ({
  type: GET_ALL_CHEF_UPCOMING_ORDER_START,
  payload,
});

export const getAllChefUpcomingOrderSaga = payload => ({
  type: GET_ALL_CHEF_UPCOMING_ORDER_SAGA,
  payload,
});

export const getAllChefUpcomingOrderSuccess = payload => ({
  type: GET_ALL_CHEF_UPCOMING_ORDER_SUCCESS,
  payload,
});

export const getAllChefUpcomingOrderFail = payload => ({
  type: GET_ALL_CHEF_UPCOMING_ORDER_FAIL,
  payload,
});

export const editAcceptOrderStart = () => ({
  type: EDIT_ACCEPT_ORDER_START,
});

export const editAcceptOrderSaga = payload => ({
  type: EDIT_ACCEPT_ORDER_SAGA,
  payload,
});

export const editAcceptOrderSuccess = payload => ({
  type: EDIT_ACCEPT_ORDER_SUCCESS,
  payload,
});

export const editAcceptOrderFail = payload => ({
  type: EDIT_ACCEPT_ORDER_FAIL,
  payload,
});

export const editRejectOrderStart = () => ({
  type: EDIT_REJECT_ORDER_START,
});

export const editRejectOrderSaga = payload => ({
  type: EDIT_REJECT_ORDER_SAGA,
  payload,
});

export const editRejectOrderSuccess = payload => ({
  type: EDIT_REJECT_ORDER_SUCCESS,
  payload,
});

export const editRejectOrderFail = payload => ({
  type: EDIT_REJECT_ORDER_FAIL,
  payload,
});

export const editOrderStatusStart = () => ({
  type: EDIT_ORDER_STATUS_START,
});

export const editOrderStatusSaga = payload => ({
  type: EDIT_ORDER_STATUS_SAGA,
  payload,
});

export const editOrderStatusSuccess = payload => ({
  type: EDIT_ORDER_STATUS_SUCCESS,
  payload,
});

export const editOrderStatusFail = payload => ({
  type: EDIT_ORDER_STATUS_FAIL,
  payload,
});

export const transactionHistoryStart = () => ({
  type: TRANSACTION_HISTORY_START,
});

export const transactionHistorySaga = payload => ({
  type: TRANSACTION_HISTORY_SAGA,
  payload,
});

export const transactionHistorySuccess = payload => ({
  type: TRANSACTION_HISTORY_SUCCESS,
  payload,
});

export const transactionHistoryFail = payload => ({
  type: TRANSACTION_HISTORY_FAIL,
  payload,
});

export const resetOrderList = () => ({
  type: RESET_ORDER_LIST,
});

export const resetOrdersListChef = () => ({
  type: RESET_ORDERS_LIST_CHEF,
});
