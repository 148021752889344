import {
    DELETE_DRIVER_BY_ID_FAIL,
    DELETE_DRIVER_BY_ID_SAGA,
    DELETE_DRIVER_BY_ID_START,
    DELETE_DRIVER_BY_ID_SUCCESS
} from "../actionLabels";

export const deleteDriverByIdStart = () => ({
    type: DELETE_DRIVER_BY_ID_START,
});

export const deleteDriverByIdSaga = payload => ({
    type: DELETE_DRIVER_BY_ID_SAGA,
    payload,
});

export const deleteDriverByIdSuccess = payload => ({
    type: DELETE_DRIVER_BY_ID_SUCCESS,
    payload,
});

export const deleteDriverByIdFail = payload => ({
    type: DELETE_DRIVER_BY_ID_FAIL,
    payload,
});