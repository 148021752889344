import { put } from 'redux-saga/effects';
import {
  logout,
  getAllContactusStart,
  getAllContactusSuccess,
  getAllContactusFail,
  editMarkAsResolvedStart,
  editMarkAsResolvedSuccess,
  editMarkAsResolvedFail,
  getAllContactusSaga as getAllContactusSagaAction,
  showModal,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllContactusSaga(action) {
  yield put(getAllContactusStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllContactUsList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllContactusSuccess({ contactus: response.data.data }));
    } else {
      yield put(getAllContactusFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllContactusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllContactusFail(error.response.data.message));
      } else {
        yield put(getAllContactusFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllContactusFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editMarkAsResolvedSaga(action) {
  yield put(editMarkAsResolvedStart());
  try {
    const { tableFilter } = action.payload;
    const response = yield axios.post(`/admin/markAsResolved`, action.payload);
    if (response.status === 200) {
      yield put(editMarkAsResolvedSuccess(action.payload));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllContactusSagaAction(tableFilter));
    } else {
      yield put(
        editMarkAsResolvedFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editMarkAsResolvedFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editMarkAsResolvedFail(error.response.data.message));
      } else {
        yield put(editMarkAsResolvedFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editMarkAsResolvedFail('Something went wrong! Please try again.'),
      );
    }
  }
}
