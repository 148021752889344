import { CHANGE_CURRENT_TAB, RESET_CURRENT_TAB } from '../actionLabels';

export const changeCurrentTab = payload => ({
  type: CHANGE_CURRENT_TAB,
  payload,
});

export const resetCurrentTab = payload => ({
  type: RESET_CURRENT_TAB,
  payload,
});
