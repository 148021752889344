import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  orders: { list: [], totalRecords: { count: 0 } },
  acceptOrderList: [],
  order: {},
  orderTrack: {},
  ordersByUserId: { list: [], totalRecords: { count: 0 } },
  orderCount: {
    acceptedOrderCount: 0,
    deliveredOrderCount: 0,
    preparingOrderCount: 0,
    pendingOrderCount: 0,
    pickedUpOrderCount: 0,
    rejectedOrderCount: 0,
    totalOrderCount: 0,
  },
  transactionHistory: { list: [], totalRecords: { count: 0 } },
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_ORDER_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_ORDER_SUCCESS: {
      const { orders } = payload;
      return { ...state, orders, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_ORDER_FAIL:
      return {
        ...state,
        orders: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_ORDER_TRACK_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ORDER_TRACK_SUCCESS: {
      const { orderTrack } = payload;
      return { ...state, orderTrack, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ORDER_TRACK_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GET_ALL_CHEF_ORDER_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_CHEF_ORDER_SUCCESS: {
      const { orders } = payload;
      return { ...state, orders, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_CHEF_ORDER_FAIL:
      return {
        ...state,
        isLoading: false,
        errorMsg: payload,
        orders: { list: [], totalRecords: { count: 0 } },
      };
    case actionLabels.GET_ORDER_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ORDER_BY_ID_SUCCESS: {
      const { order } = payload;
      return { ...state, order: order || {}, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ORDER_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.GET_ALL_ORDER_BY_USER_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_ORDER_BY_USER_ID_SUCCESS: {
      const { ordersByUserId } = payload;
      return { ...state, ordersByUserId, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_ORDER_BY_USER_ID_FAIL:
      return {
        ...state,
        ordersByUserId: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_ORDER_COUNT_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ORDER_COUNT_SUCCESS: {
      const orderCount = payload;
      return { ...state, orderCount, isLoading: false };
    }
    case actionLabels.GET_ORDER_COUNT_FAIL:
      return { ...state, isLoading: false };
    case actionLabels.EDIT_INSTRUCTION_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_INSTRUCTION_SUCCESS: {
      const { orders } = state;
      orders.list.filter(
        item => item._id === payload.orderId,
      )[0].cookingInstructionsByAdmin = payload.cookingInstructions;
      return { ...state, orders, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_INSTRUCTION_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_ORDER_TRACK:
      return { ...state, orderTrack: {}, isLoading: false, errorMsg: '' };
    case actionLabels.GET_ALL_CHEF_UPCOMING_ORDER_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_CHEF_UPCOMING_ORDER_SUCCESS: {
      const { orders } = payload;
      return { ...state, orders, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_CHEF_UPCOMING_ORDER_FAIL:
      return {
        ...state,
        orders: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.EDIT_ACCEPT_ORDER_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_ACCEPT_ORDER_SUCCESS: {
      return { ...state, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_ACCEPT_ORDER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_REJECT_ORDER_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_REJECT_ORDER_SUCCESS: {
      return { ...state, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_REJECT_ORDER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_ORDER_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_ORDER_STATUS_SUCCESS: {
      return { ...state, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_ORDER_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.TRANSACTION_HISTORY_START:
      return { ...state, isLoading: true };
    case actionLabels.TRANSACTION_HISTORY_SUCCESS: {
      return { ...state, isLoading: false, errorMsg: '' };
    }
    case actionLabels.TRANSACTION_HISTORY_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_ORDER_LIST:
      return {
        ...state,
        ordersByUserId: { list: [], totalRecords: { count: 0 } },
      };
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }
    case actionLabels.RESET_ORDERS_LIST_CHEF: {
      return {
        orders: { list: [], totalRecords: { count: 0 } },
        order: {},
        orderTrack: {},
        ordersByUserId: { list: [], totalRecords: { count: 0 } },
        orderCount: {
          acceptedOrderCount: 0,
          deliveredOrderCount: 0,
          pendingOrderCount: 0,
          rejectedOrderCount: 0,
          totalOrderCount: 0,
        },
        isLoading: false,
        errorMsg: '',
      };
    }
    case "ADD_TO_ACCEPTING_ORDER": {
      console.log(payload)
      if (initialState.acceptOrderList.includes(payload._id)) {
        const selectedData = initialState.acceptOrderList.filter((item) => {
          return item === payload._id ? false : true
        })
        console.log(selectedData)
      } else {
        initialState.acceptOrderList.push(payload._id)
      }
    }
    default:
      return state;
  }
};
