import {
  GET_ALL_USER_START,
  GET_ALL_USER_SAGA,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_FAIL,
  GET_USER_BY_ID_START,
  GET_USER_BY_ID_SAGA,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
  GET_ACTIVE_INACTIVE_USER_COUNT_START,
  GET_ACTIVE_INACTIVE_USER_COUNT_SAGA,
  GET_ACTIVE_INACTIVE_USER_COUNT_SUCCESS,
  GET_ACTIVE_INACTIVE_USER_COUNT_FAIL,
  DELETE_USER_START,
  DELETE_USER_SAGA,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  EDIT_BLOCK_USER_STATUS_START,
  EDIT_BLOCK_USER_STATUS_SAGA,
  EDIT_BLOCK_USER_STATUS_SUCCESS,
  EDIT_BLOCK_USER_STATUS_FAIL,
  RESET_USER_DETAIL,
  EDIT_ACCEPTING_ORDER_START,
  EDIT_ACCEPTING_ORDER_SAGA,
  EDIT_ACCEPTING_ORDER_SUCCESS,
  EDIT_ACCEPTING_ORDER_FAIL,
  GET_ALL_USER_ORDER_SAGA,
  GET_ALL_USER_ORDER_FAIL,
  GET_ALL_USER_ORDER_SUCCESS,
  GET_ALL_USER_ORDER_START,
  GET_ALL_USER_ORDER_DETAIL_SAGA,
  GET_ALL_USER_ORDER_DETAIL_START,
  GET_ALL_USER_ORDER_DETAIL_SUCCESS,
  GET_ALL_USER_ORDER_DETAIL_FAIL,
 
} from '../actionLabels';

export const getAllUserStart = () => ({
  type: GET_ALL_USER_START,
});

export const getAllUserSaga = payload => ({
  type: GET_ALL_USER_SAGA,
  payload,
});

export const getAllUserSuccess = payload => ({
  type: GET_ALL_USER_SUCCESS,
  payload,
});

export const getAllUserFail = payload => ({
  type: GET_ALL_USER_FAIL,
  payload,
});

export const getUserByIdStart = () => ({
  type: GET_USER_BY_ID_START,
});

export const getUserByIdSaga = payload => ({
  type: GET_USER_BY_ID_SAGA,
  payload,
});

export const getUserByIdSuccess = payload => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload,
});

export const getUserByIdFail = payload => ({
  type: GET_USER_BY_ID_FAIL,
  payload,
});

export const getActiveInActiveUserCountStart = () => ({
  type: GET_ACTIVE_INACTIVE_USER_COUNT_START,
});

export const getActiveInActiveUserCountSaga = payload => ({
  type: GET_ACTIVE_INACTIVE_USER_COUNT_SAGA,
  payload,
});

export const getActiveInActiveUserCountSuccess = payload => ({
  type: GET_ACTIVE_INACTIVE_USER_COUNT_SUCCESS,
  payload,
});

export const getActiveInActiveUserCountFail = payload => ({
  type: GET_ACTIVE_INACTIVE_USER_COUNT_FAIL,
  payload,
});

export const deleteUserStart = () => ({
  type: DELETE_USER_START,
});

export const deleteUserSaga = payload => ({
  type: DELETE_USER_SAGA,
  payload,
});

export const deleteUserSuccess = payload => ({
  type: DELETE_USER_SUCCESS,
  payload,
});

export const deleteUserFail = payload => ({
  type: DELETE_USER_FAIL,
  payload,
});

export const editBlockUserStatusStart = () => ({
  type: EDIT_BLOCK_USER_STATUS_START,
});

export const editBlockUserStatusSaga = payload => ({
  type: EDIT_BLOCK_USER_STATUS_SAGA,
  payload,
});

export const editBlockUserStatusSuccess = payload => ({
  type: EDIT_BLOCK_USER_STATUS_SUCCESS,
  payload,
});

export const editBlockUserStatusFail = payload => ({
  type: EDIT_BLOCK_USER_STATUS_FAIL,
  payload,
});

export const resetUserDetail = payload => ({
  type: RESET_USER_DETAIL,
  payload,
});




export const editAcceptingOrderStart = () => ({
  type: EDIT_ACCEPTING_ORDER_START,
});

export const editAcceptingOrderSaga = payload => ({
  type: EDIT_ACCEPTING_ORDER_SAGA,
  payload,
});

export const editAcceptingOrderSuccess = payload => ({
  type: EDIT_ACCEPTING_ORDER_SUCCESS,
  payload,
});

export const editAcceptingOrderFail = payload => ({
  type: EDIT_ACCEPTING_ORDER_FAIL,
  payload,
});
















export const getAllUserOrderSaga = payload => ({
  type: GET_ALL_USER_ORDER_SAGA,
  payload,
})


export const getAllUserOrderStart = () => ({
  type: GET_ALL_USER_ORDER_START,
})

export const getAllUserOrderSuccess = payload => ({
  type: GET_ALL_USER_ORDER_SUCCESS,
  payload,
})

export const getAllUserOrderFail = payload => ({
  type: GET_ALL_USER_ORDER_FAIL,
  payload,
})

export const getAllUserOrderDetailSaga = payload => ({
  type: GET_ALL_USER_ORDER_DETAIL_SAGA,
  payload,
})

export const getAllUserOrderDetailStart = () => ({
  type: GET_ALL_USER_ORDER_DETAIL_START,
})

export const getAllUserOrderDetailSuccess = payload => ({
  type: GET_ALL_USER_ORDER_DETAIL_SUCCESS,
  payload,
})

export const getAllUserOrderDetailFail = payload => ({
  type: GET_ALL_USER_ORDER_DETAIL_FAIL,
  payload,
})

