export const GET_ALL_STORE_TYPES_START = 'GET_ALL_STORE_TYPES_START';
export const GET_ALL_STORE_TYPES_SAGA = 'GET_ALL_STORE_TYPES_SAGA';
export const GET_ALL_STORE_TYPES_SUCCESS = 'GET_ALL_STORE_TYPES_SUCCESS';
export const GET_ALL_STORE_TYPES_FAIL = 'GET_ALL_STORE_TYPES_FAIL';

export const ADD_STORE_TYPE_START = 'ADD_STORE_TYPE_START';
export const ADD_STORE_TYPE_SAGA = 'ADD_STORE_TYPE_SAGA';
export const ADD_STORE_TYPE_SUCCESS = 'ADD_STORE_TYPE_SUCCESS';
export const ADD_STORE_TYPE_FAIL = 'ADD_STORE_TYPE_FAIL';

export const EDIT_STORE_TYPE_START = 'EDIT_STORE_TYPE_START';
export const EDIT_STORE_TYPE_SAGA = 'EDIT_STORE_TYPE_SAGA';
export const EDIT_STORE_TYPE_SUCCESS = 'EDIT_STORE_TYPE_SUCCESS';
export const EDIT_STORE_TYPE_FAIL = 'EDIT_STORE_TYPE_FAIL';

export const EDIT_STORE_TYPE_STATUS_START = 'EDIT_STORE_TYPE_STATUS_START';
export const EDIT_STORE_TYPE_STATUS_SAGA = 'EDIT_STORE_TYPE_STATUS_SAGA';
export const EDIT_STORE_TYPE_STATUS_SUCCESS = 'EDIT_STORE_TYPE_STATUS_SUCCESS';
export const EDIT_STORE_TYPE_STATUS_FAIL = 'EDIT_STORE_TYPE_STATUS_FAIL';

export const DELETE_STORE_TYPE_START = 'DELETE_STORE_TYPE_START';
export const DELETE_STORE_TYPE_SAGA = 'DELETE_STORE_TYPE_SAGA';
export const DELETE_STORE_TYPE_SUCCESS = 'DELETE_STORE_TYPE_SUCCESS';
export const DELETE_STORE_TYPE_FAIL = 'DELETE_STORE_TYPE_FAIL';

export const CLEAR_STORE_TYPE_ERROR_MSG = 'CLEAR_STORE_TYPE_ERROR_MSG';
