import React from 'react';

export const adminRoutes = [
  {
    path: '/admin/dashboard',
    exact: true,
    name: 'Dashboard',
    component: React.lazy(() =>
      import('../../views/admin/Dashboard/Dashboard'),
    ),
  },
  {
    path: '/vendors/profile',
    name: 'Profile',
    component: React.lazy(() => import('../../views/admin/Profile/Profile')),
  },
  {
    path: '/vendors/users',
    exact: true,
    name: 'Users',
    component: React.lazy(() => import('../../views/admin/Users/Users')),
  },
  {
    path: '/vendors/users/user-detail/:id',
    name: 'User Detail',
    component: React.lazy(() => import('../../views/admin/Users/UserDetail')),
  },
  {
    path: '/vendors/user-package',
    name: 'User Package',
    component: React.lazy(() => import('../../views/admin/UserPackage/UserPackage')),
  },
  {
    path: '/vendors/user-package-type',
    name: 'User Package Type',
    component: React.lazy(() => import('../../views/admin/UserPackage/UserPackageType')),
  },
  {
    path: '/vendors/user-package-detail/:id',
    name: 'User Package Detail',
    component: React.lazy(() => import('../../views/admin/UserPackage/UserPackageDetail')),
  },
  {
    path: '/vendors/chefs',
    exact: true,
    name: 'Vendors',
    component: React.lazy(() => import('../../views/admin/Chefs/Chefs')),
  },
  {
    path: '/vendors/chefs/chef-detail/:id',
    name: 'Vendors Detail',
    component: React.lazy(() => import('../../views/admin/Chefs/ChefDetail')),
  },
  {
    path: '/vendors/chefs/rating-detail/:id',
    name: 'Rating Detail',
    component: React.lazy(() => import('../../views/admin/Chefs/RatingDetail')),
  },
  // Drivers , SS
  {
    path: '/vendors/drivers',
    exact: true,
    name: 'Drivers',
    component: React.lazy(() => import('../../views/admin/Drivers/Drivers')),
  },
  {
    path: '/vendors/drivers/add-driver',
    exact: true,
    name: 'Add Driver',
    component: React.lazy(() => import('../../views/admin/Drivers/AddDriver')),
  },
  {
    path: '/vendors/drivers/driver-detail/:id',
    name: 'Driver Details',
    component: React.lazy(() =>
      import('../../views/admin/Drivers/DriverDetails'),
    ),
  },
  // {
  //   path: '/vendors/drivers/add-owner',
  //   exact: true,
  //   name: 'Add Owner',
  //   component: React.lazy(() => import('../../views/admin/Drivers/AddOwner')),
  // },
  // {
  //   path: '/vendors/drivers/add-vehicle',
  //   exact: true,
  //   name: 'Add Vehicle',
  //   component: React.lazy(() => import('../../views/admin/Drivers/AddVehicle')),
  // },
  // ========== Owner inside Drivers

//Logistics Hub
  {
    path: '/vendors/logistic-hub',
    name: 'logistic Hub',
    component: React.lazy(() =>
      import('../../views/admin/LogisticHub/LogisticHub'),
    ),
  },
  {
    path: '/vendors/logistic-hub-details/:id',
    name: 'logistic Hub',
    component: React.lazy(() =>
      import('../../views/admin/LogisticHub/LogisticHubDetail'),
    ),
  },
  {
    path: '/logistics/logistic-order-details/:id',
    name: 'logistic order detail',
    component: React.lazy(() =>
      import('../../views/admin/LogisticHub/LogisticOrderDetail.jsx'),
    ),
  },
  {
    path: '/vendors/logistic-orders',
    name: 'logistic Order',
    component: React.lazy(() =>
      import('../../views/admin/LogisticHub/LogisticOrders'),
    ),
  },
  {
    path: '/vendors/InterCity-orders',
    name: 'logistic Order',
    component: React.lazy(() =>
      import('../../views/admin/LogisticHub/InterCityOrder'),
    ),
  },
  {
    path: '/vendors/InterCity-orders-detail',
    name: 'logistic Order',
    component: React.lazy(() =>
      import('../../views/admin/LogisticHub/InterCityOrderDetail'),
    ),
  },

  // Commissions , SS ====================================================
  {
    path: '/vendors/commissions',
    name: 'Commissions',
    component: React.lazy(() =>
      import('../../views/admin/Commissions/Commissions'),
    ),
  },
  // {
  //   path: '/vendors/commissions/commissionhistory',
  //   name: 'Commissions History',
  //   component: React.lazy(() =>
  //     import('../../views/admin/Commissions/CommissionHistory'),
  //   ),
  // },
  {
    path: '/vendors/commissionsDetails/:id',
    name: 'Commissions Details',
    exact: true,
    component: React.lazy(() =>
      import('../../views/admin/Commissions/CommissionDetail'),
    ),
  },
  // Locations , SS ====================================================
  {
    path: '/vendors/locations',
    exact: true,
    name: 'Locations',
    component: React.lazy(() =>
      import('../../views/admin/Locations/Locations'),
    ),
  },
  // Notifications , SS ====================================================
  {
    path: '/vendors/requests',
    exact: true,
    name: 'Enquiries',
    component: React.lazy(() => import('../../views/admin/Requests/Requests')),
  },
  {
    path: '/vendors/requests/request-detail/:id',
    name: 'Enquiry Detail',
    component: React.lazy(() =>
      import('../../views/admin/Requests/RequestDetail'),
    ),
  },
  // Product , SS ====================================================
  {
    path: '/vendors/products',
    exact: true,
    name: 'Products',
    component: React.lazy(() => import('../../views/admin/Products/Products')),
  },
  {
    path: '/vendors/productss/products-detail/:id',
    name: 'Product Details',
    component: React.lazy(() =>
      import('../../views/admin/Drivers/DriverDetails'),
    ),
  },
  // Transportation Medium ==========================
  {
    path: '/vendors/transportation-medium',
    exact: true,
    name: 'Transportation Mmedium',
    component: React.lazy(() =>
      import('../../views/admin/TransportationModes/TransportationModes'),
    ),
  },

  // Vehicle Types ==========================
  {
    path: '/vendors/vehicle-types',
    exact: true,
    name: 'Vehicle Types',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/VehiclesTypes'),
    ),
  },
  {
    path: '/vendors/vehicles/add-vehicle',
    exact: true,
    name: 'Add Vehicle Type',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/AddVehicleType'),
    ),
  },
  {
    path: '/vendors/vehicles/edit-vehicle/:id',
    exact: true,
    name: 'Edit Vehicle Type',
    component: React.lazy(() =>
      import('../../views/admin/VehiclesTypes/EditVehicleType'),
    ),
  },

  // Vehicle Owners==========================
  {
    path: '/vendors/vehicle-owners',
    exact: true,
    name: 'Vehicle Owners ',
    component: React.lazy(() =>
      import('../../views/admin/VehicleOwners/VehicleOwners'),
    ),
  },
  {
    path: '/vendors/vehicle-owner-detail/:id',
    exact: true,
    name: 'Vehicle Owners Detail',
    component: React.lazy(() =>
      import('../../views/admin/VehicleOwners/ViewVehicleOwner'),
    ),
  },
  {
    path: '/vendors/vehicle-owner-detail/vehicle/:id',
    exact: true,
    name: 'Owner Vehicle Detail',
    component: React.lazy(() =>
      import('../../views/admin/VehicleOwners/ViewVehicleDetails'),
    ),
  },

  {
    path: '/vendors/vehicle-owners/add-vehicle-owner',
    exact: true,
    name: 'Add Vehicle Owners',
    component: React.lazy(() =>
      import('../../views/admin/VehicleOwners/AddVehicleOwner'),
    ),
  },
  {
    path: '/vendors/vehicle-owners/edit-vehicle-owner/:id',
    exact: true,
    name: 'Edit Vehicle Owners',
    component: React.lazy(() =>
      import('../../views/admin/VehicleOwners/EditVehicleOwner'),
    ),
  },

  {
    path: '/vendors/subadmins',
    name: 'Subadmins',
    component: React.lazy(() =>
      import('../../views/admin/Subadmins/Subadmins'),
    ),
  },
  {
    path: '/vendors/orders',
    name: 'Orders',
    component: React.lazy(() => import('../../views/admin/Orders/Orders')),
  },
  {
    path: '/vendors/promocodes',
    exact: true,
    name: 'Promocodes',
    component: React.lazy(() =>
      import('../../views/admin/Promocodes/Promocodes'),
    ),
  },
  {
    path: '/vendors/promocodes/add-promocode',
    name: 'Add Promocode',
    component: React.lazy(() =>
      import('../../views/admin/Promocodes/AddPromocode'),
    ),
  },
  {
    path: '/vendors/promocodes/edit-promocode/:id',
    name: 'Edit Promocode',
    component: React.lazy(() =>
      import('../../views/admin/Promocodes/AddPromocode'),
    ),
  },
  {
    path: '/vendors/rating-management',
    exact: true,
    name: 'Rating Management',
    component: React.lazy(() =>
      import('../../views/admin/RatingManagement/RatingManagement'),
    ),
  },
  {
    path: '/vendors/inquiries',
    exact: true,
    name: 'Enquiries',
    component: React.lazy(() =>
      import('../../views/admin/Inquiries/Inquiries'),
    ),
  },
  {
    path: '/vendors/inquiries/inquiry-detail/:id',
    name: 'Enquiry Detail',
    component: React.lazy(() =>
      import('../../views/admin/Inquiries/InquiryDetail'),
    ),
  },
  // Promoters ======================================================
  {
    path: '/vendors/promoters',
    exact: true,
    name: 'Promoters',
    component: React.lazy(() =>
      import('../../views/admin/Promoters/Promoters'),
    ),
  },
  {
    path: '/vendors/promoters/add-promoter',
    exact: true,
    name: 'Promoters',
    component: React.lazy(() =>
      import('../../views/admin/Promoters/AddPromoter'),
    ),
  },
  {
    path: '/vendors/promoters/edit-promoter/:id',
    exact: true,
    name: 'Promoters',
    component: React.lazy(() =>
      import('../../views/admin/Promoters/EditPromoter'),
    ),
  },
  {
    path: '/vendors/services',
    name: 'Services',
    component: React.lazy(() => import('../../views/admin/Services/Services')),
  },
  {
    path: '/vendors/module-access',
    name: 'Module Access',
    component: React.lazy(() =>
      import('../../views/admin/ModuleAccess/ModuleAccess'),
    ),
  },
  {
    path: '/vendors/store-Types',
    name: 'StoreTypes',
    component: React.lazy(() =>
      import('../../views/admin/StoreTypes/StoreTypes'),
    ),
  },
  {
    path: '/vendors/menu-categories',
    name: 'Products',
    component: React.lazy(() =>
      import('../../views/admin/MenuCategories/MenuCategories'),
    ),
  },
  {
    path: '/vendors/brand-types',
    name: 'Vendor Types',
    component: React.lazy(() =>
      import('../../views/admin/BrandTypes/BrandTypes'),
    ),
  },
  {
    path: '/vendors/menu-sub-categories',
    name: 'Sub-Products',
    component: React.lazy(() =>
      import('../../views/admin/MenuSubCategories/MenuSubCategories'),
    ),
  },
  {
    path: '/vendors/contact-us',
    name: 'Contact Us',
    component: React.lazy(() =>
      import('../../views/admin/ContactUs/ContactUs'),
    ),
  },
  {
    path: '/vendors/payout',
    exact: true,
    name: 'Contact Us',
    component: React.lazy(() => import('../../views/admin/Payout/Payout')),
  },
  {
    path: '/vendors/payout/:id',
    name: 'Payout',
    component: React.lazy(() =>
      import('../../views/admin/Payout/PayoutHistory'),
    ),
  },
  {
    path: '/vendors/cms',
    exact: true,
    name: 'CMS',
    component: React.lazy(() => import('../../views/admin/CMS/CMS')),
  },
  {
    path: '/vendors/cms/edit-cms/:key',
    name: 'Edit CMS',
    component: React.lazy(() => import('../../views/admin/CMS/EditCMS')),
  },
  {
    path: '/vendors/cms/view-cms/:key',
    name: 'View CMS',
    component: React.lazy(() => import('../../views/admin/CMS/ViewCMS')),
  },
  {
    path: '/vendors/reports',
    name: 'Reports',
    component: React.lazy(() => import('../../views/admin/Reports/Reports')),
  },
  {
    path: '/vendors/settings',
    name: 'Settings',
    component: React.lazy(() => import('../../views/admin/Settings/Settings')),
  },
  {
    path: '/vendors/referrals',
    name: 'Referrals',
    component: React.lazy(() => import('../../views/admin/Referral/Referrals')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/admin/dashboard',
    // rootRedirect: true,
  },
];
