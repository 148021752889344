import {
  GET_ALL_CATEGORY_START,
  GET_ALL_CATEGORY_SAGA,
  GET_ALL_CATEGORY_CHEF_SAGA,
  GET_ALL_CATEGORY_SUCCESS,
  GET_ALL_CATEGORY_FAIL,
  GET_ALL_CATEGORY_CHEF_FAIL,
  GET_ALL_SUB_CATEGORY_START,
  GET_ALL_SUB_CATEGORY_SAGA,
  GET_ALL_SUB_CATEGORY_CHEF_SAGA,
  GET_ALL_SUB_CATEGORY_SUCCESS,
  GET_ALL_SUB_CATEGORY_FAIL,
  GET_ALL_SUB_CATEGORY_CHEF_FAIL,
  GET_CATEGORY_BY_ID_START,
  GET_CATEGORY_BY_ID_SAGA,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_BY_ID_FAIL,
  ADD_CATEGORY_START,
  ADD_CATEGORY_SAGA,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  ADD_SUB_CATEGORY_START,
  ADD_SUB_CATEGORY_SAGA,
  ADD_SUB_CATEGORY_SUCCESS,
  ADD_SUB_CATEGORY_FAIL,
  EDIT_CATEGORY_START,
  EDIT_CATEGORY_SAGA,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_FAIL,
  EDIT_SUB_CATEGORY_START,
  EDIT_SUB_CATEGORY_SAGA,
  EDIT_SUB_CATEGORY_SUCCESS,
  EDIT_SUB_CATEGORY_FAIL,
  GET_ALL_DISH_START,
  GET_ALL_DISH_SAGA,
  GET_ALL_DISH_ADMIN_SAGA,
  GET_ALL_DISH_SUCCESS,
  GET_ALL_DISH_FAIL,
  ADD_DISH_START,
  ADD_DISH_SAGA,
  ADD_DISH_SUCCESS,
  ADD_DISH_FAIL,
  EDIT_DISH_START,
  EDIT_DISH_SAGA,
  EDIT_DISH_SUCCESS,
  EDIT_DISH_FAIL,
  EDIT_DISH_PHOTO_START,
  EDIT_DISH_PHOTO_SAGA,
  EDIT_DISH_PHOTO_SUCCESS,
  EDIT_DISH_PHOTO_FAIL,
  EDIT_DISH_PUBLISH_STATUS_START,
  EDIT_DISH_PUBLISH_STATUS_SAGA,
  EDIT_DISH_PUBLISH_STATUS_SUCCESS,
  EDIT_DISH_PUBLISH_STATUS_FAIL,
  DELETE_DISH_START,
  DELETE_DISH_SAGA,
  DELETE_DISH_SUCCESS,
  DELETE_DISH_FAIL,
  EDIT_CATEGORY_STATUS_START,
  EDIT_CATEGORY_STATUS_SAGA,
  EDIT_CATEGORY_STATUS_SUCCESS,
  EDIT_CATEGORY_STATUS_FAIL,
  GET_ALL_ADMIN_BRAND_TYPES_START,
  GET_ALL_ADMIN_BRAND_TYPES_SAGA,
  GET_ALL_ADMIN_BRAND_TYPES_SUCCESS,
  GET_ALL_ADMIN_BRAND_TYPES_FAIL,
  ADD_BRAND_TYPE_START,
  ADD_BRAND_TYPE_SAGA,
  ADD_BRAND_TYPE_SUCCESS,
  ADD_BRAND_TYPE_FAIL,
  EDIT_BRAND_TYPE_START,
  EDIT_BRAND_TYPE_SAGA,
  EDIT_BRAND_TYPE_SUCCESS,
  EDIT_BRAND_TYPE_FAIL,
  ADD_ADMIN_DISH_START,
  ADD_ADMIN_DISH_SAGA,
  ADD_ADMIN_DISH_SUCCESS,
  ADD_ADMIN_DISH_FAIL,
  EDIT_ADMIN_DISH_START,
  EDIT_ADMIN_DISH_SAGA,
  EDIT_ADMIN_DISH_SUCCESS,
  EDIT_ADMIN_DISH_FAIL,
  DELETE_ADMIN_DISH_START,
  DELETE_ADMIN_DISH_SAGA,
  DELETE_ADMIN_DISH_SUCCESS,
  DELETE_MEDIA_FAIL,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_SAGA,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  DELETE_SUB_CATEGORY_START,
  DELETE_SUB_CATEGORY_SAGA,
  DELETE_SUB_CATEGORY_SUCCESS,
  DELETE_SUB_CATEGORY_FAIL,
  EDIT_SUB_CATEGORY_STATUS_START,
  EDIT_SUB_CATEGORY_STATUS_SAGA,
  EDIT_SUB_CATEGORY_STATUS_SUCCESS,
  EDIT_SUB_CATEGORY_STATUS_FAIL,
  DELETE_BRAND_TYPE_START,
  DELETE_BRAND_TYPE_SAGA,
  DELETE_BRAND_TYPE_SUCCESS,
  DELETE_BRAND_TYPE_FAIL,
  GET_CATEGORY_BY_VENDOR_TYPE_SAGA,
  GET_SUB_CATEGORY_BY_CATEGORY_SAGA,
  EDIT_BRAND_TYPE_STATUS_START,
  EDIT_BRAND_TYPE_STATUS_SAGA,
  EDIT_BRAND_TYPE_STATUS_SUCCESS,
  EDIT_BRAND_TYPE_STATUS_FAIL,
  ADD_DARK_HOME_VENDOR_PRODUCT_SAGA,
  EDIT_DARK_HOME_VENDOR_PRODUCT_SAGA,
  DELETE_DARK_HOME_VENDOR_PRODUCT_SAGA,
  EDIT_DARK_HOME_VENDOR_PRODUCT_PUBLISH_STATUS_SAGA,
  GET_ALL_DISH_LIST_PROMOTER_START,
  GET_ALL_DISH_LIST_PROMOTER_SAGA,
  GET_ALL_DISH_LIST_PROMOTER_SUCCESS,
  GET_ALL_DISH_LIST_PROMOTER_FAIL,
  DELETE_PRODUCT_BY_PROMOTER_START,
  DELETE_PRODUCT_BY_PROMOTER_SAGA,
  DELETE_PRODUCT_BY_PROMOTER_SUCCESS,
  DELETE_PRODUCT_BY_PROMOTER_FAIL,
} from '../actionLabels';

export const getAllCategoryStart = () => ({
  type: GET_ALL_CATEGORY_START,
});

export const getAllCategorySaga = payload => ({
  type: GET_ALL_CATEGORY_SAGA,
  payload,
});

export const getAllCategoryChefSaga = payload => ({
  type: GET_ALL_CATEGORY_CHEF_SAGA,
  payload,
});

export const getAllCategorySuccess = payload => ({
  type: GET_ALL_CATEGORY_SUCCESS,
  payload,
});

export const getAllCategoryFail = payload => ({
  type: GET_ALL_CATEGORY_FAIL,
  payload,
});

export const getAllCategoryChefFail = payload => ({
  type: GET_ALL_CATEGORY_CHEF_FAIL,
  payload,
});

export const getAllSubCategoryStart = () => ({
  type: GET_ALL_SUB_CATEGORY_START,
});

export const getAllSubCategorySaga = payload => ({
  type: GET_ALL_SUB_CATEGORY_SAGA,
  payload,
});

export const getAllSubCategoryChefSaga = payload => ({
  type: GET_ALL_SUB_CATEGORY_CHEF_SAGA,
  payload,
});

export const getAllSubCategorySuccess = payload => ({
  type: GET_ALL_SUB_CATEGORY_SUCCESS,
  payload,
});

export const getAllSubCategoryFail = payload => ({
  type: GET_ALL_SUB_CATEGORY_FAIL,
  payload,
});

export const getAllSubCategoryChefFail = payload => ({
  type: GET_ALL_SUB_CATEGORY_CHEF_FAIL,
  payload,
});

export const getCategoryByIdStart = () => ({
  type: GET_CATEGORY_BY_ID_START,
});

export const getCategoryByIdSaga = payload => ({
  type: GET_CATEGORY_BY_ID_SAGA,
  payload,
});

export const getCategoryByIdSuccess = payload => ({
  type: GET_CATEGORY_BY_ID_SUCCESS,
  payload,
});

export const getCategoryByIdFail = payload => ({
  type: GET_CATEGORY_BY_ID_FAIL,
  payload,
});

export const addCategoryStart = () => ({
  type: ADD_CATEGORY_START,
});

export const addCategorySaga = payload => ({
  type: ADD_CATEGORY_SAGA,
  payload,
});

export const addCategorySuccess = payload => ({
  type: ADD_CATEGORY_SUCCESS,
  payload,
});

export const addCategoryFail = payload => ({
  type: ADD_CATEGORY_FAIL,
  payload,
});

export const addSubCategoryStart = () => ({
  type: ADD_SUB_CATEGORY_START,
});

export const addSubCategorySaga = payload => ({
  type: ADD_SUB_CATEGORY_SAGA,
  payload,
});

export const addSubCategorySuccess = payload => ({
  type: ADD_SUB_CATEGORY_SUCCESS,
  payload,
});

export const addSubCategoryFail = payload => ({
  type: ADD_SUB_CATEGORY_FAIL,
  payload,
});

export const editCategoryStart = () => ({
  type: EDIT_CATEGORY_START,
});

export const editCategorySaga = payload => ({
  type: EDIT_CATEGORY_SAGA,
  payload,
});

export const editCategorySuccess = payload => ({
  type: EDIT_CATEGORY_SUCCESS,
  payload,
});

export const editCategoryFail = payload => ({
  type: EDIT_CATEGORY_FAIL,
  payload,
});

export const editSubCategoryStart = () => ({
  type: EDIT_SUB_CATEGORY_START,
});

export const editSubCategorySaga = payload => ({
  type: EDIT_SUB_CATEGORY_SAGA,
  payload,
});

export const editSubCategorySuccess = payload => ({
  type: EDIT_SUB_CATEGORY_SUCCESS,
  payload,
});

export const editSubCategoryFail = payload => ({
  type: EDIT_SUB_CATEGORY_FAIL,
  payload,
});

export const editCategoryStatusStart = () => ({
  type: EDIT_CATEGORY_STATUS_START,
});

export const editCategoryStatusSaga = payload => ({
  type: EDIT_CATEGORY_STATUS_SAGA,
  payload,
});

export const editCategoryStatusSuccess = payload => ({
  type: EDIT_CATEGORY_STATUS_SUCCESS,
  payload,
});

export const editCategoryStatusFail = payload => ({
  type: EDIT_CATEGORY_STATUS_FAIL,
  payload,
});
// Edit Sub Category Status
export const editSubCategoryStatusStart = () => ({
  type: EDIT_SUB_CATEGORY_STATUS_START,
});

export const editSubCategoryStatusSaga = payload => ({
  type: EDIT_SUB_CATEGORY_STATUS_SAGA,
  payload,
});

export const editSubCategoryStatusSuccess = payload => ({
  type: EDIT_SUB_CATEGORY_STATUS_SUCCESS,
  payload,
});

export const editSubCategoryStatusFail = payload => ({
  type: EDIT_SUB_CATEGORY_STATUS_FAIL,
  payload,
});

export const getAllDishStart = () => ({
  type: GET_ALL_DISH_START,
});

export const getAllDishSaga = payload => ({
  type: GET_ALL_DISH_SAGA,
  payload,
});

export const getAllDishAdminSaga = payload => ({
  type: GET_ALL_DISH_ADMIN_SAGA,
  payload,
});

export const getAllDishSuccess = payload => ({
  type: GET_ALL_DISH_SUCCESS,
  payload,
});

export const getAllDishFail = payload => ({
  type: GET_ALL_DISH_FAIL,
  payload,
});

export const addDishStart = () => ({
  type: ADD_DISH_START,
});

export const addDishSaga = payload => ({
  type: ADD_DISH_SAGA,
  payload,
});

export const addDishSuccess = payload => ({
  type: ADD_DISH_SUCCESS,
  payload,
});

export const addDishFail = payload => ({
  type: ADD_DISH_FAIL,
  payload,
});

// Admin Dish
// Add Dish
export const addAdminDishStart = () => ({
  type: ADD_ADMIN_DISH_START,
});

export const addAdminDishSaga = payload => ({
  type: ADD_ADMIN_DISH_SAGA,
  payload,
});

export const addAdminDishSuccess = payload => ({
  type: ADD_ADMIN_DISH_SUCCESS,
  payload,
});

export const addAdminDishFail = payload => ({
  type: ADD_ADMIN_DISH_FAIL,
  payload,
});

// Edit Dish
export const editAdminDishStart = () => ({
  type: EDIT_ADMIN_DISH_START,
});

export const editAdminDishSaga = payload => ({
  type: EDIT_ADMIN_DISH_SAGA,
  payload,
});

export const editAdminDishSuccess = payload => ({
  type: EDIT_ADMIN_DISH_SUCCESS,
  payload,
});

export const editAdminDishFail = payload => ({
  type: EDIT_ADMIN_DISH_FAIL,
  payload,
});

// Delete Disj
export const deleteAdminDishStart = () => ({
  type: DELETE_ADMIN_DISH_START,
});

export const deleteAdminDishSaga = payload => ({
  type: DELETE_ADMIN_DISH_SAGA,
  payload,
});

export const deleteAdminDishSuccess = payload => ({
  type: DELETE_ADMIN_DISH_SUCCESS,
  payload,
});

export const deleteAdminDishFail = payload => ({
  type: DELETE_MEDIA_FAIL,
  payload,
});
// Admin dish end

export const editDishStart = () => ({
  type: EDIT_DISH_START,
});

export const editDishSaga = payload => ({
  type: EDIT_DISH_SAGA,
  payload,
});

export const editDishSuccess = payload => ({
  type: EDIT_DISH_SUCCESS,
  payload,
});

export const editDishFail = payload => ({
  type: EDIT_DISH_FAIL,
  payload,
});

export const editDishPhotoStart = () => ({
  type: EDIT_DISH_PHOTO_START,
});

export const editDishPhotoSaga = payload => ({
  type: EDIT_DISH_PHOTO_SAGA,
  payload,
});

export const editDishPhotoSuccess = payload => ({
  type: EDIT_DISH_PHOTO_SUCCESS,
  payload,
});

export const editDishPhotoFail = payload => ({
  type: EDIT_DISH_PHOTO_FAIL,
  payload,
});

export const editDishPublishStatusStart = () => ({
  type: EDIT_DISH_PUBLISH_STATUS_START,
});

export const editDishPublishStatusSaga = payload => ({
  type: EDIT_DISH_PUBLISH_STATUS_SAGA,
  payload,
});

export const editDishPublishStatusSuccess = payload => ({
  type: EDIT_DISH_PUBLISH_STATUS_SUCCESS,
  payload,
});

export const editDishPublishStatusFail = payload => ({
  type: EDIT_DISH_PUBLISH_STATUS_FAIL,
  payload,
});

export const deleteDishStart = () => ({
  type: DELETE_DISH_START,
});

export const deleteDishSaga = payload => ({
  type: DELETE_DISH_SAGA,
  payload,
});

export const deleteDishSuccess = payload => ({
  type: DELETE_DISH_SUCCESS,
  payload,
});

export const deleteDishFail = payload => ({
  type: DELETE_DISH_FAIL,
  payload,
});

export const getAllAdminBrandTypesStart = () => ({
  type: GET_ALL_ADMIN_BRAND_TYPES_START,
});

export const getAllAdminBrandTypesSaga = payload => ({
  type: GET_ALL_ADMIN_BRAND_TYPES_SAGA,
  payload,
});

export const getAllAdminBrandTypesSuccess = payload => ({
  type: GET_ALL_ADMIN_BRAND_TYPES_SUCCESS,
  payload,
});

export const getAllAdminBrandTypesFail = payload => ({
  type: GET_ALL_ADMIN_BRAND_TYPES_FAIL,
  payload,
});

export const addBrandTypeStart = () => ({
  type: ADD_BRAND_TYPE_START,
});

export const addBrandTypeSaga = payload => ({
  type: ADD_BRAND_TYPE_SAGA,
  payload,
});

export const addBrandTypeSuccess = payload => ({
  type: ADD_BRAND_TYPE_SUCCESS,
  payload,
});

export const addBrandTypeFail = payload => ({
  type: ADD_BRAND_TYPE_FAIL,
  payload,
});

export const editBrandTypeStart = () => ({
  type: EDIT_BRAND_TYPE_START,
});

export const editBrandTypeSaga = payload => ({
  type: EDIT_BRAND_TYPE_SAGA,
  payload,
});

export const editBrandTypeSuccess = payload => ({
  type: EDIT_BRAND_TYPE_SUCCESS,
  payload,
});

export const editBrandTypeFail = payload => ({
  type: EDIT_BRAND_TYPE_FAIL,
  payload,
});

// Delete Category Start
export const deleteCategoryStart = () => ({
  type: DELETE_CATEGORY_START,
});

export const deleteCategorySaga = payload => ({
  type: DELETE_CATEGORY_SAGA,
  payload,
});

export const deleteCategorySuccess = payload => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload,
});

export const deleteCategoryFail = payload => ({
  type: DELETE_CATEGORY_FAIL,
  payload,
});
// Delete Category End

// Delete Sub-Category Start
export const deleteSubCategoryStart = () => ({
  type: DELETE_SUB_CATEGORY_START,
});

export const deleteSubCategorySaga = payload => ({
  type: DELETE_SUB_CATEGORY_SAGA,
  payload,
});

export const deleteSubCategorySuccess = payload => ({
  type: DELETE_SUB_CATEGORY_SUCCESS,
  payload,
});

export const deleteSubCategoryFail = payload => ({
  type: DELETE_SUB_CATEGORY_FAIL,
  payload,
});
// Delete Sub-Category End

// Delete Brand Type or Vendor Type start
export const deleteBrandTypeStart = () => ({
  type: DELETE_BRAND_TYPE_START,
});

export const deleteBrandTypeSaga = payload => ({
  type: DELETE_BRAND_TYPE_SAGA,
  payload,
});

export const deleteBrandTypeSuccess = payload => ({
  type: DELETE_BRAND_TYPE_SUCCESS,
  payload,
});

export const deleteBrandTypeFail = payload => ({
  type: DELETE_BRAND_TYPE_FAIL,
  payload,
});
// Delete Brand Type or Vendor Type end

// get category by vendor type
export const getCategoryByVendorTypeSaga = payload => ({
  type: GET_CATEGORY_BY_VENDOR_TYPE_SAGA,
  payload,
});

// get subcategory by category id
export const getSubCategoryByCategorySaga = payload => ({
  type: GET_SUB_CATEGORY_BY_CATEGORY_SAGA,
  payload,
});

// Stores were earlier called Brand Types thats why im using old naming conventions
// Edit BRand Type / Store Status
export const editBrandTypeStatusStart = () => ({
  type: EDIT_BRAND_TYPE_STATUS_START,
});

export const editBrandTypeStatusSaga = payload => ({
  type: EDIT_BRAND_TYPE_STATUS_SAGA,
  payload,
});

export const editBrandTypeStatusSuccess = payload => ({
  type: EDIT_BRAND_TYPE_STATUS_SUCCESS,
  payload,
});

export const editBrandTypeStatusFail = payload => ({
  type: EDIT_BRAND_TYPE_STATUS_FAIL,
  payload,
});

// Add Dark Home Vendor PRoducts
export const addDarkHomeVendorProductSaga = payload => ({
  type: ADD_DARK_HOME_VENDOR_PRODUCT_SAGA,
  payload,
});

export const editDarkHomeVendorProductSaga = payload => ({
  type: EDIT_DARK_HOME_VENDOR_PRODUCT_SAGA,
  payload,
});

export const deleteDarkHomeVendorProductSaga = payload => ({
  type: DELETE_DARK_HOME_VENDOR_PRODUCT_SAGA,
  payload,
});

export const editDarkHomeVendorProductPublishStatusSaga = payload => ({
  type: EDIT_DARK_HOME_VENDOR_PRODUCT_PUBLISH_STATUS_SAGA,
  payload,
});

//get all product list fro promter of their vendors
export const getAllDishListForPromoterStart = () => ({
  type: GET_ALL_DISH_LIST_PROMOTER_START,
});

export const getAllDishListForPromoterSaga = payload => ({
  type: GET_ALL_DISH_LIST_PROMOTER_SAGA,
  payload,
});

export const getAllDishListForPromoterSuccess = payload => ({
  type: GET_ALL_DISH_LIST_PROMOTER_SUCCESS,
  payload,
});

export const getAllDishListForPromoterFail = payload => ({
  type: GET_ALL_DISH_LIST_PROMOTER_FAIL,
  payload,
});

//delete product by admin or promter

export const deleteProductByPromoterStart = () => ({
  type: DELETE_PRODUCT_BY_PROMOTER_START,
});

export const deleteProductByPromoterSaga = payload => ({
  type: DELETE_PRODUCT_BY_PROMOTER_SAGA,
  payload,
});

export const deleteProductByPromoterSuccess = payload => ({
  type: DELETE_PRODUCT_BY_PROMOTER_SUCCESS,
  payload,
});

export const deleteProductByPromoterFail = payload => ({
  type: DELETE_PRODUCT_BY_PROMOTER_FAIL,
  payload,
});