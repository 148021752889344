export const GET_ALL_CHEF_PAYOUT_LIST_START = 'GET_ALL_CHEF_PAYOUT_LIST_START';
export const GET_ALL_CHEF_PAYOUT_LIST_SAGA = 'GET_ALL_CHEF_PAYOUT_LIST_SAGA';
export const GET_ALL_CHEF_PAYOUT_LIST_SUCCESS =
  'GET_ALL_CHEF_PAYOUT_LIST_SUCCESS';
export const GET_ALL_CHEF_PAYOUT_LIST_FAIL = 'GET_ALL_CHEF_PAYOUT_LIST_FAIL';

export const GET_PAYMENT_HISTORY_START = 'GET_PAYMENT_HISTORY_START';
export const GET_PAYMENT_HISTORY_SAGA = 'GET_PAYMENT_HISTORY_SAGA';
export const GET_PAYMENT_HISTORY_CHEF_SAGA = 'GET_PAYMENT_HISTORY_CHEF_SAGA';
export const GET_PAYMENT_HISTORY_SUCCESS = 'GET_PAYMENT_HISTORY_SUCCESS';
export const GET_PAYMENT_HISTORY_FAIL = 'GET_PAYMENT_HISTORY_FAIL';

export const GET_PAYMENT_HISTORY_DETAILS_START =
  'GET_PAYMENT_HISTORY_DETAILS_START';
export const GET_PAYMENT_HISTORY_DETAILS_SAGA =
  'GET_PAYMENT_HISTORY_DETAILS_SAGA';
export const GET_PAYMENT_HISTORY_DETAILS_SUCCESS =
  'GET_PAYMENT_HISTORY_DETAILS_SUCCESS';
export const GET_PAYMENT_HISTORY_DETAILS_FAIL =
  'GET_PAYMENT_HISTORY_DETAILS_FAIL';

export const PAY_PAYMENT_START = 'PAY_PAYMENT_START';
export const PAY_PAYMENT_SAGA = 'PAY_PAYMENT_SAGA';
export const PAY_PAYMENT_SUCCESS = 'PAY_PAYMENT_SUCCESS';
export const PAY_PAYMENT_FAIL = 'PAY_PAYMENT_FAIL';

export const GET_PAYMENTTYPE_START = 'GET_PAYMENTTYPE_START';
export const GET_PAYMENTTYPE_SAGA = 'GET_PAYMENTTYPE_SAGA';
export const GET_PAYMENTTYPE_SUCCESS = 'GET_PAYMENTTYPE_SUCCESS';
export const GET_PAYMENTTYPE_FAIL = 'GET_PAYMENTTYPE_FAIL';


export const ADD_PAYMENTTYPE_START = 'ADD_PAYMENTTYPE_START';
export const ADD_PAYMENTTYPE_SAGA = 'ADD_PAYMENTTYPE_SAGA';
export const ADD_PAYMENTTYPE_SUCCESS = 'ADD_PAYMENTTYPE_SUCCESS';
export const ADD_PAYMENTTYPE_FAIL = 'ADD_PAYMENTTYPE_FAIL';
