import React, { useEffect, useState } from 'react';
import moment from 'moment';

const CountDown = props => {
  const [time, setTime] = useState(
    Math.floor(props.time) * 60 +
      (Math.ceil((props.time - Math.floor(props.time)) * 100) * 60) / 100,
    // Math.ceil(Number(props.time.toFixed(2)) * 36),
  );

  useEffect(() => {
    setTimeout(() => {
      setTime(time - 1);
    }, 1000);
  }, []);

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [time]);

  return (
    <div className="action-btn">
      <button type="button" className="btn-no-bg action-btn-color">
        <i className="plr-5">
          {moment('2015-01-01').startOf('day').seconds(time).format('H:mm:ss')}
        </i>
      </button>
    </div>
  );
};

export default CountDown;
