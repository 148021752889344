import {
  EDIT_MARK_AS_READ_FAIL,
  EDIT_MARK_AS_READ_SAGA,
  EDIT_MARK_AS_READ_START,
  EDIT_MARK_AS_READ_SUCCESS,
  GET_ALL_NOTIFICATION_FAIL,
  GET_ALL_NOTIFICATION_SAGA,
  GET_ALL_NOTIFICATION_START,
  GET_ALL_NOTIFICATION_SUCCESS,
  RESET_NOTIFICATION_LIST,
  SEND_NOTIFICATION_TO_VENDOR_START,
  SEND_NOTIFICATION_TO_VENDOR_SUCCESS,
  SEND_NOTIFICATION_TO_VENDOR_SAGA,
  SEND_NOTIFICATION_TO_VENDOR_FAIL,
} from '../actionLabels';

export const getAllNotificationStart = payload => ({
  type: GET_ALL_NOTIFICATION_START,
  payload,
});

export const getAllNotificationSaga = payload => ({
  type: GET_ALL_NOTIFICATION_SAGA,
  payload,
});

export const getAllNotificationSuccess = payload => ({
  type: GET_ALL_NOTIFICATION_SUCCESS,
  payload,
});

export const getAllNotificationFail = payload => ({
  type: GET_ALL_NOTIFICATION_FAIL,
  payload,
});

export const editMarkAsReadStart = payload => ({
  type: EDIT_MARK_AS_READ_START,
  payload,
});

export const editMarkAsReadSaga = payload => ({
  type: EDIT_MARK_AS_READ_SAGA,
  payload,
});

export const editMarkAsReadSuccess = payload => ({
  type: EDIT_MARK_AS_READ_SUCCESS,
  payload,
});

export const editMarkAsReadFail = payload => ({
  type: EDIT_MARK_AS_READ_FAIL,
  payload,
});

export const resetNotificationList = () => ({
  type: RESET_NOTIFICATION_LIST,
});

export const sendNotificationToVendorStart = payload => ({
  type: SEND_NOTIFICATION_TO_VENDOR_START,
  payload,
});

export const sendNotificationToVendorSaga = payload => ({
  type: SEND_NOTIFICATION_TO_VENDOR_SAGA,
  payload,
});

export const sendNotificationToVendorSuccess = payload => ({
  type: SEND_NOTIFICATION_TO_VENDOR_SUCCESS,
  payload,
});

export const sendNotificationToVendorFail = payload => ({
  type: SEND_NOTIFICATION_TO_VENDOR_FAIL,
  payload,
});
