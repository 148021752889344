import * as actionLabels from '../../actions/actionLabels';

export const initialState = {
  isLogin: false,
  isLoading: false,
  authToken: '',
  userRole: '',
  isSubAdmin: true,
  isPromoter: '',
  isLogistics: '',
  isvehicleOwner: '',
  isDarkHomeVendor: '',
  errorMsg: '',
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  profilePic: '',
  userName: '',
  fcmToken: '',
  accessOption: {
    isVendor: false,
    isDarkHomeVendor: false,
    isDriver: false,
    isVehicleOwner: false,
  },
  registerEmail: '',
  isRegisterTokenValid: false,
  isRegisterSuccess: false,
  isResetPasswordTokenValid: false,
  isForgotPasswordSuccess: false,
  isEmailVerificationTokenValid: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.LOGIN_START:
      return { ...state, isLoading: true };
    case actionLabels.LOGIN_SUCCESS: {
      return {
        ...state,
        isLogin: true,
        isLoading: false,
        authToken: payload.userToken,
        userRole: payload.userRole,
        isSubAdmin: payload.isSubAdmin,
        isPromoter: payload.isPromoter,
        isLogistics: payload.isLogistics,
        isvehicleOwner: payload.isvehicleOwner,
        isDarkHomeVendor: !!payload.isDarkHomeVendor
          ? payload.isDarkHomeVendor
          : false,
        accessOption: { ...state.accessOption, ...payload.accessOption },
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phone,
        userName: payload.userName,
        profilePic: payload.profilePic,
        userId: payload._id,
        fcmToken: payload.deviceToken,
        errorMsg: '',
      };
    }
    case actionLabels.LOGIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }
    case actionLabels.LOGOUT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionLabels.EDIT_USER_PROFILE_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionLabels.EDIT_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phone,
        profilePic: payload.profilePic,
        isLoading: false,
      };
    }
    case actionLabels.EDIT_USER_PROFILE_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }
    case actionLabels.CHANGE_PASSWORD_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionLabels.CHANGE_PASSWORD_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.CHANGE_PASSWORD_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }
    case actionLabels.CHANGE_PASSWORD_CHEF_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionLabels.CHANGE_PASSWORD_CHEF_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.CHANGE_PASSWORD_CHEF_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }
    case actionLabels.LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLogin: false,
        authToken: '',
        userRole: '',
        isSubAdmin: true,
        errorMsg: '',
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        profilePic: '',
        userName: '',
        fcmToken: '',
      };
    }
    case actionLabels.LOGOUT_FAILED: {
      return { ...state, isLoading: false, errorMsg: payload };
    }
    case actionLabels.RESET_ERROR_MSG: {
      return {
        ...state,
        errorMsg: '',
        isRegisterSuccess: false,
        isResetPasswordTokenValid: false,
        isForgotPasswordSuccess: false,
        isEmailVerificationTokenValid: false,
        isRegisterTokenValid: false,
      };
    }
    case actionLabels.REGISTER_VERIFY_START:
      return { ...state, isLoading: true };
    case actionLabels.REGISTER_VERIFY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isRegisterTokenValid: true,
        registerEmail: payload,
        errorMsg: '',
      };
    }
    case actionLabels.REGISTER_VERIFY_FAIL: {
      return {
        ...state,
        isLoading: false,
        isRegisterTokenValid: false,
        errorMsg: payload,
      };
    }
    case actionLabels.REGISTER_START:
      return { ...state, isLoading: true };
    case actionLabels.REGISTER_SUCCESS: {
      return {
        ...state,
        isRegisterSuccess: true,
        errorMsg: '',
      };
    }
    case actionLabels.REGISTER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isRegisterSuccess: false,
        errorMsg: payload,
      };
    }
    case actionLabels.OTP_START:
      return { ...state, isLoading: true };
    case actionLabels.OTP_SUCCESS: {
      return {
        ...state,
        isOTPVerificationSuccess: true,
        errorMsg: '',
      };
    }
    case actionLabels.OTP_FAIL: {
      return {
        ...state,
        isLoading: false,
        isOTPVerificationSuccess: false,
        errorMsg: payload,
      };
    }
    case actionLabels.RESET_PASSWORD_TOKEN_VERIFICATION_START:
      return { ...state, isLoading: true };
    case actionLabels.RESET_PASSWORD_TOKEN_VERIFICATION_SUCCESS: {
      return {
        ...state,
        isResetPasswordTokenValid: true,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.RESET_PASSWORD_TOKEN_VERIFICATION_FAIL: {
      return {
        ...state,
        isLoading: false,
        isResetPasswordTokenValid: false,
        errorMsg: payload,
      };
    }
    case actionLabels.RESET_PASSWORD_START:
      return { ...state };
    case actionLabels.RESET_PASSWORD_SUCCESS: {
      return { ...state, errorMsg: '' };
    }
    case actionLabels.RESET_PASSWORD_FAIL: {
      return {
        ...state,
        errorMsg: payload,
      };
    }
    case actionLabels.FORGOT_PASSWORD_START: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case actionLabels.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        isForgotPasswordSuccess: true,
        errorMsg: '',
      };
    }
    case actionLabels.FORGOT_PASSWORD_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
      };
    }
    case actionLabels.EMAIL_VERIFICATION_TOKEN_VERIFICATION_START:
      return { ...state, isLoading: true };
    case actionLabels.EMAIL_VERIFICATION_TOKEN_VERIFICATION_SUCCESS: {
      return {
        ...state,
        isEmailVerificationTokenValid: true,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.EMAIL_VERIFICATION_TOKEN_VERIFICATION_FAIL: {
      return {
        ...state,
        isLoading: false,
        isEmailVerificationTokenValid: false,
        errorMsg: payload,
      };
    }

    default:
      return state;
  }
};
