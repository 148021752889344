import { put } from 'redux-saga/effects';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';
import { capitalize } from '../../../utils/capitalize';
import {
  getAllVehicleOwnersSaga as getAllVehicleOwnersSagaAction,
  getAllVehicleOwnersFail,
  getAllVehicleOwnersStart,
  getAllVehicleOwnersSuccess,
  addVehicleOwnerStart,
  addVehicleOwnerSuccess,
  addVehicleOwnerFail,
  showModal,
  editBlockVehicleOwnerStatusStart,
  editBlockVehicleOwnerStatusSuccess,
  editBlockVehicleOwnerStatusFail,
  getVehicleOwnerByIdStart,
  getVehicleOwnerByIdSuccess,
  getVehicleOwnerByIdFail,
  editVehicleOwnerCommissionStart,
  editVehicleOwnerCommissionSuccess,
  editVehicleOwnerCommissionFail,
  logout,
  editVehicleOwnerStart,
  editVehicleOwnerSuccess,
  editVehicleOwnerFail,
  deleteVehicleOwnerStart,
  deleteVehicleOwnerSuccess,
  deleteVehicleOwnerFail,
} from '../../actions';

export function* getAllVehicleOwnersSaga(action) {
  yield put(getAllVehicleOwnersStart());
  try {
    const { route, query } = action.payload;
    const response = yield axios.get(
      urlPaginationGenerator(`/${route}/getAllVehicleOwners`, query),
    );
    console.log('payload object of getAllVehi Owne Saga \n', action.payload);
    // console.log('response coming from vehicle owners saga \n', response);
    if (response.status === 200) {
      yield put(
        getAllVehicleOwnersSuccess({ vehicleOwners: response.data.data }),
      );
    } else {
      yield put(
        getAllVehicleOwnersFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllVehicleOwnersFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllVehicleOwnersFail(error.response.data.message));
      } else {
        yield put(getAllVehicleOwnersFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllVehicleOwnersFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Get Vehicle Owner By Id
export function* getVehicleOwnerByIdSaga(action) {
  console.log(action.payload);
  yield put(getVehicleOwnerByIdStart());
  try {
    const response = yield axios.post(
      `/promoter/getVehicleOwnerById`,
      action.payload,
    );
    console.log('get vehicle owner details saga  \n', response);
    if (response.status === 200) {
      yield put(
        getVehicleOwnerByIdSuccess({ vehicleOwner: response.data.data }),
      );
    } else {
      yield put(
        getVehicleOwnerByIdFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getVehicleOwnerByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getVehicleOwnerByIdFail(error.response.data.message));
      } else {
        yield put(getVehicleOwnerByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getVehicleOwnerByIdFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Add Vehicle Owner
export function* addVehicleOwnerSaga(action) {
  yield put(addVehicleOwnerStart());
  try {
    const { route, redirectUrl, formValues, tableFilter } = action.payload;
    // console.log(action.payload);
    const response = yield axios.post(`/${route}/addVehicleOwner`, formValues);
    if (response.status === 200) {
      yield put(addVehicleOwnerSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: redirectUrl,
          message: capitalize(response.data.message) || 'Success',
        }),
      );
      yield put(
        getAllVehicleOwnersSagaAction({ route: 'driver', query: tableFilter }),
      );
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(addVehicleOwnerFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addVehicleOwnerFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addVehicleOwnerFail(error.response.data.message));
      } else {
        yield put(addVehicleOwnerFail('Server error! Please try again.'));
      }
    } else {
      yield put(addVehicleOwnerFail('Something went wrong! Please try again.'));
    }
  }
}

// Block-Unblock Vehicle owner
export function* editBlockVehicleOwnerStatusSaga(action) {
  yield put(editBlockVehicleOwnerStatusStart());
  try {
    const response = yield axios.post(
      `/driver/VehicleOwnerStatus`,
      action.payload,
    );
    console.log(response);
    if (response.status === 200) {
      yield put(editBlockVehicleOwnerStatusSuccess(action.payload));
    } else {
      yield put(
        editBlockVehicleOwnerStatusFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBlockVehicleOwnerStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBlockVehicleOwnerStatusFail(error.response.data.message));
      } else {
        yield put(
          editBlockVehicleOwnerStatusFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        editBlockVehicleOwnerStatusFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}

// Edit Vehicle Owner Commission
export function* editVehicleOwnerCommissionSaga(action) {
  yield put(editVehicleOwnerCommissionStart());
  const { setIsSubmitted } = action.payload;
  try {
    const response = yield axios.post(
      `/driver/updateCommission`,
      action.payload,
    );
    if (response.status === 200) {
      const { closeModel, tableFilter } = action.payload;
      yield put(editVehicleOwnerCommissionSuccess());
      if (action.payload.isUpdated === true) {
        if (closeModel) {
          closeModel();
        }
        yield put(
          showModal({
            open: true,
            notifyType: 2,
            redirectURL:window.location.reload(),
            message: response.data.message || 'Success',
          }),
        );
        // yield put(getAllVehicleOwnersSagaAction(tableFilter));
      }
    } else {
      yield put(
        editVehicleOwnerCommissionFail(
          'Something went wrong! Please try again.',
        ),
      );
      setIsSubmitted(false);
    }
  } catch (error) {
    setIsSubmitted(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editVehicleOwnerCommissionFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editVehicleOwnerCommissionFail(error.response.data.message));
      } else {
        yield put(
          editVehicleOwnerCommissionFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        editVehicleOwnerCommissionFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}

// Edit Vehicle Owner Details start
export function* editVehicleOwnerSaga(action) {
  yield put(editVehicleOwnerStart());
  try {
    const { redirectUrl, formValues, tableFilter } = action.payload;
    console.log(action.payload);
    const response = yield axios.post('driver/updateVehicleOwner', formValues);
    if (response.status === 200 || response.statusCode === 200) {
      yield put(editVehicleOwnerSuccess(response.data.data));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          redirectURL: redirectUrl,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllVehicleOwnersSagaAction(tableFilter));
      // setTimeout(() => history.goBack(), 2000);
    } else {
      yield put(
        editVehicleOwnerFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editVehicleOwnerFail(error.response.data.message));
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            // redirectURL: redirectUrl,
            message: error.response.data.message || 'Error',
          }))
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editVehicleOwnerFail(error.response.data.message));
      } else {
        yield put(editVehicleOwnerFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editVehicleOwnerFail('Something went wrong! Please try again.'),
      );
    }
  }
}
// Edit Vehicle Owner Details end

// Delete Vehicle Owner Start
export function* deleteVehicleOwnerSaga(action) {
  yield put(deleteVehicleOwnerStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    console.log(action.payload);
    // To-DO change API address
    const response = yield axios.post(`/admin/deleteVehicleOwner`, {
      VehicleOwnerId: id,
    });
    if (response.status === 200) {
      yield put(deleteVehicleOwnerSuccess(action.payload));
      yield setModelDeleteOpen(false);
      
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
          redirectURL:window.location.reload()
        }),
      );
      yield put(getAllVehicleOwnersSagaAction(tableFilter));
    } else {
      yield put(
        deleteVehicleOwnerFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      if (error.response.status === 400) {
        yield put(deleteVehicleOwnerFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteVehicleOwnerFail(error.response.data.message));
      } else {
        yield put(
          showModal({
            open: true,
            notifyType: 1,
            message: 'Server error! Please try again.',
          }),
        );
        yield put(deleteVehicleOwnerFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        deleteVehicleOwnerFail('Something went wrong! Please try again.'),
      );
    }
  }
}
// Delete Vehicle Owner End
