export const GET_ALL_SETTING_START = 'GET_ALL_SETTING_START';
export const GET_ALL_SETTING_SAGA = 'GET_ALL_SETTING_SAGA';
export const GET_ALL_SETTING_SUCCESS = 'GET_ALL_SETTING_SUCCESS';
export const GET_ALL_SETTING_FAIL = 'GET_ALL_SETTING_FAIL';

export const EDIT_ALL_SETTINGS_START = 'EDIT_ALL_SETTINGS_START';
export const EDIT_ALL_SETTINGS_SAGA = 'EDIT_ALL_SETTINGS_SAGA';
export const EDIT_ALL_SETTINGS_SUCCESS = 'EDIT_ALL_SETTINGS_SUCCESS';
export const EDIT_ALL_SETTINGS_FAIL = 'EDIT_ALL_SETTINGS_FAIL';

export const EDIT_ALL_SETTINGS_COMMISSION_SAGA =
  'EDIT_ALL_SETTINGS_COMMISSION_SAGA';

export const EDIT_DEFAULT_FOOD_DISH_IMAGE_START =
  'EDIT_DEFAULT_FOOD_DISH_IMAGE_START';
export const EDIT_DEFAULT_FOOD_DISH_IMAGE_SAGA =
  'EDIT_DEFAULT_FOOD_DISH_IMAGE_SAGA';
export const EDIT_DEFAULT_FOOD_DISH_IMAGE_SUCCESS =
  'EDIT_DEFAULT_FOOD_DISH_IMAGE_SUCCESS';
export const EDIT_DEFAULT_FOOD_DISH_IMAGE_FAIL =
  'EDIT_DEFAULT_FOOD_DISH_IMAGE_FAIL';

export const SEND_NOTIFICATION_START = 'SEND_NOTIFICATION_START';
export const SEND_NOTIFICATION_SAGA = 'SEND_NOTIFICATION_SAGA';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAIL = 'SEND_NOTIFICATION_FAIL';
