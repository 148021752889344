import {
  GET_ALL_INQUIRIES_START,
  GET_ALL_INQUIRIES_SAGA,
  GET_ALL_INQUIRIES_SUCCESS,
  GET_ALL_INQUIRIES_FAIL,
  EDIT_MARK_AS_APPROVED_START,
  EDIT_MARK_AS_APPROVED_SAGA,
  EDIT_MARK_AS_APPROVED_SUCCESS,
  EDIT_MARK_AS_APPROVED_FAIL,
  EDIT_MARK_AS_REJECTED_START,
  EDIT_MARK_AS_REJECTED_SAGA,
  EDIT_MARK_AS_REJECTED_SUCCESS,
  EDIT_MARK_AS_REJECTED_FAIL,
  RESET_INQUIRY_LIST,
} from '../actionLabels';

export const getAllInquiriesStart = () => ({
  type: GET_ALL_INQUIRIES_START,
});

export const getAllInquiriesSaga = payload => ({
  type: GET_ALL_INQUIRIES_SAGA,
  payload,
});

export const getAllInquiriesSuccess = payload => ({
  type: GET_ALL_INQUIRIES_SUCCESS,
  payload,
});

export const getAllInquiriesFail = payload => ({
  type: GET_ALL_INQUIRIES_FAIL,
  payload,
});

export const editMarkAsApprovedStart = () => ({
  type: EDIT_MARK_AS_APPROVED_START,
});

export const editMarkAsApprovedSaga = payload => ({
  type: EDIT_MARK_AS_APPROVED_SAGA,
  payload,
});

export const editMarkAsApprovedSuccess = payload => ({
  type: EDIT_MARK_AS_APPROVED_SUCCESS,
  payload,
});

export const editMarkAsApprovedFail = payload => ({
  type: EDIT_MARK_AS_APPROVED_FAIL,
  payload,
});

export const editMarkAsRejectedStart = () => ({
  type: EDIT_MARK_AS_REJECTED_START,
});

export const editMarkAsRejectedSaga = payload => ({
  type: EDIT_MARK_AS_REJECTED_SAGA,
  payload,
});

export const editMarkAsRejectedSuccess = payload => ({
  type: EDIT_MARK_AS_REJECTED_SUCCESS,
  payload,
});

export const editMarkAsRejectedFail = payload => ({
  type: EDIT_MARK_AS_REJECTED_FAIL,
  payload,
});

export const resetInquiryList = () => ({
  type: RESET_INQUIRY_LIST,
});
