import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  reports: { list: [], totalRecords: { count: 0 } },
  report: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_REPORT_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_REPORT_SUCCESS: {
      let { reports } = payload;
      const { isCSV } = payload;
      if (isCSV) {
        const link = document.createElement('a');
        link.download = reports.split('/').pop();
        link.href = reports;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return { ...state };
      }
      if (reports.list === undefined) {
        const reportArray = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const key in reports) {
          if (Object.prototype.hasOwnProperty.call(reports, key)) {
            const element = {
              type: key,
              value: reports[key],
            };
            reportArray.push(element);
          }
        }
        reports = {
          list: reportArray,
          totalRecords: {
            count: reportArray.length,
          },
        };
      }
      return { ...state, reports, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_REPORT_FAIL:
      return {
        ...state,
        reports: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.RESET_REPORT:
      return {
        ...state,
        reports: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: '',
      };
    default:
      return state;
  }
};
