export const GET_ALL_USER_START = 'GET_ALL_USER_START';
export const GET_ALL_USER_SAGA = 'GET_ALL_USER_SAGA';
export const GET_ALL_USER_SUCCESS = 'GET_ALL_USER_SUCCESS';
export const GET_ALL_USER_FAIL = 'GET_ALL_USER_FAIL';

export const GET_USER_BY_ID_START = 'GET_USER_BY_ID_START';
export const GET_USER_BY_ID_SAGA = 'GET_USER_BY_ID_SAGA';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL';

export const GET_ACTIVE_INACTIVE_USER_COUNT_START =
  'GET_ACTIVE_INACTIVE_USER_COUNT_START';
export const GET_ACTIVE_INACTIVE_USER_COUNT_SAGA =
  'GET_ACTIVE_INACTIVE_USER_COUNT_SAGA';
export const GET_ACTIVE_INACTIVE_USER_COUNT_SUCCESS =
  'GET_ACTIVE_INACTIVE_USER_COUNT_SUCCESS';
export const GET_ACTIVE_INACTIVE_USER_COUNT_FAIL =
  'GET_ACTIVE_INACTIVE_USER_COUNT_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SAGA = 'DELETE_USER_SAGA';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const EDIT_BLOCK_USER_STATUS_START = 'EDIT_BLOCK_USER_STATUS_START';
export const EDIT_BLOCK_USER_STATUS_SAGA = 'EDIT_BLOCK_USER_STATUS_SAGA';
export const EDIT_BLOCK_USER_STATUS_SUCCESS = 'EDIT_BLOCK_USER_STATUS_SUCCESS';
export const EDIT_BLOCK_USER_STATUS_FAIL = 'EDIT_BLOCK_USER_STATUS_FAIL';

export const RESET_USER_DETAIL = 'RESET_USER_DETAIL';

export const EDIT_ACCEPTING_ORDER_START = 'EDIT_ACCEPTING_ORDER_START';
export const EDIT_ACCEPTING_ORDER_SAGA = 'EDIT_ACCEPTING_ORDER_SAGA';
export const EDIT_ACCEPTING_ORDER_SUCCESS = 'EDIT_ACCEPTING_ORDER_SUCCESS';
export const EDIT_ACCEPTING_ORDER_FAIL = 'EDIT_ACCEPTING_ORDER_FAIL';

export const GET_ALL_USER_ORDER_START = 'GET_ALL_USER_ORDER_START';
export const GET_ALL_USER_ORDER_SAGA = 'GET_ALL_USER_ORDER_SAGA';
export const GET_ALL_USER_ORDER_SUCCESS = 'GET_ALL_USER_ORDER_SUCCESS';
export const GET_ALL_USER_ORDER_FAIL = 'GET_ALL_USER_ORDER_FAIL';

export const GET_ALL_USER_ORDER_DETAIL_START = 'GET_ALL_USER_ORDER_DETAIL_START';
export const GET_ALL_USER_ORDER_DETAIL_SAGA = 'GET_ALL_USER_ORDER_DETAIL_SAGA';
export const GET_ALL_USER_ORDER_DETAIL_SUCCESS = 'GET_ALL_USER_ORDER_DETAIL_SUCCESS';
export const GET_ALL_USER_ORDER_DETAIL_FAIL = 'GET_ALL_USER_ORDER_DETAIL_FAIL';
