/* eslint-disable import/prefer-default-export */
export const urlPaginationGenerator = (url, payload) => {
  const {
    id,
    columnName,
    orderBy,
    search,
    skip,
    limit,
    durationType,
    userId,
    chefId,
    filterSearch,
    isUpComing,
    isUserRequest,
    isRejected,
    isNew,
    isCSV,
    status: Status,
    isvehicleOwner,
    reportType,
    isDarkHomeVendor,
    isLogisticRequest,
    serviceType,
  } = payload;

  const urlParams = [];

  if (id !== undefined && id !== '') {
    urlParams.push(`id=${id}`);
  }

  if (isDarkHomeVendor !== undefined && isDarkHomeVendor !== '') {
    urlParams.push(`isDarkHomeVendor=${isDarkHomeVendor}`);
  }
  if (columnName !== undefined && columnName !== '') {
    urlParams.push(`columnName=${columnName}`);
  }
  if (orderBy !== undefined && orderBy !== '') {
    urlParams.push(`orderBy=${orderBy}`);
  }
  if (search !== undefined && search !== '') {
    urlParams.push(`search=${search}`);
  }
  if (skip !== undefined && skip !== '') {
    urlParams.push(`skip=${skip}`);
  }
  if (limit !== undefined && limit !== '') {
    urlParams.push(`limit=${limit}`);
  }
  if (durationType !== undefined && durationType !== '') {
    urlParams.push(`durationType=${durationType}`);
  }
  if (userId !== undefined && userId !== '') {
    urlParams.push(`userId=${userId}`);
  }
  if (chefId !== undefined && chefId !== '') {
    urlParams.push(`chefId=${chefId}`);
  }
  if (isUpComing !== undefined && isUpComing !== '') {
    urlParams.push(`isUpComing=${isUpComing}`);
  }
  if (isUserRequest !== undefined && isUserRequest !== '') {
    urlParams.push(`isUserRequest=${isUserRequest}`);
  }
  if (isRejected !== undefined && isRejected !== '') {
    urlParams.push(`isRejected=${isRejected}`);
  }
  if (isNew !== undefined && isNew !== '') {
    urlParams.push(`isNew=${isNew}`);
  }
  if (Status !== undefined && Status !== '') {
    urlParams.push(`status=${Status}`);
  }
  if (isvehicleOwner !== undefined && isvehicleOwner !== '') {
    urlParams.push(`isvehicleOwner=${isvehicleOwner}`);
  }
  if (isLogisticRequest !== undefined && isLogisticRequest !== '') {
    urlParams.push(`isLogisticRequest=${isLogisticRequest}`);
  }
  if (serviceType !== undefined && serviceType !== '') {
    urlParams.push(`serviceType=${serviceType}`);
  }
  if (filterSearch !== undefined) {
    const {
      startDate,
      endDate,
      isSelfPickUp,
      status,
      country,
      state,
      city,
      category,
      corridorId,
      corridorName,
      subcorridorName,
      venderType,
      venderName,
      subcorridorId,
      subCategory,
      store,
      storeId,
      storeName,
      serviceType,
      storeTypeId,
      storeTypeName,
    } = filterSearch;
    if (startDate !== undefined && startDate !== '') {
      urlParams.push(`startDate=${startDate}`);
    }
    if (endDate !== undefined && endDate !== '') {
      urlParams.push(`endDate=${endDate}`);
    }
    if (corridorId !== undefined && corridorId !== '') {
      urlParams.push(`corridorId=${corridorId}`);
    }
    if (subcorridorId !== undefined && subcorridorId !== '') {
      urlParams.push(`subcorridorId=${subcorridorId}`);
    }
    if (corridorName !== undefined && corridorName !== '') {
      urlParams.push(`corridorName=${corridorName}`);
    }
    if (subcorridorName !== undefined && subcorridorName !== '') {
      urlParams.push(`subcorridorName=${subcorridorName}`);
    }
    if (venderType !== undefined && venderType !== '') {
      urlParams.push(`venderType=${venderType}`);
    }
    if (venderName !== undefined && venderName !== '') {
      urlParams.push(`venderName=${venderName}`);
    }
    if (storeId !== undefined && storeId !== '') {
      urlParams.push(`storeId=${storeId}`);
    }
    if (storeName !== undefined && storeName !== '') {
      urlParams.push(`storeName=${storeName}`);
    }
    if (isSelfPickUp !== undefined && isSelfPickUp !== '') {
      urlParams.push(`isSelfPickUp=${isSelfPickUp}`);
    }
    if (status !== undefined && status !== '') {
      urlParams.push(`status=${status}`);
    }
    if (country !== undefined && country !== '') {
      urlParams.push(`country=${country}`);
    }
    if (state !== undefined && state !== '') {
      urlParams.push(`state=${state}`);
    }
    if (city !== undefined && city !== '') {
      urlParams.push(`city=${city}`);
    }
    if (store !== undefined && store !== '') {
      urlParams.push(`store=${store}`);
    }
    if (category !== undefined && category !== '') {
      urlParams.push(`category=${category}`);
    }
    if (subCategory !== undefined && subCategory !== '') {
      urlParams.push(`subCategory=${subCategory}`);
    }
    if (serviceType !== undefined && serviceType !== '') {
      urlParams.push(`serviceType=${serviceType}`);
    }
    if (storeTypeId !== undefined && storeTypeId !== '') {
      urlParams.push(`storeTypeId=${storeTypeId}`);
    }
    if (storeTypeName !== undefined && storeTypeName !== '') {
      urlParams.push(`storeTypeName=${storeTypeName}`);
    }
  }
  if (isCSV !== undefined && isCSV !== '') {
    urlParams.push(`isCSV=${isCSV}`);
  }
  if (reportType !== undefined && reportType !== '') {
    urlParams.push(`reportType=${reportType}`);
  }
  if (urlParams.length !== 0) return `${url}?${urlParams.join('&')}`;
  return url;
};
