export const RESET_REQUEST_DETAIL = 'RESET_REQUEST_DETAIL';

// Get ALl
export const GET_ALL_REQUESTS_START = 'GET_ALL_REQUESTS_START';
export const GET_ALL_REQUESTS_SAGA = 'GET_ALL_REQUESTS_SAGA';
export const GET_ALL_REQUESTS_SUCCESS = 'GET_ALL_REQUESTS_SUCCESS';
export const GET_ALL_REQUESTS_FAIL = 'GET_ALL_REQUESTS_FAIL';

// Approved
export const MARK_REQUEST_AS_APPROVED_START = 'MARK_REQUEST_AS_APPROVED_START';
export const MARK_REQUEST_AS_APPROVED_SAGA = 'MARK_REQUEST_AS_APPROVED_SAGA';
export const MARK_REQUEST_AS_APPROVED_SUCCESS =
  'MARK_REQUEST_AS_APPROVED_SUCCESS';
export const MARK_REQUEST_AS_APPROVED_FAIL = 'MARK_REQUEST_AS_APPROVED_FAIL';

// rejected
export const MARK_REQUEST_AS_REJECTED_START = 'MARK_REQUEST_AS_REJECTED_START';
export const MARK_REQUEST_AS_REJECTED_SAGA = 'MARK_REQUEST_AS_REJECTED_SAGA';
export const MARK_REQUEST_AS_REJECTED_SUCCESS =
  'MARK_REQUEST_AS_REJECTED_SUCCESS';
export const MARK_REQUEST_AS_REJECTED_FAIL = 'MARK_REQUEST_AS_REJECTED_FAIL';

// Add
export const ADD_REQUEST_START = 'ADD_REQUEST_START';
export const ADD_REQUEST_SAGA = 'ADD_REQUEST_SAGA';
export const ADD_REQUEST_SUCCESS = 'ADD_REQUEST_SUCCESS';
export const ADD_REQUEST_FAIL = 'ADD_REQUEST_FAIL';
