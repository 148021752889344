import {
  LOGIN_SAGA,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_START,
  LOGOUT_SAGA,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  AUTHENTICATION_VALIDATOR,
  EDIT_USER_PROFILE_SAGA,
  EDIT_USER_PROFILE_START,
  EDIT_USER_PROFILE_SUCCESS,
  EDIT_USER_PROFILE_FAIL,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SAGA,
  CHANGE_PASSWORD_CHEF_SAGA,
  CHANGE_PASSWORD_CHEF_START,
  CHANGE_PASSWORD_CHEF_SUCCESS,
  CHANGE_PASSWORD_CHEF_FAIL,
  RESET_ERROR_MSG,
  REGISTER_VERIFY_START,
  REGISTER_VERIFY_SAGA,
  REGISTER_VERIFY_SUCCESS,
  REGISTER_VERIFY_FAIL,
  REGISTER_START,
  REGISTER_SAGA,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  OTP_START,
  OTP_SAGA,
  OTP_SUCCESS,
  OTP_FAIL,
  RESET_PASSWORD_TOKEN_VERIFICATION_START,
  RESET_PASSWORD_TOKEN_VERIFICATION_SAGA,
  RESET_PASSWORD_TOKEN_VERIFICATION_SUCCESS,
  RESET_PASSWORD_TOKEN_VERIFICATION_FAIL,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SAGA,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SAGA,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  EMAIL_VERIFICATION_TOKEN_VERIFICATION_START,
  EMAIL_VERIFICATION_TOKEN_VERIFICATION_SAGA,
  EMAIL_VERIFICATION_TOKEN_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_TOKEN_VERIFICATION_FAIL,
} from '../actionLabels';

export const loginStart = () => ({
  type: LOGIN_START,
});

export const login = payload => ({
  type: LOGIN_SAGA,
  payload,
});

export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFail = payload => ({
  type: LOGIN_FAIL,
  payload,
});
export const logoutStart = payload => ({
  type: LOGOUT_START,
  payload,
});
export const logout = payload => ({
  type: LOGOUT_SAGA,
  payload,
});

export const updateProfile = payload => ({
  type: EDIT_USER_PROFILE_SAGA,
  payload,
});

export const updateProfileStart = payload => ({
  type: EDIT_USER_PROFILE_START,
  payload,
});

export const updateProfileSuccess = payload => ({
  type: EDIT_USER_PROFILE_SUCCESS,
  payload,
});

export const updateProfileFail = payload => ({
  type: EDIT_USER_PROFILE_FAIL,
  payload,
});

export const changePassword = payload => ({
  type: CHANGE_PASSWORD_SAGA,
  payload,
});

export const changePasswordStart = payload => ({
  type: CHANGE_PASSWORD_START,
  payload,
});

export const changePasswordSuccess = payload => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const changePasswordFail = payload => ({
  type: CHANGE_PASSWORD_FAIL,
  payload,
});

export const changePasswordChefSaga = payload => ({
  type: CHANGE_PASSWORD_CHEF_SAGA,
  payload,
});

export const changePasswordChefStart = payload => ({
  type: CHANGE_PASSWORD_CHEF_START,
  payload,
});

export const changePasswordChefSuccess = payload => ({
  type: CHANGE_PASSWORD_CHEF_SUCCESS,
  payload,
});

export const changePasswordChefFail = payload => ({
  type: CHANGE_PASSWORD_CHEF_FAIL,
  payload,
});

export const logoutSuccess = payload => ({
  type: LOGOUT_SUCCESS,
  payload,
});

export const logoutFailed = payload => ({
  type: LOGOUT_FAILED,
  payload,
});

export const authenticationValidator = () => ({
  type: AUTHENTICATION_VALIDATOR,
});

export const resetErrorMsg = () => ({
  type: RESET_ERROR_MSG,
});

export const registerVerifyStart = () => ({
  type: REGISTER_VERIFY_START,
});

export const registerVerifySaga = payload => ({
  type: REGISTER_VERIFY_SAGA,
  payload,
});

export const registerVerifySuccess = payload => ({
  type: REGISTER_VERIFY_SUCCESS,
  payload,
});

export const registerVerifyFail = payload => ({
  type: REGISTER_VERIFY_FAIL,
  payload,
});

export const registerStart = () => ({
  type: REGISTER_START,
});

export const register = payload => ({
  type: REGISTER_SAGA,
  payload,
});

export const registerSuccess = payload => ({
  type: REGISTER_SUCCESS,
  payload,
});

export const registerFail = payload => ({
  type: REGISTER_FAIL,
  payload,
});

export const otpStart = () => ({
  type: OTP_START,
});

export const otp = payload => ({
  type: OTP_SAGA,
  payload,
});

export const otpSuccess = payload => ({
  type: OTP_SUCCESS,
  payload,
});

export const otpFail = payload => ({
  type: OTP_FAIL,
  payload,
});

export const resetPasswordTokenVerificationStart = () => ({
  type: RESET_PASSWORD_TOKEN_VERIFICATION_START,
});

export const resetPasswordTokenVerificationSaga = payload => ({
  type: RESET_PASSWORD_TOKEN_VERIFICATION_SAGA,
  payload,
});

export const resetPasswordTokenVerificationSuccess = payload => ({
  type: RESET_PASSWORD_TOKEN_VERIFICATION_SUCCESS,
  payload,
});

export const resetPasswordTokenVerificationFail = payload => ({
  type: RESET_PASSWORD_TOKEN_VERIFICATION_FAIL,
  payload,
});

export const resetPasswordStart = () => ({
  type: RESET_PASSWORD_START,
});

export const resetPasswordSaga = payload => ({
  type: RESET_PASSWORD_SAGA,
  payload,
});

export const resetPasswordSuccess = payload => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFail = payload => ({
  type: RESET_PASSWORD_FAIL,
  payload,
});

export const forgotPasswordStart = () => ({
  type: FORGOT_PASSWORD_START,
});

export const forgotPassword = payload => ({
  type: FORGOT_PASSWORD_SAGA,
  payload,
});

export const forgotPasswordSuccess = payload => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordFail = payload => ({
  type: FORGOT_PASSWORD_FAIL,
  payload,
});

export const emailVerificationTokenVerificationStart = () => ({
  type: EMAIL_VERIFICATION_TOKEN_VERIFICATION_START,
});

export const emailVerificationTokenVerificationSaga = payload => ({
  type: EMAIL_VERIFICATION_TOKEN_VERIFICATION_SAGA,
  payload,
});

export const emailVerificationTokenVerificationSuccess = payload => ({
  type: EMAIL_VERIFICATION_TOKEN_VERIFICATION_SUCCESS,
  payload,
});

export const emailVerificationTokenVerificationFail = payload => ({
  type: EMAIL_VERIFICATION_TOKEN_VERIFICATION_FAIL,
  payload,
});
