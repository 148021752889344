import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  setting: {
    GST: '',
    appEmail: '',
    customerCareNumber: '',
    chefNumber: '',
    commissionPercentage: '',
    couponValue: '',
    defaultChefImage: '',
    defaultChefImageThumb: '',
    minimumCartValue: '',
    minimumCartValueUpdatedAt: '',
    minumumCartValue: '',
    orderAcceptTime: '',
    orderNumber: '',
    preOrderDeliveryDays: '',
    packagingCharges: '',
    deliveryChargeBasedKms: '',
    deliveryChargeBasedPrice: '',
    orderPreparingTime: '',
    rideCommission: {
      commission: '',
      tenure: '',
    },
    salesOnStoreCommission: {
      commission: '',
      tenure: '',
    },
    darkHomeStoreCommission: {
      commission: '',
      tenure: '',
    },
    affiliationDriverCommission: {
      commission: '',
      tenure: '',
    },
    affiliationStoreCommission: {
      commission: '',
      tenure: '',
    },
    affiliationDarkHomeStoreCommission: {
      commission: '',
      tenure: '',
    },
    affiliationProducerStoreCommission: {
      commission: '',
      tenure: '',
    },
    wemoveTransactionCommission: {
      commission: '',
      tenure: '',
    },
    wemovePaymentModuleCommission: {
      commission: '',
      tenure: '',
    },
  },
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_SETTING_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_SETTING_SUCCESS: {
      const { setting } = payload;
      return { ...state, setting, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_SETTING_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.EDIT_ALL_SETTINGS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_ALL_SETTINGS_SUCCESS: {
      const setting = payload;
      return {
        ...state,
        setting: { ...state.setting, ...setting },
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.EDIT_ALL_SETTINGS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }
    default:
      return state;
  }
};
