import { put } from 'redux-saga/effects';
import {
  getAllSubadminStart,
  getAllSubadminSuccess,
  getAllSubadminFail,
  getAllSubadminSaga as getAllSubadminSagaAction,
  addSubadminStart,
  addSubadminSuccess,
  addSubadminFail,
  editSubadminStart,
  editSubadminSuccess,
  editSubadminFail,
  editSubadminStatusStart,
  editSubadminStatusSuccess,
  editSubadminStatusFail,
  getSubadminByIdStart,
  getSubadminByIdSuccess,
  getSubadminByIdFail,
  generatePDFStart,
  generatePDFSuccess,
  generatePDFFail,
  logout,
  showModal,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllSubadminSaga(action) {
  yield put(getAllSubadminStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllSubAdminList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllSubadminSuccess({ subadmins: response.data.data }));
    } else {
      yield put(getAllSubadminFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllSubadminFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllSubadminFail(error.response.data.message));
      } else {
        yield put(getAllSubadminFail('Server error! Please try again.'));
      }
    } else if (
      error.message !== undefined &&
      error.message.includes('Network Error')
    ) {
      // console.log('Server is not available for a moment! Please try again later');
    } else {
      yield put(getAllSubadminFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getSubadminByIdSaga(action) {
  yield put(getSubadminByIdStart());
  try {
    const response = yield axios.get(
      `/admin/getSubAdminDetailById?id${action.payload.id}`,
    );
    if (response.status === 200) {
      yield put(getSubadminByIdSuccess({ subadmin: response.data.data }));
    } else {
      yield put(getSubadminByIdFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getSubadminByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getSubadminByIdFail(error.response.data.message));
      } else {
        yield put(getSubadminByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(getSubadminByIdFail('Something went wrong! Please try again.'));
    }
  }
}

export function* addSubadminSaga(action) {
  yield put(addSubadminStart());
  const {
    firstName,
    lastName,
    email,
    phone,
    isDeleted,
    password,
    tableFilter,
    closeModel,
    setIsDisable,
  } = action.payload;
  const data = {
    firstName,
    lastName,
    email,
    phone,
    isDeleted,
    password,
  };
  try {
    const response = yield axios.post(`/admin/addSubAdmin`, data);
    if (response.status === 200) {
      yield put(addSubadminSuccess(response.data.data));
      yield closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      setIsDisable(false);
      yield put(getAllSubadminSagaAction(tableFilter));
    } else {
      yield put(addSubadminFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addSubadminFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addSubadminFail(error.response.data.message));
      } else {
        yield put(addSubadminFail('Server error! Please try again.'));
      }
    } else {
      yield put(addSubadminFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editSubadminSaga(action) {
  yield put(editSubadminStart());
  try {
    const response = yield axios.post(`/admin/editSubAdmin`, action.payload);
    if (response.status === 200) {
      yield put(editSubadminSuccess());
    } else {
      yield put(editSubadminFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editSubadminFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editSubadminFail(error.response.data.message));
      } else {
        yield put(editSubadminFail('Server error! Please try again.'));
      }
    } else {
      yield put(editSubadminFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editSubadminStatusSaga(action) {
  yield put(editSubadminStatusStart());
  try {
    const response = yield axios.post(
      `/admin/updateSubAdminStatus`,
      action.payload,
    );
    if (response.status === 200) {
      yield put(editSubadminStatusSuccess(action.payload));
    } else {
      yield put(
        editSubadminStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editSubadminStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editSubadminStatusFail(error.response.data.message));
      } else {
        yield put(editSubadminStatusFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editSubadminStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// eslint-disable-next-line no-unused-vars
export function* generatePDFSaga(action) {
  yield put(generatePDFStart());
  try {
    const response = yield axios.get(`/admin/generatePDF`);
    if (response.status === 200) {
      yield put(generatePDFSuccess(response.data));
    } else {
      yield put(generatePDFFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(generatePDFFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(generatePDFFail(error.response.data.message));
      } else {
        yield put(generatePDFFail('Server error! Please try again.'));
      }
    } else {
      yield put(generatePDFFail('Something went wrong! Please try again.'));
    }
  }
}
