export const RESET_ALL_ACCESS_MODULES = 'RESET_ALL_ACCESS_MODULES';
export const RESET_ACCESS_MODULE_DETAIL = 'RESET_ACCESS_MODULE_DETAIL';

// Get All ACCESS_MODULEs
export const GET_ALL_ACCESS_MODULE_START = 'GET_ALL_ACCESS_MODULE_START';
export const GET_ALL_ACCESS_MODULE_SAGA = 'GET_ALL_ACCESS_MODULE_SAGA';
export const GET_ALL_ACCESS_MODULE_SUCCESS = 'GET_ALL_ACCESS_MODULE_SUCCESS';
export const GET_ALL_ACCESS_MODULE_FAIL = 'GET_ALL_ACCESS_MODULE_FAIL';

// Get ACCESS_MODULE by id
export const GET_ACCESS_MODULE_BY_ID_START = 'GET_ACCESS_MODULE_BY_ID_START';
export const GET_ACCESS_MODULE_BY_ID_SAGA = 'GET_ACCESS_MODULE_BY_ID_SAGA';
export const GET_ACCESS_MODULE_BY_ID_SUCCESS =
  'GET_ACCESS_MODULE_BY_ID_SUCCESS';
export const GET_ACCESS_MODULE_BY_ID_FAIL = 'GET_ACCESS_MODULE_BY_ID_FAIL';

// Add ACCESS_MODULE
export const ADD_ACCESS_MODULE_START = 'ADD_ACCESS_MODULE_START';
export const ADD_ACCESS_MODULE_SAGA = 'ADD_ACCESS_MODULE_SAGA';
export const ADD_ACCESS_MODULE_SUCCESS = 'ADD_ACCESS_MODULE_SUCCESS';
export const ADD_ACCESS_MODULE_FAIL = 'ADD_ACCESS_MODULE_FAIL';

// Edit ACCESS_MODULE
export const EDIT_ACCESS_MODULE_START = 'EDIT_ACCESS_MODULE_START';
export const EDIT_ACCESS_MODULE_SAGA = 'EDIT_ACCESS_MODULE_SAGA';
export const EDIT_ACCESS_MODULE_SUCCESS = 'EDIT_ACCESS_MODULE_SUCCESS';
export const EDIT_ACCESS_MODULE_FAIL = 'EDIT_ACCESS_MODULE_FAIL';

// Block Unblock
export const EDIT_BLOCK_ACCESS_MODULE_STATUS_START =
  'EDIT_BLOCK_ACCESS_MODULE_STATUS_START';
export const EDIT_BLOCK_ACCESS_MODULE_STATUS_SAGA =
  'EDIT_BLOCK_ACCESS_MODULE_STATUS_SAGA';
export const EDIT_BLOCK_ACCESS_MODULE_STATUS_SUCCESS =
  'EDIT_BLOCK_ACCESS_MODULE_STATUS_SUCCESS';
export const EDIT_BLOCK_ACCESS_MODULE_STATUS_FAIL =
  'EDIT_BLOCK_ACCESS_MODULE_STATUS_FAIL';

// Delete ACCESS_MODULE
export const DELETE_ACCESS_MODULE_START = 'DELETE_ACCESS_MODULE_START';
export const DELETE_ACCESS_MODULE_SAGA = 'DELETE_ACCESS_MODULE_SAGA';
export const DELETE_ACCESS_MODULE_SUCCESS = 'DELETE_ACCESS_MODULE_SUCCESS';
export const DELETE_ACCESS_MODULE_FAIL = 'DELETE_ACCESS_MODULE_FAIL';
