/* eslint-disable max-len */
import { put ,call} from 'redux-saga/effects';
import Axios from 'axios';
import {
    logout,
    showModal,
    getAllChefPayoutListSaga,
    addLogisticOfPromoterStart,
    addLogisticOfPromoterSuccess,
    addLogisticOfPromoterFail,
    getAllLogisticOfPromoterFail,
    getAllLogisticOfPromoterSuccess,
    getAllLogisticOfPromoterStart,
    deleteLogisticOfPromoterStart,
    deleteLogisticOfPromoterSuccess,
    deleteLogisticOfPromoterFail,
    logisticsDetailsStart,
    logisticsDetailsSuccess,
    logisticsDetailsFail,
    approveRejectLogisticsStart,
    approveRejectLogisticsSuccess,
    approveRejectLogisticsFail,
    getAllSupportRequestLogisticsStart,
    getAllSupportRequestLogisticsSuccess,
    getAllSupportRequestLogisticsFail,
    addSupportRequestLogisticsStart,
    addSupportRequestLogisticsSuccess,
    addSupportRequestLogisticsFail,
    getAllLogisticsPayoutHistoryStart,
    getAllLogisticsPayoutHistorySuccess,
    getAllLogisticsPayoutHistoryFail,
    editBlockLogisticStatusStart,
    editBlockLogisticStatusSuccess,
    editBlockLogisticStatusSFail,
    editLogisticDetailStart,
    editLogisticDetailSuccess,
    editLogisticDetailFail,
    getAllOrderListStart,
    getAllDriverOrderListStart,
    getAllOrderListSuccess,
    getAllOrderListFail,
    getReceptionListStart,
    getReceptionListSuccess,
    getReceptionListFail,
    acceptOrderForDeliveryStart,
    acceptOrderForDeliverySuccess,
    acceptOrderForDeliveryFail,

    getAllVehicleListStart,
    getAllVehicleListSuccess,
    getAllVehicleListFail,
    arrivedDataToReceptionStart,
    arrivedDataToReceptionSuccess,
    arrivedDataToReceptionFail,

    getLatLongFromAddressStart,
    getLatLongFromAddressSuccess,
    getLatLongForLogisticStart,
    getLatLongForLogisticSuccess,
    getAllLogisticDetailStart,
    getAllLogisticDetailSuccess,
    getAllLogisticDetailFail,
    sendProductToLogisticStart,
    sendProductToLogisticSuccess,
    sendProductToLogisticFail,
    getAllLogisticOrderStart,
    getAllLogisticOrderSuccess,
    getAllLogisticOrderFail,
    getOrderDetailByIdStart,
    getOrderDetailByIdSuccess,
    getOrderDetailByIdFail,
    addNewEmployeeStart,
    addNewEmployeeSuccess,
    addNewEmployeeFail,
    getAllEmployeeDataStart,
    getAllEmployeeDataSuccess,
    getAllEmployeeDataFail,
    editEmployeeStatusStart,
    editEmployeeStatusSuccess,
    editEmployeeStatusFail,
    deleteLogisticEmployeeStart,
    deleteLogisticEmployeeSuccess,
    deleteLogisticEmployeeFail,
    editNewEmployeeStart,
    editNewEmployeeSuccess,
    editNewEmployeeFail,
    getAllDestinyDataStart,
    getAllDestinyDataSuccess,
    getAllDestinyDataFail,
    getAllDriverOrderListSuccess,
    getAllDriverOrderListFail,

    deleteDriverStart,
    deleteDriverSuccess,
    deleteDriverFail,
   

} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

// ADD LOGISTIC FROM PROMOTER SIDE
export function* addLogisticOfPromoterSaga(action) {
    yield put(addLogisticOfPromoterStart());
    try {
        const { tableFilter, coordinates, reqBody } = action.payload;
        const {
            address,
            city,
            confirmPassword,
            country,
            countryCode,
            email,
            logistics,
            name,
            password,
            phone,
            state,
            transportModeId,
            landmark,
            area,
            pincode,
            address2
        } = reqBody;
        const data = new FormData();
        data.append('address', address);
        data.append('city', city);
        data.append('confirmPassword', confirmPassword);
        data.append('country', country);
        data.append('countryCode', countryCode);
        data.append('email', email);
        data.append('logistics', logistics);
        data.append('name', name);
        data.append('password', password);
        data.append('phone', phone);
        data.append('state', state);
        data.append('transportModeId', transportModeId);
        data.append('lat', coordinates?.latitude);
        data.append('long', coordinates?.longitude);

        data.append('area', area);
        data.append('landmark', landmark);
        data.append('pincode', pincode);
        data.append('address2', address2);
        const response = yield axios.post(`/promoter/addlogistics`, data);
        if (response.status === 200) {
            yield put(addLogisticOfPromoterSuccess(action.payload));
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    redirectURL: '/promoters/logistic-hub',
                    message: response.data.message || 'Success',
                }),
            );
            yield put(getAllChefPayoutListSaga(tableFilter));
        } else {
            yield put(addLogisticOfPromoterFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        console.log('ye rheee errorxxxxxxxxxxxx')
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            // yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(addLogisticOfPromoterFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(addLogisticOfPromoterFail(error.response.data.message));
            } else {
                yield put(addLogisticOfPromoterFail('Server error! Please try again.'));
            }
        } else {
            yield put(addLogisticOfPromoterFail('Something went wrong! Please try again.'));
        }
    }
}

export function* deleteLogisticOfPromoterSaga(action) {
    yield put(deleteLogisticOfPromoterStart());
    try {
        const { id, setModelDeleteOpen, isDeleted } = action.payload;
        const response = yield axios.post(`/promoter/deletelogistics`, { id, isDeleted });
        console.log(response);
        if (response.status === 200) {
            yield put(deleteLogisticOfPromoterSuccess(id));
            setModelDeleteOpen(false)
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    redirectURL: '/promoters/logistic-hub',
                    message: response.data.message || 'Success',
                }),
            );
        } else {
            yield put(deleteLogisticOfPromoterFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(deleteLogisticOfPromoterFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(deleteLogisticOfPromoterFail(error.response.data.message));
            } else {
                yield put(deleteLogisticOfPromoterFail('Server error! Please try again.'));
            }
        } else {
            yield put(deleteLogisticOfPromoterFail('Something went wrong! Please try again.'));
        }
    }
}
// GET ALL LOGISTIC FROM PROMOTER SIDE
export function* getAllLogisticOfPromoterSaga(action) {
    yield put(getAllLogisticOfPromoterStart());
    try {
        const response = yield axios.get(
            urlPaginationGenerator(`/${action.payload.routes}/getlogistics/`, action.payload),
        );
        console.log(response);
        if (response.status === 200) {
            yield put(getAllLogisticOfPromoterSuccess(response.data.data[0]));
        } else {
            yield put(getAllLogisticOfPromoterFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getAllLogisticOfPromoterFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getAllLogisticOfPromoterFail(error.response.data.message));
            } else {
                yield put(getAllLogisticOfPromoterFail('Server error! Please try again.'));
            }
        } else {
            yield put(getAllLogisticOfPromoterFail('Something went wrong! Please try again.'));
        }
    }
}
// GET ALL LOGISTIC PAYOUT HISTORY
export function* getAllLogisticsPayoutHistorySaga(action) {
    yield put(getAllLogisticsPayoutHistoryStart());
    try {
        const response = yield axios.get(
            urlPaginationGenerator(`/promoter/getAllPayoutHistoryList`, action.payload),
        );
        console.log(response);
        if (response.status === 200) {
            yield put(getAllLogisticsPayoutHistorySuccess(response.data.data[0]));
        } else {
            yield put(getAllLogisticsPayoutHistoryFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getAllLogisticsPayoutHistoryFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getAllLogisticsPayoutHistoryFail(error.response.data.message));
            } else {
                yield put(getAllLogisticsPayoutHistoryFail('Server error! Please try again.'));
            }
        } else {
            yield put(getAllLogisticsPayoutHistoryFail('Something went wrong! Please try again.'));
        }
    }
}

// GET ALL LOGISTIC DETAILS

export function* logisticsDetailsSaga(action) {
    yield put(logisticsDetailsStart());
    try {
        const response = yield axios.get(
            urlPaginationGenerator(`/promoter/getlogisticsbyid`, action.payload),
        );
        console.log(response);
        if (response.status === 200) {
            yield put(logisticsDetailsSuccess(response.data.data));
        } else {
            yield put(logisticsDetailsFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            // yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(logisticsDetailsFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(logisticsDetailsFail(error.response.data.message));
            } else {
                yield put(logisticsDetailsFail('Server error! Please try again.'));
            }
        } else {
            yield put(logisticsDetailsFail('Something went wrong! Please try again.'));
        }
    }
}
// EDIT LOGISTIC DETAILS
export function* editLogisticDetailSaga(action) {
    console.log(action,'QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ111ssssssssssssss');
    yield put(editLogisticDetailStart());
    let { data, coordinates ,profilePic} = action.payload;

    console.log(profilePic,'sssszzzzzzzzzzzzzzzz')
    console.log(action.payload,'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXx')
    if (action.payload.fromLogistics) {


        const {
            address,
            city,
            confirmPassword,
            country,
            countryCode,
            email,
            logisticId,
            logistics,
            name,
            password,
            phone,
            state,
            transportModeId,
          
            area,
            pincode,
            landmark,
            address2,
        } = data;
        data = new FormData();
        data.append('address', address);
        data.append('city', city);
        data.append('confirmPassword', confirmPassword);
        data.append('country', country);
        data.append('countryCode', countryCode);
        data.append('email', email);
        data.append('logisticId', logisticId);
        data.append('logistics', logistics);
        data.append('name', name);
        data.append('password', password);
        data.append('phone', phone);
        data.append('state', state);
        data.append('transportModeId', transportModeId);
    

        data.append('area', area);
        data.append('pincode', pincode);
        data.append('landmark', landmark);
        data.append('address2', address2);

        data.append('lat', coordinates?.latitude);
        data.append('long', coordinates?.longitude);
    }else{
        const {
            address,
            address2,
            area,
            city,
            country,
           houseNo,
           landmark,
           longitude,
           latitude,
           logisticId,
           name,
           phone,
           pincode,
           profilePic,
           state,

            // confirmPassword,
            
            // countryCode,
            // email,
            
            // logistics,
            
            // password,
           
            // state,
            // transportModeId,
          
            
            // pincode,
            
            
        } = data;
        data = new FormData();

        data.append('address', address);
        data.append('address2', address2);
        data.append('area', area);
        data.append('city', city);
        data.append('country', country);
        // data.append('houseNo', houseNo);
        data.append('landmark', landmark);
        data.append('lat', latitude);
        data.append('long', longitude);
        data.append('logisticId', logisticId);
        data.append('name', name);
        data.append('phone', phone);
        data.append('pincode', pincode);
        data.append('logistics', profilePic);
        data.append('state', state);
    }
    // data.append('logistics', profilePic);
    try {
        const response = yield axios.post(
            `/logistics/updateprofile`,
            data,
        );
        console.log(response.data.data,'logistic profile')
        if (response.status === 200) {


            let userData = yield JSON.parse(localStorage.getItem('userData'));

            // console.log(response.data.data,'@@@@@@@@@@@@@@@@@@@@@')
            userData = {
              ...userData,
              ...response?.data?.data?.updateProfile,
            };
            yield call(
              [localStorage, 'setItem'],
              'userData',
              JSON.stringify(userData),
            );


            yield put(editLogisticDetailSuccess(response.data.data));
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    message: response.data.message || 'Success',
                    redirectURL: window.location.reload(),
                }),
            );

        } else {
            yield put(
                editLogisticDetailFail('Something went wrong! Please try again.'),
            );
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            if (error.response.status === 400) {
                yield put(editLogisticDetailFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(editLogisticDetailFail(error.response.data.message));
            } else {
                yield put(editLogisticDetailFail('Server error! Please try again.'));
            }
        } else {
            yield put(
                editLogisticDetailFail('Something went wrong! Please try again.'),
            );
        }
    }
}

// All Logistic Support Request
export function* getAllSupportRequestLogisticsSaga(action) {
    yield put(getAllSupportRequestLogisticsStart());
    try {
        const response = yield axios.get(
            urlPaginationGenerator(`/logistics/getAllLogisticsSupportRequest`, action.payload),
        );
        console.log(response);
        if (response.status === 200) {
            yield put(getAllSupportRequestLogisticsSuccess(response.data.data));
        } else {
            yield put(getAllSupportRequestLogisticsFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getAllSupportRequestLogisticsFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getAllSupportRequestLogisticsFail(error.response.data.message));
            } else {
                yield put(getAllSupportRequestLogisticsFail('Server error! Please try again.'));
            }
        } else {
            yield put(getAllSupportRequestLogisticsFail('Something went wrong! Please try again.'));
        }
    }
}
// All Logistic Support Request
export function* getAllLogisticDetailSaga(action) {
    console.log('++++++++++++++++++++++++++++++++++++++++')
    yield put(getAllLogisticDetailStart());
    try {


        const response = yield axios.get(
            urlPaginationGenerator(`logistics/logisticscity`, action.payload),
        );
        console.log(response);
        if (response.status === 200) {
            yield put(getAllLogisticDetailSuccess(response.data.data));
        } else {
            yield put(getAllLogisticDetailFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getAllLogisticDetailFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getAllLogisticDetailFail(error.response.data.message));
            } else {
                yield put(getAllLogisticDetailFail('Server error! Please try again.'));
            }
        } else {
            yield put(getAllLogisticDetailFail('Something went wrong! Please try again.'));
        }
    }
}


export function* approveRejectLogisticsSaga(action) {
    yield put(approveRejectLogisticsStart());

    try {
        const { id, closeModel, isVerified, isRejected, rejectReason, promoterApi, adminApi, isApproved, isProfileSubmitted } = action.payload;
        let response
        if (promoterApi) {
            response = yield axios.post(`${promoterApi}`, { id, isVerified, isRejected, rejectReason });
        } else if (adminApi) {
            response = yield axios.post(`${adminApi}`, { id, isApproved, isRejected, rejectReason, isProfileSubmitted });
        }
        console.log(response);
        if (response.status === 200) {
            yield put(approveRejectLogisticsSuccess(response.data.data));
            if (isRejected) { closeModel(false) }
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    redirectURL: '/promoters/logistic-hub',
                    message: response.data.message || 'Success',
                }),
            );
        } else {
            yield put(approveRejectLogisticsFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(approveRejectLogisticsFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(approveRejectLogisticsFail(error.response.data.message));
            } else {
                yield put(approveRejectLogisticsFail('Server error! Please try again.'));
            }
        } else {
            yield put(approveRejectLogisticsFail('Something went wrong! Please try again.'));
        }
    }
}


export function* addSupportRequestLogisticsSaga(action) {
    yield put(addSupportRequestLogisticsStart());
    try {
        const { message, tableFilter, closeModel } = action.payload;
        console.log(message)
        const response = yield axios.post(`/promoter/addLogisticsSupportRequest`, { message, tableFilter });
        console.log(response);
        if (response.status === 200) {
            yield put(addSupportRequestLogisticsSuccess(response.data.data));
            closeModel(false)
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    message: response.data.message || 'Success',
                }),
            );
        } else {
            yield put(addSupportRequestLogisticsFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(addSupportRequestLogisticsFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(addSupportRequestLogisticsFail(error.response.data.message));
            } else {
                yield put(addSupportRequestLogisticsFail('Server error! Please try again.'));
            }
        } else {
            yield put(addSupportRequestLogisticsFail('Something went wrong! Please try again.'));
        }
    }
}

export function* sendProductToLogisticSaga(action) {
    console.log('saga logis')
    yield put(sendProductToLogisticStart());
    try {
        const { ids, orderIds, setButtonVisible, searchCardRequestEvent } = action.payload;
        const response = yield axios.post(`/logistics/addroute`, { ids, orderIds });
        console.log(response);
        if (response.status === 200) {
            setButtonVisible(true)
            yield put(sendProductToLogisticSuccess(response.data.data));
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    // redirectURL: '/logistics/new-orders',
                    // message: response.data.message || 'Success',
                    message: "Route Added Successfully , Notification Send to drive",
                }),
            );
            // yield put(searchCardRequestEvent());
        } else {
            yield put(sendProductToLogisticFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(sendProductToLogisticFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(sendProductToLogisticFail(error.response.data.message));
            } else {
                yield put(sendProductToLogisticFail('Server error! Please try again.'));
            }
        } else {
            yield put(addSupportRequestLogisticsFail('Something went wrong! Please try again.'));
        }
    }
}

export function* editBlockLogisticStatusSaga(action) {
    yield put(editBlockLogisticStatusStart());
    try {
        const { id, isBlock, routes } = action.payload;
        const response = yield axios.post(
            `/${routes}/blockunblockLogistics`,
            { id, isBlock }
        );
        console.log({ response })
        if (response.status === 200) {
            yield put(editBlockLogisticStatusSuccess(action.payload));
        } else {
            yield put(
                editBlockLogisticStatusSFail('Something went wrong! Please try again.'),
            );
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            if (error.response.status === 400) {
                yield put(editBlockLogisticStatusSFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(editBlockLogisticStatusSFail(error.response.data.message));
            } else {
                yield put(editBlockLogisticStatusSFail('Server error! Please try again.'));
            }
        } else {
            yield put(
                editBlockLogisticStatusSFail('Something went wrong! Please try again.'),
            );
        }
    }
}

// All Logistic Support Request
export function* getAllOrderListSaga(action) {

    console.log(action.payload, '00000007')
    yield put(getAllOrderListStart());
    try {

        if (action.payload.emp != null && action.payload.status == 'routes') {
            const response = yield axios.post(
                `logistics/drivershippingdetails`, {
                orderId: action.payload.emp.payload.orderIds.id,
                id: action.payload.emp.payload._id
            },
            );
            console.log(response);
            if (response.status === 200) {
                yield put(getAllOrderListSuccess(response.data.data));
            } else {
                yield put(getAllOrderListFail('Something went wrong! Please try again.'));
            }
        } else {
            const response = yield axios.get(
                urlPaginationGenerator(`logistics/orders`, action.payload),
            );
            console.log(response);
            if (response.status === 200) {
                yield put(getAllOrderListSuccess(response.data.data));
            } else {
                yield put(getAllOrderListFail('Something went wrong! Please try again.'));
            }
        }

    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            // yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getAllOrderListFail(error.response.data.message));
            } else if (error.response.status === 401) {

                console.log('shipping logout')
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getAllOrderListFail(error.response.data.message));
            } else {
                yield put(getAllOrderListFail('Server error! Please try again.'));
            }
        } else {
            yield put(getAllOrderListFail('Something went wrong! Please try again.'));
        }
    }
}

// All Logistic Driver Request
export function* getAllDriverOrderListSaga(action) {
    yield put(getAllDriverOrderListStart());
    try {
        const response = yield axios.get(
            urlPaginationGenerator(`logistics/driverorders`, action.payload),
        );
        if (response.status === 200) {
            yield put(getAllDriverOrderListSuccess(response.data.data?.data));
        } else {
            yield put(getAllDriverOrderListFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getAllDriverOrderListFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getAllDriverOrderListFail(error.response.data.message));
            } else {
                yield put(getAllDriverOrderListFail('Server error! Please try again.'));
            }
        } else {
            yield put(getAllDriverOrderListFail('Something went wrong! Please try again.'));
        }
    }
}
// All Logistic reception list
export function* getReceptionListSaga(action) {
    yield put(getReceptionListStart());
    try {
        const response = yield axios.get(
            urlPaginationGenerator(`logistics/reception`, action.payload),
        );
        console.log(response);
        if (response.status === 200) {
            yield put(getReceptionListSuccess(response.data.data));
        } else {
            yield put(getReceptionListFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getReceptionListFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getReceptionListFail(error.response.data.message));
            } else {
                yield put(getReceptionListFail('Server error! Please try again.'));
            }
        } else {
            yield put(getReceptionListFail('Something went wrong! Please try again.'));
        }
    }
}
export function* getAllVehicleListSaga(action) {
    yield put(getAllVehicleListStart());
    try {
        const response = yield axios.get(
            urlPaginationGenerator(`logistics/vehicleList`, action.payload),
        );
        console.log(response);
        if (response.status === 200) {
            yield put(getAllVehicleListSuccess(response.data.data));
        } else {
            yield put(getAllVehicleListFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload?.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getAllVehicleListFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getAllVehicleListFail(error.response.data.message));
            } else {
                yield put(getAllVehicleListFail('Server error! Please try again.'));
            }
        } else {
            yield put(getAllVehicleListFail('Something went wrong! Please try again.'));
        }
    }
}


export function* acceptOrderForDeliverySaga(action) {
    yield put(acceptOrderForDeliveryStart());
    try {
        const { status, orderId, setModelOpen } = action.payload;
        const response = yield axios.post(
            `logistics/updateStatusofLogistics`,
            { status, orderId }
        );
        console.log({ response })
        if (response.status === 200) {
            yield put(acceptOrderForDeliverySuccess(action.payload));
            setModelOpen ? setModelOpen(false) : console.log("deleted");
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    message: response.data.message || 'Success',
                }),
            );
        } else {
            yield put(
                acceptOrderForDeliveryFail('Something went wrong! Please try again.'),
            );
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            if (error.response.status === 400) {
                yield put(acceptOrderForDeliveryFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(acceptOrderForDeliveryFail(error.response.data.message));
            } else {
                yield put(acceptOrderForDeliveryFail('Server error! Please try again.'));
            }
        } else {
            yield put(
                acceptOrderForDeliveryFail('Something went wrong! Please try again.'),
            );
        }
    }
}


export function* arrivedDataToReceptionSaga(action) {
    yield put(arrivedDataToReceptionStart());
    try {
        const response = yield axios.post(
            `/logistics/reception`,
            { ids: action.payload.ids }
        );
        console.log({ response });
        if (response.status === 200) {
            yield put(arrivedDataToReceptionSuccess(action.payload.ids));
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    message: response.data.message || 'Success',
                    redirectURL: '/logistics/shipping'
                }),
            );
            yield put(action.payload.setModelOpen(false))

        } else {
            yield put(
                arrivedDataToReceptionFail('Something went wrong! Please try again.'),
            );
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            if (error.response.status === 400) {
                yield put(arrivedDataToReceptionFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(arrivedDataToReceptionFail(error.response.data.message));
            } else {
                yield put(arrivedDataToReceptionFail('Server error! Please try again.'));
            }
        } else {
            yield put(
                arrivedDataToReceptionFail('Something went wrong! Please try again.'),
            );
        }
    }
}

export function* getLatLongForLogisticSaga(action) {
    const WebURL = 'https://maps.googleapis.com/maps/api/geocode/json?';
    const APIKey = 'AIzaSyD6M4Qgp4QzRf1PMYCsOTpDuVYSm0GAFbU';
    console.log("latlongapi called")
    yield put(getLatLongForLogisticStart());
    try {
        console.log(action)
        const response = yield Axios.get(
            `${WebURL}address=${encodeURIComponent(action.payload)}&key=${APIKey}`,
        );
        console.log(response);
        if (response.data.results.length !== 0) {
            const coordinate = {
                lat: response.data.results[0].geometry.location.lat,
                long: response.data.results[0].geometry.location.lng,
            };
            yield put(getLatLongForLogisticSuccess(coordinate));
        } else {
            const coordinate = {
                lat: '',
                long: '',
            };
            yield put(getLatLongForLogisticSuccess(coordinate));
        }
    } catch (e) {
        const coordinate = {
            lat: '',
            long: '',
        };
        yield put(getLatLongForLogisticSuccess(coordinate));
    }
}


export function* getAllLogisticOrderSaga(action) {
    yield put(getAllLogisticOrderStart());
    try {
        const { routes } = action.payload;
        console.log(routes)

   
        const response = yield axios.get(
            urlPaginationGenerator(`/${action.payload.routes}/getAllOrderList`, action.payload),
            // urlPaginationGenerator(`/${action.payload.routes}/getAllOrderList?forLogistics=true`, action.payload),


            // `${routes}/getAllOrderList?forLogistics=true`
        );
        console.log(response);
        if (response.status === 200) {
            yield put(getAllLogisticOrderSuccess(response.data.data));
        } else {
            yield put(getAllLogisticOrderFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getAllLogisticOrderFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getAllLogisticOrderFail(error.response.data.message));
            } else {
                yield put(getAllLogisticOrderFail('Server error! Please try again.'));
            }
        } else {
            yield put(getAllLogisticOrderFail('Something went wrong! Please try again.'));
        }
    }
}

export function* getOrderDetailByIdSaga(action) {
    yield put(getOrderDetailByIdStart());
    try {
        const response = yield axios.get(
            `/admin/getOrderDetailById?id=${action.payload.id}&forLogistics=true`
        );
        console.log(response);
        if (response.status === 200) {
            yield put(getOrderDetailByIdSuccess(response.data.data));
        } else {
            yield put(getOrderDetailByIdFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getOrderDetailByIdFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getOrderDetailByIdFail(error.response.data.message));
            } else {
                yield put(getOrderDetailByIdFail('Server error! Please try again.'));
            }
        } else {
            yield put(getOrderDetailByIdFail('Something went wrong! Please try again.'));
        }
    }
}

export function* addNewEmployeeSaga(action) {
    yield put(addNewEmployeeStart());
    try {
        const formdata = new FormData();
        formdata.append('name', action.payload.formValues.name);
        formdata.append('email', action.payload.formValues.email);
        formdata.append('employee_profile', action.payload.formValues.profileimage);
        formdata.append('gender', action.payload.formValues.gender);
        formdata.append('phone', action.payload.formValues.phone);
        formdata.append('dob', action.payload.formValues.dob);
        formdata.append('country', action.payload.formValues.country);
        formdata.append('countryCode', action.payload.formValues.countryCode);
        formdata.append('state', action.payload.formValues.state);
        formdata.append('city', action.payload.formValues.city);
        const response = yield axios.post(
            `/logistics/addLogisticEmployee`, formdata
        );
        console.log(response);
        if (response.status === 200) {
            yield put(addNewEmployeeSuccess(response.data.data));
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    redirectURL: '/logistics/new-employee',
                    message: response.data.message || 'success',
                }),
            );
        } else {
            yield put(addNewEmployeeFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(addNewEmployeeFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(addNewEmployeeFail(error.response.data.message));
            } else {
                yield put(addNewEmployeeFail('Server error! Please try again.'));
            }
        } else {
            yield put(addNewEmployeeFail('Something went wrong! Please try again.'));
        }
    }
}

export function* editNewEmployeeSaga(action) {
    yield put(editNewEmployeeStart());
    try {
        const formdata = new FormData();
        formdata.append('name', action.payload.formValues.name);
        formdata.append('email', action.payload.formValues.email);
        formdata.append('employee_profile', action.payload.formValues.profileimage);
        formdata.append('gender', action.payload.formValues.gender);
        formdata.append('phone', action.payload.formValues.phone);
        formdata.append('dob', action.payload.formValues.dob);
        formdata.append('country', action.payload.formValues.country);
        formdata.append('countryCode', action.payload.formValues.countryCode);
        formdata.append('state', action.payload.formValues.state);
        formdata.append('city', action.payload.formValues.city);
        formdata.append('employeeId', action.payload.formValues.id);
        const response = yield axios.post(
            `/logistics/updateLogisticEmployee`, formdata
        );
        console.log(response);
        if (response.status === 200) {
            yield put(editNewEmployeeSuccess(response.data.data));
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    redirectURL: '/logistics/new-employee',
                    message: response.data.message || 'success',
                }),
            );
        } else {
            yield put(editNewEmployeeFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(editNewEmployeeFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(editNewEmployeeFail(error.response.data.message));
            } else {
                yield put(editNewEmployeeFail('Server error! Please try again.'));
            }
        } else {
            yield put(editNewEmployeeFail('Something went wrong! Please try again.'));
        }
    }
}


export function* getAllEmployeeDataSaga(action) {
    yield put(getAllEmployeeDataStart());
    try {
        const response = yield axios.get(
            urlPaginationGenerator(`/logistics/getLogisticEmployee`, action.payload),
        );
        console.log(response);
        if (response.status === 200) {
            yield put(getAllEmployeeDataSuccess(response.data.data));
        } else {
            yield put(getAllEmployeeDataFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            // yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getAllEmployeeDataFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getAllEmployeeDataFail(error.response.data.message));
            } else {
                yield put(getAllEmployeeDataFail('Server error! Please try again.'));
            }
        } else {
            yield put(getAllEmployeeDataFail('Something went wrong! Please try again.'));
        }
    }
}


export function* editEmployeeStatusSaga(action) {
    yield put(editEmployeeStatusStart());
    try {
        const { empData, location } = action.payload;
        console.log(empData)
        const response = yield axios.post(
            `/logistics/setEmployeeStatus`, empData
        );
        console.log(response);
        if (response.status === 200) {
            yield put(editEmployeeStatusSuccess(response.data.data));
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    message: response.data.message || 'success',
                }),
            );
            yield put(location.reload('/logistics/new-employee'));
        } else {
            yield put(editEmployeeStatusFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(editEmployeeStatusFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(editEmployeeStatusFail(error.response.data.message));
            } else {
                yield put(editEmployeeStatusFail('Server error! Please try again.'));
            }
        } else {
            yield put(editEmployeeStatusFail('Something went wrong! Please try again.'));
        }
    }
}

export function* deleteLogisticEmployeeSaga(action) {

    console.log(action.payload,'#######################################')
    yield put(deleteLogisticEmployeeStart());
    try {
        const { id, employeeId,tableFilter, setModelDeleteOpen, status } = action.payload;
        console.log(action)
        const response = yield axios.post(
            `/logistics/deleteLogisticEmployee`, {
                // `/logistics/updateLogisticEmployee`, {

                employeeId:employeeId
            }
        );
        console.log(response);
        if (response.status === 200) {
            yield put(deleteLogisticEmployeeSuccess(response.data.data));
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    message: response.data.message,
                    redirectURL:window.location.reload()
                }),
            );
        } else {
            yield put(deleteLogisticEmployeeFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(deleteLogisticEmployeeFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(deleteLogisticEmployeeFail(error.response.data.message));
            } else {
                yield put(deleteLogisticEmployeeFail('Server error! Please try again.'));
            }
        } else {
            yield put(deleteLogisticEmployeeFail('Something went wrong! Please try again.'));
        }
    }
}

//desting filter data

export function* getAllDestinyDataSaga(action) {

    yield put(getAllDestinyDataStart());
    try {

        const response = yield axios.get(`/logistics/logisticscity`);

        if (response.status === 200) {
            yield put(getAllDestinyDataSuccess(response.data.data));
        } else {
            yield put(getAllDestinyDataFail('Something went wrong! Please try again.'));
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            yield action.payload.setModelDeleteOpen(false);
            yield put(
                showModal({
                    open: true,
                    notifyType: 1,
                    message: error.response.data.message || 'Error',
                }),
            );
            if (error.response.status === 400) {
                yield put(getAllDestinyDataFail(error.response.data.message));
            } else if (error.response.status === 401) {

                console.log('city logout')
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(getAllDestinyDataFail(error.response.data.message));
            } else {
                yield put(getAllDestinyDataFail('Server error! Please try again.'));
            }
        } else {
            yield put(getAllDestinyDataFail('Something went wrong! Please try again.'));
        }
    }
}


export function* deleteDriverSaga(action) {
    yield put(deleteDriverStart());
    try {
        const { status, orderId, itemId, setModelOpen } = action.payload;
        const response = yield axios.post(
            `logistics/deletedriverorders`,
            { id: itemId, dataId: orderId }
        );
        console.log({ response })
        if (response.status === 200) {
            yield put(deleteDriverSuccess(action.payload));
            setModelOpen ? setModelOpen(false) : console.log("deleted");
            yield put(
                showModal({
                    open: true,
                    notifyType: 2,
                    message: response.data.message || 'Success',
                }),
            );
        } else {
            yield put(
                deleteDriverFail('Something went wrong! Please try again.'),
            );
        }
    } catch (error) {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.status !== undefined
        ) {
            if (error.response.status === 400) {
                yield put(deleteDriverFail(error.response.data.message));
            } else if (error.response.status === 401) {
                yield put(logout());
            } else if (
                error.response.data.message !== undefined &&
                error.response.data.message !== '' &&
                typeof error.response.data.message === 'string'
            ) {
                yield put(deleteDriverFail(error.response.data.message));
            } else {
                yield put(deleteDriverFail('Server error! Please try again.'));
            }
        } else {
            yield put(
                deleteDriverFail('Something went wrong! Please try again.'),
            );
        }
    }
}
