import {
  GET_ADMIN_DASHBOARD_START,
  GET_ADMIN_DASHBOARD_SAGA,
  GET_ADMIN_DASHBOARD_SUCCESS,
  GET_ADMIN_DASHBOARD_FAIL,
  GET_OWNER_DASHBOARD_START,
  GET_OWNER_DASHBOARD_SAGA,
  GET_OWNER_DASHBOARD_SUCCESS,
  GET_OWNER_DASHBOARD_FAIL,
  GET_CHEF_DASHBOARD_START,
  GET_CHEF_DASHBOARD_SAGA,
  GET_CHEF_DASHBOARD_SUCCESS,
  GET_CHEF_DASHBOARD_FAIL,
  GET_VEHICLE_OWNER_DASHBOARD_START,
  GET_VEHICLE_OWNER_DASHBOARD_SAGA,
  GET_VEHICLE_OWNER_DASHBOARD_SUCCESS,
  GET_VEHICLE_OWNER_DASHBOARD_FAIL,
  GET_PROMOTER_DASHBOARD_START,
  GET_PROMOTER_DASHBOARD_SAGA,
  GET_PROMOTER_DASHBOARD_SUCCESS,
  GET_PROMOTER_DASHBOARD_FAIL,
} from '../actionLabels';

export const getAdminDashboardStart = payload => ({
  type: GET_ADMIN_DASHBOARD_START,
  payload,
});

export const getAdminDashboardSaga = payload => ({
  type: GET_ADMIN_DASHBOARD_SAGA,
  payload,
});

export const getAdminDashboardSuccess = payload => ({
  type: GET_ADMIN_DASHBOARD_SUCCESS,
  payload,
});
           
export const getAdminDashboardFail = payload => ({
  type: GET_ADMIN_DASHBOARD_FAIL,
  payload,
});

export const getOwnerDashboardStart = payload => ({
  type: GET_OWNER_DASHBOARD_START,
  payload,
});

export const getOwnerDashboardSaga = payload => ({
  type: GET_OWNER_DASHBOARD_SAGA,
  payload,
});

export const getOwnerDashboardSuccess = payload => ({
  type: GET_OWNER_DASHBOARD_SUCCESS,
  payload,              
});

export const getOwnerDashboardFail = payload => ({
  type: GET_OWNER_DASHBOARD_FAIL,
  payload,
});

export const getChefDashboardStart = () => ({
  type: GET_CHEF_DASHBOARD_START,
});

export const getChefDashboardSaga = payload => ({
  type: GET_CHEF_DASHBOARD_SAGA,
  payload,
});

export const getChefDashboardSuccess = payload => ({
  type: GET_CHEF_DASHBOARD_SUCCESS,
  payload,
});

export const getChefDashboardFail = payload => ({
  type: GET_CHEF_DASHBOARD_FAIL,
  payload,
});

// Promoter Dashboard
export const getPromoterDashboardStart = () => ({
  type: GET_PROMOTER_DASHBOARD_START,
});

export const getPromoterDashboardSaga = payload => ({
  type: GET_PROMOTER_DASHBOARD_SAGA,
  payload,
});

export const getPromoterDashboardSuccess = payload => ({
  type: GET_PROMOTER_DASHBOARD_SUCCESS,
  payload,
});

export const getPromoterDashboardFail = payload => ({
  type: GET_PROMOTER_DASHBOARD_FAIL,
  payload,
});

// Vehicle Owner
export const getVehicleOwnerDashboardStart = () => ({
  type: GET_VEHICLE_OWNER_DASHBOARD_START,
});

export const getVehicleOwnerDashboardSaga = payload => ({
  type: GET_VEHICLE_OWNER_DASHBOARD_SAGA,
  payload,
});

export const getVehicleOwnerDashboardSuccess = payload => ({
  type: GET_VEHICLE_OWNER_DASHBOARD_SUCCESS,
  payload,
});

export const getVehicleOwnerDashboardFail = payload => ({
  type: GET_VEHICLE_OWNER_DASHBOARD_FAIL,
  payload,
});
