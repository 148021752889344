export const RESET_VEHICLE_OWNERS_LIST = 'RESET_VEHICLE_OWNER_LIST';

export const GET_ALL_VEHICLE_OWNERS_START = 'GET_ALL_VEHICLE_OWNERS_START';
export const GET_ALL_VEHICLE_OWNERS_SAGA = 'GET_ALL_VEHICLE_OWNERS_SAGA';
export const GET_ALL_VEHICLE_OWNERS_SUCCESS = 'GET_ALL_VEHICLE_OWNERS_SUCCESS';
export const GET_ALL_VEHICLE_OWNERS_FAIL = 'GET_ALL_VEHICLE_OWNERS_FAIL';

export const RESET_VEHICLE_OWNER_DETAIL = 'RESET_VEHICLE_OWNER_DETAIL';

export const GET_VEHICLE_OWNER_BY_ID_START = 'GET_VEHICLE_OWNER_BY_ID_START';
export const GET_VEHICLE_OWNER_BY_ID_SAGA = 'GET_VEHICLE_OWNER_BY_ID_SAGA';
export const GET_VEHICLE_OWNER_BY_ID_SUCCESS =
  'GET_VEHICLE_OWNER_BY_ID_SUCCESS';
export const GET_VEHICLE_OWNER_BY_ID_FAIL = 'GET_VEHICLE_OWNER_BY_ID_FAIL';

export const ADD_VEHICLE_OWNER_START = 'ADD_VEHICLE_OWNER_START';
export const ADD_VEHICLE_OWNER_SAGA = 'ADD_VEHICLE_OWNER_SAGA';
export const ADD_VEHICLE_OWNER_SUCCESS = 'ADD_VEHICLE_OWNER_SUCCESS';
export const ADD_VEHICLE_OWNER_FAIL = 'ADD_VEHICLE_OWNER_FAIL';

export const EDIT_BLOCK_VEHICLE_OWNER_STATUS_START =
  'EDIT_BLOCK_VEHICLE_OWNER_STATUS_START';
export const EDIT_BLOCK_VEHICLE_OWNER_STATUS_SAGA =
  'EDIT_BLOCK_VEHICLE_OWNER_STATUS_SAGA';
export const EDIT_BLOCK_VEHICLE_OWNER_STATUS_SUCCESS =
  'EDIT_BLOCK_VEHICLE_OWNER_STATUS_SUCCESS';  
export const EDIT_BLOCK_VEHICLE_OWNER_STATUS_FAIL =
  'EDIT_BLOCK_VEHICLE_OWNER_STATUS_FAIL';

export const EDIT_VEHICLE_OWNER_COMMISSION_START =
  'EDIT_VEHICLE_OWNER_COMMISSION_START';
export const EDIT_VEHICLE_OWNER_COMMISSION_SAGA =
  'EDIT_VEHICLE_OWNER_COMMISSION_SAGA';
export const EDIT_VEHICLE_OWNER_COMMISSION_SUCCESS =
  'EDIT_VEHICLE_OWNER_COMMISSION_SUCCESS';
export const EDIT_VEHICLE_OWNER_COMMISSION_FAIL =
  'EDIT_VEHICLE_OWNER_COMMISSION_FAIL';

// Edit / Update
export const EDIT_VEHICLE_OWNER_START = 'EDIT_VEHICLE_OWNER_START';
export const EDIT_VEHICLE_OWNER_SAGA = 'EDIT_VEHICLE_OWNER_SAGA';
export const EDIT_VEHICLE_OWNER_SUCCESS = 'EDIT_VEHICLE_OWNER_SUCCESS';
export const EDIT_VEHICLE_OWNER_FAIL = 'EDIT_VEHICLE_OWNER_FAIL';

// Delete
export const DELETE_VEHICLE_OWNER_START = 'DELETE_VEHICLE_OWNER_START';
export const DELETE_VEHICLE_OWNER_SAGA = 'DELETE_VEHICLE_OWNER_SAGA';
export const DELETE_VEHICLE_OWNER_SUCCESS = 'DELETE_VEHICLE_OWNER_SUCCESS';
export const DELETE_VEHICLE_OWNER_FAIL = 'DELETE_VEHICLE_OWNER_FAIL';

// Promoter Functions
// GET ALL Vehicle Owners
export const GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_START =
  'GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_START';
export const GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_SAGA =
  'GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_SAGA';
export const GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_SUCCESS =
  'GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_SUCCESS';
export const GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_FAIL =
  'GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_FAIL';

// Get Single vehicle owner
export const GET_VEHICLE_OWNER_OF_PROMOTER_START =
  'GET_VEHICLE_OWNER_OF_PROMOTER_START';
export const GET_VEHICLE_OWNER_OF_PROMOTER_SAGA =
  'GET_VEHICLE_OWNER_OF_PROMOTER_SAGA';
export const GET_VEHICLE_OWNER_OF_PROMOTER_SUCCESS =
  'GET_VEHICLE_OWNER_OF_PROMOTER_SUCCESS';
export const GET_VEHICLE_OWNER_OF_PROMOTER_FAIL =
  'GET_VEHICLE_OWNER_OF_PROMOTER_FAIL';

// Add VEhicle Owner
export const ADD_VEHICLE_OWNER_OF_PROMOTER_START =
  'ADD_VEHICLE_OWNER_OF_PROMOTER_START';
export const ADD_VEHICLE_OWNER_OF_PROMOTER_SAGA =
  'ADD_VEHICLE_OWNER_OF_PROMOTER_SAGA';
export const ADD_VEHICLE_OWNER_OF_PROMOTER_SUCCESS =
  'ADD_VEHICLE_OWNER_OF_PROMOTER_SUCCESS';
export const ADD_VEHICLE_OWNER_OF_PROMOTER_FAIL =
  'ADD_VEHICLE_OWNER_OF_PROMOTER_FAIL';

// Edit VEhicle Owner
export const EDIT_VEHICLE_OWNER_OF_PROMOTER_START =
  'EDIT_VEHICLE_OWNER_OF_PROMOTER_START';
export const EDIT_VEHICLE_OWNER_OF_PROMOTER_SAGA =
  'EDIT_VEHICLE_OWNER_OF_PROMOTER_SAGA';
export const EDIT_VEHICLE_OWNER_OF_PROMOTER_SUCCESS =
  'EDIT_VEHICLE_OWNER_OF_PROMOTER_SUCCESS';
export const EDIT_VEHICLE_OWNER_OF_PROMOTER_FAIL =
  'EDIT_VEHICLE_OWNER_OF_PROMOTER_FAIL';

// Delete Vehicle Owner
export const DELETE_VEHICLE_OWNER_OF_PROMOTER_START =
  'DELETE_VEHICLE_OWNER_OF_PROMOTER_START';
export const DELETE_VEHICLE_OWNER_OF_PROMOTER_SAGA =
  'DELETE_VEHICLE_OWNER_OF_PROMOTER_SAGA';
export const DELETE_VEHICLE_OWNER_OF_PROMOTER_SUCCESS =
  'DELETE_VEHICLE_OWNER_OF_PROMOTER_SUCCESS';
export const DELETE_VEHICLE_OWNER_OF_PROMOTER_FAIL =
  'DELETE_VEHICLE_OWNER_OF_PROMOTER_FAIL';
