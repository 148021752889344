export const GET_ALL_ORDER_START = 'GET_ALL_ORDER_START';
export const GET_ALL_ORDER_SAGA = 'GET_ALL_ORDER_SAGA';
export const GET_ALL_ORDER_SUCCESS = 'GET_ALL_ORDER_SUCCESS';
export const GET_ALL_ORDER_FAIL = 'GET_ALL_ORDER_FAIL';

export const GET_ORDER_TRACK_START = 'GET_ORDER_TRACK_START';
export const GET_ORDER_TRACK_SAGA = 'GET_ORDER_TRACK_SAGA';
export const GET_ORDER_TRACK_CHEF_SAGA = 'GET_ORDER_TRACK_CHEF_SAGA';
export const GET_ORDER_TRACK_SUCCESS = 'GET_ORDER_TRACK_SUCCESS';
export const GET_ORDER_TRACK_FAIL = 'GET_ORDER_TRACK_FAIL';

export const GET_ALL_CHEF_ORDER_START = 'GET_ALL_CHEF_ORDER_START';
export const GET_ALL_CHEF_ORDER_SAGA = 'GET_ALL_CHEF_ORDER_SAGA';
export const GET_ALL_CHEF_ORDER_SUCCESS = 'GET_ALL_CHEF_ORDER_SUCCESS';
export const GET_ALL_CHEF_ORDER_FAIL = 'GET_ALL_CHEF_ORDER_FAIL';

export const GET_ORDER_BY_ID_START = 'GET_ORDER_BY_ID_START';
export const GET_ORDER_BY_ID_SAGA = 'GET_ORDER_BY_ID_SAGA';
export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS';
export const GET_ORDER_BY_ID_FAIL = 'GET_ORDER_BY_ID_FAIL';

export const GET_ALL_ORDER_BY_USER_ID_START = 'GET_ALL_ORDER_BY_USER_ID_START';
export const GET_ALL_ORDER_BY_USER_ID_SAGA = 'GET_ALL_ORDER_BY_USER_ID_SAGA';
export const GET_ALL_ORDER_BY_USER_ID_SUCCESS =
  'GET_ALL_ORDER_BY_USER_ID_SUCCESS';
export const GET_ALL_ORDER_BY_USER_ID_FAIL = 'GET_ALL_ORDER_BY_USER_ID_FAIL';

export const GET_ORDER_COUNT_START = 'GET_ORDER_COUNT_START';
export const GET_ORDER_COUNT_SAGA = 'GET_ORDER_COUNT_SAGA';
export const GET_CHEF_ORDER_COUNT_SAGA = 'GET_CHEF_ORDER_COUNT_SAGA';
export const GET_ORDER_COUNT_SUCCESS = 'GET_ORDER_COUNT_SUCCESS';
export const GET_ORDER_COUNT_FAIL = 'GET_ORDER_COUNT_FAIL';

export const EDIT_INSTRUCTION_START = 'EDIT_INSTRUCTION_START';
export const EDIT_INSTRUCTION_SAGA = 'EDIT_INSTRUCTION_SAGA';
export const EDIT_INSTRUCTION_SUCCESS = 'EDIT_INSTRUCTION_SUCCESS';
export const EDIT_INSTRUCTION_FAIL = 'EDIT_INSTRUCTION_FAIL';

export const RESET_ORDER_TRACK = 'RESET_ORDER_TRACK';
export const RESET_ORDER_LIST = 'RESET_ORDER_LIST';
export const RESET_ORDERS_LIST_CHEF = 'RESET_ORDERS_LIST_CHEF';

export const GET_ALL_CHEF_UPCOMING_ORDER_START =
  'GET_ALL_CHEF_UPCOMING_ORDER_START';
export const GET_ALL_CHEF_UPCOMING_ORDER_SAGA =
  'GET_ALL_CHEF_UPCOMING_ORDER_SAGA';
export const GET_ALL_CHEF_UPCOMING_ORDER_SUCCESS =
  'GET_ALL_CHEF_UPCOMING_ORDER_SUCCESS';
export const GET_ALL_CHEF_UPCOMING_ORDER_FAIL =
  'GET_ALL_CHEF_UPCOMING_ORDER_FAIL';

export const EDIT_ACCEPT_ORDER_START = 'EDIT_ACCEPT_ORDER_START';
export const EDIT_ACCEPT_ORDER_SAGA = 'EDIT_ACCEPT_ORDER_SAGA';
export const EDIT_ACCEPT_ORDER_SUCCESS = 'EDIT_ACCEPT_ORDER_SUCCESS';
export const EDIT_ACCEPT_ORDER_FAIL = 'EDIT_ACCEPT_ORDER_FAIL';

export const EDIT_REJECT_ORDER_START = 'EDIT_REJECT_ORDER_START';
export const EDIT_REJECT_ORDER_SAGA = 'EDIT_REJECT_ORDER_SAGA';
export const EDIT_REJECT_ORDER_SUCCESS = 'EDIT_REJECT_ORDER_SUCCESS';
export const EDIT_REJECT_ORDER_FAIL = 'EDIT_REJECT_ORDER_FAIL';

export const EDIT_ORDER_STATUS_START = 'EDIT_ORDER_STATUS_START';
export const EDIT_ORDER_STATUS_SAGA = 'EDIT_ORDER_STATUS_SAGA';
export const EDIT_ORDER_STATUS_SUCCESS = 'EDIT_ORDER_STATUS_SUCCESS';
export const EDIT_ORDER_STATUS_FAIL = 'EDIT_ORDER_STATUS_FAIL';

export const TRANSACTION_HISTORY_START = 'TRANSACTION_HISTORY_START';
export const TRANSACTION_HISTORY_SAGA = 'TRANSACTION_HISTORY_SAGA';
export const TRANSACTION_HISTORY_SUCCESS = 'TRANSACTION_HISTORY_SUCCESS';
export const TRANSACTION_HISTORY_FAIL = 'TRANSACTION_HISTORY_FAIL';
