import {
  GET_ALL_STORE_TYPES_START,
  GET_ALL_STORE_TYPES_SAGA,
  GET_ALL_STORE_TYPES_SUCCESS,
  GET_ALL_STORE_TYPES_FAIL,
  ADD_STORE_TYPE_START,
  ADD_STORE_TYPE_SAGA,
  ADD_STORE_TYPE_SUCCESS,
  ADD_STORE_TYPE_FAIL,
  EDIT_STORE_TYPE_START,
  EDIT_STORE_TYPE_SAGA,
  EDIT_STORE_TYPE_SUCCESS,
  EDIT_STORE_TYPE_FAIL,
  DELETE_STORE_TYPE_START,
  DELETE_STORE_TYPE_SAGA,
  DELETE_STORE_TYPE_SUCCESS,
  DELETE_STORE_TYPE_FAIL,
  EDIT_STORE_TYPE_STATUS_START,
  EDIT_STORE_TYPE_STATUS_SAGA,
  EDIT_STORE_TYPE_STATUS_SUCCESS,
  EDIT_STORE_TYPE_STATUS_FAIL,
  CLEAR_STORE_TYPE_ERROR_MSG,
} from '../actionLabels';

// Get All
export const getAllStoreTypesStart = () => ({
  type: GET_ALL_STORE_TYPES_START,
});

export const getAllStoreTypesSaga = payload => ({
  type: GET_ALL_STORE_TYPES_SAGA,
  payload,
});

export const getAllStoreTypesSuccess = payload => ({
  type: GET_ALL_STORE_TYPES_SUCCESS,
  payload,
});

export const getAllStoreTypesFail = payload => ({
  type: GET_ALL_STORE_TYPES_FAIL,
  payload,
});

// Add
export const addStoreTypeStart = () => ({
  type: ADD_STORE_TYPE_START,
});

export const addStoreTypeSaga = payload => ({
  type: ADD_STORE_TYPE_SAGA,
  payload,
});

export const addStoreTypeSuccess = payload => ({
  type: ADD_STORE_TYPE_SUCCESS,
  payload,
});

export const addStoreTypeFail = payload => ({
  type: ADD_STORE_TYPE_FAIL,
  payload,
});

// Edit
export const editStoreTypeStart = () => ({
  type: EDIT_STORE_TYPE_START,
});

export const editStoreTypeSaga = payload => ({
  type: EDIT_STORE_TYPE_SAGA,
  payload,
});

export const editStoreTypeSuccess = payload => ({
  type: EDIT_STORE_TYPE_SUCCESS,
  payload,
});

export const editStoreTypeFail = payload => ({
  type: EDIT_STORE_TYPE_FAIL,
  payload,
});

// change status
export const editStoreTypeStatusStart = () => ({
  type: EDIT_STORE_TYPE_STATUS_START,
});

export const editStoreTypeStatusSaga = payload => ({
  type: EDIT_STORE_TYPE_STATUS_SAGA,
  payload,
});

export const editStoreTypeStatusSuccess = payload => ({
  type: EDIT_STORE_TYPE_STATUS_SUCCESS,
  payload,
});

export const editStoreTypeStatusFail = payload => ({
  type: EDIT_STORE_TYPE_STATUS_FAIL,
  payload,
});

// Delete
export const deleteStoreTypeStart = () => ({
  type: DELETE_STORE_TYPE_START,
});

export const deleteStoreTypeSaga = payload => ({
  type: DELETE_STORE_TYPE_SAGA,
  payload,
});

export const deleteStoreTypeSuccess = payload => ({
  type: DELETE_STORE_TYPE_SUCCESS,
  payload,
});

export const deleteStoreTypeFail = payload => ({
  type: DELETE_STORE_TYPE_FAIL,
  payload,
});

export const clearStoreTypeErrorMsg = () => ({
  type: CLEAR_STORE_TYPE_ERROR_MSG,
});
