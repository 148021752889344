import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  paymentHistory: [],
  chefPayoutList: { list: [], totalRecords: { count: 0 } },

  packageType: { list: [], totalRecords: { count: 0 } },

  paymentHistoryDetails: {},
  totalPaid: 0,
  totalPending: 0,
  isLoading: false,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_CHEF_PAYOUT_LIST_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_CHEF_PAYOUT_LIST_SUCCESS: {
      const { payout, totalPaid, totalPending } = payload;
      return {
        ...state,
        chefPayoutList: payout,
        totalPaid,
        totalPending,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.GET_ALL_CHEF_PAYOUT_LIST_FAIL:
      return {
        ...state,
        chefPayoutList: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_PAYMENT_HISTORY_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_PAYMENT_HISTORY_SUCCESS: {
      const { paymentHistory } = payload;
      return { ...state, paymentHistory, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_PAYMENT_HISTORY_FAIL:
      return {
        ...state,
        paymentHistory: [],
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.GET_PAYMENT_HISTORY_DETAILS_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_PAYMENT_HISTORY_DETAILS_SUCCESS: {
      const { paymentHistoryDetails } = payload;
      return {
        ...state,
        paymentHistoryDetails,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.GET_PAYMENT_HISTORY_DETAILS_FAIL:
      return {
        ...state,
        paymentHistoryDetails: {},
        isLoading: false,
        errorMsg: payload,
      };
    case actionLabels.PAY_PAYMENT_START:
      return { ...state, isLoading: true };
    case actionLabels.PAY_PAYMENT_SUCCESS: {
      if (!payload.isList) {
        const { paymentHistory } = state;
        const index = paymentHistory.findIndex(
          item => item._id === payload.payoutId,
        );
        if (index !== -1 && paymentHistory.length > index) {
          paymentHistory[index].isPaid = !paymentHistory[index].isPaid;
          paymentHistory[index].transactionId = payload.transactionId;
        }
        return { ...state, paymentHistory, isLoading: false, errorMsg: '' };
      }
      return { ...state, isLoading: false, errorMsg: '' };
    }
    case actionLabels.PAY_PAYMENT_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.RESET_ERROR_MSG: {
      return { ...state, errorMsg: '' };
    }


    case actionLabels.GET_PAYMENTTYPE_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_PAYMENTTYPE_SUCCESS: {
      const { paymentHistoryDetails } = payload;

      console.log(payload,'000000000payload')
      return {
        ...state,
        // packageType:payload.packageType,
        // packageId:payload._id,
        packageType:{
          list:payload.data,
          totalRecords:{
            count:payload?.totalRecords
          }
        },
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.GET_PAYMENTTYPE_FAIL:
      return {
        ...state,
        isLoading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
