import { put } from 'redux-saga/effects';
import {
  getAllUserStart,
  getAllUserSuccess,
  getAllUserFail,
  getUserByIdStart,
  getUserByIdSuccess,
  getUserByIdFail,
  getActiveInActiveUserCountStart,
  getActiveInActiveUserCountSuccess,
  getActiveInActiveUserCountFail,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFail,
  editBlockUserStatusStart,
  editBlockUserStatusSuccess,
  editBlockUserStatusFail,
  logout,
  editAcceptingOrderStart,
  editAcceptingOrderSuccess,
  editAcceptingOrderFail,
  showModal,
  getAllUserOrderStart,
  getAllUserOrderSuccess,
  getAllUserOrderFail,
  getAllUserOrderDetailStart,
  getAllUserOrderDetailSuccess,
  getAllUserOrderDetailFail,

  getAllUserPaymentTypeStart,
  getAllUserPaymentTypeSuccess,
  getAllUserPaymentTypeFail,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllUserSaga(action) {
  yield put(getAllUserStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllUserList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllUserSuccess({ users: response.data.data }));
    } else {
      yield put(getAllUserFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllUserFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllUserFail(error.response.data.message));
      } else {
        yield put(getAllUserFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllUserFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getUserByIdSaga(action) {
  yield put(getUserByIdStart());
  try {
    const response = yield axios.get(
      `/admin/getUserDetailById?id=${action.payload.id}`,
    );
    if (response.status === 200) {
      yield put(getUserByIdSuccess({ user: response.data.data }));
    } else {
      yield put(getUserByIdFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getUserByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getUserByIdFail(error.response.data.message));
      } else {
        yield put(getUserByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(getUserByIdFail('Something went wrong! Please try again.'));
    }
  }
}

// eslint-disable-next-line no-unused-vars
export function* getActiveInActiveUserCountSaga(action) {
  yield put(getActiveInActiveUserCountStart());
  try {
    const response = yield axios.get(`/admin/getActiveInActiveUserCount`);
    if (response.status === 200) {
      yield put(
        getActiveInActiveUserCountSuccess({ userCount: response.data.data }),
      );
    } else {
      yield put(
        getActiveInActiveUserCountFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getActiveInActiveUserCountFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getActiveInActiveUserCountFail(error.response.data.message));
      } else {
        yield put(
          getActiveInActiveUserCountFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getActiveInActiveUserCountFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}

export function* deleteUserSaga(action) {
  console.log(action.payload);
  const { id, setModelDeleteOpen, tableFilter } = action.payload;
  yield put(deleteUserStart());
  try {
    const response = yield axios.post(`/admin/deleteUser`, { id });
    console.log(response)
    if (response.status === 200) {
      yield put(deleteUserSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllUserSaga(tableFilter))
      yield put(getActiveInActiveUserCountSaga())
    } else {
      yield put(deleteUserFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(deleteUserFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteUserFail(error.response.data.message));
      } else {
        yield put(deleteUserFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteUserFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editBlockUserStatusSaga(action) {
  yield put(editBlockUserStatusStart());
  try {
    const response = yield axios.post(
      `/admin/blockUnBlockUser`,
      action.payload,
    );
    if (response.status === 200) {
      yield put(editBlockUserStatusSuccess(action.payload));
    } else {
      yield put(
        editBlockUserStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBlockUserStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBlockUserStatusFail(error.response.data.message));
      } else {
        yield put(editBlockUserStatusFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editBlockUserStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* editAcceptingOrderSaga(action) {
  yield put(editAcceptingOrderStart());
  try {
    const response = yield axios.post(
      `/admin/changeAcceptOrders`,
      action.payload,
    );
    if (response.status === 200) {
      yield put(editAcceptingOrderSuccess());
    } else {
      yield put(
        editAcceptingOrderFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editAcceptingOrderFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editAcceptingOrderFail(error.response.data.message));
      } else {
        yield put(editAcceptingOrderFail('Server error! Please try again.'));
      }
    } else {
      // yield put(
      //   editAcceptingOrderFail('Something went wrong! Please try again.'),
      // );
    }
  }
}

export function* getAllUserOrderSaga(action) {
  yield put(getAllUserOrderStart());
  try {
    const response = yield axios.get(urlPaginationGenerator(`/admin/getAllBookingHistory`, action.payload));
    if (response.status === 200) {
      yield put(
        getAllUserOrderSuccess(response.data.data),
      );
    } else {
      yield put(
        getAllUserOrderFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllUserOrderFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllUserOrderFail(error.response.data.message));
      } else {
        yield put(
          getAllUserOrderFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getAllUserOrderFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}






export function* getAllUserOrderDetailSaga(action) {
  yield put(getAllUserOrderDetailStart());
  try {
    const response = yield axios.post(`/admin/getBookingDetailsId`, action.payload);
    if (response.status === 200) {
      yield put(
        getAllUserOrderDetailSuccess(response.data.data),
      );
    } else {
      yield put(
        getAllUserOrderDetailFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllUserOrderDetailFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllUserOrderDetailFail(error.response.data.message));
      } else {
        yield put(
          getAllUserOrderDetailFail('Server error! Please try again.'),
        );
      }
    } else {
      yield put(
        getAllUserOrderDetailFail(
          'Something went wrong! Please try again.',
        ),
      );
    }
  }
}