export const GET_ALL_SUBADMIN_START = 'GET_ALL_SUBADMIN_START';
export const GET_ALL_SUBADMIN_SAGA = 'GET_ALL_SUBADMIN_SAGA';
export const GET_ALL_SUBADMIN_SUCCESS = 'GET_ALL_SUBADMIN_SUCCESS';
export const GET_ALL_SUBADMIN_FAIL = 'GET_ALL_SUBADMIN_FAIL';

export const GET_SUBADMIN_BY_ID_START = 'GET_SUBADMIN_BY_ID_START';
export const GET_SUBADMIN_BY_ID_SAGA = 'GET_SUBADMIN_BY_ID_SAGA';
export const GET_SUBADMIN_BY_ID_SUCCESS = 'GET_SUBADMIN_BY_ID_SUCCESS';
export const GET_SUBADMIN_BY_ID_FAIL = 'GET_SUBADMIN_BY_ID_FAIL';

export const ADD_SUBADMIN_START = 'ADD_SUBADMIN_START';
export const ADD_SUBADMIN_SAGA = 'ADD_SUBADMIN_SAGA';
export const ADD_SUBADMIN_SUCCESS = 'ADD_SUBADMIN_SUCCESS';
export const ADD_SUBADMIN_FAIL = 'ADD_SUBADMIN_FAIL';

export const EDIT_SUBADMIN_START = 'EDIT_SUBADMIN_START';
export const EDIT_SUBADMIN_SAGA = 'EDIT_SUBADMIN_SAGA';
export const EDIT_SUBADMIN_SUCCESS = 'EDIT_SUBADMIN_SUCCESS';
export const EDIT_SUBADMIN_FAIL = 'EDIT_SUBADMIN_FAIL';

export const EDIT_SUBADMIN_STATUS_START = 'EDIT_SUBADMIN_STATUS_START';
export const EDIT_SUBADMIN_STATUS_SAGA = 'EDIT_SUBADMIN_STATUS_SAGA';
export const EDIT_SUBADMIN_STATUS_SUCCESS = 'EDIT_SUBADMIN_STATUS_SUCCESS';
export const EDIT_SUBADMIN_STATUS_FAIL = 'EDIT_SUBADMIN_STATUS_FAIL';

export const GENERATE_PDF_START = 'GENERATE_PDF_START';
export const GENERATE_PDF_SAGA = 'GENERATE_PDF_SAGA';
export const GENERATE_PDF_SUCCESS = 'GENERATE_PDF_SUCCESS';
export const GENERATE_PDF_FAIL = 'GENERATE_PDF_FAIL';
