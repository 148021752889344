import { useEffect } from 'react';
import { usePrevious } from '..';

function useTableHooks(
  setMounted,
  setPageCount,
  totalPages,
  pageSize,
  setStatusColumn,
  currentPage,
  searchFilter,
  skip,
  sortObj,
  tableData,
  alteredData,
  setAlteredData,
  getData,
) {
  const prevAlteredData = usePrevious(alteredData);
  const prevTableData = usePrevious(tableData);
  const prevSortObj = usePrevious(sortObj);
  const prevCurrentPage = usePrevious(currentPage);
  const prevPageSize = usePrevious(pageSize);
  const prevSearchFilter = usePrevious(searchFilter);
  const prevSkip = usePrevious(skip);
  useEffect(() => {
    setMounted(true);
    setPageCount(Math.ceil(totalPages / pageSize));
    setStatusColumn();
    return () => setMounted(false);
  }, []);
  useEffect(() => {
    if (prevTableData !== tableData) {
      setAlteredData([...tableData]);
      setStatusColumn();
      setPageCount(Math.ceil(totalPages / pageSize));
    }
  }, [
    alteredData,
    tableData,
    prevTableData,
    setStatusColumn,
    prevAlteredData,
    setPageCount,
    totalPages,
    pageSize,
    setAlteredData,
  ]);
  useEffect(() => {
    if (
      getData &&
      prevTableData &&
      (prevSkip !== skip ||
        prevPageSize !== pageSize ||
        prevSortObj !== sortObj ||
        prevSearchFilter !== searchFilter)
    ) {

      console.log(prevSortObj,'soprevSortObj')
      console.log(searchFilter,'searchFiltersearchFilter')
      console.log(skip,'skipskipskip')
      console.log(pageSize,'pageSizepageSizepageSize')
      getData(
        sortObj.columnName,
        sortObj.orderBy,
        searchFilter,
        skip,
        pageSize,
      );
    }
  }, [
    sortObj,
    pageSize,
    getData,
    tableData,
    prevTableData,
    prevCurrentPage,
    prevPageSize,
    prevSortObj,
    prevSearchFilter,
    searchFilter,
    prevSkip,
    skip,
  ]);
}
export default useTableHooks;
