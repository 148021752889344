import {
  ADD_PROMOTER_SAGA,
  ADD_PROMOTER_START,
  ADD_PROMOTER_SUCCESS,
  ADD_PROMOTER_FAIL,
  GET_ALL_PROMOTERS_FAIL,
  GET_ALL_PROMOTERS_SAGA,
  GET_ALL_PROMOTERS_START,
  GET_ALL_PROMOTERS_SUCCESS,
  EDIT_BLOCK_PROMOTER_STATUS_START,
  EDIT_BLOCK_PROMOTER_STATUS_SAGA,
  EDIT_BLOCK_PROMOTER_STATUS_SUCCESS,
  EDIT_BLOCK_PROMOTER_STATUS_FAIL,
  GET_PROMOTER_BY_ID_START,
  GET_PROMOTER_BY_ID_SAGA,
  GET_PROMOTER_BY_ID_SUCCESS,
  GET_PROMOTER_BY_ID_FAIL,
  RESET_PROMOTER_DETAIL,
  EDIT_PROMOTER_COMMISSION_START,
  EDIT_PROMOTER_COMMISSION_SAGA,
  EDIT_PROMOTER_COMMISSION_SUCCESS,
  EDIT_PROMOTER_COMMISSION_FAIL,
  EDIT_PROMOTER_START,
  EDIT_PROMOTER_SAGA,
  EDIT_PROMOTER_SUCCESS,
  EDIT_PROMOTER_FAIL,
  DELETE_PROMOTER_START,
  DELETE_PROMOTER_SAGA,
  DELETE_PROMOTER_SUCCESS,
  DELETE_PROMOTER_FAIL,
  PROMOTER_DELETE_DRIVER_SAGA,
  PROMOTER_DELETE_DRIVER_START,
  PROMOTER_DELETE_DRIVER_SUCCESS,
  PROMOTER_DELETE_DRIVER_FAIL,
  PROMOTER_DELETE_VEHICLE_OWNER_SAGA,
  PROMOTER_DELETE_VEHICLE_OWNER_START,
  PROMOTER_DELETE_VEHICLE_OWNER_SUCCESS,
  PROMOTER_DELETE_VEHICLE_OWNER_FAIL,
  ADD_VENDOR_OF_PROMOTER_START,
  ADD_VENDOR_OF_PROMOTER_SAGA,
  ADD_VENDOR_OF_PROMOTER_SUCCESS,
  ADD_VENDOR_OF_PROMOTER_FAIL,
  GET_ALL_VENDORS_OF_PROMOTER_START,
  GET_ALL_VENDORS_OF_PROMOTER_SAGA,
  GET_ALL_VENDORS_OF_PROMOTER_SUCCESS,
  GET_ALL_VENDORS_OF_PROMOTER_FAIL,
  EDIT_VENDOR_OF_PROMOTER_START,
  EDIT_VENDOR_OF_PROMOTER_SAGA,
  EDIT_VENDOR_OF_PROMOTER_SUCCESS,
  EDIT_VENDOR_OF_PROMOTER_FAIL,
  DELETE_VENDOR_OF_PROMOTER_START,
  DELETE_VENDOR_OF_PROMOTER_SAGA,
  DELETE_VENDOR_OF_PROMOTER_SUCCESS,
  DELETE_VENDOR_OF_PROMOTER_FAIL,
  PROMOTER_DELETE_VEHICLE_SAGA,
  PROMOTER_DELETE_VEHICLE_START,
  PROMOTER_DELETE_VEHICLE_SUCCESS,
  PROMOTER_DELETE_VEHICLE_FAIL,
  GET_ALL_VEHICLE_OF_PROMOTER_SAGA,
  PROMOTER_OWNERS_VEHICLE_START,
  PROMOTER_OWNERS_VEHICLE_SAGA,
  PROMOTER_OWNERS_VEHICLE_SUCCESS,
  PROMOTER_OWNERS_VEHICLE_FAIL,
} from '../actionLabels';

export const resetPromoterDetail = () => ({
  type: RESET_PROMOTER_DETAIL,
});
// Get ALL Promoters Start
export const getAllPromotersStart = () => ({
  type: GET_ALL_PROMOTERS_START,
});

export const getAllPromotersSaga = payload => ({
  type: GET_ALL_PROMOTERS_SAGA,
  payload,
});

export const getAllPromotersSuccess = payload => ({
  type: GET_ALL_PROMOTERS_SUCCESS,
  payload,
});

export const getAllPromotersFail = payload => ({
  type: GET_ALL_PROMOTERS_FAIL,
  payload,
});
// Get ALL Promoters End

// Get Promoters By Id Start
export const getPromoterByIdStart = () => ({
  type: GET_PROMOTER_BY_ID_START,
});

export const getPromoterByIdSaga = payload => ({
  type: GET_PROMOTER_BY_ID_SAGA,
  payload,
});

export const getPromoterByIdSuccess = payload => ({
  type: GET_PROMOTER_BY_ID_SUCCESS,
  payload,
});

export const getPromoterByIdFail = payload => ({
  type: GET_PROMOTER_BY_ID_FAIL,
  payload,
});
// Get Promoters By Id End

// Add Promoter Start
export const addPromoterStart = () => ({
  type: ADD_PROMOTER_START,
});

export const addPromoterSaga = payload => ({
  type: ADD_PROMOTER_SAGA,
  payload,
});

export const addPromoterSuccess = payload => ({
  type: ADD_PROMOTER_SUCCESS,
  payload,
});

export const addPromoterFail = payload => ({
  type: ADD_PROMOTER_FAIL,
  payload,
});
// Add Promoter End

// Block Promoter status Start
export const editBlockPromoterStatusStart = () => ({
  type: EDIT_BLOCK_PROMOTER_STATUS_START,
});

export const editBlockPromoterStatusSaga = payload => ({
  type: EDIT_BLOCK_PROMOTER_STATUS_SAGA,
  payload,
});

export const editBlockPromoterStatusSuccess = payload => ({
  type: EDIT_BLOCK_PROMOTER_STATUS_SUCCESS,
  payload,
});

export const editBlockPromoterStatusFail = payload => ({
  type: EDIT_BLOCK_PROMOTER_STATUS_FAIL,
  payload,
});
// Block Promoter status End

// Edit Promoter Commission Start
export const editPromoterCommissionStart = payload => ({
  type: EDIT_PROMOTER_COMMISSION_START,
  payload,
});

export const editPromoterCommissionSaga = payload => ({
  type: EDIT_PROMOTER_COMMISSION_SAGA,
  payload,
});

export const editPromoterCommissionSuccess = payload => ({
  type: EDIT_PROMOTER_COMMISSION_SUCCESS,
  payload,
});

export const editPromoterCommissionFail = payload => ({
  type: EDIT_PROMOTER_COMMISSION_FAIL,
  payload,
});
// Edit Promoter Commission End

// Edit Promoter Start
export const editPromoterStart = () => ({
  type: EDIT_PROMOTER_START,
});

export const editPromoterSaga = payload => ({
  type: EDIT_PROMOTER_SAGA,
  payload,
});

export const editPromoterSuccess = payload => ({
  type: EDIT_PROMOTER_SUCCESS,
  payload,
});

export const editPromoterFail = payload => ({
  type: EDIT_PROMOTER_FAIL,
  payload,
});
// Edit Promoter End

// Delete Promoter Start
export const deletePromoterStart = () => ({
  type: DELETE_PROMOTER_START,
});

export const deletePromoterSaga = payload => ({
  type: DELETE_PROMOTER_SAGA,
  payload,
});

export const deletePromoterSuccess = payload => ({
  type: DELETE_PROMOTER_SUCCESS,
  payload,
});

export const deletePromoterFail = payload => ({
  type: DELETE_PROMOTER_FAIL,
  payload,
});
// Delete Promoter End

// Promoter Functions after his/her login Starts Here

// Vendor =======================

// Get ALl vendors
export const getAllVendorsOfPromoterStart = () => ({
  type: GET_ALL_VENDORS_OF_PROMOTER_START,
});

export const getAllVendorsOfPromoterSaga = payload => ({
  type: GET_ALL_VENDORS_OF_PROMOTER_SAGA,
  payload,
});

export const getAllVendorsOfPromoterSuccess = payload => ({
  type: GET_ALL_VENDORS_OF_PROMOTER_SUCCESS,
  payload,
});

export const getAllVendorsOfPromoterFail = payload => ({
  type: GET_ALL_VENDORS_OF_PROMOTER_FAIL,
  payload,
});
// Add Vendor by promoter
export const addVendorOfPromoterStart = () => ({
  type: ADD_VENDOR_OF_PROMOTER_START,
});

export const addVendorofPromoterSaga = payload => ({
  type: ADD_VENDOR_OF_PROMOTER_SAGA,
  payload,
});

export const addVendorofPromoterSuccess = payload => ({
  type: ADD_VENDOR_OF_PROMOTER_SUCCESS,
  payload,
});

export const addVendorofPromoterFail = payload => ({
  type: ADD_VENDOR_OF_PROMOTER_FAIL,
  payload,
});

// Edit Vendor by promoter
export const editVendorOfPromoterStart = () => ({
  type: EDIT_VENDOR_OF_PROMOTER_START,
});

export const editVendorOfPromoterSaga = payload => ({
  type: EDIT_VENDOR_OF_PROMOTER_SAGA,
  payload,
});

export const editVendorOfPromoterSuccess = payload => ({
  type: EDIT_VENDOR_OF_PROMOTER_SUCCESS,
  payload,
});

export const editVendorOfPromoterFail = payload => ({
  type: EDIT_VENDOR_OF_PROMOTER_FAIL,
  payload,
});

// Edit Vendor by promoter
export const deleteVendorOfPromoterStart = () => ({
  type: DELETE_VENDOR_OF_PROMOTER_START,
});

export const deleteVendorOfPromoterSaga = payload => ({
  type: DELETE_VENDOR_OF_PROMOTER_SAGA,
  payload,
});

export const deleteVendorOfPromoterSuccess = payload => ({
  type: DELETE_VENDOR_OF_PROMOTER_SUCCESS,
  payload,
});

export const deleteVendorOfPromoterFail = payload => ({
  type: DELETE_VENDOR_OF_PROMOTER_FAIL,
  payload,
});

// ================================================
// DELETE DRIVER FROM PROMOTER Start
export const promoterDeleteDriverStart = () => ({
  type: PROMOTER_DELETE_DRIVER_START,
});

export const promoterDeleteDriverSaga = payload => ({
  type: PROMOTER_DELETE_DRIVER_SAGA,
  payload,
});

export const promoterDeleteDriverSuccess = payload => ({
  type: PROMOTER_DELETE_DRIVER_SUCCESS,
  payload,
});

export const promoterDeleteDriverFail = payload => ({
  type: PROMOTER_DELETE_DRIVER_FAIL,
  payload,
});
// DELETE DRIVER FROM PROMOTER ENd

// DELETE Vehicle Owner FROM PROMOTER Start
export const promoterDeleteVehicleOwnerStart = () => ({
  type: PROMOTER_DELETE_VEHICLE_OWNER_START,
});

export const promoterDeleteVehicleOwnerSaga = payload => ({
  type: PROMOTER_DELETE_VEHICLE_OWNER_SAGA,
  payload,
});

export const promoterDeleteVehicleOwnerSuccess = payload => ({
  type: PROMOTER_DELETE_VEHICLE_OWNER_SUCCESS,
  payload,
});

export const promoterDeleteVehicleOwnerFail = payload => ({
  type: PROMOTER_DELETE_VEHICLE_OWNER_FAIL,
  payload,
});
// DELETE Vehicle Owner FROM PROMOTER ENd

// DELETE Vehicle Owner FROM PROMOTER Start
export const promoterDeleteVehicleStart = () => ({
  type: PROMOTER_DELETE_VEHICLE_START,
});

export const promoterDeleteVehicleSaga = payload => ({
  type: PROMOTER_DELETE_VEHICLE_SAGA,
  payload,
});

export const promoterDeleteVehicleSuccess = payload => ({
  type: PROMOTER_DELETE_VEHICLE_SUCCESS,
  payload,
});

export const promoterDeleteVehicleFail = payload => ({
  type: PROMOTER_DELETE_VEHICLE_FAIL,
  payload,
});
// DELETE Vehicle Owner FROM PROMOTER ENd

// Get All Vehicle in Promoter Section start
export const getAllVehicleOfPromoterSaga = payload => ({
  type: GET_ALL_VEHICLE_OF_PROMOTER_SAGA,
  payload,
});
// Get All Vehicle in Promoter Section end
