import {
  GET_ALL_REFERRAL_FAIL,
  GET_ALL_REFERRAL_SAGA,
  GET_ALL_REFERRAL_START,
  GET_ALL_REFERRAL_SUCCESS,
} from '../actionLabels';
// GET ALL Referral START
export const getAllReferralStart = () => ({
  type: GET_ALL_REFERRAL_START,
});

export const getAllReferralSaga = payload => ({
  type: GET_ALL_REFERRAL_SAGA,
  payload,
});

export const getAllReferralSuccess = payload => ({
  type: GET_ALL_REFERRAL_SUCCESS,
  payload,
});

export const getAllReferralFail = payload => ({
  type: GET_ALL_REFERRAL_FAIL,
  payload,
});
// GET ALL Referral END
