import * as actionLabels from '../../actions/actionLabels';

const intialState = {
  vehicles: { list: [], totalRecords: { count: 0 } },
  vehicle: {},
  isLoading: false,
  errorMsg: '',
};

export default (state = intialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_VEHICLES_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_VEHICLES_SUCCESS: {
      const { vehicles } = payload;
      console.log('promoter reducer data', vehicles);
      return { ...state, vehicles, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_ALL_VEHICLES_FAIL:
      return {
        ...state,
        vehicles: { list: [], totalRecords: { count: 0 } },
        isLoading: false,
        errorMsg: payload,
      };
    // Get Vehicle By Id
    case actionLabels.GET_VEHICLE_DETAIL_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_VEHICLE_DETAIL_BY_ID_SUCCESS: {
      const { vehicle } = payload;
      return { ...state, vehicle, isLoading: false, errorMsg: '' };
    }
    case actionLabels.GET_VEHICLE_DETAIL_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Add Vehicle Type
    case actionLabels.ADD_VEHICLE_START:
      return { ...state, isLoading: true };
    case actionLabels.ADD_VEHICLE_SUCCESS:
      return { ...state, isLoading: false };
    case actionLabels.ADD_VEHICLE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    // Block Unblock Status
    case actionLabels.EDIT_BLOCK_VEHICLE_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_BLOCK_VEHICLE_STATUS_SUCCESS: {
      if (payload.isDetail !== true) {
        const { vehicles } = state;
        const index = vehicles.list.findIndex(item => item._id === payload.id);
        if (index !== -1 && vehicles.list.length > index) {
          vehicles.list[index].isBlock = !vehicles.list[index].isBlock;
        }
        return { ...state, vehicles, isLoading: false, errorMsg: '' };
      }
      const { vehicle } = state;
      vehicle.isBlock = !vehicle.isBlock;
      return { ...state, vehicle, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_BLOCK_VEHICLE_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    // Delete
    case actionLabels.DELETE_VEHICLE_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_VEHICLE_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.DELETE_VEHICLE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    // Delete by vehicle by vehicle owner
    case actionLabels.DELETE_VEHICLE_BY_VEHICLE_OWNER_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_VEHICLE_BY_VEHICLE_OWNER_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case actionLabels.DELETE_VEHICLE_BY_VEHICLE_OWNER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    // Reset
    case actionLabels.RESET_VEHICLE_DETAILS:
      return {
        ...state,
        isLoading: false,
        vehicle: {},
        errorMsg: '',
      };
    default:
      return state;
  }
};
