import {
  ADD_SERVICE_FAIL,
  ADD_SERVICE_SAGA,
  ADD_SERVICE_START,
  ADD_SERVICE_SUCCESS,
  EDIT_SERVICE_FAIL,
  EDIT_SERVICE_SAGA,
  EDIT_SERVICE_START,
  EDIT_SERVICE_SUCCESS,
  GET_ALL_SERVICE_FAIL,
  GET_ALL_SERVICE_SAGA,
  GET_ALL_SERVICE_START,
  GET_ALL_SERVICE_SUCCESS,
  GET_SERVICE_BY_ID_FAIL,
  GET_SERVICE_BY_ID_SAGA,
  GET_SERVICE_BY_ID_START,
  GET_SERVICE_BY_ID_SUCCESS,
  EDIT_BLOCK_SERVICE_STATUS_SAGA,
  EDIT_BLOCK_SERVICE_STATUS_START,
  EDIT_BLOCK_SERVICE_STATUS_SUCCESS,
  EDIT_BLOCK_SERVICE_STATUS_FAIL,
  DELETE_SERVICE_START,
  DELETE_SERVICE_SAGA,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAIL,
} from '../actionLabels';

// GET ALL SERVICES START
export const getAllServiceStart = () => ({
  type: GET_ALL_SERVICE_START,
});

export const getAllServiceSaga = payload => ({
  type: GET_ALL_SERVICE_SAGA,
  payload,
});

export const getAllServiceSuccess = payload => ({
  type: GET_ALL_SERVICE_SUCCESS,
  payload,
});

export const getAllServiceFail = payload => ({
  type: GET_ALL_SERVICE_FAIL,
  payload,
});
// GET ALL SERVICES END

// GET SERVICES By Id Start
export const getServiceByIdStart = () => ({
  type: GET_SERVICE_BY_ID_START,
});

export const getServiceByIdSaga = payload => ({
  type: GET_SERVICE_BY_ID_SAGA,
  payload,
});

export const getServiceByIdSuccess = payload => ({
  type: GET_SERVICE_BY_ID_SUCCESS,
  payload,
});

export const getServiceByIdFail = payload => ({
  type: GET_SERVICE_BY_ID_FAIL,
  payload,
});
// GET SERVICES By Id END

// add service start

export const addServiceStart = () => ({
  type: ADD_SERVICE_START,
});

export const addServiceSaga = payload => ({
  type: ADD_SERVICE_SAGA,
  payload,
});

export const addServiceSuccess = payload => ({
  type: ADD_SERVICE_SUCCESS,
  payload,
});

export const addServiceFail = payload => ({
  type: ADD_SERVICE_FAIL,
  payload,
});
// add service end

// edit service start
export const editServiceStart = () => ({
  type: EDIT_SERVICE_START,
});

export const editServiceSaga = payload => ({
  type: EDIT_SERVICE_SAGA,
  payload,
});

export const editServiceSuccess = payload => ({
  type: EDIT_SERVICE_SUCCESS,
  payload,
});

export const editServiceFail = payload => ({
  type: EDIT_SERVICE_FAIL,
  payload,
});
// edit service end

// Block Service status Start
export const editBlockServiceStatusStart = () => ({
  type: EDIT_BLOCK_SERVICE_STATUS_START,
});

export const editBlockServiceStatusSaga = payload => ({
  type: EDIT_BLOCK_SERVICE_STATUS_SAGA,
  payload,
});

export const editBlockServiceStatusSuccess = payload => ({
  type: EDIT_BLOCK_SERVICE_STATUS_SUCCESS,
  payload,
});

export const editBlockServiceStatusFail = payload => ({
  type: EDIT_BLOCK_SERVICE_STATUS_FAIL,
  payload,
});
// Block Service status End

// delete service start
export const deleteServiceStart = () => ({
  type: DELETE_SERVICE_START,
});

export const deleteServiceSaga = payload => ({
  type: DELETE_SERVICE_SAGA,
  payload,
});

export const deleteServiceSuccess = payload => ({
  type: DELETE_SERVICE_SUCCESS,
  payload,
});

export const deleteServiceFail = payload => ({
  type: DELETE_SERVICE_FAIL,
  payload,
});
// delete service end
