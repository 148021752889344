// Vendor Types is called Brand Types
import { put } from 'redux-saga/effects';
import { capitalize } from '../../../utils/capitalize';
import {
  getAllCategorySaga as getAllCategorySagaAction,
  getAllCategoryStart,
  getAllCategorySuccess,
  getAllCategoryFail,
  getAllCategoryChefFail,
  getAllSubCategorySaga as getAllSubCategorySagaAction,
  getAllSubCategoryStart,
  getAllSubCategorySuccess,
  getAllSubCategoryFail,
  getAllSubCategoryChefFail,
  getCategoryByIdStart,
  getCategoryByIdSuccess,
  getCategoryByIdFail,
  addCategoryStart,
  addCategorySuccess,
  addCategoryFail,
  addSubCategoryStart,
  addSubCategorySuccess,
  addSubCategoryFail,
  editCategoryStart,
  editCategorySuccess,
  editCategoryFail,
  editSubCategoryStart,
  editSubCategorySuccess,
  editSubCategoryFail,
  getAllDishSaga as getAllDishSagaAction,
  getAllDishStart,
  getAllDishSuccess,
  getAllDishFail,
  addDishStart,
  addDishSuccess,
  addDishFail,
  getAllDishAdminSaga as getAllDishAdminSagaAction,
  addAdminDishStart,
  addAdminDishSuccess,
  addAdminDishFail,
  editDishStart,
  editDishSuccess,
  editDishFail,
  editDishPhotoStart,
  editDishPhotoSuccess,
  editDishPhotoFail,
  editDishPublishStatusStart,
  editDishPublishStatusSuccess,
  editDishPublishStatusFail,
  deleteDishStart,
  deleteDishSuccess,
  deleteDishFail,
  logout,
  editCategoryStatusStart,
  editCategoryStatusSuccess,
  editCategoryStatusFail,
  showModal,
  getAcceptingOrderSaga,
  getAllAdminBrandTypesSaga as getAllAdminBrandTypesSagaAction,
  getAllAdminBrandTypesStart,
  getAllAdminBrandTypesSuccess,
  getAllAdminBrandTypesFail,
  addBrandTypeStart,
  addBrandTypeSuccess,
  addBrandTypeFail,
  editBrandTypeStart,
  editBrandTypeSuccess,
  editBrandTypeFail,
  editAdminDishStart,
  editAdminDishSuccess,
  editAdminDishFail,
  deleteSubCategoryStart,
  deleteSubCategorySuccess,
  deleteSubCategoryFail,
  deleteCategoryStart,
  deleteCategorySuccess,
  deleteCategoryFail,
  editSubCategoryStatusStart,
  editSubCategoryStatusSuccess,
  editSubCategoryStatusFail,
  deleteBrandTypeStart,
  deleteBrandTypeSuccess,
  deleteBrandTypeFail,
  deleteAdminDishStart,
  deleteAdminDishSuccess,
  deleteAdminDishFail,
  editBrandTypeStatusStart,
  editBrandTypeStatusSuccess,
  editBrandTypeStatusFail,
  getAllDishListForPromoterStart,
  getAllDishListForPromoterSuccess,
  getAllDishListForPromoterFail,
  deleteProductByPromoterStart,
  deleteProductByPromoterSuccess,
  deleteProductByPromoterFail,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllCategorySaga(action) {
  console.log(action)
  yield put(getAllCategoryStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllCategoryList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllCategorySuccess({ categories: response.data.data }));
    } else {
      yield put(getAllCategoryFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllCategoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllCategoryFail(error.response.data.message));
      } else {
        yield put(getAllCategoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllCategoryFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getAllSubCategorySaga(action) {
  console.log(action.payload)
  yield put(getAllSubCategoryStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllSubCategoryList`, action.payload),
    );
    if (response.status === 200) {
      yield put(
        getAllSubCategorySuccess({ subCategories: response.data.data }),
      );
    } else {
      yield put(
        getAllSubCategoryFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllSubCategoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllSubCategoryFail(error.response.data.message));
      } else {
        yield put(getAllSubCategoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllSubCategoryFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getAllCategoryChefSaga(action) {
  yield put(getAllCategoryStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(
        `/chef/getAllCategoryList?columnName=name&orderBy=asc&skip=0&limit=5000`,
        action.payload,
      ),
    );
    if (response.status === 200) {
      yield put(
        getAllCategorySuccess({
          categories: response.data.data,
          forDD: action.payload.forDD,
        }),
      );
    } else {
      yield put(
        getAllCategoryChefFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllCategoryChefFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllCategoryChefFail(error.response.data.message));
      } else {
        yield put(getAllCategoryChefFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllCategoryChefFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// get category by vendor type start
export function* getCategoryByVendorTypeSaga(action) {
  yield put(getAllCategoryStart());
  try {
    const response = yield axios.post(
      `/chef/getAllCategoryListByVendorType`,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        getAllCategorySuccess({
          categories: response.data.data,
          forDD: action.payload.forDD,
        }),
      );
    } else {
      yield put(
        getAllCategoryChefFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllCategoryChefFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllCategoryChefFail(error.response.data.message));
      } else {
        yield put(getAllCategoryChefFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllCategoryChefFail('Something went wrong! Please try again.'),
      );
    }
  }
}
// get category by vendor type end

// get SubCategory By Category start
export function* getSubCategoryByCategorySaga(action) {
  yield put(getAllSubCategoryStart());
  try {
    const response = yield axios.get(
      `/chef/getAllSubCategoryList?categoryId=${action.payload}`,
    );
    if (response.status === 200) {
      yield put(
        getAllSubCategorySuccess({ subCategories: response.data.data }),
      );
    } else {
      yield put(
        getAllSubCategoryFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllSubCategoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllSubCategoryFail(error.response.data.message));
      } else {
        yield put(getAllSubCategoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllSubCategoryFail('Something went wrong! Please try again.'),
      );
    }
  }
}
// get SubCategory By Category end

export function* getAllAdminBrandTypesSaga(action) {
  yield put(getAllAdminBrandTypesStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllBrandTypes`, action.payload),
    );
    if (response.status === 200) {
      yield put(
        getAllAdminBrandTypesSuccess({ brandTypes: response.data.data }),
      );
    } else {
      yield put(
        getAllAdminBrandTypesFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllAdminBrandTypesFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllAdminBrandTypesFail(error.response.data.message));
      } else {
        yield put(getAllAdminBrandTypesFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllAdminBrandTypesFail('Something went wrong! Please try again.'),
      );
    }
  }
}
export function* getAllSubCategoryChefSaga(action) {
  yield put(getAllSubCategoryStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(
        `/chef/getAllSubCategoryList?columnName=name&orderBy=asc&skip=0&limit=5000`,
        action.payload,
      ),
    );
    if (response.status === 200) {
      yield put(
        getAllSubCategorySuccess({
          subCategories: response.data.data,
          forDD: action.payload.forDD,
        }),
      );
    } else {
      yield put(
        getAllSubCategoryChefFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllSubCategoryChefFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllSubCategoryChefFail(error.response.data.message));
      } else {
        yield put(getAllSubCategoryChefFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllSubCategoryChefFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getCategoryByIdSaga(action) {
  yield put(getCategoryByIdStart());
  try {
    const response = yield axios.get(
      `/admin/getCategoryDetailById?id=${action.payload.id}`,
    );
    if (response.status === 200) {
      yield put(getCategoryByIdSuccess({ category: response.data.data }));
    } else {
      yield put(getCategoryByIdFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getCategoryByIdFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getCategoryByIdFail(error.response.data.message));
      } else {
        yield put(getCategoryByIdFail('Server error! Please try again.'));
      }
    } else {
      yield put(getCategoryByIdFail('Something went wrong! Please try again.'));
    }
  }
}

export function* addBrandTypeSaga(action) {
  const { setIsDisable } = action.payload;
  yield put(addBrandTypeStart());
  try {
    const { closeModel, tableFilter, serviceTypeId } = action.payload;
    const formdata = new FormData();
    formdata.append('name', action.payload.name);
    formdata.append('isEdible', action.payload.isEdible);
    formdata.append('isProducer', action.payload.isProducer);
    formdata.append('photo', action.payload.photo);
    if (serviceTypeId === '') {
      formdata.append('principalCategoryId', action.payload.principalCategoryId);
    } else {
      formdata.append('principalCategoryId', serviceTypeId);
    }

    // const response = yield axios.post(`/admin/addBrandType`, formdata);
    const response = yield axios.post(`/admin/addStore`, formdata);
    if (response.status === 200) {
      yield put(addBrandTypeSuccess(response.data.data));
      setIsDisable(false);
      closeModel();
      yield put(getAllAdminBrandTypesSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(addBrandTypeFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addBrandTypeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addBrandTypeFail(error.response.data.message));
      } else {
        yield put(addBrandTypeFail('Server error! Please try again.'));
      }
    } else {
      yield put(addBrandTypeFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editBrandTypeSaga(action) {
  yield put(editBrandTypeStart());
  try {
    const formdata = new FormData();
    const { closeModel, setIsDisable, tableFilter } = action.payload;

    formdata.append('id', action.payload.id);
    formdata.append('name', action.payload.name);
    formdata.append('isEdible', action.payload.isEdible);
    formdata.append('photo', action.payload.photo);
    formdata.append('principalCategoryId', action.payload.principalCategoryId);

    const response = yield axios.post(`/admin/editBrandType`, formdata);
    if (response.status === 200) {
      yield put(editBrandTypeSuccess());
      setIsDisable(false);
      closeModel();
      yield put(getAllAdminBrandTypesSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(editBrandTypeFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBrandTypeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBrandTypeFail(error.response.data.message));
      } else {
        yield put(editBrandTypeFail('Server error! Please try again.'));
      }
    } else {
      yield put(editBrandTypeFail('Something went wrong! Please try again.'));
    }
  }
}

export function* addCategorySaga(action) {
  const { setIsDisable } = action.payload;
  yield put(addCategoryStart());
  try {
    const { closeModel, tableFilter, storeId } = action.payload;
    const formdata = new FormData();
    formdata.append('name', action.payload.name);
    formdata.append('photo', action.payload.photo);
    if (storeId === '') {
      formdata.append('venderType', action.payload.vendorTypeId);
    } else {
      formdata.append('venderType', storeId);
    }
    const response = yield axios.post(`/admin/addCategory`, formdata);
    if (response.status === 200) {
      yield put(addCategorySuccess(response.data.data));
      setIsDisable(false);
      closeModel();
      yield put(getAllCategorySagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: capitalize(response.data.message) || 'Success',
        }),
      );
    } else {
      yield put(addCategoryFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addCategoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addCategoryFail(error.response.data.message));
      } else {
        yield put(addCategoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(addCategoryFail('Something went wrong! Please try again.'));
    }
  }
}
export function* addSubCategorySaga(action) {
  const { setIsDisable } = action.payload;
  yield put(addSubCategoryStart());
  console.log('payload of addSubCategorySaga \n', action.payload);
  try {
    const { closeModel, tableFilter } = action.payload;
    const formdata = new FormData();
    formdata.append('name', action.payload.name);
    formdata.append('categoryId', action.payload.categoryId);
    formdata.append('photo', action.payload.photo);

    const response = yield axios.post(`/admin/addSubCategory`, formdata);
    if (response.status === 200) {
      yield put(addSubCategorySuccess(response.data.data));
      setIsDisable(false);
      closeModel();
      yield put(getAllSubCategorySagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: capitalize(response.data.message) || 'Success',
        }),
      );
    } else {
      yield put(addSubCategoryFail('Something went wrong! Please try again.'));
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addSubCategoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addSubCategoryFail(error.response.data.message));
      } else {
        yield put(addSubCategoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(addSubCategoryFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editCategorySaga(action) {
  yield put(editCategoryStart());
  try {
    const formdata = new FormData();
    const { closeModel, setIsDisable, tableFilter } = action.payload;
    formdata.append('id', action.payload.id);
    formdata.append('name', action.payload.name);
    formdata.append('photo', action.payload.photo);
    formdata.append('venderType', action.payload.vendorTypeId);

    const response = yield axios.post(`/admin/editCategory`, formdata);
    if (response.status === 200) {
      yield put(editCategorySuccess());
      setIsDisable(false);
      closeModel();
      yield put(getAllCategorySagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(editCategoryFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editCategoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editCategoryFail(error.response.data.message));
      } else {
        yield put(editCategoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(editCategoryFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editSubCategorySaga(action) {
  yield put(editSubCategoryStart());
  try {
    const formdata = new FormData();
    const { closeModel, setIsDisable, tableFilter } = action.payload;

    formdata.append('id', action.payload.id);
    formdata.append('name', action.payload.name);
    formdata.append('categoryId', action.payload.categoryId);
    //  formdata.append('photo', action.payload.photo);

    const response = yield axios.post(`/admin/editSubCategory`, formdata);
    if (response.status === 200) {
      yield put(editSubCategorySuccess());
      setIsDisable(false);
      closeModel();
      yield put(getAllSubCategorySagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(editSubCategoryFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editSubCategoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editSubCategoryFail(error.response.data.message));
      } else {
        yield put(editSubCategoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(editSubCategoryFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editCategoryStatusSaga(action) {
  yield put(editCategoryStatusStart());
  try {
    const response = yield axios.post(
      `/admin/updateCategoryStatus`,
      action.payload,
    );

    if (response.status === 200) {
      yield put(editCategoryStatusSuccess(action.payload));
    } else {
      yield put(
        editCategoryStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editCategoryStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editCategoryStatusFail(error.response.data.message));
      } else {
        yield put(editCategoryStatusFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editCategoryStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* editSubCategoryStatusSaga(action) {
  yield put(editSubCategoryStatusStart());
  try {
    const response = yield axios.post(
      `admin/updateSubCategoryStatus`,
      action.payload,
    );

    if (response.status === 200) {
      yield put(editSubCategoryStatusSuccess(action.payload));
    } else {
      yield put(
        editSubCategoryStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editSubCategoryStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editSubCategoryStatusFail(error.response.data.message));
      } else {
        yield put(editSubCategoryStatusFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editSubCategoryStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* getAllDishSaga(action) {
  console.log(action)
  yield put(getAllDishStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/chef/getAllDishList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllDishSuccess({ dishes: response.data.data }));
    } else {
      yield put(getAllDishFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllDishFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllDishFail(error.response.data.message));
      } else {
        yield put(getAllDishFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllDishFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getAllDishAdminSaga(action) {
  yield put(getAllDishStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllDishList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllDishSuccess({ dishes: response.data.data }));
    } else {
      yield put(getAllDishFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllDishFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllDishFail(error.response.data.message));
      } else {
        yield put(getAllDishFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllDishFail('Something went wrong! Please try again.'));
    }
  }
}

export function* addDishSaga(action) {
  yield put(addDishStart());
  const { setIsDisable, tableFilter, closeModel } = action.payload;
  console.log(action.payload)
  try {
    const formdata = new FormData();
    formdata.append('name', action.payload.name);
    formdata.append('price', action.payload.price);
    formdata.append('isVeg', action.payload.isVeg);
    formdata.append('chefId', JSON.parse(localStorage.getItem('userData'))._id);
    formdata.append('categoryId', action.payload.categoryId);
    formdata.append('subCategoryId', action.payload.subCategoryId);
    formdata.append('quantity', action.payload.quantity);
    formdata.append('discountPrice', action.payload.discountPrice);
    formdata.append('description', action.payload.description);
    formdata.append('storeId', action.payload.storeId);
    formdata.append('intercitydeliverycharge', action.payload.intercitydeliverycharge);
    if (action.payload.photo) {
      formdata.append('photo', action.payload.photo);
    }

    const response = yield axios.post(`/chef/addDish`, formdata);

    if (response.status === 200) {
      yield put(addDishSuccess(response.data.data));
      closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          // message: response.data.message || 'Success',
          message: 'Item Added Successfully',
        }),
      );
      yield put(getAllDishSagaAction(tableFilter));
    } else {
      setIsDisable(false);
      yield put(editDishFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addDishFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addDishFail(error.response.data.message));
      } else {
        yield put(addDishFail('Server error! Please try again.'));
      }
    } else {
      yield put(addDishFail('Something went wrong! Please try again.'));
    }
  }
}

// Admin DIsh SAga Shashank
// Add Dish
export function* addAdminDishSaga(action) {
  yield put(addAdminDishStart());
  const { setIsDisable, tableFilter, closeModel } = action.payload;
  try {
    const formdata = new FormData();
    formdata.append('name', action.payload.name);
    formdata.append('price', action.payload.price);
    formdata.append('isVeg', action.payload.isVeg);
    formdata.append('chefId', JSON.parse(localStorage.getItem('userData'))._id);
    formdata.append('categoryId', action.payload.categoryId);
    formdata.append('subCategoryId', action.payload.subCategoryId);
    formdata.append('discountPrice', action.payload.discountPrice);
    formdata.append('intercitydeliverycharge', action.payload.intercitydeliverycharge);
    formdata.append('description', action.payload.description);
    if (action.payload.photo) {
      formdata.append('photo', action.payload.photo);
    }

    const response = yield axios.post(`/admin/addDish`, formdata);

    if (response.status === 200) {
      yield put(addAdminDishSuccess(response.data.data));
      closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllDishAdminSagaAction(tableFilter));
    } else {
      setIsDisable(false);
      yield put(addAdminDishFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addAdminDishFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addAdminDishFail(error.response.data.message));
      } else {
        yield put(addAdminDishFail('Server error! Please try again.'));
      }
    } else {
      yield put(addAdminDishFail('Something went wrong! Please try again.'));
    }
  }
}

// Edit DIsh
export function* editAdminDishSaga(action) {
  yield put(editAdminDishStart());
  const { setIsDisable } = action.payload;
  try {
    const { tableFilter, closeModel } = action.payload;
    console.log('action.payload in editDishAdminSaga \n', action.payload);
    const formdata = new FormData();
    formdata.append('id', action.payload.id);
    formdata.append('name', action.payload.name);
    formdata.append('price', action.payload.price);
    formdata.append('isVeg', action.payload.isVeg);
    formdata.append('chefId', JSON.parse(localStorage.getItem('userData'))._id);
    formdata.append('categoryId', action.payload.categoryId);
    formdata.append('subCategoryId', action.payload.subCategoryId);
    formdata.append('discountPrice', action.payload.discountPrice);
    formdata.append('intercitydeliverycharge', action.payload.intercitydeliverycharge);
    formdata.append('description', action.payload.description);
    if (action.payload.photo) {
      formdata.append('photo', action.payload.photo);
    }

    const response = yield axios.post(`/admin/updateDish`, formdata);

    if (response.status === 200) {
      yield put(editAdminDishSuccess(response.data.data));
      closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllDishAdminSagaAction(tableFilter));
    } else {
      setIsDisable(false);
      yield put(editAdminDishFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editAdminDishFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editAdminDishFail(error.response.data.message));
      } else {
        yield put(editAdminDishFail('Server error! Please try again.'));
      }
    } else {
      yield put(editAdminDishFail('Something went wrong! Please try again.'));
    }
  }
}

// Edit Dish Admin Side
export function* editDishSaga(action) {
  yield put(editDishStart());
  const { setIsDisable } = action.payload;
  try {
    const { tableFilter, closeModel } = action.payload;
    const formdata = new FormData();
    formdata.append('id', action.payload.id);
    formdata.append('name', action.payload.name);
    formdata.append('quantity', action.payload.quantity);
    formdata.append('price', action.payload.price);
    formdata.append('isVeg', action.payload.isVeg);
    formdata.append('chefId', JSON.parse(localStorage.getItem('userData'))._id);
    formdata.append('categoryId', action.payload.categoryId);
    formdata.append('subCategoryId', action.payload.subCategoryId);
    formdata.append('discountPrice', action.payload.discountPrice);
    formdata.append('intercitydeliverycharge', action.payload.intercitydeliverycharge);
    formdata.append('description', action.payload.description);
    if (action.payload.photo) {
      formdata.append('photo', action.payload.photo);
    }

    const response = yield axios.post(`/chef/updateDish`, formdata);

    if (response.status === 200) {
      yield put(editDishSuccess(response.data.data));
      closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          // message: response.data.message || 'Success',
          message: 'Item Updated Successfully',
        }),
      );
      yield put(getAllDishSagaAction(tableFilter));
    } else {
      setIsDisable(false);
      yield put(editDishFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editDishFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editDishFail(error.response.data.message));
      } else {
        yield put(editDishFail('Server error! Please try again.'));
      }
    } else {
      yield put(editDishFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editDishPhotoSaga(action) {
  yield put(editDishPhotoStart());
  try {
    const formdata = new FormData();
    formdata.append('id', action.payload.id);
    formdata.append('photo', action.payload.photo);

    const response = yield axios.post(`/chef/updateDishPhoto`, formdata);

    if (response.status === 200) {
      yield put(editDishPhotoSuccess(response.data.data));
    } else {
      yield put(editDishPhotoFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editDishPhotoFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editDishPhotoFail(error.response.data.message));
      } else {
        yield put(editDishPhotoFail('Server error! Please try again.'));
      }
    } else {
      yield put(editDishPhotoFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editDishPublishStatusSaga(action) {
  yield put(editDishPublishStatusStart());
  const { route, id, status } = action.payload;
  try {
    const response = (route === 'chef' ? yield axios.post(`/chef/updatePublishStatus`, { id, status },)
      : yield axios.post(`promoter/updatePublishStatusforPromoter`, { id, status },))

    if (response.status === 200) {
      yield put(editDishPublishStatusSuccess(action.payload));
      yield put(getAllDishAdminSaga({
        columnName: 'name',
        orderBy: 'asc',
        skip: 0,
        limit: 10,
      }))
      yield put(getAcceptingOrderSaga());
    } else {
      yield put(
        editDishPublishStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editDishPublishStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editDishPublishStatusFail(error.response.data.message));
      } else {
        yield put(editDishPublishStatusFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editDishPublishStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* deleteDishSaga(action) {
  yield put(deleteDishStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    const response = yield axios.post(`/chef/deleteDish`, { id });

    if (response.status === 200) {
      yield put(deleteDishSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          // message: response.data.message || 'Success',
          message: 'Item Deleted Successfully',
        }),
      );
      yield put(getAllDishSagaAction(tableFilter));
      yield put(getAcceptingOrderSaga());
    } else {
      yield put(deleteDishFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      if (error.response.status === 400) {
        yield put(deleteDishFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteDishFail(error.response.data.message));
      } else {
        yield put(deleteDishFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteDishFail('Something went wrong! Please try again.'));
    }
  }
}

// Delete Category Start
export function* deleteCategorySaga(action) {
  yield put(deleteCategoryStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    const response = yield axios.post(`/admin/deleteCategory`, { id });

    if (response.status === 200) {
      yield put(deleteCategorySuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllCategorySagaAction(tableFilter));
    } else {
      yield put(deleteCategoryFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 1,
          message: error.response.data.message || 'Error',
        }),
      );
      if (error.response.status === 400) {
        yield put(deleteCategoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteCategoryFail(error.response.data.message));
      } else {
        yield put(deleteCategoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteCategoryFail('Something went wrong! Please try again.'));
    }
  }
}

// delete subcategory start
export function* deleteSubCategorySaga(action) {
  yield put(deleteSubCategoryStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    console.log(action.payload);
    const response = yield axios.post(`/admin/deleteSubCategory`, { id });

    if (response.status === 200) {
      yield put(deleteSubCategorySuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllSubCategorySagaAction(tableFilter));
    } else {
      yield put(
        deleteSubCategoryFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 1,
          message: error.response.data.message || 'Error',
        }),
      );
      if (error.response.status === 400) {
        yield put(deleteSubCategoryFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteSubCategoryFail(error.response.data.message));
      } else {
        yield put(deleteSubCategoryFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        deleteSubCategoryFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// delete vendor type or Brand Type
export function* deleteBrandTypeSaga(action) {
  yield put(deleteBrandTypeStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    console.log(action.payload);
    // const response = yield axios.post(`/admin/deleteVendorType`, { id });
    const response = yield axios.post(`/admin/deleteStores`, { id });

    if (response.status === 200) {
      yield put(deleteBrandTypeSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllAdminBrandTypesSagaAction(tableFilter));
    } else {
      yield put(deleteBrandTypeFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 1,
          message: error.response.data.message || 'Error',
        }),
      );
      if (error.response.status === 400) {
        yield put(deleteBrandTypeFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteBrandTypeFail(error.response.data.message));
      } else {
        yield put(deleteBrandTypeFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteBrandTypeFail('Something went wrong! Please try again.'));
    }
  }
}

// delete vendor type or Brand Type
export function* deleteAdminDishSaga(action) {
  yield put(deleteAdminDishStart());
  try {
    const { id, tableFilter, setModelDeleteOpen } = action.payload;
    console.log(action.payload);
    const response = yield axios.post(`/admin/deleteDish`, { id });

    if (response.status === 200) {
      yield put(deleteAdminDishSuccess(action.payload));
      yield setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(getAllDishAdminSagaAction(tableFilter));
    } else {
      yield put(deleteAdminDishFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      yield action.payload.setModelDeleteOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 1,
          message: error.response.data.message || 'Error',
        }),
      );
      if (error.response.status === 400) {
        yield put(deleteAdminDishFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteAdminDishFail(error.response.data.message));
      } else {
        yield put(deleteAdminDishFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteAdminDishFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editBrandTypeStatusSaga(action) {
  yield put(editBrandTypeStatusStart());
  try {
    const response = yield axios.post(
      `/admin/updateStoreStatus`,
      action.payload,
    );

    if (response.status === 200) {
      yield put(editBrandTypeStatusSuccess(action.payload));
    } else {
      yield put(
        editBrandTypeStatusFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editBrandTypeStatusFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editBrandTypeStatusFail(error.response.data.message));
      } else {
        yield put(editBrandTypeStatusFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editBrandTypeStatusFail('Something went wrong! Please try again.'),
      );
    }
  }
}

// Dark Home Vendor Products
export function* addDarkHomeVendorProductSaga(action) {
  console.log(action.payload);

  yield put(addDishStart());
  const {
    setIsDisable,
    tableFilter,
    closeModel,
    productsIds,
    producerId,
  } = action.payload;
  try {
    const response = yield axios.post(`/chef/addProductsForDarkhHomeStore`, {
      producerId,
      productsIds,
    });

    if (response.status === 200) {
      yield put(addDishSuccess(response.data.data));
      closeModel();
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      yield put(
        getAllDishSagaAction({ isDarkHomeVendor: true, ...tableFilter }),
      );
    } else {
      setIsDisable(false);
      yield put(editDishFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addDishFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(addDishFail(error.response.data.message));
      } else {
        yield put(addDishFail('Server error! Please try again.'));
      }
    } else {
      yield put(addDishFail('Something went wrong! Please try again.'));
    }
  }
}

//dish list for promoter

export function* getAllDishListForPromoterSaga(action) {
  console.log(action)
  yield put(getAllDishStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/promoter/getAllDishListForPromoter`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllDishSuccess({ dishes: response.data.data }));
    } else {
      yield put(getAllDishFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllDishFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllDishFail(error.response.data.message));
      } else {
        yield put(getAllDishFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllDishFail('Something went wrong! Please try again.'));
    }
  }
}

export function* deleteProductByPromoterSaga(action) {
  yield put(deleteProductByPromoterStart(action.payload));
  try {
    const { productId, setModelOpen } = action.payload;
    const id = productId;
    const response = yield axios.post(`promoter/deleteItemByPromoter`, { id });

    if (response.status === 200) {
      yield put(deleteProductByPromoterSuccess(response.data.data));
      setModelOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          // message: response.data.message || 'Success',
          message: 'Item Deleted Successfully',
        }),
      );
    } else {
      yield put(deleteProductByPromoterFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    console.log({ error, msg: error.message })
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      action.payload.setModelOpen(false);
      yield put(
        showModal({
          open: true,
          notifyType: 1,
          message: error.response.data.message || 'Error',
        }),
      );
      if (error.response.status === 400) {
        yield put(deleteProductByPromoterFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(deleteProductByPromoterFail(error.response.data.message));
      } else {
        yield put(deleteProductByPromoterFail('Server error! Please try again.'));
      }
    } else {
      yield put(deleteProductByPromoterFail('Something went wrong! Please try again.'));
    }
  }
}
// export function* deleteProductByPromoterSaga(action) {
//   console.log(action)
//   const { productId } = action.payload;
//   const id = productId;
//   yield put(getAllDishStart());
//   try {
//     const response = yield axios.post(`promoter/deleteItemByPromoter`, { id });
//     if (response.status === 200) {
//       yield put(getAllDishSuccess({ dishes: response.data.data }));
//     } else {
//       yield put(getAllDishFail('Something went wrong! Please try again.'));
//     }
//   } catch (error) {
//     if (
//       error !== undefined &&
//       error.response !== undefined &&
//       error.response.status !== undefined
//     ) {
//       if (error.response.status === 400) {
//         yield put(getAllDishFail(error.response.data.message));
//       } else if (error.response.status === 401) {
//         yield put(logout());
//       } else if (
//         error.response.data.message !== undefined &&
//         error.response.data.message !== '' &&
//         typeof error.response.data.message === 'string'
//       ) {
//         yield put(getAllDishFail(error.response.data.message));
//       } else {
//         yield put(getAllDishFail('Server error! Please try again.'));
//       }
//     } else {
//       yield put(getAllDishFail('Something went wrong! Please try again.'));
//     }
//   }
// }