export const RESET_ALL_SERVICES = 'RESET_ALL_SERVICES';
export const RESET_SERVICE_DETAIL = 'RESET_SERVICE_DETAIL';

// Get All Services
export const GET_ALL_SERVICE_START = 'GET_ALL_SERVICE_START';
export const GET_ALL_SERVICE_SAGA = 'GET_ALL_SERVICE_SAGA';
export const GET_ALL_SERVICE_SUCCESS = 'GET_ALL_SERVICE_SUCCESS';
export const GET_ALL_SERVICE_FAIL = 'GET_ALL_SERVICE_FAIL';

// Get Service by id
export const GET_SERVICE_BY_ID_START = 'GET_SERVICE_BY_ID_START';
export const GET_SERVICE_BY_ID_SAGA = 'GET_SERVICE_BY_ID_SAGA';
export const GET_SERVICE_BY_ID_SUCCESS = 'GET_SERVICE_BY_ID_SUCCESS';
export const GET_SERVICE_BY_ID_FAIL = 'GET_SERVICE_BY_ID_FAIL';

// Add Service
export const ADD_SERVICE_START = 'ADD_SERVICE_START';
export const ADD_SERVICE_SAGA = 'ADD_SERVICE_SAGA';
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS';
export const ADD_SERVICE_FAIL = 'ADD_SERVICE_FAIL';

// Edit Service
export const EDIT_SERVICE_START = 'EDIT_SERVICE_START';
export const EDIT_SERVICE_SAGA = 'EDIT_SERVICE_SAGA';
export const EDIT_SERVICE_SUCCESS = 'EDIT_SERVICE_SUCCESS';
export const EDIT_SERVICE_FAIL = 'EDIT_SERVICE_FAIL';

// Block Unblock
export const EDIT_BLOCK_SERVICE_STATUS_START =
  'EDIT_BLOCK_SERVICE_STATUS_START';
export const EDIT_BLOCK_SERVICE_STATUS_SAGA = 'EDIT_BLOCK_SERVICE_STATUS_SAGA';
export const EDIT_BLOCK_SERVICE_STATUS_SUCCESS =
  'EDIT_BLOCK_SERVICE_STATUS_SUCCESS';
export const EDIT_BLOCK_SERVICE_STATUS_FAIL = 'EDIT_BLOCK_SERVICE_STATUS_FAIL';

// Delete Service
export const DELETE_SERVICE_START = 'DELETE_SERVICE_START';
export const DELETE_SERVICE_SAGA = 'DELETE_SERVICE_SAGA';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL';
