import * as actionLabels from '../../actions/actionLabels';

const initialState = {
  sidebarShow: 'responsive',
};

export default (state = initialState, { type }) => {
  switch (type) {
    case actionLabels.SET:
      return { ...state };
    default:
      return state;
  }
};
