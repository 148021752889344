import {
  GET_ALL_DRIVER_START,
  GET_ALL_DRIVER_SAGA,
  GET_ALL_DRIVER_SUCCESS,
  GET_ALL_DRIVER_FAIL,
  GET_DRIVER_BY_ID_START,
  GET_DRIVER_BY_ID_SAGA,
  GET_DRIVER_BY_ID_SUCCESS,
  GET_DRIVER_BY_ID_FAIL,
  RESET_DRIVER_LIST,
  RESET_DRIVER_DETAILS,
  EDIT_BLOCK_DRIVER_STATUS_START,
  EDIT_BLOCK_DRIVER_STATUS_SAGA,
  EDIT_BLOCK_DRIVER_STATUS_SUCCESS,
  EDIT_BLOCK_DRIVER_STATUS_FAIL,
  ADD_DRIVER_START,
  ADD_DRIVER_SAGA,
  ADD_DRIVER_SUCCESS,
  ADD_DRIVER_FAIL,
  PROMOTER_ADD_DRIVER_SAGA,
  PROMOTER_EDIT_DRIVER_SAGA,
  PROMOTER_DRIVER_DETAILS_SAGA,
  EDIT_DRIVER_START,
  EDIT_DRIVER_SAGA,
  EDIT_DRIVER_SUCCESS,
  EDIT_DRIVER_FAIL,
  DRIVER_APPROVE_REJECT_START,
  DRIVER_APPROVE_REJECT_SAGA,
  DRIVER_APPROVE_REJECT_SUCCESS,
  DRIVER_APPROVE_REJECT_FAIL,
  EDIT_DRIVER_COMMISSION_START,
  EDIT_DRIVER_COMMISSION_SAGA,
  EDIT_DRIVER_COMMISSION_SUCCESS,
  EDIT_DRIVER_COMMISSION_FAIL,
  EDIT_DRIVER_SERVICE_ASSIGNED_START,
  EDIT_DRIVER_SERVICE_ASSIGNED_SAGA,
  EDIT_DRIVER_SERVICE_ASSIGNED_SUCCESS,
  EDIT_DRIVER_SERVICE_ASSIGNED_FAIL,
  ADD_OWNER_IN_DRIVER_START,
  ADD_OWNER_IN_DRIVER_SAGA,
  ADD_OWNER_IN_DRIVER_SUCCESS,
  ADD_OWNER_IN_DRIVER_FAIL,
  GET_AVAILABLE_DRIVERS_TO_ASSIGN_VEHICLE_SAGA,
  ASSIGN_VEHICLE_TO_A_DRIVER_SAGA,
  CLEAR_AVAILABLE_DRIVER_LIST,
  DETAILS_OWNER_DRIVER_START,
  DETAILS_OWNER_DRIVER_SAGA,
  DETAILS_OWNER_DRIVER_SUCCESS,
  DETAILS_OWNER_DRIVER_FAIL,
  PROMOTER_OWNERS_VEHICLE_START,
  PROMOTER_OWNERS_VEHICLE_SAGA,
  PROMOTER_OWNERS_VEHICLE_SUCCESS,
  PROMOTER_OWNERS_VEHICLE_FAIL,
} from '../actionLabels';

export const resetDriverList = () => ({
  type: RESET_DRIVER_LIST,
});

export const resetDriverDetails = () => ({
  type: RESET_DRIVER_DETAILS,
});

export const getAllDriverStart = payload => ({
  type: GET_ALL_DRIVER_START,
  payload,
});

export const getAllDriverSaga = payload => ({
  type: GET_ALL_DRIVER_SAGA,
  payload,
});

export const getAllDriverSuccess = payload => ({
  type: GET_ALL_DRIVER_SUCCESS,
  payload,
});

export const getAllDriverFail = payload => ({
  type: GET_ALL_DRIVER_FAIL,
  payload,
});

// Get Driver Details
export const getDriverByIdStart = () => ({
  type: GET_DRIVER_BY_ID_START,
});

export const getDriverByIdSaga = payload => ({
  type: GET_DRIVER_BY_ID_SAGA,
  payload,
});

export const getDriverByIdSuccess = payload => ({
  type: GET_DRIVER_BY_ID_SUCCESS,
  payload,
});

export const getDriverByIdFail = payload => ({
  type: GET_DRIVER_BY_ID_FAIL,
  payload,
});

// Add Driver Start
export const addDriverStart = () => ({
  type: ADD_DRIVER_START,
});

export const addDriverSaga = payload => ({
  type: ADD_DRIVER_SAGA,
  payload,
});

export const addDriverSuccess = payload => ({
  type: ADD_DRIVER_SUCCESS,
  payload,
});

export const addDriverFail = payload => ({
  type: ADD_DRIVER_FAIL,
  payload,
});
// Add Driver End

// Edit Driver Start
export const editDriverStart = () => ({
  type: EDIT_DRIVER_START,
});

export const editDriverSaga = payload => ({
  type: EDIT_DRIVER_SAGA,
  payload,
});

export const editDriverSuccess = payload => ({
  type: EDIT_DRIVER_SUCCESS,
  payload,
});

export const editDriverFail = payload => ({
  type: EDIT_DRIVER_FAIL,
  payload,
});
// Edit Driver End

// Block Driver status Start
export const editBlockDriverStatusStart = () => ({
  type: EDIT_BLOCK_DRIVER_STATUS_START,
});

export const editBlockDriverStatusSaga = payload => ({
  type: EDIT_BLOCK_DRIVER_STATUS_SAGA,
  payload,
});

export const editBlockDriverStatusSuccess = payload => ({
  type: EDIT_BLOCK_DRIVER_STATUS_SUCCESS,
  payload,
});

export const editBlockDriverStatusFail = payload => ({
  type: EDIT_BLOCK_DRIVER_STATUS_FAIL,
  payload,
});
// Block Driver status End

// Promoter functionalities

export const promoterAddDriverSaga = payload => ({
  type: PROMOTER_ADD_DRIVER_SAGA,
  payload,
});

export const promoterEditDriverSaga = payload => ({
  type: PROMOTER_EDIT_DRIVER_SAGA,
  payload,
});

export const promoterDriverDetailsSaga = payload => ({
  type: PROMOTER_DRIVER_DETAILS_SAGA,
  payload,
});

export const driverApproveRejectStart = payload => ({
  type: DRIVER_APPROVE_REJECT_START,
  payload,
});

export const driverApproveRejectSaga = payload => ({
  type: DRIVER_APPROVE_REJECT_SAGA,
  payload,
});

export const driverApproveRejectSuccess = payload => ({
  type: DRIVER_APPROVE_REJECT_SUCCESS,
  payload,
});

export const driverApproveRejectFail = payload => ({
  type: DRIVER_APPROVE_REJECT_FAIL,
  payload,
});

// Driver Commission Start
export const editDriverCommissionStart = payload => ({
  type: EDIT_DRIVER_COMMISSION_START,
  payload,
});

export const editDriverCommissionSaga = payload => ({
  type: EDIT_DRIVER_COMMISSION_SAGA,
  payload,
});

export const editDriverCommissionSuccess = payload => ({
  type: EDIT_DRIVER_COMMISSION_SUCCESS,
  payload,
});

export const editDriverCommissionFail = payload => ({
  type: EDIT_DRIVER_COMMISSION_FAIL,
  payload,
});
// Driver Commission End

// Driver Service assigned start
export const editDriverServiceAssignedStart = payload => ({
  type: EDIT_DRIVER_SERVICE_ASSIGNED_START,
  payload,
});

export const editDriverServiceAssignedSaga = payload => ({
  type: EDIT_DRIVER_SERVICE_ASSIGNED_SAGA,
  payload,
});

export const editDriverServiceAssignedSuccess = payload => ({
  type: EDIT_DRIVER_SERVICE_ASSIGNED_SUCCESS,
  payload,
});

export const editDriverServiceAssignedFail = payload => ({
  type: EDIT_DRIVER_SERVICE_ASSIGNED_FAIL,
  payload,
});
// Driver Service assigned end

// Add Vehicle Owner From Admin in Driver List Screen start
export const addOwnerInDriverStart = payload => ({
  type: ADD_OWNER_IN_DRIVER_START,
  payload,
});

export const addOwnerInDriverSaga = payload => ({
  type: ADD_OWNER_IN_DRIVER_SAGA,
  payload,
});

export const addOwnerInDriverSuccess = payload => ({
  type: ADD_OWNER_IN_DRIVER_SUCCESS,
  payload,
});

export const addOwnerInDriverFail = payload => ({
  type: ADD_OWNER_IN_DRIVER_FAIL,
  payload,
});
// Add Vehicle Owner From Admin in Driver List Screen end

// Get Driver To Assign Vehicle From Admin Side
export const getAvailableDriverToAssignVehicleSaga = payload => ({
  type: GET_AVAILABLE_DRIVERS_TO_ASSIGN_VEHICLE_SAGA,
  payload,
});

// Clear Available Drivers List
export const clearAvaiableDriverList = payload => ({
  type: CLEAR_AVAILABLE_DRIVER_LIST,
  payload,
});

// Assign Driver To Vehicle From Admin Side
export const assignVehicleToADriverSaga = payload => ({
  type: ASSIGN_VEHICLE_TO_A_DRIVER_SAGA,
  payload,
});

// Delete Owner's Driver 
export const getDetailsOwnerDriverStart = payload => ({
  type: DETAILS_OWNER_DRIVER_START,
  payload,
});

export const getDetailsOwnerDriverSaga = payload => ({
  type: DETAILS_OWNER_DRIVER_SAGA,
  payload,
});

export const getDetailsOwnerDriverSuccess = payload => ({
  type: DETAILS_OWNER_DRIVER_SUCCESS,
  payload,
});

export const getDetailsOwnerDriverFail = payload => ({
  type: DETAILS_OWNER_DRIVER_FAIL,
  payload,
});
export const promoterOwnersVehicleStart = () => ({
  type: PROMOTER_OWNERS_VEHICLE_START,
});

export const promoterOwnersVehicleSaga = payload => ({
  type: PROMOTER_OWNERS_VEHICLE_SAGA,
  payload,
});

export const promoterOwnersVehicleSuccess = payload => ({
  type: PROMOTER_OWNERS_VEHICLE_SUCCESS,
  payload,
});

export const promoterOwnersVehicleFail = payload => ({
  type: PROMOTER_OWNERS_VEHICLE_FAIL,
  payload,
});