import {
  ADD_VEHICLE_OWNER_FAIL,
  ADD_VEHICLE_OWNER_SAGA,
  ADD_VEHICLE_OWNER_START,
  ADD_VEHICLE_OWNER_SUCCESS,
  GET_ALL_VEHICLE_OWNERS_FAIL,
  GET_ALL_VEHICLE_OWNERS_SAGA,
  GET_ALL_VEHICLE_OWNERS_START,
  GET_ALL_VEHICLE_OWNERS_SUCCESS,
  EDIT_BLOCK_VEHICLE_OWNER_STATUS_START,
  EDIT_BLOCK_VEHICLE_OWNER_STATUS_SAGA,
  EDIT_BLOCK_VEHICLE_OWNER_STATUS_SUCCESS,
  EDIT_BLOCK_VEHICLE_OWNER_STATUS_FAIL,
  GET_VEHICLE_OWNER_BY_ID_START,
  GET_VEHICLE_OWNER_BY_ID_SAGA,
  GET_VEHICLE_OWNER_BY_ID_SUCCESS,
  GET_VEHICLE_OWNER_BY_ID_FAIL,
  RESET_VEHICLE_OWNER_DETAIL,
  EDIT_VEHICLE_OWNER_COMMISSION_START,
  EDIT_VEHICLE_OWNER_COMMISSION_SAGA,
  EDIT_VEHICLE_OWNER_COMMISSION_SUCCESS,
  EDIT_VEHICLE_OWNER_COMMISSION_FAIL,
  EDIT_VEHICLE_OWNER_SAGA,
  EDIT_VEHICLE_OWNER_START,
  EDIT_VEHICLE_OWNER_SUCCESS,
  EDIT_VEHICLE_OWNER_FAIL,
  DELETE_VEHICLE_OWNER_START,
  DELETE_VEHICLE_OWNER_SUCCESS,
  DELETE_VEHICLE_OWNER_SAGA,
  DELETE_VEHICLE_OWNER_FAIL,
  GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_START,
  GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_SAGA,
  GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_SUCCESS,
  GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_FAIL,
  GET_VEHICLE_OWNER_OF_PROMOTER_START,
  GET_VEHICLE_OWNER_OF_PROMOTER_SAGA,
  GET_VEHICLE_OWNER_OF_PROMOTER_FAIL,
  GET_VEHICLE_OWNER_OF_PROMOTER_SUCCESS,
  ADD_VEHICLE_OWNER_OF_PROMOTER_START,
  ADD_VEHICLE_OWNER_OF_PROMOTER_SAGA,
  ADD_VEHICLE_OWNER_OF_PROMOTER_SUCCESS,
  ADD_VEHICLE_OWNER_OF_PROMOTER_FAIL,
  EDIT_VEHICLE_OWNER_OF_PROMOTER_START,
  EDIT_VEHICLE_OWNER_OF_PROMOTER_SAGA,
  EDIT_VEHICLE_OWNER_OF_PROMOTER_SUCCESS,
  EDIT_VEHICLE_OWNER_OF_PROMOTER_FAIL,
  DELETE_VEHICLE_OWNER_OF_PROMOTER_START,
  DELETE_VEHICLE_OWNER_OF_PROMOTER_SAGA,
  DELETE_VEHICLE_OWNER_OF_PROMOTER_SUCCESS,
  DELETE_VEHICLE_OWNER_OF_PROMOTER_FAIL,
} from '../actionLabels';

// Reset Vehicle Owner Detail
export const resetVehicleOwnerDetail = payload => ({
  type: RESET_VEHICLE_OWNER_DETAIL,
  payload,
});

export const getAllVehicleOwnersStart = payload => ({
  type: GET_ALL_VEHICLE_OWNERS_START,
  payload,
});

export const getAllVehicleOwnersSaga = payload => ({
  type: GET_ALL_VEHICLE_OWNERS_SAGA,
  payload,
});

export const getAllVehicleOwnersSuccess = payload => ({
  type: GET_ALL_VEHICLE_OWNERS_SUCCESS,
  payload,
});

export const getAllVehicleOwnersFail = payload => ({
  type: GET_ALL_VEHICLE_OWNERS_FAIL,
  payload,
});

// Get Vehicle owner by id
export const getVehicleOwnerByIdStart = payload => ({
  type: GET_VEHICLE_OWNER_BY_ID_START,
  payload,
});

export const getVehicleOwnerByIdSaga = payload => ({
  type: GET_VEHICLE_OWNER_BY_ID_SAGA,
  payload,
});

export const getVehicleOwnerByIdSuccess = payload => ({
  type: GET_VEHICLE_OWNER_BY_ID_SUCCESS,
  payload,
});

export const getVehicleOwnerByIdFail = payload => ({
  type: GET_VEHICLE_OWNER_BY_ID_FAIL,
  payload,
});
// Get vehicle owner by id

// Add VehicleOwmer Start
export const addVehicleOwnerStart = () => ({
  type: ADD_VEHICLE_OWNER_START,
});

export const addVehicleOwnerSaga = payload => ({
  type: ADD_VEHICLE_OWNER_SAGA,
  payload,
});

export const addVehicleOwnerSuccess = payload => ({
  type: ADD_VEHICLE_OWNER_SUCCESS,
  payload,
});

export const addVehicleOwnerFail = payload => ({
  type: ADD_VEHICLE_OWNER_FAIL,
  payload,
});
// Add VehicleOwner End

// Block VehicleOwner status Start
export const editBlockVehicleOwnerStatusStart = () => ({
  type: EDIT_BLOCK_VEHICLE_OWNER_STATUS_START,
});

export const editBlockVehicleOwnerStatusSaga = payload => ({
  type: EDIT_BLOCK_VEHICLE_OWNER_STATUS_SAGA,
  payload,
});

export const editBlockVehicleOwnerStatusSuccess = payload => ({
  type: EDIT_BLOCK_VEHICLE_OWNER_STATUS_SUCCESS,
  payload,
});

export const editBlockVehicleOwnerStatusFail = payload => ({
  type: EDIT_BLOCK_VEHICLE_OWNER_STATUS_FAIL,
  payload,
});
// Block VehicleOwner status End

// Edit Vehicle Owner Commission Start
export const editVehicleOwnerCommissionStart = payload => ({
  type: EDIT_VEHICLE_OWNER_COMMISSION_START,
  payload,
});

export const editVehicleOwnerCommissionSaga = payload => ({
  type: EDIT_VEHICLE_OWNER_COMMISSION_SAGA,
  payload,
});

export const editVehicleOwnerCommissionSuccess = payload => ({
  type: EDIT_VEHICLE_OWNER_COMMISSION_SUCCESS,
  payload,
});

export const editVehicleOwnerCommissionFail = payload => ({
  type: EDIT_VEHICLE_OWNER_COMMISSION_FAIL,
  payload,
});
// Edit Vehicle Owner Commission End

// Edit VehicleOwner Start
export const editVehicleOwnerStart = () => ({
  type: EDIT_VEHICLE_OWNER_START,
});

export const editVehicleOwnerSaga = payload => ({
  type: EDIT_VEHICLE_OWNER_SAGA,
  payload,
});

export const editVehicleOwnerSuccess = payload => ({
  type: EDIT_VEHICLE_OWNER_SUCCESS,
  payload,
});

export const editVehicleOwnerFail = payload => ({
  type: EDIT_VEHICLE_OWNER_FAIL,
  payload,
});
// Edit VehicleOwner End

// Delete VehicleOwner Start
export const deleteVehicleOwnerStart = () => ({
  type: DELETE_VEHICLE_OWNER_START,
});

export const deleteVehicleOwnerSaga = payload => ({
  type: DELETE_VEHICLE_OWNER_SAGA,
  payload,
});

export const deleteVehicleOwnerSuccess = payload => ({
  type: DELETE_VEHICLE_OWNER_SUCCESS,
  payload,
});

export const deleteVehicleOwnerFail = payload => ({
  type: DELETE_VEHICLE_OWNER_FAIL,
  payload,
});
// Delete VehicleOwner End

// Promoter Login Functions
// 1 Get All Vehicle Owners Added By Promoters
export const getAllVehicleOwnersOfPromoterStart = payload => ({
  type: GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_START,
  payload,
});

export const getAllVehicleOwnersOfPromoterSaga = payload => ({
  type: GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_SAGA,
  payload,
});

export const getAllVehicleOwnersOfPromoterSuccess = payload => ({
  type: GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_SUCCESS,
  payload,
});

export const getAllVehicleOwnersOfPromoterFail = payload => ({
  type: GET_ALL_VEHICLE_OWNERS_OF_PROMOTER_FAIL,
  payload,
});

// 2. Get Single Vehicle Owner
export const getVehicleOwnerOfPromoterStart = payload => ({
  type: GET_VEHICLE_OWNER_OF_PROMOTER_START,
  payload,
});

export const getVehicleOwnerOfPromoterSaga = payload => ({
  type: GET_VEHICLE_OWNER_OF_PROMOTER_SAGA,
  payload,
});

export const getVehicleOwnerOfPromoterSuccess = payload => ({
  type: GET_VEHICLE_OWNER_OF_PROMOTER_SUCCESS,
  payload,
});

export const getVehicleOwnerOfPromoterFail = payload => ({
  type: GET_VEHICLE_OWNER_OF_PROMOTER_FAIL,
  payload,
});

// 3. Add Vehicle Owner
export const addVehicleOwnerOfPromoterStart = () => ({
  type: ADD_VEHICLE_OWNER_OF_PROMOTER_START,
});

export const addVehicleOwnerOfPromoterSaga = payload => ({
  type: ADD_VEHICLE_OWNER_OF_PROMOTER_SAGA,
  payload,
});

export const addVehicleOwnerOfPromoterSuccess = payload => ({
  type: ADD_VEHICLE_OWNER_OF_PROMOTER_SUCCESS,
  payload,
});

export const addVehicleOwnerOfPromoterFail = payload => ({
  type: ADD_VEHICLE_OWNER_OF_PROMOTER_FAIL,
  payload,
});

// 4 Edit VehicleOwner Start
export const editVehicleOwnerOfPromoterStart = () => ({
  type: EDIT_VEHICLE_OWNER_OF_PROMOTER_START,
});

export const editVehicleOwnerOfPromoterSaga = payload => ({
  type: EDIT_VEHICLE_OWNER_OF_PROMOTER_SAGA,
  payload,
});

export const editVehicleOwnerOfPromoterSuccess = payload => ({
  type: EDIT_VEHICLE_OWNER_OF_PROMOTER_SUCCESS,
  payload,
});

export const editVehicleOwnerOfPromoterFail = payload => ({
  type: EDIT_VEHICLE_OWNER_OF_PROMOTER_FAIL,
  payload,
});

// 5 Delete VehicleOwner Start
export const deleteVehicleOwnerOfPromoterStart = () => ({
  type: DELETE_VEHICLE_OWNER_OF_PROMOTER_START,
});

export const deleteVehicleOwnerOfPromoterSaga = payload => ({
  type: DELETE_VEHICLE_OWNER_OF_PROMOTER_SAGA,
  payload,
});

export const deleteVehicleOwnerOfPromoterSuccess = payload => ({
  type: DELETE_VEHICLE_OWNER_OF_PROMOTER_SUCCESS,
  payload,
});

export const deleteVehicleOwnerOfPromoterFail = payload => ({
  type: DELETE_VEHICLE_OWNER_OF_PROMOTER_FAIL,
  payload,
});
