import { put } from 'redux-saga/effects';
import {
  getAllInquiriesStart,
  getAllInquiriesSaga as getAllInquiriesSagaAction,
  getAllInquiriesSuccess,
  getAllInquiriesFail,
  editMarkAsApprovedStart,
  editMarkAsApprovedSuccess,
  editMarkAsApprovedFail,
  editMarkAsRejectedStart,
  editMarkAsRejectedSuccess,
  editMarkAsRejectedFail,
  logout,
  showModal,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllInquiriesSaga(action) {
  yield put(getAllInquiriesStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getAllInquiryList`, action.payload),
      // urlPaginationGenerator(`/admin/getAllContactUsList`, action.payload),
    );
    if (response.status === 200) {
      yield put(getAllInquiriesSuccess({ inquiries: response.data.data }));
    } else {
      yield put(getAllInquiriesFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllInquiriesFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllInquiriesFail(error.response.data.message));
      } else {
        yield put(getAllInquiriesFail('Server error! Please try again.'));
      }
    } else {
      yield put(getAllInquiriesFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editMarkAsApprovedSaga(action) {
  yield put(editMarkAsApprovedStart());
  try {
    const { tableFilter, id, status } = action.payload;
    const reqBody = {
      id,
      status,
    };
    const response = yield axios.post(
      `/admin/pendingOrApproveInquiry`,
      reqBody,
    );
    if (response.status === 200) {
      yield put(editMarkAsApprovedSuccess({ inquiries: response.data.data }));
      yield put(getAllInquiriesSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
    } else {
      yield put(
        editMarkAsApprovedFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editMarkAsApprovedFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editMarkAsApprovedFail(error.response.data.message));
      } else {
        yield put(editMarkAsApprovedFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editMarkAsApprovedFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* editMarkAsRejectedSaga(action) {
  yield put(editMarkAsRejectedStart());
  const { setIsDisable } = action.payload;
  try {
    const { closeModel, tableFilter } = action.payload;
    const reqBody = {
      id: action.payload.isRejectedId,
      reason: action.payload.reason,
    };
    const response = yield axios.post(`/admin/rejectInquiry`, reqBody);
    if (response.status === 200) {
      yield put(editMarkAsRejectedSuccess({ inquiries: response.data.data }));
      closeModel();
      yield put(getAllInquiriesSagaAction(tableFilter));
      yield put(
        showModal({
          open: true,
          notifyType: 2,
          message: response.data.message || 'Success',
        }),
      );
      setIsDisable(false);
    } else {
      yield put(
        editMarkAsRejectedFail('Something went wrong! Please try again.'),
      );
      setIsDisable(false);
    }
  } catch (error) {
    setIsDisable(false);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editMarkAsRejectedFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editMarkAsRejectedFail(error.response.data.message));
      } else {
        yield put(editMarkAsRejectedFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        editMarkAsRejectedFail('Something went wrong! Please try again.'),
      );
    }
  }
}
