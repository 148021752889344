/* eslint-disable no-multi-assign */
/* eslint-disable no-case-declarations */
import * as actionLabels from '../../actions/actionLabels';

const intialState = {
  logistics: { list: [], totalRecords: { count: 0 } },
  logistic: {},
  logisticOrders: { list: [], totalRecords: { count: 0 } },
  logisticEmployees: { list: [], totalRecords: { count: 0 } },
  supportRequests: { list: [], totalRecords: { count: 0 } },
  vehicleList: { list: [], totalRecords: { count: 0 } },
  payoutHistory: { list: [], totalRecords: { count: 0 } },
  orderList: { list: [], totalRecords: { count: 0 } },
  isLoading: false,
  location: {
    latitude: 0,
    longitude: 0,
  },
  destinylist:{list:[], totalRecords: { count: 0 }},
  latitudeFromApi: 0,
  longitudeFromApi: 0,
  errorMsg: '',
  isMapLoading: false,
  isApproved: null,
  isProfileSubmitted: null,
};

export default (state = intialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_ALL_LOGISTIC_OF_PROMOTER_START:
      return { ...state, isLoading: true };

    case actionLabels.GET_ALL_LOGISTIC_OF_PROMOTER_SUCCESS:
      console.log("reducer logistics", payload)
      const p = {
        list: payload.list,
        totalRecords: { count: payload.totalRecords.count },
      }
      return { ...state, logistics: p, isLoading: false, errorMsg: '' };

    case actionLabels.GET_ALL_LOGISTIC_OF_PROMOTER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.LOGISTICS_DETAILS_START:
      return { ...state, isLoading: true };

    case actionLabels.LOGISTICS_DETAILS_SUCCESS:
      const newData = payload
      return { ...state, logistic: newData, isLoading: false, errorMsg: '' };

    case actionLabels.LOGISTICS_DETAILS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.ARRIVED_DATA_TO_RECEPTION_START:
      return { ...state, isLoading: true };

    case actionLabels.ARRIVED_DATA_TO_RECEPTION_SUCCESS:
      console.log({ state })
      console.log({ payload })
      // const deletedData = state.logistics.list.filter((value) => value._id !== payload)
      // const deletedCount = state.logistics.totalRecords.count -= 1
      // console.log(deletedData);
      // const p1 = {
      //   list: deletedData,
      //   totalRecords: { count: deletedCount },
      // }
      return { ...state, isLoading: false, errorMsg: '' };

    case actionLabels.ARRIVED_DATA_TO_RECEPTION_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.GET_ALL_VEHICLE_LIST_START:
      return { ...state, isLoading: true };

    case actionLabels.GET_ALL_VEHICLE_LIST_SUCCESS:
      console.log(payload.list)
      const vehiclesList = {
        list: payload.list,
        totalRecords: { count: payload.totalRecords.count },
      }
      return { ...state, vehicleList: vehiclesList, isLoading: false, errorMsg: "" };

    case actionLabels.GET_ALL_VEHICLE_LIST_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.GET_ALL_LOGISTIC_DETAIL_START:
      return { ...state, isLoading: true };

    case actionLabels.GET_ALL_LOGISTIC_DETAIL_SUCCESS:
      console.log(payload)
      console.log(state)
      const logistiCDetail = {
        list: payload[0].list,
        totalRecords: { count: payload[0].totalRecords.count },
      }
      return { ...state, logistics: logistiCDetail, isLoading: false, errorMsg: "" };

    case actionLabels.GET_ALL_LOGISTIC_DETAIL_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.SEND_PRODUCT_TO_LOGISTIC_START:
      return { ...state, isLoading: true };

    case actionLabels.SEND_PRODUCT_TO_LOGISTIC_SUCCESS:
      return { ...state, isLoading: false, errorMsg: "" };

    case actionLabels.SEND_PRODUCT_TO_LOGISTIC_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.ADD_SUPPORT_REQUEST_LOGISTICS_START:
      return { ...state, isLoading: true };

    case actionLabels.ADD_SUPPORT_REQUEST_LOGISTICS_SUCCESS:
      return { ...state, isLoading: false, errorMsg: '' };

    case actionLabels.ADD_SUPPORT_REQUEST_LOGISTICS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.APPROVE_REJECT_LOGISTICS_START:
      return { ...state, isLoading: true };

    case actionLabels.APPROVE_REJECT_LOGISTICS_SUCCESS:
      return { ...state, isLoading: false, errorMsg: '' };

    case actionLabels.APPROVE_REJECT_LOGISTICS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.GET_ALL_SUPPORT_REQUEST_LOGISTICS_START:
      return { ...state, isLoading: true };

    case actionLabels.GET_ALL_SUPPORT_REQUEST_LOGISTICS_SUCCESS:
      const data = {
        list: payload.list,
        totalRecords: { count: payload.totalRecords.count },
      }
      return { ...state, supportRequests: data, isLoading: false, errorMsg: "" };

    case actionLabels.GET_ALL_SUPPORT_REQUEST_LOGISTICS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.GET_ALL_LOGISTICS_PAYOUT_HISTORY_START:
      return { ...state, isLoading: true };

    case actionLabels.GET_ALL_LOGISTICS_PAYOUT_HISTORY_SUCCESS:
      const payoutHistoryData = {
        list: payload.list,
        totalRecords: { count: payload.totalRecords.count },
      }
      return { ...state, payoutHistory: payoutHistoryData, isLoading: false, errorMsg: "" };

    case actionLabels.GET_ALL_LOGISTICS_PAYOUT_HISTORY_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.GET_ALL_ORDER_LIST_START:
      return { ...state, isLoading: true };

    case actionLabels.GET_ALL_ORDER_LIST_SUCCESS:
      const orderListData = {
        list: payload.list,
        totalRecords: { count: payload.totalRecords },
      }
      return { ...state, orderList: orderListData, isLoading: false, errorMsg: "" };

    case actionLabels.GET_ALL_ORDER_LIST_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.GET_ALL_DRIVER_ORDER_LIST_START:
      return { ...state, isLoading: true };

    case actionLabels.GET_ALL_DRIVER_ORDER_LIST_SUCCESS:
      const driverOrderListData = {
        list: payload.list,
        totalRecords: { count: payload.totalRecords.count },
      }
      return { ...state, orderList: driverOrderListData, isLoading: false, errorMsg: "" };

    case actionLabels.GET_ALL_DRIVER_ORDER_LIST_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.GET_RECEPTION_LIST_START:
      return { ...state, isLoading: true };

    case actionLabels.GET_RECEPTION_LIST_SUCCESS:
      const receptionListData = {
        list: payload.list,
        totalRecords: { count: payload.totalRecords.count },
      }
      return { ...state, orderList: receptionListData, isLoading: false, errorMsg: payload.message };

    case actionLabels.GET_RECEPTION_LIST_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.EDIT_BLOCK_LOGISTIC_STATUS_START:
      return { ...state, isLoading: true }

    case actionLabels.EDIT_BLOCK_LOGISTIC_STATUS_SUCCESS:
      console.log({ payload })
      if (payload.isDetail !== true) {
        console.log({ state })
        const { logistics } = state;
        const index = logistics.list.findIndex(item => item._id === payload.id);
        console.log({ index })
        if (index !== -1 && logistics.list.length > index) {
          logistics.list[index].isBlock = !logistics.list[index].isBlock;
        }
        return { ...state, logistics, isLoading: false, errorMsg: '' };
      }
      return { ...state, isLoading: true, errorMsg: payload }

    case actionLabels.EDIT_BLOCK_LOGISTIC_STATUS_FAIL:
      return { ...state, isLoading: true, errorMsg: payload }

    case actionLabels.DELETE_LOGISTIC_OF_PROMOTER_START:
      return { ...state, isLoading: true };

    case actionLabels.DELETE_LOGISTIC_OF_PROMOTER_SUCCESS:
      console.log("delete reducer logistics", payload);
      console.log("delete reducer logistics", state);
      const deletedData = state.logistics.list.filter((value) => value._id !== payload)
      const deletedCount = state.logistics.totalRecords.count -= 1
      console.log(deletedData);
      const p1 = {
        list: deletedData,
        totalRecords: { count: deletedCount },
      }
      return { ...state, logistics: p1, isLoading: false, errorMsg: '' };

    case actionLabels.DELETE_LOGISTIC_OF_PROMOTER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.EDIT_MEDIA_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_MEDIA_SUCCESS: {
      const { media } = payload;
      // media.splice(payload.index, 0, payload.media);
      const chef = {
        ...state.chef,
        media,
      };
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_MEDIA_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };
    case actionLabels.DELETE_MEDIA_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_MEDIA_SUCCESS: {
      const { media } = state.chef;
      media.splice(payload.index, 1);
      const chef = {
        ...state.chef,
        media,
      };
      return { ...state, chef, isLoading: false, errorMsg: '' };
    }
    case actionLabels.DELETE_MEDIA_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };



    case actionLabels.EDIT_LOGISTIC_DETAIL_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_LOGISTIC_DETAIL_SUCCESS: {
      console.log(payload)
      console.log(state)
      const logistics = {
        ...state.logistics,
        ...payload,
        brandTypeIds: payload.brandType,
        lat: payload.latitude,
        long: payload.longitude,
      };
      return { ...state, logistic: logistics, isLoading: false, errorMsg: '' };
    }
    case actionLabels.EDIT_LOGISTIC_DETAIL_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };



    case actionLabels.GET_ALL_LOGISTIC_ORDER_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_LOGISTIC_ORDER_SUCCESS: {
      const orders = {
        list: payload.list,
        totalRecords: { count: payload.totalRecords.count },
      }
      return { ...state, logisticOrders: orders, isLoading: true };
    }
    case actionLabels.GET_ALL_LOGISTIC_ORDER_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.GET_ORDER_DETAIL_BY_ID_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ORDER_DETAIL_BY_ID_SUCCESS: {
      return { ...state, logistic: payload, isLoading: true };
    }
    case actionLabels.GET_ORDER_DETAIL_BY_ID_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

    case actionLabels.GET_ALL_EMPLOYEE_DATA_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_ALL_EMPLOYEE_DATA_SUCCESS: {
      console.log(payload)
      const employee = {
        list: payload[0].list,
        totalRecords: { count: payload[0].totalRecords[0].count },
      }
      return { ...state, logisticEmployees: employee, isLoading: true, errorMsg: '' };
    }
    case actionLabels.GET_ALL_EMPLOYEE_DATA_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.EDIT_EMPLOYEE_STATUS_START:
      return { ...state, isLoading: true };
    case actionLabels.EDIT_EMPLOYEE_STATUS_SUCCESS: {
      console.log({ state })
      console.log({ payload })
      const { logisticEmployees } = state;
      const index = logisticEmployees.list.findIndex(item => item._id === payload.data._id);
      if (index !== -1 && logisticEmployees.list.length > index) {
        logisticEmployees.list[index].status = !logisticEmployees.list[index].status;
      }
      return { ...state, logisticEmployees, isLoading: false, errorMsg: '' };
      // return { ...state, isLoading: true, errorMsg: '' };
    }
    case actionLabels.EDIT_EMPLOYEE_STATUS_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };



    case actionLabels.DELETE_LOGISTIC_EMPLOYEE_START:
      return { ...state, isLoading: true };
    case actionLabels.DELETE_LOGISTIC_EMPLOYEE_SUCCESS: {
      console.log({ state })
      console.log({ payload })
      const restData = state.logisticEmployees.list.filter((value) => value._id !== payload._id)
      const restCount = state.logisticEmployees.totalRecords.count -= 1
      console.log(restData);
      const res = {
        list: restData,
        totalRecords: { count: restCount },
      }
      return { ...state, logisticEmployees: res, isLoading: true, errorMsg: '' };
    }
    case actionLabels.DELETE_LOGISTIC_EMPLOYEE_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };


    case actionLabels.GET_LAT_LONG_FOR_LOGISTIC_START:
      return { ...state, isMapLoading: true };
    case actionLabels.GET_LAT_LONG_FOR_LOGISTIC_SUCCESS: {
      const { lat, long } = payload;
      const latitudeFromApi = lat;
      const longitudeFromApi = long;
      return {
        ...state,
        latitudeFromApi,
        longitudeFromApi,
        isMapLoading: false,
      };
    }
    case actionLabels.GET_LAT_LONG_FOR_LOGISTIC_FAIL:
      return { ...state, isLoading: false, errorMsg: payload };

///destiny filter 

case actionLabels.GET_ALL_DESTINY_DATA_START:
  return { ...state, isLoading: true };

case actionLabels.GET_ALL_DESTINY_DATA_SUCCESS:
  console.log(payload[0].list,'qaz')
  const destinylist = {
    list: payload[0].list,
    // totalRecords: { count: payload.totalRecords.count },
  }
  return { ...state, destinylist, isLoading: false, errorMsg: "" };

case actionLabels.GET_ALL_DESTINY_DATA_FAIL:
  return { ...state, isLoading: false, errorMsg: payload };


    default:
      return state;

  }
};


