/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import { put } from 'redux-saga/effects';
import { getReportFail, getReportStart, getReportSuccess } from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getReportSaga(action) {
  yield put(getReportStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(`/admin/getUserReport`, action.payload),
    );
    if (response.status === 200) {
      yield put(
        getReportSuccess({
          reports: response.data.data,
          isCSV: action.payload.isCSV,
        }),
      );
    } else {
      yield put(getReportFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getReportFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getReportFail(error.response.data.message));
      } else {
        yield put(getReportFail('Server error! Please try again.'));
      }
    } else {
      yield put(getReportFail('Something went wrong! Please try again.'));
    }
  }
}
