export const GET_ALL_COMMISSION_LIST_START = 'GET_ALL_COMMISSION_LIST_START';
export const GET_ALL_COMMISSION_LIST_SAGA = 'GET_ALL_COMMISSION_LIST_SAGA';
export const GET_ALL_COMMISSION_LIST_SUCCESS =
  'GET_ALL_COMMISSION_LIST_SUCCESS';
export const GET_ALL_COMMISSION_LIST_FAIL = 'GET_ALL_COMMISSION_LIST_FAIL';

export const RESET_COMMISSION_LIST = 'RESET_COMMISSION_LIST';

export const GET_COMMISSION_DETAIL_START = 'GET_COMMISSION_DETAIL_START';
export const GET_COMMISSION_DETAIL_SAGA = 'GET_COMMISSION_DETAIL_SAGA';
export const GET_COMMISSION_DETAIL_SUCCESS = 'GET_COMMISSION_DETAIL_SUCCESS';
export const GET_COMMISSION_DETAIL_FAIL = 'GET_COMMISSION_DETAIL_FAIL';

export const RESET_COMMISSION_DETAIL = 'RESET_COMMISSION_DETAIL';

export const GET_TOTAL_COMMISSION_START = 'GET_TOTAL_COMMISSION_START';
export const GET_TOTAL_COMMISSION_SAGA = 'GET_TOTAL_COMMISSION_SAGA';
export const GET_TOTAL_COMMISSION_SUCCESS = 'GET_TOTAL_COMMISSION_SUCCESS';
export const GET_TOTAL_COMMISSION_FAIL = 'GET_TOTAL_COMMISSION_FAIL';

export const GET_VENDOR_TOTAL_COMMISSION_START =
  'GET_VENDOR_TOTAL_COMMISSION_START';
export const GET_VENDOR_TOTAL_COMMISSION_SAGA =
  'GET_VENDOR_TOTAL_COMMISSION_SAGA';
export const GET_VENDOR_TOTAL_COMMISSION_SUCCESS =
  'GET_VENDOR_TOTAL_COMMISSION_SUCCESS';
export const GET_VENDOR_TOTAL_COMMISSION_FAIL =
  'GET_VENDOR_TOTAL_COMMISSION_FAIL';
