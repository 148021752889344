import {
  GET_REPORT_FAIL,
  GET_REPORT_SAGA,
  GET_REPORT_START,
  GET_REPORT_SUCCESS,
  RESET_REPORT,
} from '../actionLabels';

export const getReportStart = payload => ({
  type: GET_REPORT_START,
  payload,
});

export const getReportSaga = payload => ({
  type: GET_REPORT_SAGA,
  payload,
});

export const getReportSuccess = payload => ({
  type: GET_REPORT_SUCCESS,
  payload,
});

export const getReportFail = payload => ({
  type: GET_REPORT_FAIL,
  payload,
});

export const resetReport = payload => ({
  type: RESET_REPORT,
  payload,
});
