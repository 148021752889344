import { put } from 'redux-saga/effects';
import {
  getAllNotificationStart,
  getAllNotificationSuccess,
  getAllNotificationFail,
  editMarkAsReadStart,
  editMarkAsReadSuccess,
  editMarkAsReadFail,
  logout,
  getAllNotificationSaga as getAllNotificationSagaAction,
  sendNotificationToVendorStart,
  sendNotificationToVendorSuccess,
  sendNotificationToVendorFail,
} from '../../actions';
import axios from '../../../http/axios/axios_main';
import { urlPaginationGenerator } from '../../../utils/urlGenerator/urlGenerator';

export function* getAllNotificationSaga(action) {
  console.log(action.payload);
  yield put(getAllNotificationStart());
  try {
    const response = yield axios.get(
      urlPaginationGenerator(
        `/chef/getAllChefNotificationsList`,
        action.payload,
      ),
    );
    if (response.status === 200) {
      yield put(
        getAllNotificationSuccess({ notification: response.data.data }),
      );
    } else {
      yield put(
        getAllNotificationFail('Something went wrong! Please try again.'),
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllNotificationFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(getAllNotificationFail(error.response.data.message));
      } else {
        yield put(getAllNotificationFail('Server error! Please try again.'));
      }
    } else {
      yield put(
        getAllNotificationFail('Something went wrong! Please try again.'),
      );
    }
  }
}

export function* editMarkAsReadSaga(action) {
  yield put(editMarkAsReadStart());
  try {
    // const { tableFilter } = action.payload;
    const payload = {
      columnName: 'name',
      orderBy: 'asc',
      search: '',
      skip: 0,
      limit: 10,
    };
    const response = yield axios.post(`/chef/markAsRead`, action.payload);
    if (response.status === 200) {
      yield put(editMarkAsReadSuccess(action.payload));
      // yield put(getAllNotificationSagaAction(tableFilter));
      yield put(getAllNotificationSagaAction(payload));
    } else {
      yield put(editMarkAsReadFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editMarkAsReadFail(error.response.data.message));
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(editMarkAsReadFail(error.response.data.message));
      } else {
        yield put(editMarkAsReadFail('Server error! Please try again.'));
      }
    } else {
      yield put(editMarkAsReadFail('Something went wrong! Please try again.'));
    }
  }
}
export function* sendNotificationToVendorSaga(action) {
  yield put(sendNotificationToVendorStart());
  const { value, toast } = action.payload;
  try {
    // const { tableFilter } = action.payload;
    const payload = {
      columnName: 'name',
      orderBy: 'asc',
      search: '',
      skip: 0,
      limit: 10,
    };
    const response = yield axios.post(`promoter/sendWarningMessagetoVendorForDish`, value);
    if (response.status === 200) {
      yield put(sendNotificationToVendorSuccess(value));
      toast('Message Sent Successfully', {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      });
      // yield put(getAllNotificationSagaAction(tableFilter));
      yield put(getAllNotificationSagaAction(payload));
    } else {
      yield put(sendNotificationToVendorFail('Something went wrong! Please try again.'));
      toast('Something went wrong! Please try again.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      });
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(sendNotificationToVendorFail(error.response.data.message));
        toast(error.response.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      } else if (error.response.status === 401) {
        yield put(logout());
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== '' &&
        typeof error.response.data.message === 'string'
      ) {
        yield put(sendNotificationToVendorFail(error.response.data.message));
      } else {
        yield put(sendNotificationToVendorFail('Server error! Please try again.'));
      }
    } else {
      yield put(sendNotificationToVendorFail('Something went wrong! Please try again.'));
    }
  }
}
